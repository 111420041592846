import { 
    // FETCH_USER,

    FETCH_ADDRESSES_FAILED,
    FETCH_ADDRESSES_SUCCESS,

    FETCH_SIGNERS_FAILED,
    FETCH_SIGNERS_SUCCESS,
    FETCH_SIGNER_FAILED,
    FETCH_SIGNER_SUCCESS,
    FETCH_APPROVALTEMPLATES_FAILED,
    FETCH_APPROVALTEMPLATES_SUCCESS,
    FETCH_APPROVALTEMPLATE_FAILED,
    FETCH_APPROVALTEMPLATE_SUCCESS,
    
} from '../actions/actionTypes';

const INIT_STATE = {
    signer: {},
    signers: [],
    signersInfo: [],
    approval: {},
    approvals: [],
    approvalTemplate: {},
    approvalTemplates: [],
    addresses: [],
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        
        case FETCH_SIGNER_SUCCESS:
            return {
                ...state,
                signer: action.payload || false,
            };
        case FETCH_SIGNER_FAILED:
            return state;
        case FETCH_SIGNERS_SUCCESS:
            return {
                ...state,
                signers: action.payload.signers,
                signersInfo: action.payload.signersInfo,
            };
        case FETCH_SIGNERS_FAILED:
            return {
                ...state,
                signers: action.payload.signers,
                signersInfo: action.payload.signersInfo,
            };
        case FETCH_APPROVALTEMPLATE_SUCCESS:
            return {
                ...state,
                approvalTemplate: action.payload,
            };
        case FETCH_APPROVALTEMPLATE_FAILED:
            return state;
        case FETCH_APPROVALTEMPLATES_SUCCESS:
            return {
                ...state,
                approvalTemplates: action.payload.approvalTemplates,
                approvalTemplate: action.payload.selectedTemplate
            };
        case FETCH_APPROVALTEMPLATES_FAILED:
            return state;
        case FETCH_ADDRESSES_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
            };
        case FETCH_ADDRESSES_FAILED:
            return state;
        default:
            return state;
    }
}
