import { 
    SHOW_PROGRESS_SPINNER,
    HIDE_PROGRESS_SPINNER
} from '../actions/actionTypes';

const INIT_STATE = {
    progress: false,
    dialog: false,
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case SHOW_PROGRESS_SPINNER:
            return {
                ...state,
                progress: true,
            }
        case HIDE_PROGRESS_SPINNER:
            return {
                ...state,
                progress: false,
            }

        default:
            return state;
    }
}
