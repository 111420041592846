import React, { Component } from 'react';
import styled from 'styled-components'

export default class TableRow extends Component {
    handleProductCodeChange = e => {
      this.props.onProductCodeUpdate(this.props.data.item, e.target.value);
    };
    handleProductNameChange = e => {
      this.props.onProductNameUpdate(this.props.data.item, e.target.value);
    };
    handleProductUnitChange = e => {
      this.props.onProductUnitUpdate(this.props.data.item, e.target.value);
    };
    handleProductQuantityChange = e => {
      this.props.onProductQuantityUpdate(this.props.data.item, e.target.value);
    };
    handleProductPriceChange = e => {
      this.props.onProductPriceUpdate(this.props.data.item, e.target.value);
    };
    handleProductCostChange = e => {
      this.props.onProductCostUpdate(this.props.data.item, e.target.value);
    };
  
    render() {
      const {
        data: { 
            item, 
            productCode,
            productName,
            productUnit,
            productQuantity,
            productPrice,
            productCost,
        }
      } = this.props;
  
      return (
        <TR>
          <TD>
            {item}
          </TD>
          <TD>
            <Input type="text" value={productCode} onChange={this.handleProductCodeChange} />
          </TD>
          <TD>
            <Input type="text" value={productName} onChange={this.handleProductNameChange} />
          </TD>
          <TD>
          <Input type="text" value={productUnit} onChange={this.handleProductUnitChange} />
          </TD>
          <TD>
            <Input type="text" value={productQuantity} onChange={this.handleProductQuantityChange} />
          </TD>
          <TD>
            <Input type="text" value={productPrice} onChange={this.handleProductPriceChange} />
          </TD>
          <TD>
            <Input type="text" value={(productPrice && productQuantity) ? parseFloat(productPrice) * parseFloat(productQuantity) : ''} onChange={this.handleProductCostChange} />
          </TD>
          <TD></TD>
        </TR>
      );
    }
  }


const TR = styled.tr`
  border: solid 1px #A1A1A1;
`;

const TH = styled.th.attrs(props => ({
  width: props.width
  }))`
  padding: 7px;
  font-weight: bold;
  text-align: left;
  border-collapse: collapse;
  background-color: #F2F2F2;
  border: solid 1px #A1A1A1;
  width: ${props => props.width || "auto"};
}
`;

const TD = styled.td.attrs(props => ({
  align: props.align
  }))`
  border: solid 1px #A1A1A1;
  text-align: ${props => props.align || "left"};
`;

const Input = styled.input`
  border-width: 0px;
  font-size: 14px;
  height: 30px;
  width: 100%;
  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;