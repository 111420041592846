import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { v1 as uuidv1 } from 'uuid';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import SystemNavigation from '../../components/SystemNavigation';

import {
  createUserPost, updatePasswordPost, resetPasswordPost, createUserGet, updateUserGet, updateUserPost, updateProfileGet
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //userId = this.props.match.params.userId,
      //Header: 60px, Footer: 52px, border included
      height: window.innerHeight - 112,
      //Get ID from user list page.
    //   userId: this.props.location.state.userId,
      user: null,
      company: null,
      redirect: false,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // let requestedId = this.props.userId;
    if (!this.props.currentUser) {
      return this.props.history.push('/views/login');
    } 
    if (this.props.user) {
      this.setState(prevState => ({
        ...prevState,
        user: this.props.user,
      }))
    } else {
      return this.props.history.push('/views/user/list');
    }
    
  }

  addUser = () => {
    let newUser = this.props.user;
    axios.post('http://localhost:3001/api/users', {user: newUser}).then((response) => {
    });
  };

  updateUser = () => {
    let updateUser = this.props.user;
    axios.put('http://localhost:3001/api/users', {user: updateUser}).then((response) => {
    });
  };

  deleteUser = () => {
    let deleteUserId = this.props.user.id;
    axios.delete('http://localhost:3001/api/users', {params: {userId: deleteUserId}}).then((response) => {
    });
  };

  updateUserHandler = () => {
    console.log('redirec to user form to update user profile');
    return this.props.history.push({
      pathname: '/views/user_form',
      // search: '?query=abc',
      state: { user:  this.props.user}
    })
  }

  deleteHandleClick() {
    if(this.props.userId === '0'){
      //nothing to delete
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmBox>
              <h3>Xác nhận</h3>
              <p>Bạn có thực sự muốn xóa người dùng không?</p>
              <ButtonGroup>
                <Button onClick={() => {
                    this.deleteUser();
                    onClose();
                    this.setState({
                      redirect: true
                    });
                  }} backgroundColor="#F2F2F2" color="#303030" marginRight="10px">  
                  Xóa
                </Button>
                <Button onClick={onClose} backgroundColor="#F2F2F2" color="#303030">Không</Button>
              </ButtonGroup>
            </ConfirmBox>
          );
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onClickUpdateUser = () => {
    let newUser = this.props.user;
    let currentUser = this.props.currentUser;
    if (newUser._id.toString() === currentUser._id.toString()) {
      console.log('this is the update of own use');
      this.props.onGetUpdateProfile(newUser);
    }
    this.props.onGetUpdateUser(newUser);
  };

  createNotification = (title,message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/views/UserManagement' />
    }
  }

  renderSignature() {
    if (this.props.user.signature) {
      return (
        <FormBox>
          <Label>{"Digital Signature"}</Label>
          <Input value={this.props.user.signature._id ? this.props.user.signature._id : this.props.user.signature} />
        </FormBox>
      )
    } else {
      return (
        <FormBox>
          <Label>{"Digital Signature"}</Label>
          <CustomLink to='/views/signature/create'>{"Create Digital Signature"}</CustomLink>
        </FormBox>
      )
    }
  }

  render() {
    return (
      <Container>
        {this.renderRedirect()}
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="1" />
          </NavigationContainer>
          <InfoContainer>
            <FormHeader>
              <Title>{"User Profile"}</Title>
              <BackButton to='/views/UserManagement'><LeftArrow />{"Back"}</BackButton>
            </FormHeader>
            <InnerContainer>
              <FormContainer>
                <FormBox>
                  <Label>{"First Name"}</Label>
                  <Input value={this.props.user.firstname ? this.props.user.firstname : 'loading' } />
                </FormBox>
                <FormBox>
                  <Label>{"Last Name"}</Label><Input value={this.props.user.lastname} />
                </FormBox>
                <FormBox>
                  <Label>{"Organization Name"}</Label>
                </FormBox>
                
                <FormBox>
                  <Label>{"Phone"}</Label><Input value={this.props.user.phone}  />
                </FormBox>
                <FormBox>
                  <Label>{"Email"}</Label><Input value={this.props.user.email} />
                </FormBox>
                <FormBox>
                  <Label>{"Pass"}</Label><Input type="password" value={this.props.user.password} />
                </FormBox>
                <FormBox>
                  <Label>{"Roles"}</Label>
                  <Input value={this.props.user.role} />
                </FormBox>
                {this.renderSignature()}
                <FormBox>
                  <Label>{"Status"}</Label>
                  <Input value={this.props.user.status ? this.props.user.status : 'active'} />
                </FormBox>
                <ButtonContainer>
                  <Button onClick={this.deleteHandleClick.bind(this)} backgroundColor="#F2F2F2" color="#303030" marginRight="10px">{"Delete"}</Button>
                  <Button onClick={this.updateUserHandler.bind(this)} backgroundColor="#2680EB">{"Update"}</Button>
                </ButtonContainer>
              </FormContainer>
              <LogoContainer>
                <LogoBox>
                  <Logo />
                </LogoBox>
                <CustomLink to='/views/UserDetail'>{"Update Profile Picture"}</CustomLink>
              </LogoContainer>
            </InnerContainer>
          </InfoContainer>
        </ContentContainer>
        <Footer />

        <ToastContainer />

      </Container>
    );
  }
}

const mapStateToProps = state => {
 
  return {
      currentUser: state.authState.currentUser,
      company: state.company.company,
      user: state.user.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetUpdateUser: (user) => dispatch(updateUserGet(user)),
    onGetUpdateProfile: (user) => dispatch(updateProfileGet(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const BackButton = styled(Link)`
  text-decoration: none;
  border: solid 1px #707070;
  color: #303030;
  height: 36px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const LeftArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

const Label = styled.div`
  height: 42px;
  line-height: 42px;
  width: 160px;
`;

const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

const Logo = styled.div`
  width: 186px;
  height: 148px;
  
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  margin: 20px 0px 20px 160px;
  display: flex;
  flex-direction: row;
`;

const Button = styled.button.attrs(props => ({
  color: props.color,
  backgroundColor: props.backgroundColor,
  marginRight: props.marginRight
  }))`
  border: solid 1px #707070;
  background-color: ${props => props.backgroundColor || "#841420"};
  color: ${props => props.color || "#ffffff"};
  margin-right: ${props => props.marginRight || "0px"};
  height: 40px;
  width: 190px;
  font-size: 16px;
  cursor: pointer;
`;

const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 44px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 410px;
`;

const Option = styled.option`
`;

const ConfirmBox = styled.div`
  width: 410px;
  height: 130px;
  border: solid 1px #B7B7B7;
  background-color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  text-align: right;
`;
/*********************End Stylesheet**********************/