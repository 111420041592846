import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import DocumentBase from './DocumentBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, 
  YellowButton, ContentContainer, RightContainer, HeaderContainer, DocumentHeader, PlusIcon,
  GreenButton, Table, DocumentHeadTitle, DocumentHeadRight, StandardButton
} from './style';

import {
  selectDocumentAction,
  fetchDocuments,
  SelectCreateDocumentAction,
  downloadDocument,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class DocumentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.currentUser ? this.props.currentUser : null,
      documents: [],
    };
  }

 
  componentDidMount() {
    // fetch all Documents
    this.props.onFetchDocuments();
    // set state for documents
    let documents = this.props.documents ? this.props.documents : [];
    this.setState(prevState => ({
      ...prevState,
      documents: documents,
    }))
  }
  componentWillUnmount() {
    
  }

  selectedDocumentHandler = (document) => {
    console.log('user selected this Document: ');
    console.log(util.inspect(document));
    this.props.onSelectedDocument(document);
  }

  createDocumentHandler = () => {
    console.log('start action of creating new Document..');
    this.props.onSelectCreateDocument();
  }

  handleSearchDocumentDocument = (documentName) => {
    console.log('documentName selected is: ', documentName);
    let documents = this.props.documents ? this.props.documents : [];
    let _documents = [];
    documents.forEach((_row, _index) => {
      if (_row.docName.toString() == documentName.value.toString()) {
        _documents.push(_row);
      }
    })
    this.setState(prevState => ({
      ... prevState,
      documents: _documents,
    }))
  }

  onDownloadDocument = (document) => {
    this.props.onDownloadDocument(document);
  }

  renderTableData() {
    console.log('list of Documents:');
    console.log(util.inspect(this.props.documents));
    let documents = this.props.documents;
    if (documents.length > 0) {
      return documents.map((document, index) => {
          const { _id, docName, docType, uploadUser, uploadDate } = document //destructuring
          let _item = parseInt(index) + 1;
          let _uploadUser = uploadUser ? (uploadUser.firstname + ' ' + uploadUser.lastname ): '';
          // "item": 3,
          // "docId": '00000003',
          // "docName": "sample doc 3",
          // "uploadUser": "sampleUserId3",
          // "uploadDate": "2020-05-12",
          return (
              <Tr key={_id}>
                <Td>{_item ? _item.toString() : ''}</Td>
                <Td>{docName ? docName : ''}</Td>
                <Td>{docType ? docType : ''}</Td>
                <Td>{_uploadUser}</Td>
                <Td>{uploadDate ? formatDate(uploadDate) : ''}</Td>
                {/* <Td paddingLeft="0px" align="right">
                  <StandardButton width="60px"
                  onClick = {() => {
                    this.selectedDocumentHandler(document)
                  }}
                >View</StandardButton></Td> */}

                <Td paddingLeft="0px" align="right">
                  <StandardButton width="100px"
                  onClick = {() => {
                    this.onDownloadDocument(document)
                  }}
                >{"Download"}</StandardButton></Td> 
              </Tr>
          )
      })
    } else {
      return (
        <div>loading ..</div>
      )
    }
 }

 body = () => {
   return (
    <ContentContainer>
      <DocumentHeader>
        <DocumentHeadTitle>Documents Management</DocumentHeadTitle>
        <DocumentHeadRight>
          <ReactSearchBox
            style={seachBoxStyle}
            placeholder="Search documents"
            value=""
            data={this.props.documentNames}
            callback={(record )=> {
              console.log(record);
            }}
            // onChange={(e) => {
            //   let {searchValue} = this.state;
            //   searchValue = e.target.value;
            //   this.setState(searchValue);
            // }}
            onSelect={(documentName) => {
              this.handleSearchDocumentDocument(documentName)
            }}
            onFocus={() => {
              console.log('This function is called when is focussed')
            }}
            onChange={value => console.log(value)}
            fuseConfigs={{
              threshold: 0.05,
            }}
          />
          
          <GreenButton marginLeft="10px"
            onClick={() => this.createDocumentHandler()}>
            Upload Document
          </GreenButton>
        </DocumentHeadRight>
        
      </DocumentHeader>
    
      <Table>
        <THead>
          <Tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Creator</Th>
            <Th>Last Modified</Th>
            {/* <Th>View Detail</Th> */}
            <Th>Download</Th>
          </Tr>
        </THead>
        
        <TBody>
          {this.renderTableData()}
        </TBody>
      </Table>
  </ContentContainer>
   )
 }
  
  render() {
    return (
      <DocumentBase 
        contentContainer = {this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log(`current list of Documents are `);
  console.log(util.inspect(state.fetchData.documentNames));
  console.log(`current list of Documents are `);
  console.log(util.inspect(state.fetchData.documents));
  return {
      documents: state.document.documents,
      documentNames: state.document.documentNames,
      currentUser: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      onFetchDocuments: () => dispatch(fetchDocuments()),
      onSelectedDocument: (document) => dispatch(selectDocumentAction(document)),
      onSelectCreateDocument: () => dispatch(SelectCreateDocumentAction()),
      onDownloadDocument: (document) => dispatch(downloadDocument(document)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);

/*********************Start Stylesheet**********************/
const seachBoxStyle = {
  display: 'flex',
  flexGrow: 1,
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}