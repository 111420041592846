const sampleDocs = [
    {
        "item": 1,
        "_id": '00000001',
        "docName": "sample doc 1",
        "docType": "Nghị định",
        "uploadUser": "sampleUserId1",
        "uploadDate": "2020-05-01",
    },
    {
        "item": 2,
        "_id": '00000002',
        "docName": "sample doc 2",
        "docType": "Nội bộ",
        "uploadUser": "sampleUserId2",
        "uploadDate": "2020-05-02",
    },
    {
        "item": 3,
        "_id": '00000003',
        "docName": "sample doc 3",
        "docType": "Nghị định",
        "uploadUser": "sampleUserId3",
        "uploadDate": "2020-05-12",
    },
    {
        "item": 4,
        "_id": '00000004',
        "docName": "sample doc 4",
        "docType": "Biên bản",
        "uploadUser": "sampleUserId4",
        "uploadDate": "2020-05-10",
    },
    {
        "item": 5,
        "_id": '00000005',
        "docName": "sample doc 5",
        "docType": "Phê duyệt",
        "uploadUser": "sampleUserId5",
        "uploadDate": "2020-05-22",
    }
]

const documentTypes = [
    {
        key: 'report',
        value: 'Report',
    },
    {
        key: 'invoice',
        value: 'Hoá đơn',
    },
    {
        key: 'proposal',
        value: 'Biên đề xuất',
    },
    {
        key: 'contract',
        value: 'Hợp đồng',
    },
    {
        key: 'bill',
        value: 'Phiếu thu',
    },
    {
        key: 'financialStatement',
        value: 'Báo cáo tài chính',
    },
    {
        key: 'agreement',
        value: 'Thoả thuận',
    },
    {
        key: 'law',
        value: 'Luật',
    },
    {
        key: 'certificate',
        value: 'Giấy chứng nhận',
    },
    {
        key: 'license',
        value: 'Giấy phép',
    },
    {
        key: 'nationalIdentity',
        value: 'Chứng minh thư',
    },
    {
        key: 'passport',
        value: 'Hộ chiếu',
    }
]

module.exports = {
    sampleDocs,
    documentTypes,
}