export const adminRole = [
    "editSystem",
    "deleteSystem",
    "viewSystem",
    "viewApproval",
    "viewDocument",
    "editInfo",
    "deleteInfo",
    "viewInfo"
]

export const safeStaffRole = [
    "editApproval",
    "viewApproval",
    "viewDocument",
    "editInfo",
    "viewInfo"
]

export const safeManagerRole = [
    "viewSystem",
    "editApproval",
    "deleteApproval",
    "viewApproval",
    "editDocument",
    "deleteDocument",
    "viewDocument",
    "editInfo",
    "deleteInfo",
    "viewInfo"
]

export const accountantRole = [
    "editApproval",
    "viewApproval",
    "editDocument",
    "viewDocument",
    "editInfo",
    "viewInfo"
]

export const accountManagerRole = [
    "viewSystem",
    "editApproval",
    "deleteApproval",
    "viewApproval",
    "editDocument",
    "deleteDocument",
    "viewDocument",
    "editInfo",
    "deleteInfo",
    "viewInfo"
]

export const managerRole = [
    "viewSystem",
    "editApproval",
    "deleteApproval",
    "viewApproval",
    "editDocument",
    "deleteDocument",
    "viewDocument",
    "editInfo",
    "deleteInfo",
    "viewInfo"
]

export const allRole = [
    "editSystem",
    "deleteSystem",
    "viewSystem",
    "editApproval",
    "deleteApproval",
    "viewApproval",
    "editDocument",
    "deleteDocument",
    "viewDocument",
    "editInfo",
    "deleteInfo",
    "viewInfo",
    "signApproval",
    "signDocument"
]

export const staffRole = [
    "viewInfo"
]