export const formControl = {
    back: {
        en: "back",
        vn: "Quay lại"
    },
    create: {
        en: "create",
        vn: "Tạo mới",
    },
    update: {
        en: "update",
        vn: "Cập nhật"
    },
    confirm: {
        en: "confirm",
        vn: "Xác nhận",
    },
    delete : {
        en: "delete",
        vn: "Xoá"
    },
    register: {
        en: "Register",
        vn: "Đăng ký"
    },
    changePassword: {
        en: "Change Password",
        vn: "Thay đổi mật khẩu"
    },
    validateInfoRequire: {
        en: "Please check and validate info",
        vn: "Thông tin sai, cần nhập lại"
    }
}