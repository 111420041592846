import axios from 'axios';
import { push } from 'react-router-redux';
import { history } from '../../App';

import {
    SELECTED_FARMDATA,
    UPDATE_FARMDATA_ACTION_SUCCESS,
    UPDATE_FARMDATA_ACTION_FAILED,
    CREATE_FARMDATA_ACTION_SUCCESS,
    CREATE_FARMDATA_ACTION_FAILED,
    CREATE_FARMDATA_ACTION,
    GET_CREATE_FARMDATA_SECCUSS,
    GET_CREATE_FARMDATA_FAILED,
    SELECT_CREATE_FARMDATA_ACTION,
    FETCH_FARMDATAS_SUCCESS,
    FETCH_FARMDATAS_FAILED,
    ON_CLICK_UPDATE_FARMDATA,
    FETCH_AUTO_GENERATED_FARMDATA_SUCCESS,
    FETCH_AUTO_GENERATED_FARMDATA_FAILED,
    FARMDATA_IMAGE_UPLOAD_GENERATE_URL_SUCCESS,
    FARMDATA_IMAGE_UPLOAD_GENERATE_URL_FAIL,
} from './actionTypes';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
const IMAGE_UPLOAD_BASE_URL = "https://grainapi.victoriousstone-f80887c9.australiaeast.azurecontainerapps.io/"

// const url = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:8080/api/";

export const getImageUploadUrl = (farm_data_id) => {
    return async (dispatch) => {
        console.log('selected inoice received on actions:');
        console.log(farm_data_id);
        let config = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
        const res = await axios.post(`${IMAGE_UPLOAD_BASE_URL}generate_link`, {farm_data_id: farm_data_id}, config);
            
        if (res.data.verification_link) {
            dispatch({
                type: FARMDATA_IMAGE_UPLOAD_GENERATE_URL_SUCCESS,
                payload: {
                    imageUrl: res.data.verification_link,
                }
            });
            
        } else {
            dispatch({
                type: FARMDATA_IMAGE_UPLOAD_GENERATE_URL_FAIL,
                payload: {
                    imageUrl: "localhost",
                }
            });
        }
    };
};

export const selectFarmDataAction = (selectedFarmData) => {
    console.log('selected inoice received on actions:');
    console.log(util.inspect(selectedFarmData));
    // temporarily use crafted data
    // const {farmDataDetail} = require('../../data/farmDatas');
    // console.log('farmData detail imported: ');
    // console.log(util.inspect(farmDataDetail));
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: SELECTED_FARMDATA,
            payload: {
                selectedFarmData,      //data is thr user's data
                page: 'detail',
                // farmDataDetail: farmDataDetail
            }
        });
        history.push(`/views/farmData/detail`);
    };
};

export const selectViewFarmDataAction = (selectedFarmData) => {
    console.log('selected inoice received on actions:');
    console.log(util.inspect(selectedFarmData));
    // temporarily use crafted data
    // const {farmDataDetail} = require('../../data/farmDatas');
    // console.log('farmData detail imported: ');
    // console.log(util.inspect(farmDataDetail));
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: SELECTED_FARMDATA,
            payload: {
                selectedFarmData,      //data is thr user's data
                page: 'detail',
                // farmDataDetail: farmDataDetail
            }
        });
        // history.push(`/views/farmData/detail`);
    };
}

export const onClickUpdateFarmData = (_farmData) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: ON_CLICK_UPDATE_FARMDATA,
            payload: {
                selectedFarmData: _farmData,      //data is thr user's data
                page: 'update',
            }
        });
        history.push(`/views/farmData/update`);
    };
}
// action hanling for downloading a file
export const downloadFarmData = (_farmData) => {

    return (dispatch) => {
        const method = 'GET';
        axios
            .request({
                url: `${url}farmData/harvesting/${_farmData._id}`,
                method,
                responseType: 'blob', //importan
            })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = _farmData.createElement('a');
                link.href = downloadUrl;

                link.setAttribute('download', `${_farmData.uploadUrl}`); //any other extension
                _farmData.body.appendChild(link);
                link.click();
                link.remove();
            });
  
    };
}

export const updateFarmDataAction = (updatingFarmData) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: UPDATE_FARMDATA_ACTION_SUCCESS,
            payload: {
                farmData: updatingFarmData,      //data is thr user's data
                page: 'update'
            }
        });
        history.push(`/views/farmdata/harvesting/update`);
    };
}

export const selectCreateFarmDataAction = () => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('use select create farmData ..');
        dispatch({
            type: SELECT_CREATE_FARMDATA_ACTION,
            payload: {
                farmData: null,      //data is thr user's data
                page: 'create',
            }
        });
        history.push('/views/farmData/harvesting/create');
    };
}

export const createFarmDataAction = (farmData) => {
    return async (dispatch) => {

        // return;
        const res = await axios.post(`${url}farmdata/harvesting/create`, {farmData: farmData});
        
        if (res.data.farmDatas) {
            var _docNames = [];
            const _farmDatas = res.data.farmDatas;
            dispatch({
                type: CREATE_FARMDATA_ACTION_SUCCESS,
                payload: {
                    farmDatas: res.data.farmDatas,
                }
            });
            history.push('/views/farmdata/harvesting/list');
            
        } else {
            dispatch({
                type: CREATE_FARMDATA_ACTION_FAILED,
                payload: {
                    farmData: farmData,      //data is thr user's data
                    page: 'create',
                }
            });
            history.push('/views/farmdata/harvesting/create');
        }
        
    };
}

export const createStreamingFarmDataAction = (farmData) => {
    return async (dispatch) => {

        // return;
        const res = await axios.post(`${url}farmdata/harvesting/create`, {farmData: farmData});
        
        if (res.data.farmDatas) {
            var _docNames = [];
            const _farmDatas = res.data.farmDatas;
            dispatch({
                type: CREATE_FARMDATA_ACTION_SUCCESS,
                payload: {
                    farmDatas: res.data.farmDatas,
                }
            });
            
        } 
    };
}

export const getCreateFarmData = () => {
    return async (dispatch) => {
        const res = await axios.get(`${url}company/farmdata/harvesting/create`);
        if (res.data.farmDataTypes) {
            console.log('res data received through service fetchData:');
            console.log(util.inspect(res));
            dispatch({
                type: GET_CREATE_FARMDATA_SECCUSS,
                payload: {
                    farmDataTypes: res.data.farmDataTypes
                }
            })
        }
        else {
            // use crafted data
            // const {farmDataTypes} = require('../../data/farmDatas');
            const farmDataTypes = {};
            console.log('imported farmData types: ');
            console.log(util.inspect(farmDataTypes));
            dispatch({
                type: GET_CREATE_FARMDATA_FAILED,
                payload: {
                    farmDataTypes: farmDataTypes,
                }
            })
        }
    }
}


export const fetchFarmDatas = () => {

    return async (dispatch) => {
        const res = await axios.get(`${url}farmdata/harvesting/list`)
        console.log('res data received through service fetchData:');
        console.log(util.inspect(res.data));
        let _farmDataKeyValues = []
        if (res.data.farmDatas) {
            let _farmDatas = res.data.farmDatas;
            _farmDatas.forEach((_row, _index) => {

                let keyValue = {
                    key: _row._id,
                    value: _row.number,
                }
                _farmDataKeyValues.push(keyValue);
            
            })
            dispatch({
                type: FETCH_FARMDATAS_SUCCESS,
                payload: {
                    farmDatas: res.data.farmDatas,
                    farmDataKeyValues: _farmDataKeyValues,
                },
            });
        } else {
            dispatch({
                type: FETCH_FARMDATAS_FAILED,
                payload: {
                    farmDatas: [],
                    farmDataKeyValues: _farmDataKeyValues,
                },
            });
        }
    }
}

export const fetchAutoGeneratedFarmData = () => {
    return async (dispatch) => {
        const res = await axios.get(`${url}farmdata/harvesting/autogenerate`)
        console.log('res data received through service fetchData:');
        console.log(util.inspect(res.data));
        if (res.data.farmData) {
            dispatch({
                type: FETCH_AUTO_GENERATED_FARMDATA_SUCCESS,
                payload: {
                    autoGeneratedFarmData: res.data.farmData,
                },
            });
        } else {
            dispatch({
                type: FETCH_AUTO_GENERATED_FARMDATA_FAILED,

            });
        }
    }
}