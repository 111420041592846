const approvals = [
    {   
        _id: '11111111',
        name: 'Khách hàng cá nhân ABC',
        type: 'Vay cá nhân',
        deadline: '2020-05-10',
        status: 'Xong'
    },
    {   
        _id: '11111112',
        name: 'Khách hàng doanh nghiệp CDF',
        type: 'Vay doanh nghiệp',
        deadline: '2020-05-30',
        status: 'Chờ phê duyệt'
    },
    {   
        _id: '11111113',
        name: 'Khách hàng doanh nghiệp GHI',
        type: 'Vay doanh nghiệp',
        deadline: '2020-05-15',
        status: 'Xong'
    }
]

const approvalDetail = 
    {
        _id: '1111111',
        name: 'Phê duyệt XYZ',
        mainDocs: [
            {
                "item": 5,
                "docId": '00000005',
                "docName": "sample doc 5",
                "uploadUser": "sampleUserId5",
                "uploadDate": "2020-05-22",
            }
        ],
        relevantDocs: [
            {
                "item": 2,
                "docId": '00000002',
                "docName": "sample doc 2",
                "uploadUser": "sampleUserId2",
                "uploadDate": "2020-05-02",
            },
            {
                "item": 3,
                "docId": '00000003',
                "docName": "sample doc 3",
                "uploadUser": "sampleUserId3",
                "uploadDate": "2020-05-12",
            },
        ],
        approvalLevels: [
            {level: 1, description: "Phê duyệt cấp 1", signers: [
                {
                    _id: '1111111',
                    fullName: 'Mr ABC',
                    role: 'Head of Admin',
                    deadline: '2020-05-01',
                    status: 'Đã ký',
                },
                {
                    _id: '1111112',
                    fullName: 'Mr DFE',
                    role: 'CEO',
                    deadline: '2020-05-10',
                    status: 'Chưa ký',
                },
            ]},
            {level: 2, description: "Phê duyệt cấp 2", signers: [
                {
                    _id: '1111114',
                    fullName: 'Mr XYZ',
                    role: 'Vice CTO',
                    deadline: '2020-05-25',
                    status: 'Đã ký',
                }
            ]},
        ], 
    }
// signing: option

module.exports = {
    approvals,
    approvalDetail,
}