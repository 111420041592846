import React from 'react';

const SpanLabel = (props) => {
    return (  
  <div className="form-group">
    <label htmlFor={props.name} className="form-label">{props.title}</label>
    <span className="form-label" id={props.name} name={props.name} style={props.style}>{props.text}</span>
  </div>
)
}

export { SpanLabel };