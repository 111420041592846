import { BehaviorSubject } from 'rxjs';
// import axios from 'axios';
// const prodKeys = require('../config/prodKeys');
// const url = prodKeys.apiUrl;
const util = require('util');


const selectedInvoiceTemplate = new BehaviorSubject(JSON.parse(localStorage.getItem('selectedInvoiceTemplate')));
// const currentCompanySubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentCompany')));
  
function storeInvoiceTemplate(invoiceTemplate) {
    // using local storate temporarily for Demo
    console.log('selected invoiceTemplate to be saved: ');
    console.log(util.inspect(invoiceTemplate));
    localStorage.setItem('selectedInvoiceTemplate', JSON.stringify(invoiceTemplate));
}

export const storageData = {
    selectedInvoiceTemplate: selectedInvoiceTemplate.asObservable(),
    storeInvoiceTemplate: storeInvoiceTemplate,

};