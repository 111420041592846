// import axios for fetching data
import axios from 'axios';
import { push } from 'react-router-redux';
import { history } from '../../App';
// import for test data
import approvals from '../../data/approvals';

// import action types
import { 
    FETCH_USER,
    FETCH_ADDRESSES_FAILED,
    FETCH_ADDRESSES_SUCCESS,
    FETCH_COMPANY_FAILED,
    FETCH_COMPANY_SUCCESS,
    FETCH_SIGNERS_FAILED,
    FETCH_SIGNERS_SUCCESS,
    FETCH_SIGNER_FAILED,
    FETCH_SIGNER_SUCCESS,
    FETCH_APPROVALTEMPLATES_FAILED,
    FETCH_APPROVALTEMPLATES_SUCCESS,
    FETCH_APPROVALTEMPLATE_FAILED,
    FETCH_APPROVALTEMPLATE_SUCCESS,
    FETCH_APPROVAL_NUMBER_SUCCESS,
    FETCH_APPROVAL_NUMBER_FAILED,
} from './actionTypes';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
console.log(util.inspect(approvals));

// const url = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:8080/api/";
export const fetchAddresses = () => {
    return async (dispatch) => {
        const res = await axios.get(`${url}company/addresses`);
        
        console.log('response from fetch addresses');
        console.log(util.inspect(res.data));
        if (res.data.address_list) {
            console.log('addresses received: ', util.inspect(res.data.address_list))
            // update state of addresses
            // return res.data.address_list;
            dispatch({
                type: FETCH_ADDRESSES_SUCCESS,
                payload: res.data.address_list,      //data is thr user's data
            });
        } else {
            dispatch({
                type: FETCH_ADDRESSES_FAILED,
            });
        }
    }
}


export const fetchUserAction = () => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        const res = await axios.get(`${url}auth/current_user`);
        if (res) {
            console.log('current user at actions: ');
            console.log(util.inspect(res.data));
            dispatch({
                type: FETCH_USER,
                payload: res.data.user,      //data is thr user's data
            });
        } else {
            history.push(`${url}auth/login`);
        }
        
    };
};

// export const fetchCompany = (companyId) => {
//     return async (dispatch) => {
//         console.log('fetching company ..with companyId = ', companyId);
//         const res = await axios.get(`${url}company/profile/${companyId}`);
//         console.log('response from fetch company');
//         console.log(util.inspect(res.data));
//         if (res.data.company) {
//             console.log('company received: ', util.inspect(res.data.company))
//             // update state of addresses
//             dispatch({
//                 type: FETCH_COMPANY_SUCCESS,
//                 payload: res.data.company,      //data is thr user's data
//             });
//             // return res.data.company;
//         } else {
//             dispatch({
//                 type: FETCH_COMPANY_FAILED,
//             });
//         }
        
//     }
// }

export const fetchSigner = (userId) => {
    return async (dispatch) => {
        console.log('fetching user..')
        const res = await axios.get(`${url}company/user/${userId}`);
        console.log('response from fetch user');
        console.log(util.inspect(res.data));
        if (res.data.user) {
            console.log('user received: ', util.inspect(res.data.user))
            // since we have not populate addresses and bankAccount, we do it now
            // const addRes = await axios.get(`${url}company/user/${userId}`);
            dispatch({
                type: FETCH_SIGNER_SUCCESS,
                payload: res.data.user,      //data is thr user's data
            });
            // return res.data.user;
        } else {
            dispatch({
                type: FETCH_SIGNER_FAILED,
            });
        }
        
    }
}

export const fetchSigners = () => {
    
    const sampleSigners = [
        {
            _id: '1111111',
            fullName: 'Mr ABC',
            role: 'Head of Admin',
            deadline: '2020-05-01',
        },
        {
            _id: '1111112',
            fullName: 'Mr DFE',
            role: 'CEO',
            deadline: '2020-05-05',
        }
    ]

    return async (dispatch) => {
        var signersInfo = [];
        sampleSigners.forEach(async (_row, _index) => {

            let keyValue = {
                key: _row._id,
                value: _row.fullName,
            }
            await signersInfo.push(keyValue);
        
        })
        console.log('fetching users ..')
        const res = await axios.get(`${url}company/users`);
        console.log('response from fetch users');
        console.log(util.inspect(res.data));
        if (res.data.user_list) {
            console.log('list of users from server: ', util.inspect(res.data.user_list));
            let _signers = res.data.user_list;
            var _signersInfo = [];
            _signers.forEach((_row, _index) => {

                let keyValue = {
                    key: _row._id,
                    value: _row.firstname + ' ' + _row.lastname,
                }
                _signersInfo.push(keyValue);
            
            })
            // update state of addresses
            dispatch({
                type: FETCH_SIGNERS_SUCCESS,
                // payload: res.data.user_list,      //data is thr user's data
                payload: {
                    signers: _signers,
                    signersInfo: _signersInfo,
                }
            });
            // history.push('/views/approval/list');
            
        } else {
            dispatch({
                type: FETCH_SIGNERS_FAILED,
                payload: {
                    signers: sampleSigners,
                    signersInfo: signersInfo,
                }
            });
            // history.push('/views/approval/list');
        }
        // history.push('/views/approval/list');
        
    }
}


// export const fetchApprovalTemplates = () => {
//     return async (dispatch) => {
//         console.log('fetching approvalTemplate ..')
//         const res = await axios.get(`${url}company/approvaltemplates`);
    
//         console.log('response from fetch approvalTemplates');
//         console.log(util.inspect(res.data));
//         if (res.data.approvalTemplate_list) {
//             let approvalTemplate_list = res.data.approvalTemplate_list;
//             console.log('approvalTemplate received: ', util.inspect(approvalTemplate_list))
//             // update state of addresses
//             // return res.data.approvalTemplate_list;
//             dispatch({
//                 type: FETCH_APPROVALTEMPLATES_SUCCESS,
//                 payload: {
//                     approvalTemplates: approvalTemplate_list,
//                     selectedTemplate: approvalTemplate_list[0],
//                 }
//             });
//         } else {
//             dispatch({
//                 type: FETCH_APPROVALTEMPLATES_FAILED,
//             });
//         }
//     }
// }

export const fetchApprovalTemplate = (approvalTemplateId) => {
    return async (dispatch) => {
        console.log('fetching approvalTemplate for id: ', approvalTemplateId);
        const res = await axios.get(`${url}company/approvaltemplate/${approvalTemplateId}`);
    
        console.log('response from fetch approvalTemplate');
        console.log(util.inspect(res.data));
        if (res.data.approvalTemplate) {
            console.log('approvalTemplate received on actions: ', util.inspect(res.data.approvalTemplate))
            // update state of addresses
            // return res.data.approvalTemplate_list;
            dispatch({
                type: FETCH_APPROVALTEMPLATE_SUCCESS,
                payload: res.data.approvalTemplate,      //data is thr user's data
            });
        } else {
            dispatch({
                type: FETCH_APPROVALTEMPLATE_FAILED,
            });
        }
    }
}

export const fetchGetApprovalCreate = () => {
    return async (dispatch) => {
        const res = await axios.get(`${url}company/approval/create`)
        console.log('res data received through service fetchData:');
        console.log(util.inspect(res));
        if (res.data.approvalNumber) {
            dispatch({
                type: FETCH_APPROVAL_NUMBER_SUCCESS,
                payload: res.data.approvalNumber,      //data is thr user's data
            });
        } else {
            dispatch({
                type: FETCH_APPROVAL_NUMBER_FAILED,
            });
        }
    }
}

