import axios from 'axios';
const prodKeys = require('../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

function fetchCurrentUser() {
    return axios.get(`${url}auth/current_user`)
    .then((res) => {
      console.log('response from fetch current user');
      console.log(util.inspect(res.data));
      if (res.data.user) {
        console.log('current user received: ', util.inspect(res.data.user))
        // update state of addresses
        return res.data.user;
      }
    })
    .catch((_err) => {
      console.log('fetching user error');
      console.log(util.inspect(_err));
    })
}

function fetchAddresses() {
    return axios.get(`${url}company/addresses`)
    .then((res) => {
      console.log('response from fetch addresses');
      console.log(util.inspect(res.data));
      if (res.data.address_list) {
        console.log('addresses received: ', util.inspect(res.data.address_list))
        // update state of addresses
        return res.data.address_list;
      }
    })
    .catch((_err) => {
      console.log('fetching addreses error');
      console.log(util.inspect(_err));
    })
}

function fetchBankAccounts() {
    console.log('fetching addresses ..')
    return axios.get(`${url}company/bankaccountes`)
    .then((res) => {
    console.log('response from fetch bank accounts');
    console.log(util.inspect(res.data));
    if (res.data.bankaccount_list) {
        console.log('bankaccounts received: ', util.inspect(res.data.bankaccount_list))
        // update state of addresses
        return res.data.bankaccount_list;
    }
    })
    .catch((_err) => {
    console.log('fetching addreses error');
    console.log(util.inspect(_err));
    })
}

function fetchCustomers() {
    console.log('fetching customers ..')
    return axios.get(`${url}company/customers`)
    .then((res) => {
        console.log('response from fetch customer');
        console.log(util.inspect(res.data));
        if (res.data.customer_list) {
            console.log('customers received: ', util.inspect(res.data.customer_list))
            // update state of addresses
            return res.data.customer_list;
    }
    })
    .catch((_err) => {
        console.log('fetching customers error');
        console.log(util.inspect(_err));
    })
}

function fetchCustomer(customerId) {
    console.log('fetching customers ..')
    return axios.get(`${url}company/customer${customerId}`)
    .then((res) => {
        console.log('response from fetch customer');
        console.log(util.inspect(res.data));
        if (res.data.customer) {
            console.log('customers received: ', util.inspect(res.data.customer))
            // update state of addresses
            return res.data.customer;
    }
    })
    .catch((_err) => {
        console.log('fetching customers error');
        console.log(util.inspect(_err));
    })
}

function fetchInvoiceTemplates() {
    console.log('fetching invoiceTemplate ..')
    return axios.get(`${url}company/invoicetemplates`)
    .then((res) => {
        console.log('response from fetch invoiceTemplate');
        console.log(util.inspect(res.data));
        if (res.data.invoiceTemplate_list) {
            console.log('invoiceTemplate received: ', util.inspect(res.data.invoiceTemplate_list))
            // update state of addresses
            return res.data.invoiceTemplate_list;
    }
    })
    .catch((_err) => {
        console.log('fetching invoiceTemplate error');
        console.log(util.inspect(_err));
    })
}

function fetchInvoices() {
    console.log('fetching invoices ..')
    return axios.get(`${url}company/invoices`)
    .then((res) => {
        console.log('response from fetch invoices');
        console.log(util.inspect(res.data));
        if (res.data.invoice_list) {
            console.log('invoices received: ', util.inspect(res.data.invoice_list))
            // update state of addresses
            return res.data.invoice_list;
    }
    })
    .catch((_err) => {
        console.log('fetching invoices error');
        console.log(util.inspect(_err));
    })
}

function fetchCompany(companyId) {
    console.log('fetching company ..')
    return axios.get(`${url}company/profile/${companyId}`)
    .then((res) => {
        console.log('response from fetch company');
        console.log(util.inspect(res.data));
        if (res.data.company) {
            console.log('company received: ', util.inspect(res.data.company))
            // update state of addresses
            return res.data.company;
    }
    })
    .catch((_err) => {
        console.log('fetching company error');
        console.log(util.inspect(_err));
    })
}

export const fetchData = {
    fetchAddresses,
    fetchBankAccounts,
    fetchCustomers,
    fetchInvoiceTemplates,
    fetchCurrentUser,
    fetchInvoices,
    fetchCompany,
}