import axios from 'axios';
import { history } from '../../App';

// import action types
import { 
    ON_CLICK_CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILED,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILED,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILED,
    GET_CREATE_USER_SUCCESS,
    GET_CREATE_USER_FAILED,
    GET_UPDATE_USER_FAILED,
    GET_UPDATE_USER_SUCCESS,
    UPDATE_USER_POST_FAILED,
    UPDATE_USER_POST_SUCCESS,
    UPDATE_PASSWORD_POST_FAILED,
    UPDATE_PASSWORD_POST_SUCCESS,
    RESET_PASSWORD_POST_FAILED,
    RESET_PASSWORD_POST_SUCCESS,
    FETCH_GROUPS_SUCCESS,
    VIEW_USER_DETAIL,
    UPDATE_PROFILE_POST_SUCCESS,
    UPDATE_PROFILE_POST_FAILED,
    GET_UPDATE_PROFILE_FAILED,
    GET_UPDATE_PROFILE_SUCCESS,
    NEW_PASSWORD_POST_SUCCESS,
    NEW_PASSWORD_POST_FAILED,
} from './actionTypes';
const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');


export const createUserGet = () => {


    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.get(`${url}company/user/create`)
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.title) {
            console.log("users list are ", res.data.title);
            console.log("groups list prepared for creating user are ", res.data.groups);
            
            dispatch({
                type: GET_CREATE_USER_SUCCESS,
                payload: {
                    title: res.data.title,
                    groups: res.data.groups,
                    user: null,
                    company: res.data.company,
                },      //data is thr user's data
            });

            history.push('/views/user/create');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: GET_CREATE_USER_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                }
            })
            history.push('/views/user/list');
        }
        
    };
};


export const createUserPost = (user) => {
    let {email, password, firstname, lastname, phone, role, status, company} = user;
    console.log('sending request to server: ', user);
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/user/create`, {
            email, password, firstname, lastname, phone, role, status, company,
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (!res.data.invalidMsg) {
            console.log("users list are ", res.data.users);
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: {
                    users: res.data.users,
                },      //data is thr user's data
            });
            history.push('/views/user/list');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: CREATE_USER_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    user: res.data.user,
                }
            })
            history.push('/views/user/create');
        }
        
    };
};


export const updateUserGet = (user) => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.get(`${url}company/user/${user._id}/update`)
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.user) {
            console.log("users list are ", res.data.user);
            dispatch({
                type: GET_UPDATE_USER_SUCCESS,
                payload: {
                    user: res.data.user,
                },      //data is thr user's data
            });
            history.push('/views/user/update');                  
        } else {
            console.log(`error with res ${res.data}`);
            dispatch({
                type: GET_UPDATE_USER_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                }
            })
            history.push('/views/user/list');
        }
        
    };
};


export const updateUserPost = (user) => {
    let {email, firstname, lastname, phone, role, status} = user;

    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/user/${user._id}/update`, {
            email, firstname, lastname, phone, role, status,
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.users) {
            console.log("users list are ", res.data.users);
            dispatch({
                type: UPDATE_USER_POST_SUCCESS,
                payload: {
                    users: res.data.users,
                },      //data is thr user's data
            });
            history.push('/views/user/list');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: UPDATE_USER_POST_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    user: res.data.user
                }
            })
            history.push('/views/user/update');
        }
        
    };
};

export const updateProfileGet = (user) => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.get(`${url}company/user/${user._id}/update_profile`)
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.user) {
            console.log("users list are ", res.data.user);
            dispatch({
                type: GET_UPDATE_PROFILE_SUCCESS,
                payload: {
                    user: res.data.user,
                },      //data is thr user's data
            });
            history.push('/views/user/update');                  
        } else {
            console.log(`error with res ${res.data}`);
            dispatch({
                type: GET_UPDATE_PROFILE_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                }
            })
            history.push('/views/user/list');
        }
        
    };
};

export const updateProfilePost = (user) => {
    let {email, firstname, lastname, phone, role, status} = user;

    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/user/${user._id}/update_profile`, {
            email, firstname, lastname, phone, role, status,
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.user) {
            console.log("users list are ", res.data.user);
            dispatch({
                type: UPDATE_PROFILE_POST_SUCCESS,
                payload: {
                    user: res.data.user,
                },      //data is thr user's data
            });
            history.push('/views/user/detail');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: UPDATE_PROFILE_POST_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    user: user
                }
            })
            history.push('/views/home');
            history.push('/views/user/update');
        }
        
    };
};

export const updatePasswordPost = (user) => {
    let {password} = user;

    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/user/${user._id}/update_password`, {
            password
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.user) {
            console.log("users list are ", res.data.user);
            dispatch({
                type: UPDATE_PASSWORD_POST_SUCCESS,
                payload: {
                    user: res.data.user,
                },      //data is thr user's data
            });
            history.push('/views/user/detail');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: UPDATE_PASSWORD_POST_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    user: res.data.user
                }
            })
            history.push('/views/user/update_password');
        }
        
    };
};

export const resetPasswordPost = (email) => {


    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        // axios.post(`${url}company/user/reset_password`, {email: _email})
        const res = await axios.post(`${url}company/user/reset_password`, {
            email
        })
            
        console.log(`Res from serverr is ${res.data}`);
        let _resetPasswordSuccess = true;
        if (res.data.invalidMsg) {
            if (res.data.invalidMsg.toString() != "") {
                _resetPasswordSuccess = false;
            } 
        }
        if (_resetPasswordSuccess) {
            dispatch({
                type: RESET_PASSWORD_POST_SUCCESS,
                payload: {
                    resetPassword: true,
                    
                },      //data is thr user's data
            });
            history.push('/views/login');
            
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: RESET_PASSWORD_POST_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    resetPassword: false
                }
            })
            
        }
        
    };
};

export const newPasswordAction = (email, password) => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/user/new_password`, {
            email, password
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (!res.data.invalidMsg) {
            console.log("users list are ", res.data.user);
            dispatch({
                type: NEW_PASSWORD_POST_SUCCESS,
                payload: {
                    resetPassword: false,
                },      //data is thr user's data
            });
            history.push('/views/login');
               
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: NEW_PASSWORD_POST_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    resetPassword: false
                }
            })
            history.push('/views/home');
            history.push('/views/user/newpassword');
        }
        
    };
}


export const fetchUsers = () => {
    return async (dispatch) => {
        console.log('fetching users of company ..')
        const res = await axios.get(`${url}company/users`);
        console.log('response from fetch customers');
        console.log(util.inspect(res.data));
        if (res.data.user_list) {
            console.log('user received from server: : ', util.inspect(res.data.user_list))
            // update state of addresses
            dispatch({
                type: FETCH_USERS_SUCCESS,
                payload: {
                    users: res.data.user_list,      //data is thr user's data
                }
            });
            dispatch({
                type: FETCH_GROUPS_SUCCESS,
                payload: {
                    groups: res.data.groups,
                },      //data is thr group's data
            });
            // return res.data.customer;
        } else {
            dispatch({
                type: FETCH_USERS_FAILED,
            });
        }
        
    }
}

export const fetchUser = (user) => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.get(`${url}company/user/${user._id}`)
            
        console.log(`Res from serverr is ${res.data}`);
        if (res.data.user) {
            console.log("users list are ", res.data.user);
            dispatch({
                type: FETCH_USER_SUCCESS,
                payload: {
                    user: res.data.user,
                },      //data is thr user's data
            });
            history.push('/views/user/detail');                  
        } else {
            console.log(`error with res ${res.data}`);
            dispatch({
                type: FETCH_USER_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    user: user,
                }
            })
            history.push('/views/user/detail');
        }
        
    };
};


export const viewUserDetail = (user) => {
    return async (dispatch) => {
        if (user) {
            console.log("users list are ", user);
            dispatch({
                type: VIEW_USER_DETAIL,
                payload: {
                    user: user,
                },      //data is thr user's data
            });
            history.push('/views/user/detail');                  
        } 
    };
};

export const onClickCreateNewUser = () => {
    return dispatch => {
        dispatch({
            type: ON_CLICK_CREATE_USER,
            payload: {
                user: null,
            },      //data is thr user's data
        });
        history.push('/views/user/create');  
    }
}


export const createUser = (user) => {
    let {email, password, firstname, lastname, phone, role, status, company} = user;

    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/user/create`, {
            email, password, firstname, lastname, phone, role, status, company,
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (!res.data.invalidMsg) {
            console.log("users list are ", res.data.users);
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: {
                    users: res.data.users,
                },      //data is thr user's data
            });
            history.push('/views/user/list');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: CREATE_USER_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    user: res.data.user,
                }
            })
            history.push('/views/user/create');
        }
        
    };
};




export const onViewUserDetail = (user) => {
    return (dispatch) => {
        dispatch({
            type: VIEW_USER_DETAIL,
            payload: user,
        })
        history.push('/views/user/detail');
    }
}