import {validateLanguage, selectLanguage} from '../langueges';

// VALIDATION OF INPUTS FROM CLIENT SIDE
export const vietnameseUnicodes = 'a-zA-Z\s\u1EF9\u1EF8\u1EF7\u1EF6\u1EF5\u1EF4\u1EF3\u1EF2\u1EF1\u1EF0\u1EEF\u1EEE\u1EED\u1EEC\u1EEB\u1EEA\u1EE9\u1EE8\u1EE7\u1EE6\u1EE5\u1EE4\u1EE3\u1EE2\u1EE1\u1EE0\u1EDF\u1EDE\u1EDD\u1EDC\u1EDB\u1EDA\u1ED9\u1ED8\u1ED7\u1ED6\u1ED5\u1ED4\u1ED3\u1ED2\u1ED1\u1ED0\u1ECF\u1ECE\u1ECD\u1ECC\u1ECB\u1ECA\u1EC9\u1EC8\u1EC7\u1EC6\u1EC5\u1EC4\u1EC3\u1EC2\u1EC1\u1EC0\u1EBF\u1EBE\u1EBD\u1EBC\u1EBB\u1EBA\u1EB9\u1EB8\u1EB7\u1EB6\u1EB5\u1EB4\u1EB3\u1EB2\u1EB1\u1EB0\u1EAF\u1EAE\u1EAD\u1EAC\u1EAB\u1EAA\u1EA9\u1EA8\u1EA7\u1EA6\u1EA5\u1EA4\u1EA3\u1EA2\u1EA1\u1EA0\u01B0\u01AF\u01A1\u01A0\u0169\u0168\u0129\u0128\u0111\u0110\u0103\u0102\u00FD\u00FA\u00F9\u00F5\u00F4\u00F3\u00F2\u00ED\u00EC\u00EA\u00E9\u00E8\u00E3\u00E2\u00E1\u00E0\u00DD\u00DA\u00D9\u00D5\u00D4\u00D3\u00D2\u00CD\u00CC\u00CA\u00C9\u00C8\u00C3\u00C2\u00C1\u00C0';
// Special characters: 	Any character except [\^$.|?*+()


export const phoneTypingValidate = (_char) =>{
    let result = true;
    const _pattern = new RegExp('^[\+]?[0-9]{0,12}$');
    if (_pattern.test(_char) == false) {
        return false;
    }
    return result;
}

export const enailTypingValidate = (_char) =>{
    let result = true;
    const _pattern = new RegExp('^[\+]?[0-9]{0,12}$');
    if (_pattern.test(_char) == false) {
        return false;
    }
    return result;
}


export const nameTypingValidate = (_char) =>{
    let result = true;
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '\s ]{0,100}$');
    if (_pattern.test(_char) == false) {
        return false;
    }
    return result;
}

export const companyNameTypingValidate = (_char) =>{
    let result = true;
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '\s 0-9_\-]{0,400}$');
    if (_pattern.test(_char) == false) {
        return false;
    }
    return result;
}

// 500 chars
export const productNameTypingValidate = (productName) => {
    let result = true;
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '0-9\-_\s ]{0,500}$');
    if (_pattern.test(productName) == false) {
        return false;
    }
    return result;
}

export const productCodeTypingValidate = (productCode) => {
    let result = true;
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '0-9_\-\s ]{0,50}$');
    if (_pattern.test(productCode) == false) {
        return false;
    }
    return result;
}

// 50 chars
export const productUnitTypingValidate = (productUnit) => {
    let result = true;
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '\s ]{0,50}$');
    if (_pattern.test(productUnit) == false) {
        return false;
    }
    return result;
}

export const productQuantityTypingValidate = (productQuantity) => {
    let result = true;
    const _pattern = new RegExp('^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$');
    if (_pattern.test(productQuantity) == false) {
        return false;
    }
    return result;
}

export const productPriceTypingValidate = (productPrice) => {
    let result = true;
    const _pattern = new RegExp('^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$');
    if (_pattern.test(productPrice) == false) {
        return false;
    }
    return result;
}

export const dateTypingValidate = (_date) => {
    const _pattern = new RegExp("^[0-9\/\-]{0,12}$");
    let result = true;
    if (_pattern.test(_date) == false) {
        return false;
    }
    return result;
}

export const vietnamTfnTypingValidate = (_tfn) => {
    const _pattern = new RegExp("^[0-9]{0,10}$");
    let result = true;
    if (_pattern.test(_tfn) == false) {
       return false;
    }
    return result;
}

export const vietnamBankAccountTypingValidate = (_tfn) => {
    const _pattern = new RegExp("^[0-9]{0,16}$");
    let result = true;
    if (_pattern.test(_tfn) == false) {
        return false;
    }
    return result;
}


// 6 chars
// 1. [CK]    //Ký tự đầu tiên là một (01) chữ cái được quy định là C hoặc K để thể hiện hóa đơn điện tử loại có mã của cơ quan thuế hoặc loại không có mã của cơ quan thuế trong đó
// 2. [0-9]{2}        //2 chữ số cuối của năm dương lịch
// 3. [TDLM]    //chữ cái được quy định là T hoặc D hoặc L hoặc M thể hiện loại hóa đơn điện tử được sử dụng, cụ thể:
        // + Chữ T: Áp dụng đối với hóa đơn điện tử do các doanh nghiệp, tổ chức, hộ, cá nhân kinh doanh đăng ký sử dụng với cơ quan thuế.
        // + Chữ D: Áp dụng đối với hóa đơn điện tử đặc th không nhất thiết phải có một số tiêu thức do các doanh nghiệp, tổ chức đăng ký sử dụng.
        // + Chữ L: Áp dụng đối với hóa đơn điện tử của cơ quan thuế cấp theo từng lần phát sinh.
        // + Chữ M: Áp dụng đối với hóa đơn điện tử được khởi tạo từ máy tính tiền.
// 4. [A-Za-z]{2}  //Hai ký tự cuối là chữ viết do người bán tự xác định căn cứ theo nhu cầu quản lý, trường hợp không có nhu cầu quản lý thì để là YY.

//  (?<=(?<=(?<=[CK])[0=9]{2})[TDLM])[A-Z]{2}
export const invoiceCodeTypingValidate = (_chars) => {
    let result = true;
    // const _pattern = new RegExp("(?<=(?<=(?<=[CK])[0-9]{2})[TDLM])[A-Z]{2}");
    let _pattern = new RegExp("[CK]");
    
    if (_pattern.test(_chars) == false) {
        return false;
    } else {
        _pattern = new RegExp("(?<=[CK])[0-9]{2}");
        if (_pattern.test(_chars) == false) {
            return false;
        } else {
            _pattern = new RegExp("(?<=(?<=[CK])[0-9]{2})[TDLM]");
            if (_pattern.test(_chars) == false) {
                return false;
            } else {
                _pattern = new RegExp("(?<=(?<=(?<=[CK])[0-9]{2})[TDLM])[A-Z]{2}");
                if (_pattern.test(_chars) == false) {
                    return false;
                }
            }
        }
    }
    return result;
}


// 50 chars - self-defined
export const groupNameTypingValidate = (userGroupName) => {
    let result = true;
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '0-9\-_\s ]{0,50}$');
    if (_pattern.test(userGroupName) == false) {
        return false;
    }
    return result;
}

export const streetTypingValidate = (_street) => {
    let result = true;
    const _pattern = new RegExp('^[0-9 ' + vietnameseUnicodes + ']{0,200}$');
    if (_pattern.test(_street) == false) {
        return false;
    }
    return result;
}

export const suburbTypingValidate = (_suburb) => {
    let result = true;
    const _pattern = new RegExp('^[0-9 ' + vietnameseUnicodes + ']{0,50}$');
    if (_pattern.test(_suburb) == false) {
        return false;
    }
    return result;
}

export const cityTypingValidate = (_city) => {
    let result = true;
    const _pattern = new RegExp('^[ ' + vietnameseUnicodes + ']{0,50}$');
    if (_pattern.test(_city) == false) {
        return false;
    }
    return result;
}

// 50 chars
export const countryTypingValidate = (_country) => {
    let result = true;
    const _pattern = new RegExp('^[ ' + vietnameseUnicodes + ']{0,50}$');
    if (_pattern.test(_country) == false) {
        return false;
    }
    return result;
}

/**
 * from: 2000-01-01 to 2025-12-31
 * pattern: 13 digits
 * @param {*} _account 
 */
export const bankAccountTypingValidate = (_account) => {
    const _pattern = new RegExp("^[0-9]{0,16}$");
    let result = true;
    if (_pattern.test(_account) == false) {
        return false;
    }
    return result;
}

export const vnBankNameTypingValidate = (_bankName) => {
    let result = true;
    const _pattern = new RegExp('^[0-9 ' + vietnameseUnicodes + ']{0,400}$');
    if (_pattern.test(_bankName) == false) {
        return false;
    }
    return result;
}

// 400 chars - 635_QD-TCT_442915
export const vnGeneralAddressTypingValidate = (_address) => {
    let result = true;
    const _pattern = new RegExp('^[0-9, ' + vietnameseUnicodes + ']{0,400}$');
    if (_pattern.test(_address) == false) {
        return false;
    }
    return result;
}

export const generalCodeTypingValidate = (_address) => {
    let result = true;
    const _pattern = new RegExp('^[0-9a-zA-Z\-_\/]{0,50}$');
    if (_pattern.test(_address) == false) {
        return false;
    }
    return result;
}

// function test(_chars) {
//     let result = true;
//     const _pattern = new RegExp("(?<=(?<=(?<=[CK])([0-9]{2})?)[TDLM]?)[A-Z]{0,2}");
    
//     if (_pattern.test(_chars) == false) {
//         return false;
//     } 
//     return result;
// }

// function test1(_chars) {
//     let reulst = true;
//     // const _pattern = new RegExp("(?<=(?<=[CK])[0-9]{2})[TDLM]");
//     const _pattern = new RegExp("(?<=(?<=[CK])[0-9]{2})[TDLM]");
//     if (_pattern.test(_chars) == false) {
//         return false;
//     } 
//     return result;
// }

// test1("C20");