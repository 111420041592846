import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { v1 as uuidv1 } from 'uuid';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import SystemNavigation from '../../components/SystemNavigation'
import { authenticationService, fetchData } from '../../services';
import {
  SpanLabel,
} from '../../commons';
import {
  createUserPost, updatePasswordPost, resetPasswordPost, createUserGet, updateUserGet, updateUserPost, updateProfilePost, fetchGroups,
} from '../../store/actions';
import {
  allRole,
} from '../../data/userRole';
import {
  userProfile, selectLanguage, userManagementText, formControl, userFormControl, userRole, userRoleArray, userGroupStatus,
} from '../../langueges';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
const {
  nameValidate,
  passwordValidate,
  emailValidate,
  phoneValidate,
} = require('../../services/validate');

class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //userId = this.props.match.params.userId,
      //Header: 60px, Footer: 52px, border included
      // height: window.innerHeight - 112,
      //Get ID from user list page.
    //   userId: this.props.location.state.userId,
      user: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        phone: '',
        role: 'admin',
        status: 'active',
        company: this.props.currentUser ? this.props.currentUser.company : null,
      },
      admin: this.props.currentUser ? this.props.currentUser : null,
      redirect: false,
      company: this.props.company ? this.props.company : null,
      existingCheck: false,
      invalidMsg: '',
      userStatusList: [],
      editPermission: false,
    };
  }

  // updateDimensions = () => {
  //   this.setState({ 
  //     height: window.innerHeight - 112
  //   });
  // };

  componentDidMount() {
    // window.addEventListener('resize', this.updateDimensions);
    if (!this.props.currentUser) {
      this.props.history.push('/views/login');
    } else {
      this.checkEditPermission();

      if (this.props.user) {
        let user = this.props.user;
        this.setState(prevState => ({
          ...prevState,
          user: user,
          existingCheck: true,
        }))
      }
    }
  }

  createUpdateUserHandler = () => {
    let newUser = this.state.user;
    if (this.state.existingCheck) {
      let currentUser = this.props.currentUser;
      // if this is the owner of updating user
      if (currentUser._id.toString() === newUser._id.toString()) {
        this.props.onUpdateProfile(newUser);
      } else {
        // this is the admin user to update another user task.
        this.props.onUpdateUser(newUser);
      }
    } else {
      console.log('updating new user: ', newUser);
      // return;
      this.props.onCreateUser(newUser);
    }
  };

  updatePasswordHandler = () => {
    console.log('change password hanle');
    let user = this.state.user;
    this.props.onChangePassword(user);
  }
  
  resetPasswordChangeUpHandler = () => {
    console.log('reset password hanle');
    let user = this.state.user;
    this.props.onResetPassword(user);
  }

  checkEditPermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let editPermission = false;
    let user = this.props.user;
    if (user && user._id) {
      // if this is the owner of this user, then set permission = true
      if (user._id.toString() == currentUser._id.toString()) {
        editPermission = true;
      }
    }
    
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('editSystem')) {
        editPermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        editPermission = true;
      }
      this.setState({editPermission: editPermission})
    }
  }


  passwordChangeUpHandler = () => {
    console.log('change password hanle');
    let newUser = this.state.user;
    if (this.state.existingCheck) {
      console.log('updating existing user');
      console.log('creating new user');
      axios.post(`${url}company/user/${newUser._id}/update_password`, {
        password: newUser.password,
      })
      .then((response) => {
        let resData = response.data;
        console.log('response object object: ', util.inspect(resData));
        if (resData.user) {
            console.log('user update password successfully, redirect to user management');
            console.log('redirect to user management page');
            const _redirectLoginPath = '/views/UserManagement';
            return this.props.history.push(_redirectLoginPath);
        }

      })
      .catch(function (error) {
          console.log(error);
        });
    } else {
      const _redirectLoginPath = '/views/login';
      return this.props.history.push(_redirectLoginPath);
    }
  }

  updateUser = () => {
    let updateUser = this.state.user;
    axios.put('http://localhost:3001/api/users', {user: updateUser}).then((response) => {
    });
  };

  deleteUser = () => {
    let deleteUserId = this.state.user.id;
    axios.delete('http://localhost:3001/api/users', {params: {userId: deleteUserId}}).then((response) => {
    });
  };

  saveHandleClick() {
    if(this.state.userId === '0'){
      this.addUser();
    } else {
      this.updateUser();
    }
    this.createNotification('','Thông tin người dùng đã được lưu thành công!');
  }

  deleteHandleClick() {
    if(this.state.userId === '0'){
      //nothing to delete
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmBox>
              <h3>{"Confirm"}</h3>
              <p>{"Confirm you want to delete this user?"}</p>
              <ButtonGroup>
                <Button onClick={() => {
                    this.deleteUser();
                    onClose();
                    this.setState({
                      redirect: true
                    });
                  }} backgroundColor="#F2F2F2" color="#303030" marginRight="10px">  
                  Xóa
                </Button>
                <Button onClick={onClose} backgroundColor="#F2F2F2" color="#303030">Không</Button>
              </ButtonGroup>
            </ConfirmBox>
          );
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  createNotification = (title,message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/views/UserManagement' />
    }
  }

  render() {
    let userGroups = this.props.groups;
    if ((!userGroups) || (userGroups.length <= 0)) {
      userGroups = [];
      const _adminGroup = {
        groupName: "Admin",
        status: "active"
      };
      userGroups.push(_adminGroup);
    }
    return (
      <Container>
        {this.renderRedirect()}
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="1" />
          </NavigationContainer>
          <InfoContainer>
            <Title>{"Update User Profile"}</Title>
            <InnerContainer>
            <FormContainer>
              {
                ((this.state.invalidMsg != '') || (this.props.invalidMsg != '')) ? 
                <SpanLabel 
                text={this.state.invalidMsg}
                style={{color: 'red'}}
              /> : null}
              <FormBox>
                <Label>{"Email"}</Label>
                <Input placeholder="Email" 
                  value={this.state.user.email} 
                  onChange={(e) => {
                    let {user} = this.state;
                    user.email = e.target.value;
                    this.setState({user});
                    let _validateResult = emailValidate(this.props.language, e.target.value);
                    if (!_validateResult) {
                      this.setState(prevState => ({
                        ...prevState,
                        invalidMsg: _validateResult,
                      }))
                    }
                  }}
                />
              </FormBox>

              <FormBox>
                <Label>{"Password"}</Label>
                <Input placeholder="Password" type="password" value={this.state.user.password} onChange={(e) => {
                  let {user} = this.state;
                  user.password = e.target.value;
                  this.setState({user});
                  let _validateResult = passwordValidate(this.props.language, e.target.value);
                  if (!_validateResult) {
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }
                }} />
              </FormBox>

              <FormBox>
                <Label>{"Confirm Password"}</Label>
                <Input placeholder="re-enter password" type="password" />
              </FormBox>

              <FormBox>
                <Label>{"Company Name"}</Label>
                <Input placeholder="name of organisation" 
                  value={this.props.company ? this.props.company.name : ''} />
              </FormBox>

              <FormBox>
                <Label>{"First Name"}</Label>
                <Input placeholder="first name" value={this.state.user.firstname} 
                onChange={(e) => {
                  let {user} = this.state;
                  user.firstname = e.target.value;
                  this.setState({user});
                  let _validateResult = nameValidate(this.props.language, e.target.value);
                  if (!_validateResult) {
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }
                }} />
              </FormBox>

              <FormBox>
                <Label>{"Last Name"}</Label>
                <Input placeholder="last name" value={this.state.user.lastname} onChange={(e) => {
                  let {user} = this.state;
                  user.lastname = e.target.value;
                  this.setState({user});
                  let _validateResult = nameValidate(this.props.language, e.target.value);
                  if (!_validateResult) {
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }
                }} />
              </FormBox>

              <FormBox>
                <Label>{"Phone"}</Label>
                <Input placeholder="phone number" value={this.state.user.phone} onChange={(e) => {
                  let {user} = this.state;
                  user.phone = e.target.value;
                  this.setState({user});
                  let _validateResult = phoneValidate(this.props.language, e.target.value);
                  if (!_validateResult) {
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }
                }} />
              </FormBox>
              
              <FormBox>
                <Label>{"Roles"}</Label>
                <Select 
                  value={this.state.user.role} 
                  onChange={(e) => {
                    let {user} = this.state;
                    user.role = e.target.value;
                    this.setState({user});
                  }}
                >
                  {userGroups.map(_userRole => {
                    const userRoleTextLabel = userRole[_userRole.groupName] ? userRole[_userRole.groupName] : _userRole.groupName;
                    return (
                      <Option
                        selected={(_userRole.groupName.toString() == this.state.user.role.toString()) 
                        ? true : false
                        }
                        value={_userRole.groupName}>
                          {selectLanguage(this.props.language, userRoleTextLabel)}
                      </Option>
                    );
                  })}
                </Select>
              </FormBox>

              <FormBox>
                <Label>{"Status"}</Label>
                <Select value={this.state.user.status} 
                  onChange={(e) => {
                    console.log('use select role: ', e.target.value);
                    let {user} = this.state;
                    user.status = e.target.value;
                    this.setState({user});
                }}>
                  {[{status: 'active', desc: 'Active'}, {status: 'inactive', desc: 'Inactive'}, {status: 'locked', desc: 'Locked'}].map(_statusObj => {
                    return (
                      <Option
                        selected={(_statusObj.status == this.state.user.status) 
                        ? true : false
                        }
                        value={_statusObj.status}>
                          {_statusObj.desc}
                      </Option>
                    );
                  })}
                </Select>

              </FormBox>
              
              <ButtonContainer>
                <Button marginRight="10px" onClick={this.createUpdateUserHandler}>
                  {this.state.existingCheck ? 'Update' : 'Register'}
                </Button>
                <Button onClick={this.passwordChangeUpHandler}
                  hidden={this.state.existingCheck ? false : true}
                >
                  {"Change Password"}
                </Button>
              </ButtonContainer>
              </FormContainer>
              <LogoContainer>
                <LogoBox>
                  <Logo />
                </LogoBox>
                <CustomLink to='/views/UserDetail'>{"Upload Profile Picture"}</CustomLink>
              </LogoContainer>
            </InnerContainer>
          </InfoContainer>
        </ContentContainer>
        <Footer />

        <ToastContainer />

      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('groups received from reducer: ');
  console.log(util.inspect(state.user.groups));
  return {
    currentUser: state.authState.currentUser,
    invalidMsg: state.user.invalidMsg,
    user: state.user.user,
    company: state.user.company,
    groups: state.user.groups,
    language: state.topMenu.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchGroups: () => dispatch(fetchGroups()),
    onGetCreateUser: () => dispatch(createUserGet()),
    onCreateUser: (user) => dispatch(createUserPost(user)),
    onGetUpdateUser: () => dispatch(updateUserGet()),
    onUpdateUser: (user) => dispatch(updateUserPost(user)),
    onResetPassword: (user) => dispatch(resetPasswordPost(user)),
    onChangePassword: (user) => dispatch(updatePasswordPost(user)),
    onUpdateProfile: (user) => dispatch(updateProfilePost(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 10px 15px;
  font-size: 24px;
`;

const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

const Label = styled.div`
  height: 42px;
  line-height: 42px;
  width: 160px;
`;

const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

const Logo = styled.div`
  width: 186px;
  height: 148px;
  
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  margin: 20px 0px 20px 160px;
  display: flex;
  flex-direction: row;
`;

const Button = styled.button.attrs(props => ({
  color: props.color,
  backgroundColor: props.backgroundColor,
  marginRight: props.marginRight
  }))`
  border: solid 1px #707070;
  background-color: ${props => props.backgroundColor || "#2680EB"};
  color: ${props => props.color || "#ffffff"};
  margin-right: ${props => props.marginRight || "0px"};
  height: 40px;
  width: 190px;
  font-size: 16px;
  cursor: pointer;
`;

const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 40px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 390px;
`;

const Option = styled.option`
`;

const ConfirmBox = styled.div`
  width: 410px;
  height: 130px;
  border: solid 1px #B7B7B7;
  background-color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const ConfirmationText = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

const LinkContainer = styled.div`
  font-size: 14px;
  width: 400px;
  text-align: center;
`;

const FormTitle = styled.div`
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
`;
/*********************End Stylesheet**********************/