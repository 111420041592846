export const productLanguage = {
    item: {
        en: 'Item',
        vn: 'STT'
    },
    code: {
        en: "Product Code",
        vn: "Mã hàng hoá"
    },
    name: {
        en: "Product Name",
        vn: "Tên Hàng Hoá"
    },
    unit: {
        en: "Unit",
        vn: "Đơn vị"
    },
    quantity: {
        en: "Quantity",
        vn: "Số lượng"
    },
    price: {
        en: "Unit Price",
        vn: "Đơn giá"
    },
    cost: {
        en: "Cost",
        vn: "Thành tiền"
    },
    subtotal: {
        en: "Subtotal",
        vn: "Cộng"
    },
    tax: {
        en: "Tax",
        vn: "Thuế"
    },
    totalCost: {
        en: "Total Amount",
        vn: ""
    },
    money: {
        en: "In words",
        vn: "Thành tiền"
    },
    createProduct: {
        en: "Create Product",
        vn: "Tạo sản phẩm mới"
    },
    updateProduct: {
        en: "Update Product",
        vn: "Cập nhật"
    },
    deleteProduct: {
        en: "Delete Product",
        vn: "Xoá bỏ sản phẩm"
    },
    uploadCsvFile: {
        en: "Upload CSV File",
        vn: "Upload CSV file"
    },
    uploadProducts: {
        en: "Upload Products",
        vn: "Upload Danh sách Sản phẩm"
    },
    uploadFileFormat: {
        en: "Csv File Header: ",
        vn: "Header file CSV: "
    },
    productManagement: {
        en: "Product Management", 
        vn: "Quản lý Hàng Hoá"
    },
    createProductInvalidMsg: {
        en: "Please correct info before submit",
        vn: "Nhập lại những thông tin sai"
    },
    currency: {
        en: "Currency",
        vn: "Loại tiền"
    },
    uploadFileMsg: {
        en: "Please choose csv file",
        vn: "Chọn file định dạng csv"
    },
    uploadFileErrorMsg: {
        en: "Upload file error with type file",
        vn: "Upload file lỗi"
    },
    reUploadFileRequired: {
        en: "Please reupload csv file",
        vn: "Chọn và upload lại csv file"
    },
    csvFileFormatError: {
        en: "Please correct header of csv file and reupload: item, code, discription, unit, quantity, price, currency",
        vn: "Cần sửa lại format file csv cho đúng định dạng: item, code, discription, unit, quantity, price, currency"
    },
}

export const productError = {
    duplicatedProductCodeError: {
        en: "Duplicated Product code Error",
        vn: "Lỗi mã hàng hoá lặp lại"
    },
    duplicatedProductCode: {
        en: "Duplicated Product Code",
        vn: "Trùng mã"
    },
    findProductError: {
        en: "Find product code Error",
        vn: "Lỗi tìm hàng hoá"
    },
    unknownError: {
        en: "Unknown Error",
        vn: "Lỗi bất thường"
    },
    mismatchCurrency: {
        vn: "Mismatch Currency",
        vn: "Loại tiền không đồng nhất",
    }
}

export const productStatusLanguage = {
    active: {
        en: "In use",
        vn: "Đang sử dụng",
    },
    deactive: {
        en: "Not used",
        vn: "Dừng sử dụng"
    },
    deleted: {
        en: "Deleted",
        vn: "Đã xoá"
    },
    status: {
        en: "Status",
        vn: "Trạng thái"
    },
    valid: {
        en: "Valid",
        vn: "Hợp lệ"
    }
}