import styled from 'styled-components';
import { Link } from 'react-router-dom';
//import sampleLogo from '../../assets/images/LogoSample186x148.png'


/*********************Start Stylesheet**********************/
export const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

export const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

export const Title = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 10px 15px;
  font-size: 24px;
`;

export const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

export const Label = styled.div`
  height: 42px;
  line-height: 42px;
  width: 160px;
`;

export const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

export const Logo = styled.div`
  width: 186px;
  height: 148px;
  
  background-repeat: no-repeat;
`;

export const Button = styled.button`
  border: solid 1px #707070;
  background-color: #2680EB;
  color: #ffffff;
  height: 40px;
  width: 390px;
  font-size: 16px;
  margin: 20px 0px 20px 160px;
  cursor: pointer;
`;

export const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

export const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 44px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 390px;
`;

export const Option = styled.option`
`;