import { 
   FETCH_DOCUMENTS_FAILED,
   FETCH_DOCUMENTS_SUCCESS,
   GET_CREATE_DOCUMENT_SECCUSS,
   GET_CREATE_DOCUMENT_FAILED,
   SELECT_CREATE_DOCUMENT_ACTION,
   SELECTED_DOCUMENT,
   ON_CLICK_UPDATE_DOCUMENT,
   CREATE_DOCUMENT_ACTION_SUCCESS,
   CREATE_DOCUMENT_ACTION_FAILED,
} from '../actions/actionTypes'; 


 // initicate email and pass to prevent from returning undefined object
const INIT_STATE = { 
    documents: [],
    documentNames: [],
    documentTypes: [],
    document: null,
    page: 'list',
    hideDocumentModal: true,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_DOCUMENT_ACTION_SUCCESS:
            return {
                ...state,
                document: action.payload.documents,
                documentNames: action.payload.documentNames,
                hideDocumentModal: action.payload.hideDocumentModal ? action.payload.hideDocumentModal : true,
                page: 'detail',
            }
        case CREATE_DOCUMENT_ACTION_FAILED:
            return {
                ...state,
                document: action.payload.document,
                page: 'create',
            }
        case ON_CLICK_UPDATE_DOCUMENT: 
            return {
                ...state,
                document: action.payload.selectedDocument,
                page: action.payload.page,
            }
        case SELECTED_DOCUMENT:
            return {
                ...state,
                document: action.payload.selectedDocument,
                page: 'detail',
            }
        case SELECT_CREATE_DOCUMENT_ACTION:
            return {
                ... state,
                document: null,
                page: 'create',
            }
        case FETCH_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documentNames: action.payload.documentNames,
                documents: action.payload.documents,
            }
        case FETCH_DOCUMENTS_FAILED:
            return {
                ...state,
                documentNames: action.payload.documentNames,
                documents: action.payload.documents
            }
        case GET_CREATE_DOCUMENT_SECCUSS:
            return {
                ...state,
                documentTypes: action.payload.documentTypes,
                page: 'create',
            }
        case GET_CREATE_DOCUMENT_FAILED:
            return {
                ...state,
                documentTypes: action.payload.documentTypes,
                page: 'create',
            }
        default:
            return state;
    }
};