import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DocumentBase from './DocumentBase';
import PDFViewer from 'pdf-viewer-reactjs';
import util from 'util';
// import pdfFile from './sample1.pdf';
import {
  downloadDocument,
  onClickUpdateDocument,
} from '../../store/actions';
import {
  ContentContainer,
  DocumentHeadRight,
  DocumentHeadTitle,
  DocumentHeader,
  YellowButton,
  BackButton,
  GrayBorderButton,
  DocumentContainer,
  PDFViewerContainer,
  DocumentIcon,
  NormalButton
} from './style';


const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};
class DocumentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      file: null,
    };
  }

  componentDidMount() {
    // if (!this.props.currentUser) {
    //   return this.props.history.push('/views/login');
    // }
    // if (!this.props.document) {
    //   return this.props.history.push('/views/document/list');
    // }
    // // fetch invoice
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  }


  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  onDownloadDocument = () => {
    if (this.props.document) {
      this.props.onDownloadDocument(this.props.document);
    }
  }

  onClickUpdateDocument = () => {
    if (this.props.document) {
      this.props.onClickUpdateDocument(this.props.document);
    }
  }

  renderImage = (_document) => {
    return (
      <ContentContainer>
        <DocumentContainer>
          <DocumentHeader bgColor="#373C44" padding="10px 15px" marginBottom="10px">
            <DocumentHeadTitle color="#FFFFFF"><DocumentIcon />{_document.docName}</DocumentHeadTitle>
            <DocumentHeadRight>
              {this.renderTopContentBar()}
              {this.renderDownloadButton()}
              <BackButton to='/views/document/list'>Back</BackButton>
            </DocumentHeadRight>
          </DocumentHeader>
          <img 
            src={this.props.docName ? `${url}company/document/${_document._id}` : ''}
            alt={'Document as an image'}
            // width={"200"}
        />
        </DocumentContainer>
      </ContentContainer>
    )
  }

  body = () => {
    const { pageNumber, numPages, file } = this.state;
    let _document = this.props.document ? this.props.document : null;
    if (!_document) {
      return this.props.history.push('/views/document/list');
    }
    if (_document._id) {
      console.log('document ID: ', _document._id)
      let _documentTypes = _document.uploadUrl.toString().split(".");
      console.log('document url: ', _document.uploadUrl);
      let _documentType = _documentTypes[_documentTypes.length - 1];
      console.log('type of doc: ', _documentType)
      if (['png', 'jpec', 'PNG', 'JPEG', 'Jpeg', 'Png'].includes(_documentType)) {
        console.log('this is an image document..')
        return (
          <ContentContainer>
            <DocumentContainer>
              <DocumentHeader bgColor="#373C44" padding="10px 15px" marginBottom="10px">
                <DocumentHeadTitle color="#FFFFFF"><DocumentIcon />{_document.docName}</DocumentHeadTitle>
                <DocumentHeadRight>
                  {this.renderTopContentBar()}
                  {this.renderDownloadButton()}
                  <BackButton to='/views/document/list'>Back</BackButton>
                </DocumentHeadRight>
              </DocumentHeader>
              <img 
                src={this.props.docName ? `${url}company/document/${_document._id}` : ''}
                alt={'Document as an image'}
                // width={"200"}
            />
            </DocumentContainer>
          </ContentContainer>
        )
      } else {
        return (
          <ContentContainer>
            <DocumentContainer>
              <DocumentHeader bgColor="#373C44" padding="10px 15px" marginBottom="10px">
                <DocumentHeadTitle color="#FFFFFF"><DocumentIcon />{_document.docName}</DocumentHeadTitle>
                <DocumentHeadRight>
                  {this.renderTopContentBar()}
                  {this.renderDownloadButton()}
                  <BackButton to='/views/document/list'>Back</BackButton>
                </DocumentHeadRight>
              </DocumentHeader>
              
              <PDFViewerContainer
                  document={{
                      url: `${url}company/document/${_document._id}`,
                      // client/src/views/document/sample1.pdf
                  }}
  
                  navigation={[
                    {
                      css: {
                        zoomInBtn: `${NormalButton}`,
                      }
                    }
                    
                  ]}
  
              />
            </DocumentContainer>
          </ContentContainer>
        );
      }
      
    }
  }

  renderDownloadButton = () => {
    if (this.props.page) {
      if (this.props.page == 'detail') {
        return (
          <GrayBorderButton marginRight="8px" onClick={() => this.onDownloadDocument()}>
            {
              (this.props.page == 'create') ? "Save" :
              (this.props.page == 'update') ? "Update" :
              (this.props.page == 'detail') ? "Download" : null
            }
          </GrayBorderButton>
        )
      } else {
        return null;
      }
    }
  }

  renderTopContentBar = () => {
    if (this.props.page) {
      if (this.props.page == 'list') {
        return null;
      } else {
        return (
          <YellowButton marginRight="8px" onClick={() => this.onClickUpdateDocument()}>
            {
              (this.props.page == 'create') ? "Save" :
              (this.props.page == 'update') ? "Update" :
              (this.props.page == 'detail') ? "Change" : null
            }
          </YellowButton>
        )
      }
    }
  }

  render() {
    return (
      <DocumentBase
        contentContainer={this.body()}
      />
    )
  }
}

const mapStateToProps = state => {
  console.log('check if document availble at props');
  console.log(util.inspect(state.document.document));
  return {
    document: state.document.document,
    currentUser: state.authState.currentUser,
    page: state.document.page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDownloadDocument: (document) => dispatch(downloadDocument(document)),
    onClickUpdateDocument: (document) => dispatch(onClickUpdateDocument(document)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail);
/*********************Start Stylesheet**********************/
function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
