import React from 'react';

const Button = (props) => {
    console.log(props.style);
    return(
        <button 
            className={props.className ? props.className : 'btn btn-primary'}
            style= {props.style} 
            onClick= {props.onClick}
            disabled={props.disabled}
            >    
            {props.title} 
        </button>)
}

export { Button };