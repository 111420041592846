import axios from 'axios';
import { history } from '../../App';

// import action types
import { 
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILED,
    FETCH_GROUPS_SUCCESS,
    FETCH_GROUPS_FAILED,
    GET_CREATE_GROUP,
    GET_UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILED,
    GET_GROUP_DETAIL_SUCCESS,
    GET_GROUP_DETAIL_FAILED,
} from './actionTypes';
const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

export const onViewGroupDetail = (group) => {
    return async (dispatch) => {
        const res = await axios.get(`${url}company/group/${group._id}`);
        console.log('res.data received from server: ', res.data);
        if (res.data.group) {
            dispatch({
                type: GET_GROUP_DETAIL_SUCCESS,
                payload: {
                    group: res.data.group,
                }
            });
            return history.push('/views/group/detail');
        } else {
            dispatch({
                type: GET_GROUP_DETAIL_FAILED,
            });
            console.log('something wrong with getUpdategroup');
            return history.push('/views/group/list');
        }
        
    }
}

export const updateGroupGet = (_group) => {
    return async (dispatch) => {
        const res = await axios.get(`${url}company/group/${_group._id}/update`);
        if (res.data.group) {
            dispatch({
                type: GET_UPDATE_GROUP,
                payload: {
                    group: _group
                }
            });
            history.push('/views/group/update');
        } else {
            console.log('something wrong with getUpdategroup');
            return;
        }
        
    }
}

export const updateGroupPost = (group) => {
    console.log('udpate group cming from site: ', group);
    let {groupName, roles, status} = group;

    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/group/${group._id}/update`, {
            groupName, roles, status
        })
            
        console.log(`Res from updating serverr is ${res.data}`);
        if (!res.data.invalidMsg) {
            console.log("groups list are ", res.data.groups);
            dispatch({
                type: UPDATE_GROUP_SUCCESS,
                payload: {
                    groups: res.data.groups,
                },      //data is thr group's data
            });
            history.push('/views/group/list');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: UPDATE_GROUP_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    group: res.data.group,
                }
            })
            history.push('/views/group/detail');
        }
        
    };
};

export const onClickCreateGroup = () => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.get(`${url}company/group/create`)
        console.log(`Res from serverr is ${res.data}`);
        if (!res.data.invalidMsg) {
            console.log("groups list are ", res.data.groups);
            dispatch({
                type: GET_CREATE_GROUP,
                payload: null,
            });
        }
        history.push('/views/group/create');                 
        
    };
}

export const createGroup = (group) => {
    let {groupName, roles, status, company} = group;

    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}company/group/create`, {
            groupName, roles, status, company
        })
            
        console.log(`Res from serverr is ${res.data}`);
        if (!res.data.invalidMsg) {
            console.log("groups list are ", res.data.groups);
            dispatch({
                type: CREATE_GROUP_SUCCESS,
                payload: {
                    groups: res.data.groups,
                },      //data is thr group's data
            });
            history.push('/views/group/list');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: CREATE_GROUP_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                    group: res.data.group,
                }
            })
            history.push('/views/group/create');
        }
        
    };
};

export const fetchGroups = () => {
    return async (dispatch) => {
        console.log('req to fetch groups from site');
        const res = await axios.get(`${url}company/groups`);
            
        console.log(`fetching groups Res from serverr is:`, util.inspect(res.data));
        if (res.data.groups) {
            console.log("groups list are ", res.data.groups);
            dispatch({
                type: FETCH_GROUPS_SUCCESS,
                payload: {
                    groups: res.data.groups,
                },      //data is thr group's data
            });
            history.push('/views/group/list');                  
        } else {
            console.log(`error with res ${res}`);
            dispatch({
                type: FETCH_GROUPS_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                }
            })
            history.push('/views/group/list');
        }
        
    };
};