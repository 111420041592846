import styled from "styled-components";
import Container from 'react-bootstrap/Container'

export const Table = styled.table`
  width: 100%;
  margin: 10px 0px;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px #B8B8B8;
`;

export const THead = styled.thead`
`;

export const TBody = styled.tbody`
`;

export const Tr = styled.tr`
  border: solid 1px #B8B8B8;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:nth-child(even) {
    background-color: #F9F9F9;
  }
  &:hover {
    background-color: #FFFAD9;
  }
`;

export const Th = styled.th`
  padding: 10px;
  font-weight: bold;
  background-color: #EEEEEE;
  text-align: left;
  border-collapse: collapse;
}
`;

export const Td = styled.td`
  padding: 10px;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const DocumentHeadRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

export const DocumentHeader = styled.div.attrs(props => ({
    marginBottom: props.marginBottom,
    padding: props.padding,
    bgColor: props.bgColor,
  }))`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color:  ${props => props.bgColor};
  padding:  ${props => props.padding || "0px"};
  margin-bottom: ${props => props.marginBottom || "0px"};
`;

export const DocumentHeadTitle = styled.div.attrs(props => ({
  color: props.color,
}))`
  display: flex;
  flex-direction: row;
  color: ${props => props.color};
  margin-top: 8px;
  font-size: 24px;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;
