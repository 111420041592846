// import axios for fetching data
import axios from 'axios';
import { push } from 'react-router-redux';
import { history } from '../../App';

// import action types
import { 
    CREATE_SIGNATURE_SUCCESS,
    CREATE_SIGNATURE_FAILED,
    UPDATE_SIGNATURE_SUCCESS,
    UPDATE_SIGNATURE_FAILED,
    CLICK_CREATE_SIGNATURE,
    FETCH_SIGNATURE_SUCCESS,
    FETCH_SIGNATURE_FAILED,
    FETCH_COMPANY_SUCCESS,
} from './actionTypes';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');


export const clickCreateSignature = (prevPath) => {
    return (dispatch) => {
        dispatch({
            type: CLICK_CREATE_SIGNATURE,    //data is thr user's data
            payload: {
                prevPath: prevPath,
            },
        });
        history.push('/views/signature/create');
    }
}

export const fetchSignature = (user) => {
    let signatureId = user.signature ? user.signature._id ? user.signature._id : user.signature : null;
    return async (dispatch) => {
        if (!signatureId) {
            return history.push('/views/signature/create');
        }
        const res = await axios.get(`${url}company/signature/${signatureId}`);
        console.log('response from create signature');
        console.log(util.inspect(res.data.signature));
        if (res.data.signature) {
            // update state of signaturees
            dispatch({
                type: FETCH_SIGNATURE_SUCCESS,    //data is thr user's data
                payload: {
                    signature: res.data.signature,
                }
            });
 
        } else {
            console.log('failed created signature: ', res.data.invalidMsg);
            dispatch({
                type: FETCH_SIGNATURE_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg
                }
            });
        }
        
    }
}

export const createSignature = (_signature, prevPath) => {
    let {password, effectiveDate} = _signature;
    return async (dispatch) => {
        const res = await axios.post(`${url}company/signature/create`, {
            password,
        });
        console.log('response from create signature');
        console.log(util.inspect(res.data.signature));
        if (res.data.signature) {
            // update state of signaturees
            dispatch({
                type: CREATE_SIGNATURE_SUCCESS,    //data is thr user's data
                payload: {
                    signature: res.data.signature,
                }
            });

            history.push('/views/signature/detail');

        } else {
            console.log('failed created signature: ', res.data.invalidMsg);
            dispatch({
                type: CREATE_SIGNATURE_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg
                }
            });
        }
        
    }
}


// axios.post(`${url}company/signature/${newSignature._id}/update`
export const updateSignature = (_signature) => {
    let {password, _id, effectiveDate} = _signature;
    return async (dispatch) => {
        const res = await axios.post(`${url}company/signature/${_id}/update`, {
            password, _id, effectiveDate,
        });
        console.log('response from create bankAccount');
        console.log(util.inspect(res.data.signature));
        if (res.data.signature) {
            // update state of signaturees
            dispatch({
                type: UPDATE_SIGNATURE_SUCCESS,    //data is thr user's data
                payload: {
                    signature: res.data.signature,
                }
            });

            history.push('/views/signature/detail');
        } else {
            dispatch({
                type: UPDATE_SIGNATURE_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg
                }
            });
            history.push('/views/signature/update');
        }
        
    }
}