import React from 'react'
import { Redirect,Link } from 'react-router-dom'
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import UserProfile from '../../services/UserProfile'
import SystemNavigation from '../../components/SystemNavigation';
import {
  ContentContainer, Container, NavigationContainer, InfoContainer, Title, InnerContainer, FormContainer,
  FormBox, Label, Input, Select, Option, Button, LogoContainer, LogoBox, Logo, CustomLink,
} from './styles';
import {
  updateCompany,
  fetchAddresses,
  fetchUsers,
} from '../../store/actions';
import {
  SpanLabel,
} from '../../commons';
const {
  nameValidate,
  emailValidate,
  phoneValidate,
  streetValidate,
  cityValidate,
  suburbValidate,
  countryValidate,
  bankAccountValidate,
  vietnamTfnNumberValidate,
  urlValidate,
} = require('../../services/validate');

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

class CompanyUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {
        _id: this.props.company ? this.props.company._id : null,
        tfn: this.props.company ? this.props.company.tfn : null,
        name: this.props.company ? this.props.company.name : null,
        address: this.props.company ? this.props.company.address : null,
        reprentative: this.props.users ? this.props.users.length > 0 ? this.props.users[0]._id : null: null,
        email: this.props.company ? this.props.company.email : null,
        website: this.props.company ? this.props.company.website: null,
        bankAccounts: this.props.company ? this.props.company.bankAccounts ? this.props.company.bankAccounts : [] : [],
        taxOfficeLevel1: this.props.company ? this.props.company.taxOfficeLevel1 : null,
        taxOfficeLevel2: this.props.company ? this.props.company.taxOfficeLevel2 : null,
        phone: this.props.company ? this.props.company.phone : null,
        admin: this.props.company ? this.props.company.admin : null,
      },
      user: '',
      addresses: [
        {
          address: 'default street',
          suburb: 'default suburb',
          city: 'default city',
          country: 'Vietnam',
        }
      ],
      bankAccounts: [
        {
          name: 'default name',
          account: 'default account',
          bankName: 'default bankname',
          branch: 'Hanoi',
        }
      ],
      //Header: 60px, Footer: 52px, border included
      height: window.innerHeight - 112,
      redirect: false,
      invalidMsg: '',
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (!this.props.currentUser) {
      this.props.history.push('/views/login');
    } else {
      if (!this.props.company) {
        return this.props.history.push('/views/company/create');
      }
      let company = this.props.company;
      if (company) {
        this.setState(prevState => ({
          ...prevState,
          company: company,
        }))
      }
      if (!(this.props.addresses)) {
        // fet addresses
        this.props.onFetchAddresses();
      } else if (this.props.addresses.length == 0) {
        this.props.onFetchAddresses();
      }
      if (!(this.props.users)) {
        this.props.onFetchUsers();
      } else if (this.props.users.length == 0) {
        this.props.onFetchUsers();
      }
    }

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateCompany = (e) => {
    let company = this.state.company;
    company.bankAccounts = this.props.bankAccounts ? this.props.bankAccounts : [];
    if (company._id) {
      this.props.onUpdateCompany(company);
    } else {
      this.setState(prevState => ({
        ...prevState,
        invalidMsg: 'User ID was missing',
      }))
    }
  }

  
  render() {
    return (
      <Container>
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="0" />
          </NavigationContainer>
          <InfoContainer>
            <Title>{"Company Profile"}</Title>
            <InnerContainer>
              <FormContainer>
                {
                  ((this.state.invalidMsg != '') || (this.props.invalidMsg != '')) ? 
                  <SpanLabel 
                  text={this.state.invalidMsg}
                  style={{color: 'red'}}
                /> : null}
                <FormBox>
                  <Label>{"ABN/ACN/TFN"}</Label>
                  <Input 
                    value={this.props.company ? this.props.company.tfn : ''} 
                    onChange={(e) => {
                      let {company} = this.state;
                      company.tfn = e.target.value;
                      this.setState({company});
                      let _validateResult = vietnamTfnNumberValidate(e.target.value);
                      this.setState(prevState => ({
                        ...prevState,
                        invalidMsg: _validateResult,
                      }))
                    }}
                  />
                </FormBox>
                <FormBox>
                  <Label>{"Name of Organization"}</Label>
                  <Input value={this.props.company ? this.props.company.name : ''} 
                  onChange={(e) => {
                    let {company} = this.state;
                    company.name = e.target.value;
                    this.setState({company});
                    let _validateResult = nameValidate(e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }} />
                </FormBox>
                <FormBox>
                  <Label>{"Address"}</Label>
                  <Select value={this.state.company.address} onChange={(e) => {
                    let {company} = this.state;
                    company.address = e.target.value;
                    this.setState({company});
                   
                  }}>
                    {this.props.addresses.map(_address => {
                    return (
                      <Option
                        selected={(_address._id == this.state.company.address._id
                        || _address._id == this.state.company.address) 
                        ? true : false
                        }
                        value={_address._id}>
                          {_address.street + ', ' + _address.suburb + ', ' + _address.city + ', ' + _address.country}
                      </Option>
                    );
                  })}
                  </Select>
                </FormBox>
                <FormBox>
                  <Label>{"Director"}</Label>
                  <Select 
                    value={this.state.company.reprentative} 
                    onChange={(e) => {
                      let {company} = this.state;
                      company.reprentative = e.target.value;
                      this.setState({company});
                    }}
                  >
                    {(this.props.users ? this.props.users : []).map(_user => {
                      return (
                        <Option
                          selected={(_user._id == this.state.company.reprentative) 
                          ? true : false
                          }
                          value={_user._id}>
                            {_user.firstname + ' ' + _user.lastname + ' : ' + _user.role}
                        </Option>
                      );
                    })}
                  </Select>
                </FormBox>
                <FormBox>
                  <Label>{"Email"}</Label>
                  <Input 
                    value={this.props.company ? this.props.company.email : ''} 
                    onChange={(e) => {
                      let {company} = this.state;
                      company.email = e.target.value;
                      this.setState({company});
                      let _validateResult = emailValidate(e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }} />
                </FormBox>
                <FormBox>
                  <Label>{"Phone Number"}</Label>
                  <Input 
                    value={this.props.company ? this.props.company.phone : ''} 
                    onChange={(e) => {
                      let {company} = this.state;
                      company.phone = e.target.value;
                      this.setState({company});
                      let _validateResult = phoneValidate(e.target.value);
                      this.setState(prevState => ({
                        ...prevState,
                        invalidMsg: _validateResult,
                      }))
                  }} />
                </FormBox>
                <FormBox>
                  <Label>{"Website"}</Label>
                  <Input 
                    value={this.props.company ? this.props.company.website : ''} 
                    onChange={(e) => {
                      let {company} = this.state;
                      company.website = e.target.value;
                      this.setState({company});
                      let _validateResult = urlValidate(e.target.value);
                      this.setState(prevState => ({
                        ...prevState,
                        invalidMsg: _validateResult,
                      }))
                  }} />
                </FormBox>
                
                <Button onClick={(e) => this.updateCompany(e)}>{"Update"}</Button>
              </FormContainer>
              {/* <LogoContainer>
                <LogoBox>
                  <Logo />
                </LogoBox>
                <CustomLink to='/views/company/detail'>{"Update Logo"}</CustomLink>
              </LogoContainer> */}
            </InnerContainer>
          </InfoContainer>
        </ContentContainer>
        <Footer />
        <ToastContainer />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('check if addresses receive from reducer:');
  console.log(util.inspect(state.fetchData.addresses));
  return {
      currentUser: state.authState.currentUser,
      company: state.company.company,
      users: state.user.users,
      addresses: state.fetchData.addresses,
      bankAccounts: state.company.bankAccounts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateCompany: (_company) => dispatch(updateCompany(_company)),
    onFetchAddresses: () => dispatch(fetchAddresses()),
    onFetchUsers: () => dispatch(fetchUsers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUpdate);

/*********************Start Stylesheet**********************/
// const Container = styled.section`
//   background-color: #F2F2F2;
//   color: #4B4B4B;
// `;

// const ContentContainer = styled.section.attrs(props => ({
//   height: props.height
//   }))`
//   min-height: ${props => props.height}px;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   padding: 0px 20px;
//   font-size: 14px;
// `;

// const NavigationContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 200px;
//   margin: 12px 20px 15px 0px;
// `;

// const InfoContainer = styled.div`
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   margin: 12px 0px 15px 0px;
//   border: solid 1px #C4C4C4;
//   background-color: #ffffff;
//   box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
// `;

// const Title = styled.div`
//   border-bottom: solid 1px #C4C4C4;
//   background-color: #F2F2F2;
//   padding: 10px 15px;
//   font-size: 24px;
// `;

// const InnerContainer = styled.div`
//   padding: 20px 60px;
//   display: flex;
//   flex-direction: row;
// `;

// const FormContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const FormBox = styled.div`
//   display: flex;
//   flex-direction: row;
//   margin-top: 10px;
// `;

// const Input = styled.input`
//   border: solid 1px #B7B7B7;
//   height: 40px;
//   width: 390px;
//   font-size: 14px;
//   padding-left: 10px;

//   :focus {
//     background-color: #FFF6DB;
//     box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
//   }
// `;

// const Label = styled.div`
//   height: 42px;
//   line-height: 42px;
//   width: 160px;
// `;

// const LogoContainer = styled.div`
//   margin-left: 60px;
//   margin-top: 10px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const LogoBox = styled.div`
//   width: 200px;
//   height: 160px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #F2F2F2;
//   border: dotted 1px #B7B7B7;
// `;

// const Logo = styled.div`
//   width: 186px;
//   height: 148px;
//   background-image: url(${sampleLogo});
//   background-repeat: no-repeat;
// `;

// const Button = styled.button`
//   border: solid 1px #707070;
//   background-color: #841420;
//   color: #ffffff;
//   height: 40px;
//   width: 390px;
//   font-size: 16px;
//   margin: 20px 0px 20px 160px;
//   cursor: pointer;
// `;

// const CustomLink = styled(Link)`
//   margin-top: 15px;
//   text-decoration: none;
//   color: #0021FB;
// `;

// const Select = styled.select`
//   border: solid 1px #B7B7B7;
//   height: 44px;
//   padding: 0px 10px;
//   font-size: 14px;
//   color: #4B4B4B;
//   width: 390px;
// `;

// const Option = styled.option`
// `;

// /*********************End Stylesheet**********************/