import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { v1 as uuidv1 } from 'uuid';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {BaseLayout} from '../../commons';
import searchIcon from '../../assets/images/icons/search22x22.png'
import SystemNavigation from '../../components/SystemNavigation';
import { authenticationService, fetchData } from '../../services';
import blockchainImage from '../../assets/images/blockchain350x136.png'
import bcBGImage from '../../assets/images/BCBackground800x300.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
// import HeaderNew from '../../components/HeaderNew'
import ReactSearchBox from 'react-search-box';
// import action types
import {
  blockInfoAction,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

//const bodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
//Header: 60px, Footer: 52px, border included
//const contentHeight = bodyHeight - 112;

class Blockchain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight - 112,
        };
    }
    
    updateDimensions = () => {
        this.setState({ 
        height: window.innerHeight - 112
        });
    };
    
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        let currentUser = this.props.currentUser;
        if (currentUser) {
          console.log('user: ', util.inspect(currentUser));
          this.props.onFetchLastBlock();
        } else {
          console.log('you should login or register to view blockchain info');
          // const _redirectLoginPath = '/views/login';
          // return this.props.history.push(_redirectLoginPath);
        }
        
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    createNotification = (title,message) => {
        toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        });
    };
    header = () => {
        return (
            <Header />
        )
    }
    body = () => {
      const lastBlock = this.props.lastBlock;
      let previous_hash = '';
      let blockNumber = "";
      let data_hash = "";
      let chaincode_id = "Gain_Quality";
      let channel_id = "Grain_Channel";
      let type = "";
      let timestamp = "";
      let tx_id = "";
      let version = "";

      if (lastBlock) {
        previous_hash = lastBlock.lastBlock ? lastBlock.lastBlock : '';
        blockNumber = lastBlock.blockNumber ? lastBlock.blockNumber : '';
        data_hash = lastBlock.data_hash ? lastBlock.data_hash : '';
        // chaincode_id = lastBlock.chaincode_id ? lastBlock.chaincode_id : '';
        // channel_id = lastBlock.channelHeader ? lastBlock.channelHeader.channel_id : '';
        type = lastBlock.channelHeader ? lastBlock.channelHeader.type : '';
        timestamp = lastBlock.channelHeader ? lastBlock.channelHeader.timestamp : '';
        tx_id = lastBlock.channelHeader ? lastBlock.channelHeader.tx_id : '';
        version = lastBlock.channelHeader ? lastBlock.channelHeader.version : '';
      }
        return (
            <Container>
                <BoldLabel />
                
                <Row style={SummaryContainer}>
                  <Col style={Cell} md> 
                    <div style={Title}>Last Block</div>
                    <BlockInfo><b>Block Number:</b> {blockNumber ? blockNumber : ''}</BlockInfo>
                    <BlockInfo><b>Block hash:</b> {previous_hash ? previous_hash.slice(0,20) : ''}</BlockInfo>
                    <BlockInfo><b>Data hash:</b> {data_hash ? data_hash.slice(0,20) : ''}</BlockInfo>
                  </Col>
                  <Col style={Cell} md>
                    <div style={Title}>Grain Quality Channel</div>
                    <BlockInfo><b>Contract Name:</b> {chaincode_id ? chaincode_id : ''}</BlockInfo>
                    <BlockInfo><b>Channel Name:</b> {channel_id ? channel_id : ''}</BlockInfo>
                    <BlockInfo><b>Channel Type:</b> {type? type : ''}</BlockInfo>
                  </Col>
                  <Col style={Cell} md>
                    <div style={Title}>Transaction Info</div>
                    <BlockInfo><b>Created Time:</b> {timestamp ? timestamp : ''}</BlockInfo>
                    <BlockInfo><b>Transaction ID:</b> {tx_id ? tx_id.slice(0,20) : ''}</BlockInfo>
                    <BlockInfo><b>Version:</b> {version ? version : ''}</BlockInfo>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <DigitalBG />
                  </Col>
                </Row>
            </Container>
               
        )
    }

    footer = () => {
        return (
            <Footer />
        )
    }
    
    render() {
        return (
          <MainContainer>
            {this.header()}
            <ContentContainer height={this.state.height}>
              {this.body()}
            </ContentContainer>
            {this.footer()}
          </MainContainer>          
        // <BaseLayout 
        //     header={this.header()}
        //     //leftComponent={this.leftMenu()}
        //     mainComponent={this.body()}
        //     footer={this.footer()}
        // />
        );
    }
}
const mapStateToProps = state => {
  console.log(`current block is ${state.lastBlock.lastBlock}`);
 
  return {
      lastBlock: state.lastBlock.lastBlock,
      currentUser: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      onFetchLastBlock: () => dispatch(blockInfoAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blockchain);

/*********************Start Stylesheet**********************/
// const Container = styled.section`
//   background-color: #F2F2F2;
//   color: #4B4B4B;
// `;

const MainContainer = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const ContentContainer = styled.section.attrs(props => ({
    height: props.height
    }))`
    min-height: ${props => props.height}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;
  
  const BoldLabel = styled.div`
    margin-top: 50px;
    width: 350px;
    height: 136px;
    background-image: url(${blockchainImage});
  `;

  const DigitalBG = styled.div`
    margin-top: 50px;
    width: 100%;
    height: 300px;
    background-image: url(${bcBGImage});
    background-repeat: no-repeat;
    background-position: center;
  `;

  const Title = {
    margin: '5px 0px',
    fontSize: '26px',
    borderLeft: 'solid 7px #00B5E0',
    paddingLeft: '10px',
  }
  
  const CompanyName = styled.div`
    margin-top: 15px;
    font-size: 20px;
  `;
  
  const Notice = styled.div`
    width: 412px;
    margin-top: 20px;
    font-size: 14px;
    padding: 10px;
    background-color: #FFF1C4;
    border: solid 1px #D6D6D6;
  `;

  const BlockInfo = styled.div`
    margin: 6px 0px 6px 18px;
`;
  
  const SearchContainer = {
    border: 'solid 1px #B7B7B7',
    backgroundColor: '#ffffff',
    height: '50px',
    fontSize: '16px',
    marginTop: '45px',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
  }
//   styled.div`
//     border: solid 1px #B7B7B7;
//     background-color: #ffffff;
//     height: 50px;
//     width: 810px;
//     font-size: 16px;
//     margin-top: 45px;
//     box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
//     display: flex;
//     flex-direction: row;
//   `;
  
  const SearchInput = styled.input`
    width: 751px;
    height: 47px;
    margin-right: 2px;
    border-width: 0px;
    padding-left: 10px;
    font-size: 16px;
  
    :focus {
      outline: none;
    }
  `;
  
  const SearchIcon = styled.button`
    margin-top: 3px;
    border-width: 0px;
    height: 44px;
    width: 44px;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    cursor: pointer;
  `;
  
  const SummaryContainer = {
    margin: '40px 0px',
    fontSize: '14px',
  }

  const Cell = {
    marginTop: '20px',
  }
//   const SummaryContainer = styled.div`
//     margin: 80px 0px;
//     width: 810px;
//     font-size: 14px;
//     display: flex;
//     flex-direction: row;
//   `;
  
  const SummaryBlock = styled.div.attrs(props => ({
    borderWidth: props.borderWidth,
    }))`
    width: 270px;
    text-align: center;
    border-left: solid ${props => props.borderWidth} #B7B7B7;
  `;
  const div = styled.div`
    margin: 20px 0px 10px 0px;
    text-align: center;
    font-size: 40px;
  `;
  
  const LinkContainer = {
      fontSize: '14px',
      marginBottom: '50px',
  }
  
//   styled.div`
//     font-size: 14px;
//     display: flex;
//     flex-direction: row;
//     margin-bottom: 50px;
//   `;

//   const processTyle = {
//     color: '#ffffff',
//     fontSize: '130%',
// }
  
  const Linkblock = {
      fontSize: '14px',
      high: '50px',
  }
//   styled.div`
//     font-size: 14px;
//     height: 50px;
//     width: 270px;
//   `;
  
  const BlockItem = styled.div.attrs(props => ({
    marginLeft: props.marginLeft,
    }))`
    font-size: 14px;
    width: 246px;
    border: solid 1px #B7B7B7;
    background-color: #ffffff;
    margin-left: ${props => props.marginLeft};
    padding: 10px 20px;
  `;
      // margin-left: ${props => props.marginLeft};
  
  const ColorBar = styled.div.attrs(props => ({
    color: props.color,
    }))`
    width: 10px;
    height: 50px;
    background-color: ${props => props.color};
  `;


 
/*********************End Stylesheet**********************/