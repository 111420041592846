



import { 
    // FETCH_GROUP,
    CHANGE_LANGUAGE_ACTION
} from '../actions/actionTypes';

const INIT_STATE = {
    language: 'en'
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case CHANGE_LANGUAGE_ACTION:
            return {
                ...state,
                language: action.payload.language,
            }
        default:
            return state;
    }
}