export const selectLanguage = (__language, __text) => {
    if (!__text) {
        return "";
    }
    // console.log('language received at selected language process: ',__language);
    let _text = {}
    if (!__text.vn) {
        _text.vn = __text;
    } else if (!__text.en) {
        _text.en = __text;
    } else {
        _text = __text;
    }
    // get language from process
    var _language = __language || 'vn';
    try {
        if (_language.toString() == "vnen") {
            return _text.vn + '/' + _text.en;
        } else if (_language.toString() == "envn") {
            return _text.en + '/' + _text.vn;
        } else if (_language.toString() == "vn") {
            return _text.vn;
        } else if (_language.toString() == "en") {
            return _text.en;
        } else {
            return _text.en;
        }
    } catch(err) {
        return _text;
    }
    
}