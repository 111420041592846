import { 
    CREATE_SIGNATURE_FAILED,
    CREATE_SIGNATURE_SUCCESS,
    UPDATE_SIGNATURE_FAILED,
    UPDATE_SIGNATURE_SUCCESS,
    CLICK_CREATE_SIGNATURE,
    CLICK_UPDATE_SIGNATURE,
    FETCH_SIGNATURE_SUCCESS,
    FETCH_SIGNATURE_FAILED,
} from '../actions/actionTypes';

const INIT_STATE = {
    signature: null,
    invalidMsg: null,
    prevPath: null,
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case FETCH_SIGNATURE_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
            }
        case FETCH_SIGNATURE_SUCCESS:
            return {
                ...state,
                signature: action.payload.signature,
                invalidMsg: null,
            }
        case CLICK_CREATE_SIGNATURE:
            return {
                ...state,
                prevPath: action.payload.prevPath,
                signature: null,
            }

        case CREATE_SIGNATURE_SUCCESS:
            return {
                ...state,
                signature: action.payload.signature,
                invalidMsg: null,
            }
        case CREATE_SIGNATURE_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
            }
        case UPDATE_SIGNATURE_SUCCESS:
            return {
                ...state,
                signature: action.payload.signature,
                invalidMsg: null,
            }
        case UPDATE_SIGNATURE_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
            }
        default:
            return state;
    }
}
