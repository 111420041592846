import React from 'react'
import {Redirect, Link } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'

import UserProfile from '../../services/UserProfile'

import {
  Container, FormContainer,TitleContainer, FormTitle, Input, Button, BLogo, ElementContainer, LinkContainer, CustomLink
} from './styles';

import { connect } from 'react-redux'
import { authenticationService } from '../../services'

// import action
import {emailLoginAction, resetPasswordPost} from '../../store/actions'


const prodKeys = require('../../config/prodKeys')
const url = prodKeys.apiUrl;
const util = require('util');
// import validates
const {
  passwordValidate,
  emailValidate,
} = require('../../services/validate');

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      user: {
        email: '',
        password: '',
      },
      redirect: false,
      invalidMsg: '',
    };
  }

  componentDidMount() {
    // let requestedId = this.state.userId;
    axios.get(`${url}auth/login`)
    .then((response) => {
      console.log('receiving any invalid messeges..');
      console.log(util.inspect(response.data));
      // console.log(response.data.title);
      console.log(response.data.invalidMsg);
      if (response.data.invalidMsg) {
          this.setState( prevState => ({ 
              ...prevState,
              invalidMsg: response.data.invalidMsg,
          }))
      }
      // logout
      // authenticationService.logout().then(() =>{
      //   console.log('user logout sucess ...')
      // })
        
    })
    .catch((_err) => {
        console.log('error: ', util.inspect(_err));
    })
  }


  loginHandler = (event) => {
    event.preventDefault();
    let requestedEmail = this.state.user.email;
    let requestedPassword = this.state.user.password;
    this.props.onLogin(requestedEmail, requestedPassword);
  };

  resetPasswordHandler = (_email) => {
    axios.post(`${url}company/user/reset_password`, {email: _email})
    .then((response) => {
      console.log('receiving any invalid messeges..');
      console.log(util.inspect(response.data));
      // console.log(response.data.title);
      console.log(response.data.invalidMsg);
      if (response.data.invalidMsg) {
          this.setState( prevState => ({ 
              ...prevState,
              invalidMsg: response.data.invalidMsg,
          }))
      }
        
    })
    .catch((_err) => {
        console.log('error: ', util.inspect(_err));
    })
    return this.props.history.push('/views/login');
  }

  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to='/views/Home' />
  //   }
  // }

  myChangeHandler = (event) => {
    this.setState({username: event.target.value});
  }

  render() {
    return (  
      <Container>
        {/* {this.renderRedirect()} */}
        <FormContainer>

          <TitleContainer>
            <FormTitle>Reset Password</FormTitle>
            <BLogo />
          </TitleContainer>
          
          <ElementContainer>
            <Input placeholder="Email" value={this.state.user.email} onChange={(e) => {
              let {user} = this.state;
              user.email = e.target.value;
              this.setState({user});
            }} />
          </ElementContainer>
          <ElementContainer>
          <LinkContainer>
            <CustomLink marginRight="auto" 
              // onClick={() => this.resetPasswordHandler(this.state.user.email)}
              onClick={() => this.props.resetPasswordForUser(this.state.user.email)}
            >{"Click to reset"}</CustomLink>
          </LinkContainer>
          </ElementContainer>
        </FormContainer>
      </Container>
    );
  }

}

const mapStateToProps = state => {
 
  return {
      user: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLogin: (email, password) => dispatch(emailLoginAction(email, password)),
      resetPasswordForUser: (email) => dispatch(resetPasswordPost(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
