import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import SystemBaseLayout from '../layout/SystemLayout';

import {
  SpanLabel,
} from '../../commons';
import {
  createGroup, emailLogoutAction, updateGroupPost,
} from '../../store/actions';
import {
  adminRole, saleStaffRole, saleManagerRole, accountantRole, accountManagerRole, managerRole, staffRole, allRole,
} from '../../data/userRole';
import {userRole, selectLanguage, formControl, userFormControl, groupRoleText, accessRoleText, validateLanguage, userPermission} from '../../langueges';
import {
  TR, TD, Checkbox, Container, ContentContainer, NavigationContainer, InfoContainer, Title, InnerContainer, FormContainer,
  FormBox, Label, Input, Select, Option, TableContainer, Table, THEAD, TH, TBODY, ButtonContainer, Button,
} from './styles';

import {
  nameValidate,
  groupNameValidate
} from '../../services/validate';
import {
  groupNameTypingValidate,
} from '../../services/inputValidate';
import util from 'util';

class GroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - 112,
      group: {
        groupName: '',
        roles: ['viewInfo'],  //default role
        status: 'active',
        company: this.props.currentUser ? this.props.currentUser.company : null,
      },
      existingCheck: false,
      invalidMsg: '',
      accessRoles: [],  //array of access role object
      editPermission: false,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (!this.props.currentUser) {
      return emailLogoutAction();
    } else {
      this.checkEditPermission();
      // load table
      let _existingRoles = []
      if (this.props.group) {
        let group = this.props.group;
        this.setState(prevState => ({
          ...prevState,
          group,
          existingCheck: true,
        }));
        console.log('existing roles from props: ', this.props.group.roles);
        _existingRoles = this.props.group.roles ? this.props.group.roles : [];
      }
      
    
      let _accessRolesTable = allRole.map(_role => {
        let _row = {
          accessRole: _role,
          check: false,
        }
        if (_existingRoles.includes(_role)) {
          _row.check = true;
        }
        return _row;
      })
      console.log('permission table: ', util.inspect(_accessRolesTable));
      this.setState({accessRoles: _accessRolesTable});
      
        // if (this.props.invalidMsg != '') {
        //   this.setState({invalidMsg: this.props.invalidMsg})
        // }
    }
  }

  createUpdateGroupHandler = () => {
    if (this.state.editPermission == false) {
      return this.setState({invalidMsg: selectLanguage(this.props.language, userPermission.accessDeny)});
    }
    let {invalidMsg} = this.state;
    if (invalidMsg != '') {
      console.log('invalid data');
      return;
    }
    let {accessRoles} = this.state;
    console.log('current access roles table: ', util.inspect(accessRoles));
    let {group} = this.state;
    accessRoles.forEach((_row, _index) => {
      if (_row.check == true) {
        group.roles.push(_row.accessRole);
      }
    })
    console.log('new group before submit: ', group);
    // return;
    if (this.state.existingCheck) {
      this.props.onUpdateGroup(group);
    } else {
      this.props.onCreateGroup(group);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  checkboxHandler = (_accessRole) => {
    console.log('checked row: ', _accessRole);
    let {accessRoles, group} = this.state;
    // if it is from uncheck to check
    if (_accessRole.check == false) {
      // change state to true
      for (var i = 0; i < accessRoles.length; i++) {
        if (accessRoles[i].accessRole.toString() == _accessRole.accessRole.toString()) {
          accessRoles[i].check = true;
        }
      }
      this.setState({accessRoles});
    } else {
      // change state to true
      for (var i = 0; i < accessRoles.length; i++) {
        if (accessRoles[i].accessRole.toString() == _accessRole.accessRole.toString()) {
          accessRoles[i].check = false;
        }
      }
      this.setState({accessRoles});
    }
  }

  checkEditPermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let editPermission = true;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('editSystem')) {
        editPermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        editPermission = true;
      }
      this.setState({editPermission: editPermission})
    }
  }

  renderCreateUpdateButton() {
    const editPermission = this.state.editPermission;
    if (editPermission == true) {
      return (
        <Button marginRight="10px" onClick={() => this.createUpdateGroupHandler()}>
            {this.state.existingCheck ? 
            selectLanguage(this.props.language, formControl.update) : 
            selectLanguage(this.props.language, formControl.create)}
        </Button>
      )
    } else {
      return null;
    }
  }

  renderTableData() {
    let _accessRoles = [];
    if (this.props.group) {
      _accessRoles = this.props.group.roles;
    }
   
    return this.state.accessRoles.map((_accessRole, index) => {
       let { accessRole,check} = _accessRole //destructuring
       if (_accessRoles.includes(accessRole)) {
         check = true;
       }
       return (
          <TR key={(index)}>
            <TD>{(index + 1).toString()}</TD>
            <TD>{selectLanguage(this.props.language, accessRoleText[accessRole])}</TD>
            <TD align="center"><Checkbox defaultChecked={check} onChange={() => this.checkboxHandler(_accessRole)}/></TD>
            {/* <TD align="center"><Checkbox defaultChecked={deleteRole} onChange={this.checkboxHandler}/></TD>
            <TD align="center"><Checkbox defaultChecked={viewRole}  onChange={this.checkboxHandler}/></TD> */}
          </TR>
       )
    })
 }

 body() {
   return (
    <InfoContainer>
      <Title>{selectLanguage(this.props.language, userFormControl.createGroup)}</Title>
      <InnerContainer>
      <FormContainer>
        {
            (this.state.invalidMsg != '') ? 
            <SpanLabel 
              text={this.state.invalidMsg}
              style={{color: 'red'}}
            /> : null
          }

        <FormBox>
            <Label marginRight="10px">{selectLanguage(this.props.language, userFormControl.groupName)}:</Label>
            <Input 
              value={this.state.group.groupName} 
              onChange={(e) => {
                if (groupNameTypingValidate(e.target.value)) {
                  let {group} = this.state;
                  group.groupName = e.target.value;
                  this.setState({group});
                  let _validateResult = groupNameValidate(e.target.value);
                  this.setState(prevState => ({
                    ...prevState,
                    invalidMsg: _validateResult,
                  }))
                }
              }} />
          </FormBox>

        <FormBox>
          <Label>Trạng thái:</Label>
          <Select value={this.state.group.status} 
            onChange={(e) => {
              console.log('use select role: ', e.target.value);
              let {group} = this.state;
              group.status = e.target.value;
              this.setState({group});
          }}>
            {[{status: 'active', desc: 'Active'}, {status: 'inactive', desc: 'Inactive'}, {status: 'locked', desc: 'Locked'}].map(_statusObj => {
              return (
                <Option
                  selected={(_statusObj.status == this.state.group.status) 
                  ? true : false
                  }
                  value={_statusObj.status}>
                    {_statusObj.desc}
                </Option>
              );
            })}
          </Select>
        </FormBox>
        
        <TableContainer>
            <Table>
              <THEAD>
                <TR>
                  <TH width="50px">STT</TH>
                  <TH width="300px">Quyền</TH>
                  <TH width="auto" align="center">{selectLanguage(this.props.language, groupRoleText.editRole)}</TH>
                  {/* <TH width="auto" align="center">{selectLanguage(this.props.language, groupRoleText.deleteRole)}</TH>
                  <TH width="auto" align="center">{selectLanguage(this.props.language, groupRoleText.viewRole)}</TH> */}
                </TR>
              </THEAD>
              
              <TBODY>
                {this.renderTableData()}
              </TBODY>
            </Table>
          </TableContainer>

        <ButtonContainer>
          {this.renderCreateUpdateButton()}
        </ButtonContainer>
        </FormContainer>
      </InnerContainer>
    </InfoContainer>
   )
 }

  render() {
    return (
      <SystemBaseLayout 
        leftMenuItem={"2"}
        mainComponent={this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('group received from reducer: ', util.inspect(state.group.group));
  return {
      currentUser: state.authState.currentUser,
      groups: state.group.groups,
      invalidMsg: state.group.invalidMsg,
      company: state.company.company,
      group: state.group.group,
      language: state.topMenu.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onCreateGroup: (_group) => dispatch(createGroup(_group)),
      onUpdateGroup: (_group) => dispatch(updateGroupPost(_group)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);

/*********************Start Stylesheet**********************/

/*********************End Stylesheet**********************/