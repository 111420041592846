export const signatureLanguage = {
    generateSignature: {
        en: "Generate digital signature",
        vn: "Tạo chữ ký số"
    },
    updateSignature: {
        en: "Recreate signature",
        vn: "Tạo mới Chữ ký số"
    },
    signaturePassword: {
        en: "Password for signature",
        vn: "Mật khẩu chữ ký số"
    },
    confirmSignaturePassword: {
        en: "Confirm password",
        vn: "Xác nhận mật khẩu"
    },
    encryptedKey: {
        en: "Encrypted Private Key",
        vn: "Khoá bí mật đã mã hoá"
    },
    certificate: {
        en: "Certificate",
        vn: "Certificate"
    },
    effectiveDate: {
        en: "Effective Date",
        vn: "Ngày hiệu lực"
    },
    creator: {
        en: "Creator",
        vn: "Người khởi tạo"
    },
    lastModified: {
        en: "Last modified date",
        vn: "Ngày cập nhật"
    }
}