/**
 * THE VALIDATION VBASED ON 635_QD-TCT_442915 2020
 */

import {validateLanguage, selectLanguage} from '../langueges';

// VALIDATION OF INPUTS FROM CLIENT SIDE
export const vietnameseUnicodes = 'a-zA-Z\s\u1EF9\u1EF8\u1EF7\u1EF6\u1EF5\u1EF4\u1EF3\u1EF2\u1EF1\u1EF0\u1EEF\u1EEE\u1EED\u1EEC\u1EEB\u1EEA\u1EE9\u1EE8\u1EE7\u1EE6\u1EE5\u1EE4\u1EE3\u1EE2\u1EE1\u1EE0\u1EDF\u1EDE\u1EDD\u1EDC\u1EDB\u1EDA\u1ED9\u1ED8\u1ED7\u1ED6\u1ED5\u1ED4\u1ED3\u1ED2\u1ED1\u1ED0\u1ECF\u1ECE\u1ECD\u1ECC\u1ECB\u1ECA\u1EC9\u1EC8\u1EC7\u1EC6\u1EC5\u1EC4\u1EC3\u1EC2\u1EC1\u1EC0\u1EBF\u1EBE\u1EBD\u1EBC\u1EBB\u1EBA\u1EB9\u1EB8\u1EB7\u1EB6\u1EB5\u1EB4\u1EB3\u1EB2\u1EB1\u1EB0\u1EAF\u1EAE\u1EAD\u1EAC\u1EAB\u1EAA\u1EA9\u1EA8\u1EA7\u1EA6\u1EA5\u1EA4\u1EA3\u1EA2\u1EA1\u1EA0\u01B0\u01AF\u01A1\u01A0\u0169\u0168\u0129\u0128\u0111\u0110\u0103\u0102\u00FD\u00FA\u00F9\u00F5\u00F4\u00F3\u00F2\u00ED\u00EC\u00EA\u00E9\u00E8\u00E3\u00E2\u00E1\u00E0\u00DD\u00DA\u00D9\u00D5\u00D4\u00D3\u00D2\u00CD\u00CC\u00CA\u00C9\u00C8\u00C3\u00C2\u00C1\u00C0';
// Special characters: 	Any character except [\^$.|?*+()


// 400 chars
export const nameValidate = (_language, _name) =>{
    let result = '';
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '\s ]{1,100}$');
    if (_pattern.test(_name) == false) {
        return selectLanguage(_language, validateLanguage.nameInvalid);
    }
    return result;
}

// 400 chars
export const companyNameValidate = (_language, _name) =>{
    let result = '';
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '\s 0-9_\-]{2,400}$');
    if (_pattern.test(_name) == false) {
        return selectLanguage(_language, validateLanguage.companyNameInvalid);
    }
    return result;
}

// 500 chars
export const farmDataNameValidate = (_language, farmDataName) => {
    let result = '';
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '0-9\-_\s ]{1,500}$');
    if (_pattern.test(farmDataName) == false) {
        return selectLanguage(_language, validateLanguage.farmDataNameInvalid);
    }
    return result;
}

export const farmDataCodeValidate = (_language, farmDataCode) => {
    let result = '';
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '0-9_\-\s ]{2,250}$');
    if (_pattern.test(farmDataCode) == false) {
        return selectLanguage(_language, validateLanguage.farmDataCodeInvalid);
    }
    return result;
}

// 50 chars
export const farmDataUnitValidate = (_language, farmDataUnit) => {
    let result = '';
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '\s ]{1,50}$');
    if (_pattern.test(farmDataUnit) == false) {
        return selectLanguage(_language, validateLanguage.farmDataUnitInvalid);
    }
    return result;
}

// 19,4 
export const farmDataQuantityValidate = (_language, farmDataQuantity) => {
    let result = '';
    const _pattern = new RegExp('^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$');
    if (_pattern.test(farmDataQuantity) == false) {
        return selectLanguage(_language, validateLanguage.quantityInvalid);
    }
    return result;
}

export const farmDataPriceValidate = (_language, farmDataPrice) => {
    let result = '';
    const _pattern = new RegExp('^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$');
    if (_pattern.test(farmDataPrice) == false) {
        return selectLanguage(_language, validateLanguage.priceInvalid);
    }
    return result;
}

export const floatValidate = (_language, farmDataPrice) => {
    let result = '';
    const _pattern = new RegExp('^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$');
    if (_pattern.test(farmDataPrice) == false) {
        return selectLanguage(_language, validateLanguage.priceInvalid);
    }
    return result;
}

export const passwordValidate =(_language, _pass) => {
    const _pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let result = '';
    if (_pattern.test(_pass) == false) {
        return selectLanguage(_language, validateLanguage.passwordValidate);
    }
    return result;
}

export const  mismatchPassword = (_language, _pass, _confirmpass) => {
    let result = '';
    if (!_pass) {
        return result;
    }
    if (!_confirmpass) {
        return result;
    }
    if (_pass != '' && _confirmpass != '') {
        if (_pass.toString() != _confirmpass.toString()){
            return selectLanguage(_language, validateLanguage.mismatchPassword);
        }
    }
    return result;
}

export const emailValidate = (_language, _email) => {
    let result = '';
    const _pattern = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
    if (_pattern.test(_email) == false) {
        return selectLanguage(_language, validateLanguage.emailValidate);
    }
    return result;
}

export const phoneValidate = (_language, _phone) => {
    const _pattern = new RegExp('^[\+]{0,1}[0-9]{2}[0-9]{8,10}$')
    let result = '';
    if (_pattern.test(_phone) == false) {
        return selectLanguage(_language, validateLanguage.phoneValidate);
    }
    return result;
}

export const vnBankNameValidate = (_language, _bankName) => {
    let result = '';
    const _pattern = new RegExp('^[0-9 ' + vietnameseUnicodes + ']{2,400}$');
    if (_pattern.test(_bankName) == false) {
        return selectLanguage(_language, validateLanguage.vnBankNameValidate);
    }
    return result;
}

// 400 chars - 635_QD-TCT_442915
export const vnBankBranchValidate = (_language, _address) => {
    let result = '';
    const _pattern = new RegExp('^[0-9, ' + vietnameseUnicodes + ']{2,400}$');
    if (_pattern.test(_address) == false) {
        return selectLanguage(_language, validateLanguage.vnBankBranchValidate);
    }
    return result;
}

// 400 chars - 635_QD-TCT_442915
export const vnGeneralAddressValidate = (_language, _address) => {
    let result = '';
    const _pattern = new RegExp('^[0-9, ' + vietnameseUnicodes + ']{2,400}$');
    if (_pattern.test(_address) == false) {
        return selectLanguage(_language, validateLanguage.vnGeneralAddressValidate);
    }
    return result;
}

export const streetValidate = (_language, _street) => {
    let result = '';
    const _pattern = new RegExp('^[0-9 ' + vietnameseUnicodes + ']{2,50}$');
    if (_pattern.test(_street) == false) {
        return selectLanguage(_language, validateLanguage.streetValidate);
    }
    return result;
}

export const suburbValidate = (_language, _suburb) => {
    let result = '';
    const _pattern = new RegExp('^[0-9 ' + vietnameseUnicodes + ']{2,50}$');
    if (_pattern.test(_suburb) == false) {
        return selectLanguage(_language, validateLanguage.suburbValidate);
    }
    return result;
}

export const cityValidate = (_language, _city) => {
    let result = '';
    const _pattern = new RegExp('^[ ' + vietnameseUnicodes + ']{2,50}$');
    if (_pattern.test(_city) == false) {
        return selectLanguage(_language, validateLanguage.cityValidate);
    }
    return result;
}

// 50 chars
export const countryValidate = (_language, _country) => {
    let result = '';
    const _pattern = new RegExp('^[ ' + vietnameseUnicodes + ']{2,50}$');
    if (_pattern.test(_country) == false) {
        return selectLanguage(_language, validateLanguage.countryValidate);
    }
    return result;
}

export const urlValidate = (_language, _url) => {
    const _pattern = new RegExp("^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$");
    let result = '';
    if (_pattern.test(_url) == false) {
        return selectLanguage(_language, validateLanguage.urlValidate);
    }
    return result;
}

/**
 * from: 2000-01-01 to 2025-12-31
 * pattern: 20([0-1]?[0-9]|2[0-5])-(0?[1-9]|1[0-2])-(0?[1-9]|[1-2]?[0-9]|3[0-1])   //approximately
 * @param {*} _date 
 */
export const dateValidate = (_language, _date) => {
    const _pattern = new RegExp("^20([0-1]?[0-9]|2[0-5])-(0?[1-9]|1[0-2])-(0?[1-9]|[1-2]?[0-9]|3[0-1])$");
    let result = '';
    if (_pattern.test(_date) == false) {
        return selectLanguage(_language, validateLanguage.dateValidate);
    }
    return result;
}

export const dateSelectValidate = (_language, _date) => {
    let result = '';
    try {
        let __date = new Date(_date);
        if (!__date) {
            return selectLanguage(_language, validateLanguage.dateValidate);
        }
    } catch(err) {
        return selectLanguage(_language, validateLanguage.dateValidate);
    }
    return result;
}

/**
 * from: 2000-01-01 to 2025-12-31
 * pattern: 13 digits
 * @param {*} _account 
 */
export const bankAccountValidate = (_language, _account) => {
    const _pattern = new RegExp("^[0-9]{12,16}$");
    let result = '';
    if (_pattern.test(_account) == false) {
        return selectLanguage(_language, validateLanguage.bankAccountValidate);
    }
    return result;
}

// 1
export const vietnamTfnNumberValidate = (_language, _tfn) => {
    const _pattern = new RegExp("^[0-9]{13}$");
    let result = '';
    if (_pattern.test(_tfn) == false) {
        return selectLanguage(_language, validateLanguage.vietnamTfnNumberValidate);
    }
    return result;
}

export const quantityValidate = (_language, _quantity) => {
    const _pattern = new RegExp("^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$");
    let result = '';
    if (_pattern.test(_quantity) == false) {
        return selectLanguage(_language, validateLanguage.quantityValidate);
    }
    return result;
}

export const costValidate = (_language, _quantity) => {
    const _pattern = new RegExp("^[0-9]{0,19}([\.][0-9]{0,4}|[0-9]{0})$");
    let result = '';
    if (_pattern.test(_quantity) == false) {
        return selectLanguage(_language, validateLanguage.costValidate);
    }
    return result;
}

export const taxOfficeValidate = (_language, _address) => {
    let result = '';
    const _pattern = new RegExp('^[0-9, ' + vietnameseUnicodes + ']{2,400}$');
    if (_pattern.test(_address) == false) {
        return selectLanguage(_language, validateLanguage.taxOfficeValidate);
    }
    return result;
}


//  (?<=(?<=(?<=[CK])[0=9]{2})[TDLM])[A-Z]{2}
export const invoiceCodeValidate = (_language, _code) => {
    let result = '';
    const _pattern = new RegExp("(?<=(?<=(?<=[CK])[0-9]{2})[TDLM])[A-Z]{2}");
    if (_pattern.test(_code) == false) {
        return selectLanguage(_language, validateLanguage.invoiceCodeValide);
    }
    return result;
}

// 50 chars - self-defined
export const groupNameValidate = (_language, userGroupName) => {
    let result = '';
    const _pattern = new RegExp('^[' + vietnameseUnicodes + '0-9\-_\s ]{1,50}$');
    if (_pattern.test(userGroupName) == false) {
        return selectLanguage(_language, validateLanguage.userGroupName);
    }
    return result;
}

export const generalCodeValidate = (_language, userGroupName) => {
    let result = '';
    const _pattern = new RegExp('^[^[0-9a-zA-Z\-_\/]{0,50}$]{1,50}$');
    if (_pattern.test(userGroupName) == false) {
        return selectLanguage(_language, validateLanguage.invalidGeneralCode);
    }
    return result;
}


// let _validateResult = nameValidate('abcABC')
// console.log(_validateResult);

// module.exports = {
//     nameValidate,
//     passwordValidate,
//     emailValidate,
//     mismatchPassword,
//     phoneValidate,
//     streetValidate,
//     suburbValidate,
//     cityValidate,
//     countryValidate,
//     dateValidate,
//     urlValidate,
//     bankAccountValidate,
//     vietnamTfnNumberValidate,
//     dateValidate,
//     costValidate,
//     quantityValidate,
//     vnBankNameValidate,
//     vnGeneralAddressValidate,
// }
