
import axios from 'axios';

// import actions type from actionTypes
import { 
    EVENT_LAST_BLOCK_SUCCESS,
    EVENT_LAST_BLOCK_FAILED,
} from './actionTypes';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

// const url = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:8080/api/";


export const blockInfoAction = () => {
    return async (dispatch) => {
        const res = await axios.get(`${url}event/blockevents`);
        let lastBlock = res.data.block;
        console.log(`last block recieved from server side: `);
        console.log(util.inspect(res.data));
        if (res.data) {
            dispatch({
                type: EVENT_LAST_BLOCK_SUCCESS,
                payload: lastBlock,
            })
        } else {
            dispatch({
                type: EVENT_LAST_BLOCK_FAILED,
            })
        }
    }
}

