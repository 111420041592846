import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { v1 as uuidv1 } from 'uuid';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
//import userAvatar from '../../assets/images/avatar186x148.png'
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import SystemNavigation from '../../components/SystemNavigation';
// import { authenticationService, fetchData } from '../../services';
import {
  createAddress,
} from '../../store/actions';

import {
  SpanLabel,
} from '../../commons';
import { history } from '../../App';
const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
const {
  nameValidate,
  streetValidate,
  suburbValidate,
  cityValidate,
  countryValidate,
} = require('../../services/validate');

class AddressCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        height: window.innerHeight - 112,
        user: this.props.currentUser,
        address: {
            street: '',
            suburb: '',
            city: '',
            country: '',
        },
        invalidMsg: '',
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // let requestedId = this.state.userId;
    // let requestedId = this.state.userId;
    if (this.props.currentUser) {
      this.setState( prevState => ({ 
          ...prevState,
          user: this.props.currentUser,
        }))
    } else {
        const _redirectLoginPath = '/views/login';
        return this.props.history.push(_redirectLoginPath);
    }
  }

  createAddressHandler = (e) => {
    if (this.state.invalidMsg != '') {
      this.setState(prevState => ({
        ... prevState,
        invalidMsg: "Please re-input info: " + this.state.invalidMsg,
      }))
    } else {
      let newAddress = this.state.address;
      let prevPath = this.props.location.state ? this.props.location.state.prevPath : '/views/home';
      console.log('observe address obj before passing to server');
      console.log(util.inspect(newAddress));
      this.props.onCreateAddress(newAddress, prevPath);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  createNotification = (title,message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  render() {
    return (
      <Container>
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="6" />
          </NavigationContainer>
          <InfoContainer>
            <FormHeader>
              <Title>{"Create new address"}</Title>
              <BackButton to='/views/address_detail'><LeftArrow />{"Back"}</BackButton>
            </FormHeader>
            <InnerContainer>
              <FormContainer>
                {
                  (this.state.invalidMsg != '') ? 
                  <SpanLabel 
                    text={this.state.invalidMsg}
                    style={{color: 'red'}}
                  /> : null
                }
                <FormBox>
                  <Label>{"Street"}</Label>
                  <Input value={this.state.address.street} onChange={(e) => {
                    let {address} = this.state;
                    address.street = e.target.value;
                    this.setState({address});
                    let _validateResult = streetValidate(e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }} />
                </FormBox>

                <FormBox>
                  <Label>{"Suburb"}</Label>
                  <Input value={this.state.address.suburb} onChange={(e) => {
                    let {address} = this.state;
                    address.suburb = e.target.value;
                    this.setState({address});
                    let _validateResult = suburbValidate(e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }} />
                </FormBox>
                
                <FormBox>
                  <Label>{"State"}</Label>
                  <Input value={this.state.address.city} onChange={(e) => {
                    let {address} = this.state;
                    address.city = e.target.value;
                    this.setState({address});
                    let _validateResult = cityValidate(e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }} />
                </FormBox>

                <FormBox>
                  <Label>{"Country"}</Label>
                  <Input value={this.state.address.country} onChange={(e) => {
                    let {address} = this.state;
                    address.country = e.target.value;
                    this.setState({address});
                    let _validateResult = countryValidate(e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }}/>
                </FormBox>
      
                <Button onClick={(e) => this.createAddressHandler(e)}>
                  {"Create"}
                </Button>
                {/* <Button onClick={this.passwordChangeUpHandler}Thê
                  hidden={this.state.existingCheck ? false : true}
                >
                  Đổi Mật Khẩu
                </Button> */}
                {/* <LinkContainer>
                  Đã có tài khoản: <CustomLink to='/'>Đăng nhập</CustomLink>
                </LinkContainer> */}
              </FormContainer>
            </InnerContainer>
          </InfoContainer>
        </ContentContainer>
        <Footer />

        <ToastContainer />

      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('check if addresses receive from reducer:');
  console.log(util.inspect(state.fetchData.addresses));
  return {
      currentUser: state.authState.currentUser,
      address: state.company.address,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateAddress: (_address, prevPath) => dispatch(createAddress(_address, prevPath)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressCreate);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const BackButton = styled(Link)`
  text-decoration: none;
  border: solid 1px #707070;
  color: #303030;
  height: 36px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const LeftArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

const Label = styled.div`
  height: 42px;
  line-height: 42px;
  width: 160px;
`;

const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

const Logo = styled.div`
  width: 186px;
  height: 148px;
  
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  margin: 20px 0px 20px 160px;
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  border: solid 1px #707070;
  background-color: #841420;
  color: #ffffff;
  height: 40px;
  width: 390px;
  font-size: 16px;
  margin: 20px 0px 20px 160px;
  cursor: pointer;
`;

const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 44px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 410px;
`;

const Option = styled.option`
`;

const ConfirmBox = styled.div`
  width: 410px;
  height: 130px;
  border: solid 1px #B7B7B7;
  background-color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const ConfirmationText = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

const FormTitle = styled.div`
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
`;
/*********************End Stylesheet**********************/