import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import SystemBaseLayout from '../layout/SystemLayout';

import {
  SpanLabel,
} from '../../commons';
import {
  emailLogoutAction, updateGroupGet,
} from '../../store/actions';
import {
  adminRole, saleStaffRole, saleManagerRole, accountantRole, accountManagerRole, managerRole, staffRole, allRole,
} from '../../data/userRole';
import {userRole, selectLanguage, formControl, userFormControl, groupRoleText, accessRoleText, validateLanguage} from '../../langueges';
import {
  TR, TD, Checkbox, Container, ContentContainer, NavigationContainer, InfoContainer, Title, InnerContainer, FormContainer,
  FormBox, Label, Input, Select, Option, TableContainer, Table, THEAD, TH, TBODY, ButtonContainer, Button,
} from './styles';

import {
  nameValidate,
  productNameValidate
} from '../../services/validate';
import util from 'util';

class GroupDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - 112,
      group: {
        groupName: '',
        roles: ['viewInfo'],  //default role
        status: 'active',
        company: this.props.currentUser ? this.props.currentUser.company : null,
      },
      existingCheck: false,
      invalidMsg: '',
      accessRoles: [],  //array of access role object
      editPermission: false,
      deletePermission: false,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (!this.props.currentUser) {
      return emailLogoutAction();
    } else {
      this.checkEditPermission();
      this.checkDeletePermission();
      // load table
      let _existingRoles = []
      if (this.props.group) {
        let group = this.props.group;
        this.setState(prevState => ({
          ...prevState,
          group,
          existingCheck: true,
        }));
        console.log('existing roles from props: ', this.props.group.roles);
        _existingRoles = this.props.group.roles ? this.props.group.roles : [];
      }
      
    
      let _accessRolesTable = allRole.map(_role => {
        let _row = {
          accessRole: _role,
          check: false,
        }
        if (_existingRoles.includes(_role)) {
          _row.check = true;
        }
        return _row;
      })
      console.log('permission table: ', util.inspect(_accessRolesTable));
      this.setState({accessRoles: _accessRolesTable});
      
        // if (this.props.invalidMsg != '') {
        //   this.setState({invalidMsg: this.props.invalidMsg})
        // }
    }
  }

  onUpdateGroup = () => {
    let group = this.props.group;
    this.props.onUpdateGroup(group);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  checkEditPermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let editPermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('editSystem')) {
        editPermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        editPermission = true;
      }
      this.setState({editPermission: editPermission})
    }
  }

  checkDeletePermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let deletePermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('deleteSystem')) {
        deletePermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        deletePermission = true;
      }
      this.setState({deletePermission: deletePermission})
    }
  }

  renderCreateUpdateButton() {
    const editPermission = this.state.editPermission;
    if (editPermission == true) {
      return (
        <Button marginRight="10px" onClick={() => this.onUpdateGroup()}>
          {selectLanguage(this.props.language, formControl.update)}
        </Button>
      )
    } else {
      return null;
    }
  }

  renderTableData() {
    let _accessRoles = [];
    if (this.props.group) {
      _accessRoles = this.props.group.roles;
    }
   
    return this.state.accessRoles.map((_accessRole, index) => {
       let { accessRole,check} = _accessRole //destructuring
       if (_accessRoles.includes(accessRole)) {
         check = true;
       }
       return (
          <TR key={(index)}>
            <TD>{(index + 1).toString()}</TD>
            <TD>{selectLanguage(this.props.language, accessRoleText[accessRole])}</TD>
            <TD align="center"><Checkbox defaultChecked={check} disabled={true}/></TD>
            {/* <TD align="center"><Checkbox defaultChecked={deleteRole} onChange={this.checkboxHandler}/></TD>
            <TD align="center"><Checkbox defaultChecked={viewRole}  onChange={this.checkboxHandler}/></TD> */}
          </TR>
       )
    })
 }

 body() {
   return (
    <InfoContainer>
      <Title>{selectLanguage(this.props.language, userFormControl.createGroup)}</Title>
      <InnerContainer>
      <FormContainer>
        {
            (this.state.invalidMsg != '') ? 
            <SpanLabel 
              text={this.state.invalidMsg}
              style={{color: 'red'}}
            /> : null
          }

        <FormBox>
            <Label>{selectLanguage(this.props.language, userFormControl.groupName)}:</Label>
            <Label>{this.state.group.groupName}</Label>
          </FormBox>

        <FormBox>
          <Label>Trạng thái:</Label>
          <Label>{this.state.group.status}</Label>
        </FormBox>
        <TableContainer>
            <Table>
              <THEAD>
                <TR>
                  <TH width="50px">STT</TH>
                  <TH width="300px">Quyền</TH>
                  <TH width="auto" align="center">{selectLanguage(this.props.language, groupRoleText.editRole)}</TH>
                  {/* <TH width="auto" align="center">{selectLanguage(this.props.language, groupRoleText.deleteRole)}</TH>
                  <TH width="auto" align="center">{selectLanguage(this.props.language, groupRoleText.viewRole)}</TH> */}
                </TR>
              </THEAD>
              
              <TBODY>
                {this.renderTableData()}
              </TBODY>
            </Table>
          </TableContainer>

        <ButtonContainer>
          {this.renderCreateUpdateButton()}
        
        </ButtonContainer>
        </FormContainer>
      </InnerContainer>
    </InfoContainer>
   )
 }

  render() {
    return (
      <SystemBaseLayout 
        leftMenuItem={"2"}
        mainComponent={this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('group received from reducer: ', util.inspect(state.group.group));
  return {
      currentUser: state.authState.currentUser,
      group: state.group.group,
      language: state.topMenu.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateGroup: (group) => dispatch(updateGroupGet(group)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail);

/*********************Start Stylesheet**********************/

/*********************End Stylesheet**********************/