import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
// import {Link} from 'react-router-dom';
// import Dropzone from 'react-dropzone';
// import ApprovalBase from './ApprovalBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, Title, Label, RemoveButton, InputContainer,
  YellowButton, ContentContainer, RightContainer, HeaderContainer, BodyHeader,
  GreenButton, Table, SearchboxContainer,
  InfoContainer, FormContainer,
} from './style';

import {
  CancelButton, SubmitButton, Input 
} from './ApprovalDetail/style';



import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class ApprovalDelegate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            approvalLevel: this.props.approvalLevel,
            comment: '',
        };
    }

    updateDimensions = () => {
        this.setState({ 
        height: window.innerHeight - 112
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        // fetch all Documents
        if (this.props.approvalLevel) {
            let {approvalLevel} = this.state;
            approvalLevel = this.props.approvalLevel;
            this.setState({approvalLevel});
        }
        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onDelegateApproval = () => {
        // add signature to approvalLevel accordingly
        let approvalLevel = this.state.approvalLevel;
        let comment = this.state.comment;
        let approval = this.props.approval;
        let fullname = this.props.currentUser.firstname + ' ' + this.props.currentUser.lastname;
        if (!approvalLevel.comments) {
            approvalLevel.comments = [];
            approvalLevel.comments.push(fullname + ': ' + comment);
        } else {
            approvalLevel.comments.push(fullname + ': ' + comment);
        }
        console.log('approvalLevel after adding signers: ');
        console.log(util.inspect(approvalLevel));
        // return;
        if (approvalLevel) {
            this.props.onUpdateApprovalLevel(approvalLevel);
        }
    }

    handleAddingSigner = (_invidualSignerInfo) => {
        {
          console.log('Signer selected is: ', _invidualSignerInfo);
          let approval = this.props.approval;
          if (approval) {
            let signers = this.props.signers ? this.props.signers : [];
            let approvalLevel = this.state.approvalLevel;
            let _levelSigners = approvalLevel.signers;
            // adding new signer
            signers.forEach((_row, _index) => {
                if (_row._id.toString() == _invidualSignerInfo.key.toString()) {
                _levelSigners.push(_row);
                }
            })
            approvalLevel.signers = _levelSigners;
            this.setState(prevState => ({
                ... prevState,
                approvalLevel: approvalLevel,
            }))
          }
          
        }
    }

    renderSignerInfoTable() {
  
        let _levelSigners = this.state.approvalLevel.signers;
        if (!_levelSigners) {
          return (
            <div>Loading ..</div>
          )
        }
        if (_levelSigners.length > 0) {
          return _levelSigners.map((_signerInfo, _index) => {
            let { _id, fullName, role, firstname, lastname} = _signerInfo //destructuring
            let __index = parseInt(_index) + 1;
            if (firstname) {
              fullName = firstname + ' ' + lastname
            }
            return (
                <Tr key={_id}>
                  <Td>{__index ? __index.toString() : ''}</Td>
                  <Td>{fullName ? fullName : ''}</Td>
                  <Td>{role ? role : ''}</Td>
                  <Td paddingLeft="0px" align="right">
                    <RemoveButton
                      onClick = {() => {
                        this.onViewDocumentDetail(_id)
                      }}
                  >Xem</RemoveButton></Td>
                  <Td paddingLeft="0px" align="right">
                    <RemoveButton
                    onClick = {() => {
                      this.onRemoveRelevantDocument(_id)
                    }}
                  >X</RemoveButton></Td>
                </Tr>
            )
          })
        } else {
          return (
            <div>Loading ..</div>
          )
        }
    }

    body = () => {
        return (
            <ContentContainer height={this.state.height}>
                
                <FormContainer>
                    <Title>{"Delegate Signing Form"}</Title>
                    
                    <InfoContainer>
                        <Input 
                        title={'Note'}
                        className={'form-control'}
                        placeholder={'Comment if any'}
                        value={this.state.comment} 
                        onChange={(e) => {
                            let {comment} = this.state;
                            comment = e.target.value;
                            this.setState({comment});
                        }}
                        />
                    </InfoContainer>
                    <InputContainer>
                        <Label>{'Delegating to'}</Label>
                        <SearchboxContainer flexGrow={4}>
                        <ReactSearchBox
                            placeholder="Search for signer"
                            value=""
                            data={this.props.signersInfo}
                            callback={(record )=> {
                            console.log(record);
                            }}
                            // onChange={(e) => {
                            //   let {searchValue} = this.state;
                            //   searchValue = e.target.value;
                            //   this.setState(searchValue);
                            // }}
                            onSelect={(_invidualSignerInfo) => {
                                this.handleAddingSigner(_invidualSignerInfo)
                            }}
                            onFocus={() => {
                            console.log('This function is called when is focussed')
                            }}
                            onChange={value => console.log(value)}
                            fuseConfigs={{
                            threshold: 0.05,
                            }}
                        />
                        </SearchboxContainer>
                        
                    </InputContainer>
                    <Table>
                        <THead>
                        <Tr>
                            <Th>{"Item"}</Th>
                            <Th>{"Name"}</Th>
                            <Th>{"Role"}</Th>
                            <Th>{"View"}</Th>
                            <Th>{"Remove"}</Th>
                        </Tr>
                        </THead>
                        
                        <TBody>
                        {this.renderSignerInfoTable()}
                        </TBody>
                    </Table>
                   
                </FormContainer>
            </ContentContainer>
        )
    }
    
    render() {
        return (
          <Modal
            show={this.props.showDelegateModal}
            onHide={() => this.props.hideDelegateModal()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delegate To
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.body()}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton onClick={() => this.onDelegateApproval()}>{"Submit"}</SubmitButton>
              <CancelButton onClick={() => this.props.hideDelegateModal()}>{"Cancel"}</CancelButton>
            </Modal.Footer>
          </Modal>
        );
    }
}

const mapStateToProps = state => {

    return {
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signers: state.fetchData.signers,
        signersInfo: state.fetchData.signersInfo,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
        // onUpdateApproval: (approval, approvalLevels) => dispatch(updateApprovalAction(approval, approvalLevels)),
        onUpdateApprovalLevel: (approvalLevel) => dispatch(updateApprovalLevelAction(approvalLevel)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDelegate);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

