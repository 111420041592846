import axios from 'axios';
import { history } from '../../App';

// import action types
import { 
    EMAIL_REGISTER_USER_SUCCESS,
    EMAIL_REGISTER_USER_FAIL,
    EMAIL_LOGIN_SUCCESS,
    EMAIL_LOGIN_FAILED,
    EMAIL_LOGOUT_ACTION_SUCCESS,
    COMPANY_ADMIN_REGISTER_SUCCESS,
    COMPANY_ADMIN_REGISTER_FAILED,
    FETCH_COMPANY_SUCCESS,
} from './actionTypes';
const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');


export const adminRegister = (user) => {
    let {email, password, firstname, lastname, role} = user;
    return (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        axios.post(`${url}auth/register`, {email, password, firstname, lastname, role})
            .then((res) => {
                console.log(`Res from serverr is ${res}`);
                if (res) {
                        console.log("user is ", res.data);
                        console.log("status code is ", res.status);
                        dispatch({
                            type: COMPANY_ADMIN_REGISTER_SUCCESS,
                            currentUser: res.data,      //data is thr user's data
                        });
                        history.push('/views/company/create');                  
                } else {
                    console.log(`error with res ${res}`);
                }
            })
            .catch(err => {
                console.log("Error with the receiving res: ", err);
                dispatch({
                    type: COMPANY_ADMIN_REGISTER_FAILED,
                })
                history.push('/views/register');
            })
        
    };
};

export const emailRegisterUser = (email, password) => {
    return (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        axios.post(`${url}auth/register`, {email, password})
            .then((res) => {
                console.log(`Res from serverr is ${res}`);
                if (res) {
                        console.log("user is ", res.data);
                        console.log("status code is ", res.status);
                        dispatch({
                            type: EMAIL_REGISTER_USER_SUCCESS,
                            currentUser: res.data,      //data is thr user's data
                        });
                        history.push('/views/home');                  
                } else {
                    console.log(`error with res ${res}`);
                }
            })
            .catch(err => {
                console.log("Error with the receiving res: ", err);
                dispatch({
                    type: EMAIL_REGISTER_USER_FAIL,
                })
                history.push('/');
            })
        
    };
};

export const emailLoginAction = (email, password) => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.post(`${url}auth/login`, {email, password});            
        console.log(`Res from serverr is ${res}`);
        // return;
        if (res.data.user) {
                console.log("user from server at action: ");
                console.log(util.inspect(res.data.user));
                dispatch({
                    type: EMAIL_LOGIN_SUCCESS,
                    payload: {
                        currentUser: res.data.user,      //data is thr user's data
                        company: res.data.user.company ? res.data.user.company : null,
                    }
                });
                dispatch({
                    type: FETCH_COMPANY_SUCCESS,
                    payload: {
                        company: res.data.company,
                        address: res.data.company ? res.data.company.address : null,
                    },      //data is thr user's data
    
                });
                history.push('/views/approval/list');                  
        } else {
            console.log(`error with res ${res.data}`);
            dispatch({
                type: EMAIL_LOGIN_FAILED,
            })
        }
    }
};

export const emailLogoutAction = () => {
    return async (dispatch) => {
        // const url = process.env.NODE_ENV === 'production' ? "/" : "http://localhost:8080/";
        const res = await axios.get(`${url}auth/logout`);            
        console.log(`Res from serverr is ${res}`);        
            dispatch({
                type: EMAIL_LOGOUT_ACTION_SUCCESS,
            });
            history.push('/views/login');                  
    }
};