import React from 'react'
import { connect } from 'react-redux';
// import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';

// import styles
import {
  Select, Option,
} from '../style';
import {
    Input, CancelButton, SubmitButton, RejectOption, InfoContainer
} from './style';
// import {
//   Input,
// } from '../../commons';

import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../../store/actions';

const util = require('util');
class RejectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            nextApprovalLevel: null,
            comment: '',
            invalidMsg: '',
        };
    }

    updateDimensions = () => {
        this.setState({ 
        height: window.innerHeight - 112
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        // set default return to first approval level
        let approval = this.props.approval;
        if (approval) {
            let {nextApprovalLevel} = this.state;
            nextApprovalLevel = approval.approvalLevels[0]._id;
            this.setState({nextApprovalLevel});
        }
        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onRejectApproval = () => {
        let approval = this.props.approval;
        // if this is alreay updated approval, then cannot reject
        if (approval.status == 'done' || approval.status == 'rejected') {
            console.log('this is alreay approved, cannot reverse or reject');
            return this.props.history.push('/views/approval/list');
        }
        // add signature to approvalLevel accordingly
        let comment = this.state.comment;
        let nextApprovalLevel = this.state.nextApprovalLevel;
        
        let approvalLevel = this.props.approvalLevel;
        let fullname = this.props.currentUser.firstname + ' ' + this.props.currentUser.lastname;
        if (!approvalLevel.comments) {
            approvalLevel.comments = [];
            approvalLevel.comments.push(fullname + ': ' + comment);
        } else {
            approvalLevel.comments.push(fullname + ': ' + comment);
        }
        let approvalLevels = this.props.approval.approvalLevels;
        let updateApprovalLevels = [];
        let backLevel = 1;
        let currentLevel = approvalLevel.level;
        for (var i = 0; i < approvalLevels.length; i++) {
            if (approvalLevels[i]._id.toString() == nextApprovalLevel) {
                backLevel = parseInt(approvalLevels[i].level);
                
            }
            if (backLevel > currentLevel) {
                console.log('cannot move forward on the signing chain');
                this.setState({invalidMsg: 'cannot move forward on the signing chain'});
                return;
            } else if (backLevel == currentLevel) {
                // then just update this current approvalLevel only, no need to touch on approval stage
                approvalLevel.signed = [];
                this.props.onUpdateApprovalLevel(approvalLevel, approval);
            } else if (backLevel < currentLevel) {
                if (parseInt(approvalLevels[i].level) >= backLevel) {
                    // remove the signatuer, start signging again
                    approvalLevels[i].signed = [];
                    updateApprovalLevels.push(approvalLevels[i]);
                }
                // move stage of approval backward
                approval.stage = nextApprovalLevel;
                // set status = rejected
                approval.status = 'rejected';
                // then, update approval
                this.props.onUpdateApproval(approval, approvalLevels)
            }
        }
        // update approval
    }
    
    render() {
        return (
            <Modal
                show={this.props.showRejectModal}
                onHide={() => this.props.hideRejectModal()}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Reject To
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InfoContainer>
                        <Select 
                            value={this.state.nextApprovalLevel} 
                            onChange={(e) => {
                                let {nextApprovalLevel} = this.state;
                                nextApprovalLevel = e.target.value;
                                this.setState(prevState => ({
                                    ... prevState,
                                    nextApprovalLevel,
                                }));
                            }}
                            width="150px"
                        >
                        {(this.props.approval? this.props.approval.approvalLevels : []).map(_approvalLevel => {
                            return (
                            <Option
                                selected={this.state.nextApprovalLevel ? (_approvalLevel._id.toString() === this.state.nextApprovalLevel.toString()) ? true : false : false}
                                value={_approvalLevel._id}>
                                {_approvalLevel.group}
                            </Option>
                            );
                        })}
                        </Select>
                    </InfoContainer>

                    <InfoContainer marginTop="15px">
                        <Input 
                            title={'Reason'}
                            className={'form-control'}
                            placeholder={'reason to reject'}
                            value={this.state.comment} 
                            onChange={(e) => {
                                let {comment} = this.state;
                                comment = e.target.value;
                                this.setState({comment});
                            }}
                        />
                    </InfoContainer>
                            
                    
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton onClick={() => this.onRejectApproval()}>Submit</SubmitButton>
                    <CancelButton onClick={() => this.props.hideRejectModal()}>Cancel</CancelButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = state => {

    return {
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signer: state.fetchData.signer,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateApproval: (approval, approvalLevel) => dispatch(updateApprovalAction(approval, approvalLevel)),
        onUpdateApprovalLevel: (approvalLevel, approval) => dispatch(updateApprovalLevelAction(approvalLevel, approval)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

