import React from 'react'
import { Redirect,Link } from 'react-router-dom'
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import SystemNavigation from '../../components/SystemNavigation'
import {
  fetchCompany,
} from '../../store/actions';
import {
  ContentContainer, Container, NavigationContainer, InfoContainer, Title, InnerContainer, FormContainer,
  FormBox, Label, Input, Select, Option, Button, LogoContainer, LogoBox, Logo, CustomLink,
} from './styles';

const util = require('util');

class CompanyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Header: 60px, Footer: 52px, border included
      height: window.innerHeight - 112,
      fullAddress: '',
      fullBankInfo: '',
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (!this.props.currentUser) {
      this.props.history.push('/views/login');
    } else {
      let currentUser = this.props.currentUser;
      let companyId = currentUser.company;
      console.log('company is of this user: ', companyId)
      if (!companyId) {
        this.props.history.push('/views/company/create');
      } else {
        if (!this.props.company) {
          // fet company profile
          this.props.onFetchCompanyProfile(companyId);
        }
      }
    }

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateCompany = (e) => {
      console.log('redirect to company_form');
      this.props.history.push('/views/company/update');
  }

  
  render() {
    return (
      <Container>
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="0" />
          </NavigationContainer>
          <InfoContainer>
            <Title>Thông tin đơn vị</Title>
            <InnerContainer>
              <FormContainer>
                <FormBox>
                  <Label>Mã số thuế</Label>
                  <Input 
                    value={this.props.company ? this.props.company.tfn : ''} 
                    disabled={true}
                  />
                </FormBox>
                <FormBox>
                  <Label>Tên công ty</Label>
                  <Input 
                    value={this.props.company ? this.props.company.name : ''} 
                    disabled={true}
                  />
                </FormBox>
                <FormBox>
                  <Label>Địa chỉ</Label>
                  <Input value={this.props.address ? (this.props.address.street + ", " + 
                    this.props.address.suburb + ", " + this.props.address.city) : ''}/>
                </FormBox>
                <FormBox>
                  <Label>Đại diện pháp luật</Label>
                  <Input 
                    value={this.props.company ? this.props.company.reprentative : ''} 
                    disabled={true}
                  />
                </FormBox>
                <FormBox>
                  <Label>Email</Label>
                  <Input 
                    value={this.props.company ? this.props.company.email : ''} 
                    disabled={true}
                  />
                </FormBox>
                <FormBox>
                  <Label>Số điện thoại</Label>
                  <Input 
                    value={this.props.company ? this.props.company.phone : ''} 
                    disabled={true}/>
                </FormBox>
                <FormBox>
                  <Label>Website</Label>
                  <Input 
                    value={this.props.company ? this.props.company.website : ''} 
                    disabled={true}/>
                </FormBox>
                <FormBox>
                  <Label>TK ngân hàng</Label>
                  <Select 
                    value={this.props.bankAccounts} 
                  >
                    {(this.props.bankAccounts ? this.props.bankAccounts : []).map(_bankAccount => {
                      return (
                        <Option
                          value={_bankAccount._id}>
                            {_bankAccount.account + ' ' + _bankAccount.bankName + ' : ' + _bankAccount.branch}
                        </Option>
                      );
                    })}
                  </Select>
                </FormBox>
                <FormBox>
                  <Label>Cơ quan thuế cấp cục</Label>
                  <Input 
                    value={this.props.company ? this.props.company.taxOfficeLevel1 : ''} 
                    disabled={true}
                  />
                </FormBox>
                <FormBox>
                  <Label>Cơ quan thuế quản lý</Label>
                  <Input 
                    value={this.props.company ? this.props.company.taxOfficeLevel2 : ''} 
                    disabled={true}
                  />
                </FormBox>
                <Button onClick={(e) => this.updateCompany(e)}>Cập nhật thông tin</Button>
              </FormContainer>
              {/* <LogoContainer>
                <LogoBox>
                  <Logo />
                </LogoBox>
                <CustomLink to='/views/company_detail'>Thay đổi Logo công ty</CustomLink>
              </LogoContainer> */}
            </InnerContainer>
          </InfoContainer>
        </ContentContainer>
        <Footer />
        <ToastContainer />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('check if company receive from reducer:');
  console.log(util.inspect(state.company.company));
  return {
      currentUser: state.authState.currentUser,
      company: state.company.company,
      address: state.company.address,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchCompanyProfile: (companyId) => dispatch(fetchCompany(companyId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);



/*********************End Stylesheet**********************/