import React, { Component } from 'react'
import styled from 'styled-components';

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

const ListBlockRender = (props) => {   
    const blocks = props.blocks;
    let i = 0;
    const listItems = blocks.map((block) =>
        <BlockItem>
            <li key={() => getRandomInt(10000)}>
                <ul>
                    <li>Block Number: {block.number}</li>
                    <li>Difficulty: {block.difficulty}</li>
                    <li>Gas Limit: {block.gasLimit}</li>
                    <li>Hash: {block.hash}</li>
                    <li>Parrent Hash: {block.parentHash}</li>
                </ul>       
            </li>
        </BlockItem>
        
    );
    return (
        <ul>{listItems}</ul>
    );
      
}

const BlockItem = styled.div` 
    border: 1px solid black;
    margin-bottom: 5px;
    list-style-type: none;
`;

export { ListBlockRender };