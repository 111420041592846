import React, { Component } from 'react';
import styled from 'styled-components';

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

const ListEventRender = (props) => {   
    const events = props.events;
    let i = 0;
    const listItems = events.map((event) =>
        <EventItem>
            <li key={() => getRandomInt(10000)}>
                <ul>
                    <li>Event Type: {event.event}</li>
                    <li>Transaction ID: {event.transactionHash}</li>
                    <li>From Block Number: {event.blockNumber}</li>
                    <li>From address: {JSON.stringify(event.returnValues)}</li>
                    <li>To address: {event.returnValues.to}</li>
                    <li>Value: {event.returnValues.value}</li>
                </ul>       
            </li>
        </EventItem>
        
    );
    return (
        <ul>{listItems}</ul>
    );      
}

const EventItem = styled.div` 
    border: 1px solid black;
    margin-bottom: 5px;
    list-style-type: none;
`;
export { ListEventRender };