export const validateLanguage = {
    nameInvalid: {
        en: "Invalid Name",
        vn: "Tên không hợp lệ"
    },
    companyNameInvalid: {
        en: "Invalid Company Name",
        vn: "Tên Công ty không hợp lệ"
    },
    productNameInvalid: {
        en: "Invalid Product Name",
        vn: "Tên hàng hoá không hợp lệ"
    },
    productCodeInvalid: {
        en: "Invalid Product Code",
        vn: "Mã hàng hoá không hợp lệ"
    },
    productUnitInvalid: {
        en: "Invalid Product Unit",
        vn: "Đơn vị không hợp lệ"
    },
    quantityInvalid: {
        en: "Invalid Quantity",
        vn: "Số lượng không hợp lệ"
    },
    priceInvalid: {
        en: "Invalid Price",
        vn: "Giá không hợp lệ"
    },
    passwordValidate: {
        en: "Password too simeple",
        vn: "Mật khẩu quá đơn giản"
    },
    mismatchPassword: {
        en: "Mismatch password", 
        vn: "Mật khẩu không khớp"
    },
    emailValidate: {
        en: "Invalid Email", 
        vn: "Email không hợp lệ"
    },
    phoneValidate: {
        en: "Invalid Phone Number",
        vn: "Số điện thoại không hợp lệ"
    },
    vnBankNameValidate: {
        en: "Invalid Bank Name",
        vn: "Tên Ngân hàng không hợp lệ"
    },
    vnBankBranchValidate: {
        en: "Invalid Bank Branch",
        vn: "Chi nhánh Ngân hàng không hợp lệ"
    },
    vnGeneralAddressValidate: {
        en: "Invalid Address",
        vn: "Địa chỉ không hợp lệ"
    },
    taxOfficeValidate: {
        en: "Invalid Tax Office Address",
        vn: "Địa chỉ Cơ quan Thuế không hợp lệ"
    },
    streetValidate: {
        en: "Invalid Street name",
        vn: "Tên đường/phố không hợp lệ"
    },
    suburbValidate: {
        en: "Invalid Suburb",
        vn: "Tên Quận/Huyện không hợp lệ"
    },
    cityValidate: {
        en: "Invalid City",
        vn: "Tên Tỉnh/TP không hợp lệ"
    },
    countryValidate: {
        en: "Invalid Country name",
        vn: "Tên Nước không hợp lệ"
    },
    urlValidate: {
        en: "website url invalid",
        vn: "Địa chỉ website không hợp lệ"
    },
    dateValidate: {
        en: "Invalid Date",
        vn: "Ngày tháng không hợp lệ"
    },
    bankAccountValidate: {
        en: "invalid bank account",
        vn: "Tài khoản Ngân hàng không hợp lệ"
    },
    vietnamTfnNumberValidate: {
        en: "invalid Tax File Number in Vietnam",
        vn: "Mã số thuế không hợp lệ"
    },
    quantityValidate: {
        en: "invalid quantity",
        vn: "số lượng không hợp lệ"
    },
    costValidate: {
        en: "invalid cost/price",
        vn: "Giá/Tiền không hợp lệ"
    },
    requireCorrectData: {
        en: "Please correct data: ",
        vn: "Cần kiểm tra dữ liệu: "
    },
    invoiceCodeValide: {
        en: "Invalid Invoice Code",
        vn: "Sai ký hiệu Hoá đơn"
    },
    userGroupName: {
        en: "Invalide user group name",
        vn: "Tên nhóm người dùng không hợp lệ"
    },
    invalidGeneralCode: {
        vn: "Invalid Code Number",
        vn: "Sai định dạng mã"
    }
}