import React from 'react'

import { connect } from 'react-redux';

import SystemNavigation from '../../components/SystemNavigation';

import {
  fetchGroups, emailLogoutAction, onClickCreateGroup, updateGroupGet,
} from '../../store/actions';

import {
  groupManagement, selectLanguage, userFormControl, formControl, userRole, userGroupStatus,
} from '../../langueges';
import {BaseLayout} from './BaseLayout';
import util from 'util';
import {BodyContainer, RightContainer} from './styles';

class SystemBaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Header: 60px, Footer: 52px, border included
      height: window.innerHeight - 112,

    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    let currentUser = this.props.currentUser;
    if (!currentUser) {
      return emailLogoutAction();
    }
    
  }

  renderLeftNavigation = () => {
    return (
      <SystemNavigation 
        leftMenuItem={this.props.leftMenuItem}
      />
    )
  }

  body() {
      return (
        <BodyContainer>
            <SystemNavigation 
                menuItem={this.props.leftMenuItem}
            />
            <RightContainer>
                {this.props.mainComponent} 
            </RightContainer>
            
        </BodyContainer>
      )
  }
  render() {
    return (
      <BaseLayout 
        mainComponent={this.body()} 
      />
    )
  }

}



const mapStateToProps = state => {
  return {
      // groups: state.group.groups,
      currentUser: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      // onFetchGroups: () => dispatch(fetchGroups()),
      // onClickCreateGroup: () => dispatch(onClickCreateGroup()),
      // onUpdateGroup: (_group) => dispatch(updateGroupGet(_group)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemBaseLayout);

