// import axios for fetching data
import axios from 'axios';
import { push } from 'react-router-redux';
import { history } from '../../App';

// import action types
import { 
    FETCH_COMPANY_FAILED,
    FETCH_COMPANY_SUCCESS,
    CREATE_BANKACCOUNT_SUCCESS,
    CREATE_BANKACCOUNT_FAILED,
    UPDATE_BANKACCOUNT_SUCCESS,
    UPDATE_BANKACCOUNT_FAILED,
    CREATE_ADDRESS_SUCCESS,
    CREATE_ADDRESS_FAILED,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAILED,
    CREATE_COMPANY_FAILED,
    CREATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILED,
    UPDATE_COMPANY_SUCCESS,
    ADD_NEW_ADDRESS_ACTION,
} from './actionTypes';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

// const url = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:8080/api/";


export const createAddress = (_address, prevPath) => {
    let {street, suburb, city, country} = _address;
    return async (dispatch) => {
        const res = await axios.post(`${url}company/address/create`, {
            street, suburb, city, country,
        });
        console.log('response from create address');
        console.log(util.inspect(res.data.address));
        if (res.data.address) {
            // update state of addresses
            dispatch({
                type: CREATE_ADDRESS_SUCCESS,    //data is thr user's data
                payload: res.data.address,
            });
            dispatch({
                type: ADD_NEW_ADDRESS_ACTION,
                payload: res.data.address,
            })
            // return res.data.company;
            history.push(prevPath)
        } else {
            dispatch({
                type: CREATE_ADDRESS_FAILED,
            });
        }
        
    }
}


// axios.post(`${url}company/address/${newAddress._id}/update`
export const updateAddress = (_address) => {
    let {street, suburb, city, country, _id} = _address;
    return async (dispatch) => {
        const res = await axios.post(`${url}company/address/${_id}/update`, {
            street, suburb, city, country,
        });
        console.log('response from create bankAccount');
        console.log(util.inspect(res.data.address));
        if (res.data.address) {
            // update state of addresses
            dispatch({
                type: UPDATE_ADDRESS_SUCCESS,    //data is thr user's data
                payload: res.data.address,
            });
            // return res.data.company;
            history.push('views/address/detail')
        } else {
            dispatch({
                type: UPDATE_ADDRESS_FAILED,
            });
            history.push('views/address/update')
        }
        
    }
}

export const createCompany = (_company) => {
    let {name, tfn, bankAccount, address, phone, email, website, taxOfficeLevel1, taxOfficeLevel2, admin, reprentative} = _company;
    return async (dispatch) => {
        const res = await axios.post(`${url}company/profile/create`, {
            name, tfn, bankAccount, address, phone, email, website, taxOfficeLevel1, taxOfficeLevel2, admin, reprentative,
        });
        console.log('response from create company');
        console.log(util.inspect(res.data.company));
        if (res.data.company) {
            // update state of addresses
            dispatch({
                type: CREATE_COMPANY_SUCCESS,    //data is thr user's data
                payload: res.data.company,
            });
            // return res.data.company;
            history.push('/views/company/detail');
        } else {
            dispatch({
                type: CREATE_COMPANY_FAILED,
                invalidMsg: res.data.invalidMsg,
                company: res.data.company,
            });
        }
        
    }
}

export const updateCompany = (_company) => {
    let {name, tfn, bankAccount, address, phone, email, website, taxOfficeLevel1, taxOfficeLevel2, admin, reprentative, _id} = _company;
    return async (dispatch) => {
        const res = await axios.post(`${url}company/profile/${_id}/update`, {
            name, tfn, bankAccount, address, phone, email, website, taxOfficeLevel1, taxOfficeLevel2, admin, reprentative,
        });
        console.log('response from create company');
        console.log(util.inspect(res.data.company));
        if (!res.data.invalidMsg) {
            // update state of addresses
            dispatch({
                type: UPDATE_COMPANY_SUCCESS,    //data is thr user's data
                payload: res.data.company,
            });
            // return res.data.company;
            history.push('/views/company/detail');
        } else {
            dispatch({
                type: UPDATE_COMPANY_FAILED,
                paylaod: {
                    company: res.data.company,
                    invalidMsg: res.data.invalidMsg ? res.data.invalidMsg : 'update company failed',
                }
            });
        }
        
    }
}

export const fetchCompany = (companyId) => {
    return async (dispatch) => {
        console.log('fetching company ..with companyId = ', companyId);
        const res = await axios.get(`${url}company/profile/${companyId}`);
        console.log('response from fetch company');
        console.log(util.inspect(res.data));
        if (res.data.company) {
            console.log('company received: ', util.inspect(res.data.company))
            // update state of addresses
            let address = res.data.company.address;
            console.log('company fetching at action: '); 
            console.log(util.inspect(res.data.company));
            dispatch({
                type: FETCH_COMPANY_SUCCESS,
                payload: {
                    company: res.data.company,
                    address: address,
                },      //data is thr user's data

            });
            // return res.data.company;
        } else {
            dispatch({
                type: FETCH_COMPANY_FAILED,
            });
        }
        
    }
}
