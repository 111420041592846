import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import DocumentBase from './DocumentBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, Title, Label,
  YellowButton, ContentContainer, RightContainer, HeaderContainer, DocumentHeader,
  GreenButton, Table, PagingContainer, RecordInfo, PButton, PageNumber, SearchBoxContainer,
  InfoContainer, DocumentFormContainer, DropzoneContainer, BackButton, DocumentHeadRight, DocumentHeadTitle,
  DocumentIcon, DocumentContainer, GrayBorderButton
} from './style';

import {
  Input,
} from '../../commons';

import {
  selectDocumentAction,
  fetchDocuments,
  createDocumentAction,
  getCreateDocument,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class DocumnetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.currentUser ? this.props.currentUser : null,
      document: {
        _id: '',
        docName: '',
        docType: '',
        description: '',
        uploadUser: '',
        uploadDate: '',
        attachFile: '',
        uploadUrl: '',
      },
      attachFile: null,
      fileReader: {},
    };
  }

  componentDidMount() {
  
    // fetch all Documents
    this.props.onFetchDocuments();
    // fetch all documentTypes
    this.props.onGetCreateDocument();
    // if this is update document
    if (this.props.document) {
      this.setState(prevState => ({
        ...prevState,
        document: this.props.document,
      }))
    }
  }
  componentWillUnmount() {
    
  }

  handleSelectDocumentType = (documentType) => {
    console.log('documentType selected: ', documentType);
    let document = this.state.document;
    document.docType = documentType.value;
    this.setState(prevState => ({
      ... prevState,
      document: document,
    }))
  }

  // handle upload file
  handleFileRead = () => {
    let fileReader = this.state.fileReader;
    const content = fileReader.result;
    console.log('content of attached file');
    console.log(util.inspect(content));
  }

  onDrop = (acceptedFiles) => {
    // let fileReader = new FileReader();
    // this.setState({fileReader});
    // fileReader.onloadend = this.handleFileRead;
    // fileReader.readAsText(acceptedFiles);

    console.log(util.inspect(acceptedFiles));
    console.log(util.inspect(acceptedFiles[0].path))
    console.log('examine the content of attached file: ');
    console.log(acceptedFiles[0]);
    // console.log(util.inspect(document));
    let {document} = this.state;
    document.uploadUrl = acceptedFiles[0].path;
    this.setState(prevState => ({
      ...prevState,
      // document: document,
      attachFile: acceptedFiles[0],
      document,
    }))
  }

  onSubmitCreateDocument = () => {
    // handle external document
    console.log('submit creating document ..');
    const {document} = this.state;
    document.file = this.state.attachFile;
    let user = this.props.currentUser;
    document.company = user.company ? user.company._id? user.company._id : user.company : '';
    document.uploadUser = user._id;
    console.log('document before sending to action');
    console.log(document);
    // return;
    this.props.onCreateDocument(document);
  }
  
  createDocumentHandler = (e) => {
    // handle external document
    const _data = new FormData();
    _data.append('file', this.state.attachFile);
    console.log('file data: ', util.inspect(_data));
    return;
  }

  onChangeUploadFileHandler = (e) => {
    console.log('file attched: ', util.inspect(e.target.files[0]));
    console.log('file attached: ', e.target.files[0]);
    let acceptedFiles = e.target.files;
    let {document} = this.state;
    // _docFullname = acceptedFiles[0].path;
    document.uploadUrl = acceptedFiles[0].path;
    this.setState(prevState => ({
      ...prevState,
      // document: document,
      attachFile: acceptedFiles[0],
      document,
    }))
  }

 body = () => {
   return (
    <ContentContainer>
      <DocumentContainer>
        <DocumentHeader bgColor="#373C44" padding="10px 15px" marginBottom="10px">
          <DocumentHeadTitle color="#FFFFFF"><DocumentIcon />Upload Document</DocumentHeadTitle>
          <DocumentHeadRight>
            <GrayBorderButton marginRight="8px" onClick={() => this.onSubmitCreateDocument()}>Submit</GrayBorderButton>
            <BackButton to='/views/document/list'>Back</BackButton>
          </DocumentHeadRight>
        </DocumentHeader>

        <DocumentFormContainer>
          <Label>{"Document Type"} {this.state.document.docType}</Label>
          <ReactSearchBox
            placeholder="specify type of document"
            value=""
            data={this.props.documentTypes}
            callback={(record )=> {
              console.log(record);
            }}
            // onChange={(e) => {
            //   let {searchValue} = this.state;
            //   searchValue = e.target.value;
            //   this.setState(searchValue);
            // }}
            onSelect={(documentType) => {
              this.handleSelectDocumentType(documentType)
            }}
            onFocus={() => {
              console.log('This function is called when is focussed')
            }}
            onChange={value => console.log(value)}
            fuseConfigs={{
              threshold: 0.05,
            }}
          />
          <InfoContainer>
            <Input 
              title={'Document Name'}
              className={'form-control'}
              placeholder={'enter document name'}
              value={this.state.document.docName} 
              onChange={(e) => {
                let {document} = this.state;
                document.docName = e.target.value;
                this.setState({document});
              }}
            />
          </InfoContainer>
            
          <InfoContainer>
            <Input 
              title={'Document Description'}
              className={'form-control'}
              placeholder={'document description'}
              value={this.state.document.dé } 
              onChange={(e) => {
                let {document} = this.state;
                document.description = e.target.value;
                this.setState({document});
              }}
            />
          </InfoContainer>
          {/* <InfoContainer>
            <Label>Upload file: {this.state.document.uploadUrl}</Label>
            <input type="file" name="file" onChange={this.onChangeUploadFileHandler}/>
          </InfoContainer> */}
          <InfoContainer>
            <Label>Click on or drop to upload document: </Label>
            <DropzoneContainer>
              <Dropzone 
                onDrop={this.onDrop}
                // accept="image/png, image/gif, docx/docx, doc/doc, pdf/pdf, exell/csv"
                minSize={0}
                maxSize={52428800}
                accept="application/pdf, image/png, image/gif, docx/docx, doc/doc, pdf/pdf, exell/csv"
              >
                {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive && 'Click here or drop a file to upload!'}
                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                    {isDragReject && "File type not accepted, sorry!"}
                  </div>
                )}
              </Dropzone>
            </DropzoneContainer>
          </InfoContainer>
        <InfoContainer>
          <Label>{"Uploaded Date"}</Label>
        </InfoContainer>
      </DocumentFormContainer>

      </DocumentContainer>
  </ContentContainer>
   )
 }
  
  render() {
    return (
      <DocumentBase 
        contentContainer = {this.body()}
        onSubmitDocument={() => this.onSubmitCreateDocument()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log(`current list of DocumentTypes are `);
  console.log(util.inspect(state.document.documentTypes));
  return {
      documentNames: state.document.documentNames,
      documents: state.document.documents,
      documentTypes: state.document.documentTypes,
      currentUser: state.authState.currentUser,
      document: state.document.document,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      onFetchDocuments: () => dispatch(fetchDocuments()),
      onSelectedDocument: (Document) => dispatch(selectDocumentAction(Document)),
      onGetCreateDocument: () => dispatch(getCreateDocument()),
      onCreateDocument: (document) => dispatch(createDocumentAction(document, false)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumnetForm);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

