import { 
    FETCH_FARMDATAS_FAILED,
    FETCH_FARMDATAS_SUCCESS,
    GET_CREATE_FARMDATA_SECCUSS,
    GET_CREATE_FARMDATA_FAILED,
    SELECT_CREATE_FARMDATA_ACTION,
    SELECTED_FARMDATA,
    ON_CLICK_UPDATE_FARMDATA,
    CREATE_FARMDATA_ACTION_SUCCESS,
    CREATE_FARMDATA_ACTION_FAILED,
    FETCH_AUTO_GENERATED_FARMDATA_SUCCESS,
    FETCH_AUTO_GENERATED_FARMDATA_FAILED,
    FARMDATA_IMAGE_UPLOAD_GENERATE_URL_SUCCESS,
    FARMDATA_IMAGE_UPLOAD_GENERATE_URL_FAIL,
} from '../actions/actionTypes'; 


 // initicate email and pass to prevent from returning undefined object
const INIT_STATE = { 
    farmDatas: [],
    farmDataKeyValues: [],
    farmDataTypes: [],
    farmData: null,
    page: 'list',
    hideFarmDataModal: true,
    autoGeneratedFarmData: null,
    imageUrl: 'localhost',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FARMDATA_IMAGE_UPLOAD_GENERATE_URL_SUCCESS:
            return {
                ... state,
                imageUrl: action.payload.imageUrl,
            }
        case FETCH_AUTO_GENERATED_FARMDATA_SUCCESS:
            return {
                ... state,
                autoGeneratedFarmData: action.payload.autoGeneratedFarmData
            }
        case FETCH_AUTO_GENERATED_FARMDATA_FAILED:
            return {
                ... state,
                autoGeneratedFarmData: null
            }
        case CREATE_FARMDATA_ACTION_SUCCESS:
            return {
                ...state,
                farmData: action.payload.farmDatas,
                farmDataKeyValues: action.payload.farmDataKeyValues,
                hideFarmDataModal: action.payload.hideFarmDataModal ? action.payload.hideFarmDataModal : true,
                page: 'detail',
            }
        case CREATE_FARMDATA_ACTION_FAILED:
            return {
                ...state,
                farmData: action.payload.farmData,
                page: 'create',
            }
        case ON_CLICK_UPDATE_FARMDATA: 
            return {
                ...state,
                farmData: action.payload.selectedFarmData,
                page: action.payload.page,
            }
        case SELECTED_FARMDATA:
            return {
                ...state,
                farmData: action.payload.selectedFarmData,
                page: 'detail',
            }
        case SELECT_CREATE_FARMDATA_ACTION:
            return {
                ... state,
                farmData: null,
                page: 'create',
            }
        case FETCH_FARMDATAS_SUCCESS:
            return {
                ...state,
                farmDataKeyValues: action.payload.farmDataKeyValues,
                farmDatas: action.payload.farmDatas,
            }
        case FETCH_FARMDATAS_FAILED:
            return {
                ...state,
                farmDataKeyValues: action.payload.farmDataKeyValues,
                farmDatas: action.payload.farmDatas
            }
        case GET_CREATE_FARMDATA_SECCUSS:
            return {
                ...state,
                farmDataTypes: action.payload.farmDataTypes,
                page: 'create',
            }
        case GET_CREATE_FARMDATA_FAILED:
            return {
                ...state,
                farmDataTypes: action.payload.farmDataTypes,
                page: 'create',
            }
        default:
            return state;
    }
};