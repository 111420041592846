import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import userAvatar from '../../assets/images/avatar186x148.png'
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import SystemBaseLayout from '../layout/SystemLayout';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { authenticationService, fetchData } from '../../services';
import {
  createSignature, updateSignature, showProgress,
} from '../../store/actions';
import {
  mismatchPassword, passwordValidate, dateValidate, dateSelectValidate,
} from '../../services/validate';
import {
  SpanLabel,
} from '../../commons';
import {
  selectLanguage, companyCreate, formControl, userPermission, signatureLanguage
} from '../../langueges';
const util = require('util');
const {
  nameValidate,
  streetValidate,
  suburbValidate,
  cityValidate,
  countryValidate,
} = require('../../services/validate');

class SignatureCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        height: window.innerHeight - 112,
        user: this.props.currentUser,
        signature: {
            password: '',
            confirmPassword: '',
            effectiveDate: new Date(),
            lastModified: new Date(),
            creator: this.props.currentUser,
        },
        invalidMsg: '',
        editPermission: true,
        deletePermission: true,
        existingCheck: false,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // let requestedId = this.state.signatureId;
    // let requestedId = this.state.signatureId;
    if (this.props.currentUser) {
      if (this.props.signature) {
        if (this.props.signature._id) {
          this.setState({
            existingCheck: true,
          });
        }
        let signature = this.props.signature;
        signature.effectiveDate = signature.effectiveDate ? new Date(signature.effectiveDate) : new Date();
        signature.lastModified = signature.lastModified ? new Date(signature.lastModified) : new Date();
        this.setState({
          signature: signature,
        });
      }
      this.checkEditPermission();
      this.checkDeletePermission();
      this.setState( prevState => ({ 
          ...prevState,
          user: this.props.currentUser,
        }))
    } else {
        const _redirectLoginPath = '/views/login';
        return this.props.history.push(_redirectLoginPath);
    }
  }

  createSignatureHandler = (e) => {
    if (this.state.editPermission == false) {
      return this.setState(prevState => ({
        ... prevState,
        invalidMsg: selectLanguage(this.props.language, userPermission.accessDeny),
      }))
    }
    if (this.state.invalidMsg != '') {
      this.setState(prevState => ({
        ... prevState,
        invalidMsg: selectLanguage(this.props.language, formControl.validateInfoRequire),
      }))
    } else {
      let newSignature = this.state.signature;
      if (!this.props.currentUser) {
        console.log('no user attched for this signature, exit');
        return;
      }
      let prevPath = this.props.prevPath ? this.props.prevPath : '/views/signature/detail';
      console.log('observe signature obj before passing to server');
      console.log(util.inspect(newSignature));
      this.props.onShowProgressSpinner();
      if (this.state.existingCheck) {
        this.props.onUpdateSignature(newSignature);
      } else {
        this.props.onCreateSignature(newSignature, prevPath);
      }
      
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }


  checkEditPermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let editPermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('editInfo')) {
        editPermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        editPermission = true;
      }
      this.setState({editPermission: true})
    }
  }

  checkDeletePermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let deletePermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('deleteInfo')) {
        deletePermission = true;
      }
      this.setState({deletePermission: true})
    }
  }

  handleEffectiveDateSelect = (_value) => {
    console.log('selected date: ', _value);
    if (dateSelectValidate(this.props.language, _value) !== "") {
      this.setState({invalidMsg: dateSelectValidate(this.props.language, _value)})
    } else {
      let {signature} = this.state;
      signature.effectiveDate = _value;
      this.setState({signature});
    }
  }



  handleEffectiveDateChange = (_value) => {
    console.log('selected date: ', _value);
    if (dateSelectValidate(this.props.language, _value) !== "") {
      this.setState({invalidMsg: dateSelectValidate(this.props.language, _value)})
    } 
  }

  renderCreateUpdateButton() {
    const editPermission = this.state.editPermission;
    if (editPermission == true) {
      return (
        <Button onClick={(e) => this.createSignatureHandler(e)}>
          {selectLanguage(this.props.language, signatureLanguage.generateSignature)}
        </Button>
      )
    } else {
      return null;
    }
  }

  // renderDeleteButton() {
  //   const deletePermission = this.state.deletePermission;
  //   if (deletePermission == true) {
  //     return (
  //       <Button onClick={() => this.onDeleteHandler(this.props.customer)} backgroundColor="#F2F2F2" color="#303030" marginRight="10px">
  //         {selectLanguage(this.props.language, customerCreate.delete)}
  //       </Button>
  //     )
  //   } else {
  //     return null;
  //   }
  // }

  // renderProgresSpinner() {
  //   if (this.props.progress) {
  //     return (
  //       <CircularProgress />
  //     )
  //   }
  // }


  body() {
    return (
          <InfoContainer>
            {/* {this.renderProgresSpinner} */}
            <FormHeader>
              <Title>{selectLanguage(this.props.language, signatureLanguage.createSignature)}</Title>
              <BackButton to={this.props.prevPath ? this.props.prevPath : '/views/signature/detail'}><LeftArrow />
              {selectLanguage(this.props.language, formControl.back)}</BackButton>
            </FormHeader>
            <InnerContainer>
              <FormContainer>
                {
                  (this.state.invalidMsg != '') ? 
                  <SpanLabel 
                    text={this.state.invalidMsg}
                    style={{color: 'red'}}
                  /> : null
                }
                <FormBox>
                  <Label>{selectLanguage(this.props.language, signatureLanguage.signaturePassword)}</Label>
                  <Input 
                    placeholder={selectLanguage(this.props.language, signatureLanguage.signaturePassword)}
                    type="password" 
                    value={this.state.signature.password} 
                    onChange={(e) => {
                      let {signature} = this.state;
                      signature.password = e.target.value;
                      this.setState({signature});
                      let _validateResult = passwordValidate(this.props.language, e.target.value);
                      this.setState(prevState => ({
                        ...prevState,
                        invalidMsg: _validateResult,
                      }))
                    }}  
                  />
                </FormBox>
                <FormBox>
                  <Label>{selectLanguage(this.props.language, signatureLanguage.confirmSignaturePassword)}</Label>
                  <Input 
                  placeholder={selectLanguage(this.props.language, signatureLanguage.confirmSignaturePassword)}
                  type="password" 
                  value={this.state.signature.confirmPassword} 
                  onChange={(e) => {
                    let {signature} = this.state;
                    signature.confirmPassword = e.target.value;
                    this.setState({signature});
                    let _validateResult = mismatchPassword(this.props.language, this.state.signature.password, e.target.value);
                    this.setState(prevState => ({
                      ...prevState,
                      invalidMsg: _validateResult,
                    }))
                  }}  
                />
                </FormBox>
                <FormBox>
                  <Label>{selectLanguage(this.props.language, signatureLanguage.effectiveDate)}</Label>
                  <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={this.state.signature.effectiveDate}
                      onSelect={this.handleEffectiveDateSelect} //when day is clicked
                      onChange={(value) => this.handleEffectiveDateChange(value)} //only when value has changed
                      customInput={<Input />}
                  />
                 
                </FormBox>
      
                {this.renderCreateUpdateButton()}
                {/* <Button onClick={this.passwordChangeUpHandler}Thê
                  hidden={this.state.existingCheck ? false : true}
                >
                  Đổi Mật Khẩu
                </Button> */}
                {/* <LinkContainer>
                  Đã có tài khoản: <CustomLink to='/'>Đăng nhập</CustomLink>
                </LinkContainer> */}
              </FormContainer>
            </InnerContainer>
          </InfoContainer>
    );
  }

  render() {
    return (
      <SystemBaseLayout 
        leftMenuItem={"5"}
        mainComponent={this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('what is prevPath from reducer:');
  console.log(util.inspect(state.signatureState.prevPath));
  console.log('signature received from reducer: ', util.inspect(state.signatureState.signature));
  return {
      currentUser: state.authState.currentUser,
      groups: state.group.groups,
      language: state.topMenu.language,
      signature: state.signatureState.signature,
      prevPath: state.signatureState.prevPath,
      company: state.company.company,
      progress: state.feedbackState.progress,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateSignature: (_signature, prevPath) => dispatch(createSignature(_signature, prevPath)),
    onUpdateSignature: (_signature) => dispatch(updateSignature(_signature)),
    onShowProgressSpinner: () => dispatch(showProgress()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignatureCreate);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const BackButton = styled(Link)`
  text-decoration: none;
  border: solid 1px #707070;
  color: #303030;
  height: 36px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const LeftArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

const Label = styled.div`
  height: 42px;
  line-height: 42px;
  width: 160px;
`;

const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

const Logo = styled.div`
  width: 186px;
  height: 148px;
  background-image: url(${userAvatar});
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  margin: 20px 0px 20px 160px;
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  border: solid 1px #707070;
  background-color: #2680EB;
  color: #ffffff;
  height: 40px;
  width: 390px;
  font-size: 16px;
  margin: 20px 0px 20px 160px;
  cursor: pointer;
`;

const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 44px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 410px;
`;

const Option = styled.option`
`;

const ConfirmBox = styled.div`
  width: 410px;
  height: 130px;
  border: solid 1px #B7B7B7;
  background-color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const ConfirmationText = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

const FormTitle = styled.div`
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
`;
/*********************End Stylesheet**********************/