import styled from 'styled-components'
import facebookIcon from '../../assets/images/icons/facebook24x24.png'
import googleIcon from '../../assets/images/icons/google24x24.png'
import twitterIcon from '../../assets/images/icons/twitter24x24.png'

import BLogo from '../../assets/images/Be_Sign_70x34.png'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const BeContainer = styled.section`
  background-color: #1A1D21;
  color: #FFFFFF;
  font-size: 12px;
  padding-bottom: 40px;
`;

export const ContentContainer = styled(Container)`
  
`;

export const FooterComponent = styled(Col)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const FooterTitle = styled.div`
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const FooterText = styled.div`
  margin-top: 10px;
`;

export const FacebookIcon = styled.div`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  background-image: url(${facebookIcon});
  background-repeat: no-repeat;
`;

export const GoogleIcon = styled.div`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  background-image: url(${googleIcon});
  background-repeat: no-repeat;
`;

export const TwitterIcon = styled.div`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  background-image: url(${twitterIcon});
  background-repeat: no-repeat;
`;

export const RowContainer = styled(Row)`
`;

export const BeLogo = styled.div`
  width: 70px;
  height: 34px;
  background-image: url(${BLogo});
  background-repeat: no-repeat;
`;