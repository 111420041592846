import React from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import SystemNavigation from '../../components/SystemNavigation'
import searchIcon from '../../assets/images/icons/search22x22.png'
import {
  fetchUsers,
  onViewUserDetail,
  onClickCreateNewUser,
  createUserGet, updateUserGet, fetchUser,
} from '../../store/actions';



const util = require('util');

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Header: 60px, Footer: 52px, border included
      height: window.innerHeight - 112,
      users: [],
      redirect: false,
      selectedUserId: '0',
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (!this.props.currentUser) {
      return this.props.history.push('/views/login');
    }
    this.props.onFetchUsers();
  }

  onUpdateUser(e) {
    e.preventDefault();
    const selectedId = e.currentTarget.dataset.id;
    this.setState({
      selectedUserId: selectedId,
      redirect: true
    })
  }

  onCreateUser = () => {
    this.props.onClickCreateUser();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderTableData() {
    if (this.props.users) {
      const _users = this.props.users;
      if (_users instanceof Array) {
        return _users.map((user, index) => {
          const { _id, firstname, lastname, company, email, phone, role } = user //destructuring
          // const companyName = company.name;
          const name = lastname + ' ' + firstname;
          let status = 'active';
          let roleLabel = '';
          let statusLabel = '';
          switch(role) {
           case 'admin':
             roleLabel = 'Administrator';
             break;
           case 'manager':
             roleLabel = 'Manager';
             break;
           default:
             roleLabel = 'User';
         }
   
         switch(status) {
           case 'pending':
             statusLabel = 'pending';
             break;
           case 'active':
             statusLabel = 'active';
             break;
           default:
             statusLabel = 'inactive';
         }
   
          return (
             <TR key={_id}>
               <TD>{(index + 1)}</TD>
               <TD>{name}</TD>
               <TD></TD>
               <TD>{email}</TD>
               <TD>{phone}</TD>
               <TD>{roleLabel}</TD>
               <TD>{statusLabel}</TD>
               <TD align="right">
                 <YellowButton onClick={() => this.onViewUserDetail(user)}>{"Update"}</YellowButton>
               </TD>
             </TR>
          )
       })
      } else {
        return null;
      }
      
    } else {
      return null;
    }
    
  }

  onViewUserDetail = (user) => {
    this.props.onViewUserDetail(user);
  }

  // onCreateUser = () => {
  //   this.props.onClickCreateNewUser();
  // }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: '/views/user/detail',
        state: {userId: this.state.selectedUserId}
      }} />
    }
  }

  render() {
    return (
      <Container>
        {this.renderRedirect()}
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="1" />
          </NavigationContainer>
          <RightContainer>
            <HeaderContainer>
              <Title>{"User Managerment"}</Title>
              <HeaderRight>
                <SearchContainer>
                  <SearchInput placeholder="search by name, phone, etc.." />
                  <SearchIcon />
                </SearchContainer>
                <GreenButton onClick={() => this.onCreateUser()}><PlusIcon> + </PlusIcon>{"Add New User"}</GreenButton>
              </HeaderRight>
            </HeaderContainer>
            <TableContainer>
              <Table>
                <THEAD>
                  <TR>
                    <TH width="5%">{"Item"}</TH>
                    <TH width="15%">{"Name"}</TH>
                    <TH width="25%">{"Company"}</TH>
                    <TH width="15%">{"Email"}</TH>
                    <TH width="10%">{"Phone"}</TH>
                    <TH width="10%">{"Roles"}</TH>
                    <TH width="10%">{"Status"}</TH>
                    <TH width="10%"></TH>
                  </TR>
                </THEAD>
                
                <TBODY>
                  {this.renderTableData()}
                </TBODY>
              </Table>

              <PagingContainer>
                <RecordInfo><BoldText>1 - 15</BoldText> {"page of 25 records"}</RecordInfo>
                <PagingButtons>
                  <PButton>&lt;&lt;</PButton>
                  <PButton>&lt;</PButton>
                  <PageNumber>1</PageNumber>
                  <PButton>&gt;</PButton>
                  <PButton>&gt;&gt;</PButton>
                </PagingButtons>
              </PagingContainer>
            </TableContainer>
          </RightContainer>
        </ContentContainer>
        <Footer />
      </Container>
    );
  }
}

const mapStateToProps = state => {
 
  return {
      currentUser: state.authState.currentUser,
      company: state.company.company,
      users: state.user.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // fetch somehting 
      onFetchUsers: () => dispatch(fetchUsers()),
      onViewUserDetail: (user) => dispatch(onViewUserDetail(user)),
      // onClickCreateNewUser: () => dispatch(onClickCreateNewUser()),
      onClickCreateUser: () => dispatch(createUserGet()),
      onClickUpdateUser: (user) => dispatch(updateUserGet(user)),
      onViewUserDetail: (user) => dispatch(fetchUser(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const HeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const GreenButton = styled.button`
  border: solid 1px #707070;
  background-color: #17C177;
  color: #ffffff;
  height: 40px;
  padding: 0px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchContainer = styled.div`
  border: solid 1px #B7B7B7;
  background-color: #ffffff;
  height: 40px;
  width: 529px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const SearchInput = styled.input`
  width: 490px;
  height: 38px;
  border-width: 0px;
  padding-left: 10px;
  font-size: 14px;

  :focus {
    outline: none;
  }
`;

const SearchIcon = styled.button`
  border-width: 0px;
  height: 22px;
  width: 22px;
  background-color: #FFFFFF;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  cursor: pointer;
`;

const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: solid 1px #A1A1A1;
`;

const THEAD = styled.thead`
  
`;

const TBODY = styled.tbody`
  
`;

const TR = styled.tr`
  border: solid 1px #A1A1A1;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:hover {
    background-color:#FFF1C4;
  }
`;

const TH = styled.th.attrs(props => ({
  width: props.width
  }))`
  padding: 10px;
  font-weight: bold;
  background-color: #E1E1E1;
  text-align: left;
  border-collapse: collapse;
  width: ${props => props.width};
}
`;

const TD = styled.td.attrs(props => ({
  paddingLeft: props.paddingLeft,
  align: props.align
  }))`
  text-align: ${props => props.align || "left"};
  padding-top: 6px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: ${props => props.paddingLeft || "10px"};
`;

const YellowButton = styled.button`
  border: solid 1px #707070;
  background-color: #FFD86D;
  color: #272727;
  height: 30px;
  width: 90px;
  padding: 0px 10px;
  font-size: 14px;
  cursor: pointer;
`;

const PagingContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const RecordInfo = styled.div`
  align-items: center;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const PagingButtons = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const PButton = styled.button`
  border: solid 1px #707070;
  background-color: #FFFFFF;
  color: #272727;
  width: 33px;
  height: 25px;
  cursor: pointer;
  margin-left: 6px;
`;

const PageNumber = styled.div`
  border: solid 1px #707070;
  background-color: #484848;
  color: #FFFFFF;
  font-weight: bold;
  width: 33px;
  height: 23px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/*********************End Stylesheet**********************/