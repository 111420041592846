import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
// import {Link} from 'react-router-dom';
// import Dropzone from 'react-dropzone';
// import ApprovalBase from './ApprovalBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, Title, Label, RemoveButton, InputContainer,
  Table, SearchboxContainer,
} from '../style';

import {
  CancelButton, SubmitButton, Input, DelegateContentContainer, InfoContainer
} from './style';

import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../../store/actions';

const util = require('util');
class DelegateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            approvalLevel: null,
            comment: '',
        };
    }

    componentDidMount() {
        // fetch all Documents
        if (this.props.approvalLevel) {
            let {approvalLevel} = this.state;
            approvalLevel = this.props.approvalLevel;
            this.setState({approvalLevel});
        } 
    }
    
    onDelegateApproval = () => {
        // add signature to approvalLevel accordingly
        let approvalLevel = this.state.approvalLevel;
        //let approval = this.state.approval;
        let comment = this.state.comment;
        let approval = this.props.approval;
        let fullname = this.props.currentUser.firstname + ' ' + this.props.currentUser.lastname;
        if (!approvalLevel.comments) {
            approvalLevel.comments = [];
            approvalLevel.comments.push(fullname + ': ' + comment);
        } else {
            approvalLevel.comments.push(fullname + ': ' + comment);
        }
        console.log('approvalLevel after adding signers: ');
        console.log(util.inspect(approvalLevel));
        // return;
        if (approvalLevel && approval) {
            this.props.onUpdateApprovalLevel(approvalLevel, approval);
        }
    }

    handleAddingSigner = (_invidualSignerInfo) => {
        {
          console.log('Signer selected is: ', _invidualSignerInfo);
          let approval = this.props.approval;
          if (approval) {
            let signers = this.props.signers ? this.props.signers : [];
            let {approvalLevel} = this.state;
            let _levelSigners = approvalLevel.signers;
            // adding new signer
            signers.forEach((_row, _index) => {
              console.log('examin this signer row info: ', util.inspect(_row));
              if (_row._id.toString() == _invidualSignerInfo.key.toString()) {
                _levelSigners.push(_row);
              }
            })
            approvalLevel.signers = _levelSigners;
            this.setState(prevState => ({
                ... prevState,
                approvalLevel: approvalLevel,
            }))
          }
          
        }
    }

    onRemoveSigner = (signerId) => {
      let {approvalLevel} = this.state;
      let _existingSigner = approvalLevel.signers;
      let _newSigners = []
      _existingSigner.forEach((_row, _index) => {
        if (_row._id.toString() != signerId.toString()) {
          _newSigners.push(_row);
        }
      })
      approvalLevel.signers = _newSigners
      this.setState(prevState => ({
        ... prevState,
        approvalLevel,
      }))
    }

    renderSignerInfoTable() {
      if (!this.state.approvalLevel) {
        return null;
      }
      let _levelSigners = this.state.approvalLevel.signers;
      if (!_levelSigners) {
        return (
          <div>Loading ..</div>
        )
      }
      if (_levelSigners.length > 0) {
        return _levelSigners.map((_signerInfo, _index) => {
          let { _id, fullName, role, firstname, lastname} = _signerInfo //destructuring
          let __index = parseInt(_index) + 1;
          if (firstname) {
            fullName = firstname + ' ' + lastname
          }
          return (
              <Tr key={_id}>
                <Td>{__index ? __index.toString() : ''}</Td>
                <Td>{fullName ? fullName : ''}</Td>
                <Td>{role ? role : ''}</Td>
                <Td paddingLeft="0px" align="right">
                  <RemoveButton
                    onClick = {() => {
                      this.onViewDocumentDetail(_id)
                    }}
                >Xem</RemoveButton></Td>
                <Td paddingLeft="0px" align="right">
                  <RemoveButton
                  onClick = {() => {
                    this.onRemoveSigner(_id)
                  }}
                >X</RemoveButton></Td>
              </Tr>
          )
        })
      } else {
        return (
          <div>Loading ..</div>
        )
      }
    }

    body = () => {
        return (
          <DelegateContentContainer>
            <InputContainer>
              <SearchboxContainer flexGrow={4}>
              <ReactSearchBox
                  placeholder="find person to delegate"
                  value=""
                  data={this.props.signersInfo}
                  callback={(record )=> {
                  console.log(record);
                  }}
                  // onChange={(e) => {
                  //   let {searchValue} = this.state;
                  //   searchValue = e.target.value;
                  //   this.setState(searchValue);
                  // }}
                  onSelect={(_invidualSignerInfo) => {
                      this.handleAddingSigner(_invidualSignerInfo)
                  }}
                  onFocus={() => {
                  console.log('This function is called when is focussed')
                  }}
                  onChange={value => console.log(value)}
                  fuseConfigs={{
                  threshold: 0.05,
                  }}
              />
              </SearchboxContainer>
            </InputContainer>
            <Table>
                <THead>
                <Tr>
                    <Th>{"Item"}</Th>
                    <Th>{"Name"}</Th>
                    <Th>{"Role"}</Th>
                    <Th>{"View"}</Th>
                    <Th>{"Remove"}</Th>
                </Tr>
                </THead>
                
                <TBody>
                {this.renderSignerInfoTable()}
                </TBody>
            </Table>

            <InfoContainer marginTop="15px">
              <Input 
              title={'Note'}
              className={'form-control'}
              placeholder={'Comment if needed'}
              value={this.state.comment} 
              onChange={(e) => {
                  let {comment} = this.state;
                  comment = e.target.value;
                  this.setState({comment});
              }}
              />
            </InfoContainer>
          </DelegateContentContainer>
        )
    }
    
    render() {
      if (this.props.approvalLevel) {
        return (
          <Modal
            show={this.props.showDelegateModal}
            onHide={() => this.props.hideDelegateModal()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delegate To
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.body()}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton onClick={() => this.onDelegateApproval()}>Submit</SubmitButton>
              <CancelButton onClick={() => this.props.hideDelegateModal()}>Cancel</CancelButton>
            </Modal.Footer>
          </Modal>
        );
      } else {
        return null;
      }
    }
}

const mapStateToProps = state => {

    return {
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signers: state.fetchData.signers,
        signersInfo: state.fetchData.signersInfo,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
        // onUpdateApproval: (approval, approvalLevels) => dispatch(updateApprovalAction(approval, approvalLevels)),
        onUpdateApprovalLevel: (approvalLevel, approval) => dispatch(updateApprovalLevelAction(approvalLevel, approval)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DelegateModal);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

