import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
// import {Link} from 'react-router-dom';
// import Dropzone from 'react-dropzone';
// import ApprovalBase from './ApprovalBase';
// import styles

import {
  CancelButton, SubmitButton, Input, LoadTemplateContentContainer, InfoContainer, RadioButton, Tr, Td, Th, TBody, THead, Title, Label, RemoveButton, InputContainer,
  Table, SearchboxContainer,
} from './style';

import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../store/actions';

const util = require('util');
class LoadTemplateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            approvalLevel: null,
            comment: '',
        };
    }

    componentDidMount() {
        // fetch all Documents
        if (this.props.approvalLevel) {
            let {approvalLevel} = this.state;
            approvalLevel = this.props.approvalLevel;
            this.setState({approvalLevel});
        } 
    }
    
    onSelectTemplate = () => {
        console.log('On Select Template');
    }

    onClickTemplate = (templateId) => {
      var selectedElement = document.getElementById(templateId);
      selectedElement.checked = true;
    }

    renderTemplateInfoTable() {

      return (
        <TBody>
          <Tr  onClick = {() => {this.onClickTemplate("TEM-859494032")}}>
            <Td>TEM-859494032</Td>
            <Td>Mẫu hợp đồng hợp tác 2 bên</Td>
            <Td>Ha Pham</Td>
            <Td paddingLeft="0px" align="right">
              <RadioButton id = "TEM-859494032" type="radio" value="TEM-859494032" name="template"></RadioButton></Td>
          </Tr>
          <Tr onClick = {() => {this.onClickTemplate("TEM-982656279")}}>
            <Td>TEM-982656279</Td>
            <Td>Mẫu hợp đồng hợp tác 5 bên</Td>
            <Td>Cuong Bui</Td>
            <Td paddingLeft="0px" align="right">
              <RadioButton id="TEM-982656279" type="radio" value="TEM-982656279" name="template"></RadioButton></Td>
          </Tr>
          <Tr onClick = {() => {this.onClickTemplate("TEM-157904567")}}>
            <Td>TEM-157904567</Td>
            <Td>Mẫu hợp đồng khách hàng cá nhân</Td>
            <Td>Bac Ninh</Td>
            <Td paddingLeft="0px" align="right">
              <RadioButton id = "TEM-157904567" type="radio" value="TEM-157904567" name="template">
              </RadioButton>
            </Td>
          </Tr>
          
        </TBody>
      )
    }

    body = () => {
        return (
          <LoadTemplateContentContainer>
            <InputContainer>
              <SearchboxContainer flexGrow={4}>
              <ReactSearchBox
                  placeholder="Template name"
                  value=""
                  data={this.props.signersInfo}
                  callback={(record )=> {
                  console.log(record);
                  }}
                  // onChange={(e) => {
                  //   let {searchValue} = this.state;
                  //   searchValue = e.target.value;
                  //   this.setState(searchValue);
                  // }}
                  onSelect={(_invidualSignerInfo) => {
                      this.handleAddingSigner(_invidualSignerInfo)
                  }}
                  onFocus={() => {
                  console.log('This function is called when is focussed')
                  }}
                  onChange={value => console.log(value)}
                  fuseConfigs={{
                  threshold: 0.05,
                  }}
              />
              </SearchboxContainer>
            </InputContainer>
            <Table>
                <THead>
                <Tr>
                    <Th>ID</Th>
                    <Th>Template Name</Th>
                    <Th>Created By</Th>
                    <Th>Selected</Th>
                </Tr>
                </THead>
                
                {this.renderTemplateInfoTable()}
                
            </Table>
          </LoadTemplateContentContainer>
        )
    }
    
    render() {
      return (
        <Modal
          show={this.props.showLoadTemplateModal}
          onHide={() => this.props.hideTemplateModal()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select a template
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.body()}
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton onClick={() => this.onSelectTemplate()}>Select</SubmitButton>
            <CancelButton onClick={() => this.props.hideTemplateModal()}>Cancel</CancelButton>
          </Modal.Footer>
        </Modal>
      );
    }
}

const mapStateToProps = state => {

    return {
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signers: state.fetchData.signers,
        signersInfo: state.fetchData.signersInfo,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
        // onUpdateApproval: (approval, approvalLevels) => dispatch(updateApprovalAction(approval, approvalLevels)),
        onUpdateApprovalLevel: (approvalLevel, approval) => dispatch(updateApprovalLevelAction(approvalLevel, approval)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadTemplateModal);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

