import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { v1 as uuidv1 } from 'uuid';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
//import userAvatar from '../../assets/images/avatar186x148.png'
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import searchIcon from '../../assets/images/icons/search22x22.png'
import SystemNavigation from '../../components/SystemNavigation';
import { authenticationService, fetchData } from '../../services';
import util from 'util';
import {
  createAddress,
  updateAddress,
} from '../../store/actions';

class AddressDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        height: window.innerHeight - 112,
        user: this.props.currentUser,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // let requestedId = this.state.userId;
    if (!this.props.currentUser) {
      this.props.history.push('/views/login');
    }
    if (!this.props.address) {
      this.props.history.push('/views/address/create');
    }
  }

  updateAddressHandler = (e) => {
    this.props.history.push('/views/address/update');
  };



  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  createNotification = (title,message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  addNewAddressHandler = (e) => {
    this.props.history.push('/views/address/create');
  }


  render() {
    return (
      <Container>
        <Header menuItem="4" />
        <ContentContainer height={this.state.height}>
          <NavigationContainer>
            <SystemNavigation menuItem="6" />
          </NavigationContainer>
          <InfoContainer>
                        
            <HeaderContainer>
              <Title>{"Company Address"}</Title>
              <HeaderRight>
                <GreenButton onClick={(e) => {this.addNewAddressHandler(e)}} data-id='0'><PlusIcon> + </PlusIcon>Thêm địa chỉ mới</GreenButton>
              </HeaderRight>
            </HeaderContainer>
              
            <InnerContainer>
              <FormContainer>
                <FormBox>
                  <Label>{"Street: "}</Label>
                  <Input 
                    value={this.props.address ? this.props.address.street : ''} 
                    disabled={true}
                  />
                </FormBox>

                <FormBox>
                  <Label>{"Suburb"}</Label>
                  <Input 
                    value={this.props.address ? this.props.address.suburb : ''} 
                    disabled={true}/>
                </FormBox>

                <FormBox>
                  <Label>{"State"}</Label>
                  <Input 
                    value={this.props.address ? this.props.address.city : ''} 
                    disabled={true}/>
                </FormBox>

                <FormBox>
                  <Label>{"Country"}</Label>
                  <Input 
                    value={this.props.address ? this.props.address.country : ''}   
                    disabled={true}/>
                </FormBox>
                <Button onClick={(e) => this.updateAddressHandler(e)}>
                    {'Update Address'}
                </Button>
              </FormContainer>
            </InnerContainer>
          </InfoContainer>
        </ContentContainer>
        <Footer />

        <ToastContainer />

      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('check if addresses receive from reducer:');
  console.log(util.inspect(state.fetchData.addresses));
  return {
      currentUser: state.authState.currentUser,
      address: state.company.address,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateAddress: (_address, _prevPath) => dispatch(createAddress(_address, _prevPath)),
    onUpdateAddress: (_address) => dispatch(updateAddress(_address)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetail);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const BackButton = styled(Link)`
  text-decoration: none;
  border: solid 1px #707070;
  color: #303030;
  height: 36px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const LeftArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

const Label = styled.div`
  height: 42px;
  line-height: 42px;
  width: 160px;
`;

const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

const Logo = styled.div`
  width: 186px;
  height: 148px;
  
  background-repeat: no-repeat;
`;

const ButtonContainer = styled.div`
  margin: 20px 0px 20px 160px;
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  border: solid 1px #707070;
  background-color: #841420;
  color: #ffffff;
  height: 40px;
  width: 390px;
  font-size: 16px;
  margin: 20px 0px 20px 160px;
  cursor: pointer;
`;

const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 44px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 410px;
`;

const Option = styled.option`
`;

const ConfirmBox = styled.div`
  width: 410px;
  height: 130px;
  border: solid 1px #B7B7B7;
  background-color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 4px 15px;
`;

const HeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const GreenButton = styled.button`
  border: solid 1px #707070;
  background-color: #17C177;
  color: #ffffff;
  height: 38px;
  padding: 0px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchContainer = styled.div`
  border: solid 1px #B7B7B7;
  background-color: #ffffff;
  height: 40px;
  width: 529px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const SearchInput = styled.input`
  width: 490px;
  height: 38px;
  border-width: 0px;
  padding-left: 10px;
  font-size: 14px;

  :focus {
    outline: none;
  }
`;

const SearchIcon = styled.button`
  border-width: 0px;
  height: 22px;
  width: 22px;
  background-color: #FFFFFF;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  cursor: pointer;
`;

const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

/*********************End Stylesheet**********************/