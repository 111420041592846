import React, { Component } from 'react'
// import for layout design
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import {
    BeContainer, ContentContainer
} from './styles';



class BaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - 112,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };
  componentDidMount() {

    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <BeContainer>
        <Header menuItem={this.props.selectMenuItem} />
        <ContentContainer height={this.state.height}>
          {this.props.mainComponent} 
        </ContentContainer>
        
        <Footer />
      </BeContainer>
    )
  }
}

export {BaseLayout}

