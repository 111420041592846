import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import {Filter,InfoCircle, People, PersonCircle, PeopleFill, Square, CheckSquare, Plus } from 'react-bootstrap-icons'
import { Input } from 'semantic-ui-react'
import ReactSearchBox from 'react-search-box'
import styled from 'styled-components'

export const BeContainer = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const Status = styled.div.attrs(props => ({
    bgcolor: props.bgcolor,
  }))`
  color: #FFFFFF;
  background-color: ${props => props.bgcolor};
  text-align: center;
  border-radius: 3px;
  width: 70px;
  height: 18px;
  margin-left: auto;
  font-size: 12px;
`;

export const ItemTitle = styled.div`
  font-size: 18px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const BoldText = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

export const CustomLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: #5A5A5A;
  font-size: 12px;
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 3px 4px 5px #C9C9C9;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const StatusBar = styled.div`
  margin-top: 10px;
  margin-right: 3px;
  width: 30px;
  height: 8px;
  border-radius: 3px;
`;

export const InactiveBar = styled(StatusBar)`
  background-color: #EEEEEE;
  border: solid 1px #C2C2C2;
`;

export const ActiveBar = styled(StatusBar)`
  background-color: #53CB97;
`;

export const RowContainer = styled(Row).attrs(props => ({
  paddingTop: props.paddingTop,
}))`
  padding-top: ${props => props.paddingTop || "15px"};
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
`;

export const ColContainer = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const ControlerContainer = styled.div`
  width: 100%;
`;

export const ControlerRow = styled(Row)`
  
`;

export const ControlerCol = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const BeButton = styled.button.attrs(props => ({
  marginRight: props.marginRight,
}))`
  flex: 1
  padding: 8px 10px;
  color: #FFFFFF;
  border-width: 0px;
  border-radius: 3px;
  margin-right: ${props => props.marginRight || "8px"};
  min-height: 40px;

  @media (max-width: 768px) {
    min-height: 65px;
  }
`;

export const RedButton = styled(BeButton)`
  background-color: #D84B65;
`;

export const YellowButton = styled(BeButton)`
  background-color: #F3A83C;
`;

export const GreenButton = styled(BeButton)`
  /*background-color: #54D173;*/
  background-color: #17C177;
`;

export const GreyButton = styled(BeButton)`
  /*background-color: #F3A83C;*/
  background-color: 	#808080;
`;

export const DarkBlueButton = styled(BeButton)`
  /*background-color: #F3A83C;*/
  background-color: 	#476b6b;
`;

export const IndigoButton = styled(BeButton)`
  background-color: #224F91;
`;

export const BlueButton = styled(BeButton)`
  background-color: #2680EB;
`;

export const PurpleButton = styled(BeButton)`
  background-color: #6544A8;
`;


export const EmptySquare = styled(Square)`
  color: #FFFFFF;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const CheckedSquare = styled(CheckSquare)`
  color: #FFFFFF;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

export const SearchBox = styled(Input)`
  min-height: 40px;
  flex: 1;
`;

export const AddButton = styled.button`
  color: #FFFFFF;
  background-color: #2680EB;
  border-width: 0px;
  border-radius: 3px;
  min-height: 38px;
  margin-left: 8px;
  margin-top: 1px;
`;

export const PlusIcon = styled(Filter)`
  width: 28px;
  height: 28px;
`;

export const SearchBoxContainer = styled.div`
  flex: 1;
`;

export const ReSearchBox = styled(ReactSearchBox)`
  flex: 1 !important;
  border: solid 1px blue !important;
`;