import React from 'react'
// import {Link} from 'react-router-dom'
import { Redirect, Link } from 'react-router-dom'
import styled from 'styled-components'
import { authenticationService } from '../services';

const prodKeys = require('../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

export default class SystemNavigation extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      username: 'Ha Pham',
      redirect: false,
      menuItem: props.menuItem,
      border0: '#F2F2F2',
      border1: '#F2F2F2',
      border2: '#F2F2F2',
      border3: '#F2F2F2',
      border4: '#F2F2F2',
      border5: '#F2F2F2',
      border6: '#F2F2F2',
    };
  }

  componentDidMount() {
    if(this.state.menuItem === '0') {
      this.setState({
        border0: '#2680EB',
      });
    }
    if(this.state.menuItem === '1') {
      this.setState({
        border1: '#2680EB',
      });
    }
    if(this.state.menuItem === '2') {
      this.setState({
        border2: '#2680EB',
      });
    }
    if(this.state.menuItem === '3') {
      this.setState({
        border3: '#2680EB',
      });
    }
    if(this.state.menuItem === '4') {
      this.setState({
        border4: '#2680EB',
      });
    }
    if(this.state.menuItem === '5') {
      this.setState({
        border5: '#2680EB',
      });
    }
    if(this.state.menuItem === '6') {
      this.setState({
        border6: '#2680EB',
      });
    }
  }

  logoutHandler = () => {
    authenticationService.logout()
    return this.props.history.push('/views/login');
  }
  
  render() {
    return (
      <Container>
        <Title>{"User Setting"}</Title>
        {/* <MenuItemContainer borderColor={this.state.border0}>
          <CustomLink to='/views/company/detail'>{"Organisation Name"}</CustomLink>
        </MenuItemContainer> */}
        <MenuItemContainer borderColor={this.state.border1}>
          <CustomLink to='/views/user/list'>{"User Management"}</CustomLink>
        </MenuItemContainer>
        {/* <MenuItemContainer borderColor={this.state.border2}>
          <CustomLink to='/views/group/list'>{"Roles Management"}</CustomLink>
        </MenuItemContainer> */}
        {/* <MenuItemContainer borderColor={this.state.border4}>
          <CustomLink to='/views/Configurations'>{"Other Settings"}</CustomLink>
        </MenuItemContainer> */}
        {/* <MenuItemContainer borderColor={this.state.border6}>
          <CustomLink to='/views/address/detail'>{"Company Address"}</CustomLink>
        </MenuItemContainer> */}
        <MenuItemContainer borderColor={this.state.border6}>
          <CustomLink to='/views/signature/detail'>{"Digital Signature"}</CustomLink>
        </MenuItemContainer>
      </Container>
    );
  }
}

/*********************Start Stylesheet**********************/
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #303030;
  font-size: 14px;
`;

const MenuItemContainer = styled.div.attrs(props => ({
  borderColor: props.borderColor,
  backgroundColor: props.backgroundColor,
  }))` 
  height: 34px;
  margin: 3px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left-style: solid;
  border-left-color: ${props => props.borderColor};
  border-left-width: 5px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
`;


const CustomLink = styled(Link)`
  text-decoration: none;
  color: #303030;
  margin-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  border: solid 1px #707070;
  background-color: #841420;
  color: #ffffff;
  height: 42px;
  width: 402px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
`;

/*********************End Stylesheet**********************/