import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BeLogo from '../../assets/images/Be_Sign_70x34.png';
import FarmBackgroundImage from '../../assets/images/farmImage.jpeg';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-image: url(${FarmBackgroundImage});
  background-repeat: no-repeat;
`;

export const RegisterFormContainer = styled.section`
  padding: 20px;
  width: 460px;
  background-color: #ffffff;
  margin: 20px;
`;

export const ComponentContainer = styled.form`
  box-shadow: 5px 6px 8px #B7B7B7;
`;

export const FormContainer = styled(ComponentContainer)`
  padding: 30px 0px;
  width: 400px;
  background-color: #ffffff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FormTitle = styled.div`
  font-size: 20px;
  color: #FFFFFF;
  padding: 10px 35px 10px 15px;
  background-color:#FF9036;
  box-shadow: 3px 4px 5px #B7B7B7;
`;

export const BLogo = styled.div`
  width: 90px;
  height: 43px;
  margin-left: auto;
  margin-right: 30px;
  background-image: url(${BeLogo});
  background-repeat: no-repeat;
`;

export const ElementContainer = styled.div`
  margin: 0px 30px;
`;

export const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 100%;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 15px;

  :focus {
    background-color: #FFF6DB;
  }
`;

export const Button = styled.button`
  border: solid 0px #707070;
  background-color: #0059C2;
  color: #ffffff;
  height: 40px;
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
`;

export const LinkContainer = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px;
`;

export const CustomLink = styled(Link).attrs(props => ({
    marginRight: props.marginRight,
  }))`
  text-decoration: none;
  color: #0021FB;
  margin-left: ${props => props.marginRight};
`;

export const ConfirmationText = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

export const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 100%;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 15px;

  :focus {
    background-color: #FFF6DB;
  }
`;

export const Option = styled.option`
`;

export const Notice = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #FFF1C4;
  border: solid 1px #D6D6D6;
  border-radius: 5px;
  width: 100%;
`;


export const Label = styled.div.attrs(props => ({
  width: props.width,
  flexGrow: props.flexGrow,
  }))`
  display: flex
  margin-right: 10px;
  flex-grow: ${props => props.flexGrow || 1}
  height: 38px;
  font-weight: bold;
  line-height: 38px;
  font-size: 12px;
`;
export const DefaultButton = styled.button`
  padding: 8px 10px;
  color: #FFFFFF;
  border-width: 0px;
  border-radius: 3px;
  min-width: 80px;
  min-height: 40px;
  @media (max-width: 768px) {
    min-width: 60px;
  }
`;

export const CancelButton = styled(DefaultButton)`
  background-color: #A2A2A2;
`;


export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
`;