import React from 'react'
import { connect } from 'react-redux';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import {Label} from './styles';

import {
    CancelButton, DefaultButton, Title, TitleContainer
} from './styles';


export default class TermConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        
    }

    render() {
        return (
            <Modal
            show={this.props.showTermConditionsModal}
            onHide={() => this.props.hideTermsAndConditionsModal()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"Terms and Conditions"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TitleContainer>
                    <Title>{"1. GrainSupp"}</Title>
                </TitleContainer>
                <br/>
                <p>{"GrainSupp 2.0 represents the latest advancement in blockchain-based traceability systems developed to assure grain quality across the Australian grain supply chain, from farm to market or export. This system focuses on barley and wheat, the primary crops supported by GrainSupp 2.0. By integrating advanced IoT sensing technologies and AI-based machine verification, GrainSupp 2.0 enhances the ability to monitor and verify grain quality at various critical points in the supply chain, ensuring that data remains secure, transparent, and immutable on the blockchain."}
                </p>
                <br/>
                <p>
                Purpose: The primary purpose of GrainSupp 2.0 is to provide end-to-end grain quality assurance within the agricultural supply chain, focusing on the barley and wheat sectors. The system captures real-time data at different stages of grain production and supply, allowing for accurate traceability and certification, which is stored on-chain through a smart contract-based process. This system enhances trust between farmers, distributors, exporters, and regulators by ensuring that data related to grain quality remains untampered and readily verifiable. Although GrainSupp 2.0 does not support automated grain trading, it ensures a robust framework for monitoring grain quality, complying with regulatory standards, and offering a reliable audit trail for stakeholders.
                </p>
                <br/>
                <p>
                Scope of Grain: GrainSupp 2.0 is specifically designed for barley and wheat traceability. It support data capture at various stages, from planting and harvesting to storage and transportation, ensuring that quality standards are maintained across the supply chain. The system employs IoT devices to monitor environmental conditions and AI technologies to assess the grain quality using imaging and other sensory data.
                </p>
                <br/>
                <p>
                Copyright: GrainSupp 2.0, including its design, software, and blockchain-based traceability processes, is the intellectual property of the Internet Commerce Security Lab (ICSL) at Federation University Australia. Any use of GrainSupp 2.0's technologies, processes, or data by external stakeholders must adhere to the terms and conditions set forth by the ICSL, Federation University. Unauthorized use, reproduction, or dissemination of any part of this system is strictly prohibited without prior consent from the copyright holders.
                </p>
                <br/>
                <TitleContainer>
                    <Title>{"2.	Stakeholder Eligibility"}</Title>
                </TitleContainer>
                <br/>
                <p>
                Private Blockchain: GrainSupp 2.0 operates on a private blockchain, meaning that access is restricted to invited participants only. This ensures the integrity, security, and privacy of all transactions and data records within the system. Only authorized stakeholders are permitted to join and participate in the GrainSupp blockchain ecosystem, enhancing trust and compliance with regulatory standards.
                </p>
                <br/>
                <p>
                Roles for Participants: GrainSupp 2.0 clearly defines roles for various participants within the grain supply chain. The key participants include:
                </p>
                <br/>
                <p>
                Farmers: Responsible for the submission of accurate and real-time data regarding the cultivation, quality, and handling of barley and wheat. Farmers are required to integrate IoT devices for real-time data collection and ensure compliance with GrainSupp's quality certification standards.
                </p>
                <br/>
                <p>
                Transporters: Responsible for ensuring that the grain is handled, stored, and transported under conditions that preserve its quality. Transporters must submit transportation data to the GrainSupp blockchain, ensuring the traceability of the product during transit.
                </p>
                <br/>
                <p>
                Storage Managers: Oversee the storage conditions of the grain. Their role is to ensure that grains are stored in compliance with quality regulations and report the storage data to the blockchain. Any anomalies in storage conditions must be documented and reported in real time.
                </p>
                <br/>
                <p>
                Regulators: Ensure that all processes along the supply chain comply with local and international agricultural standards. They have the authority to review grain quality data and validate certifications on the blockchain.
                </p>
                <br/>
                <p>
                Certifiers: These are independent third parties responsible for endorsing the grain quality certifications. They verify that the grain has met the necessary quality standards through both human-in-loop verification and AI-driven imaging technologies.
                </p>
                <br/>
                <p>
                Each participant has a duty to submit accurate data at each stage of the supply chain, contribute to the certification endorsement process, and validate the quality of grain where applicable. Non-compliance or inaccurate data submission can lead to removal from the blockchain ecosystem.
                </p>
                <br/>
                <p>
                Invitation and Identity Authentication: Joining GrainSupp 2.0 as a stakeholder is by invitation only, extended by Federation University Australia. Prospective stakeholders must meet specific eligibility criteria and submit the required documentation for two-factor identity authentication to gain access. This ensures that only verified and trusted participants are allowed into the GrainSupp blockchain network, maintaining the security and integrity of the system.
                </p>
               
                <br/>
                <TitleContainer>
                    <Title>{"3. Data Submission and Privacy"}</Title>
                </TitleContainer>
                <br/>
                <p>
                Data Submission: Stakeholders in GrainSupp 2.0 submit various types of data throughout the supply chain process, ensuring a transparent, traceable record of the grain's quality from farm to market or export. This data submission includes:
                </p>
                <ul>
                    <li>IoT Real-Time Data: IoT devices installed on farms and storage facilities automatically collect real-time data on grain conditions, such as temperature, humidity, and storage duration. This data is transmitted to the blockchain, where it is recorded in an immutable ledger to ensure traceability and quality control.</li>
                    <li>Manual Data Entry: In cases where real-time IoT data is unavailable or requires verification, stakeholders such as farmers, transporters, or storage managers may manually submit additional information about the grain’s handling or processing conditions via the GrainSupp 2.0 platform.</li>
                    <li>Grain Quality Measurements: Data related to grain quality, including protein content, moisture levels, and contaminant testing, is submitted for certification processes. This data is either captured automatically using AI-driven imaging systems or provided by human inspectors.</li>
                </ul>  
                <br/>
                <p>
                Privacy and Data Protection: GrainSupp 2.0 is committed to ensuring that all data, including personal and sensitive information, is handled with the highest level of confidentiality and security. The system complies with data protection laws such as the General Data Protection Regulation (GDPR) and Australian privacy regulations, ensuring stakeholders' data is protected from unauthorized access or misuse.
                </p>
                <ul>
                    <li>Data Encryption: All personal and sensitive data, including identity authentication and transactional information, is encrypted both at rest and in transit.</li>
                    <li>Anonymization and Pseudonymization: To comply with privacy regulations, personal data that is not essential to the traceability process is anonymized or pseudonymized.</li>
                    <li>Access Controls: Only authorized personnel and stakeholders have access to specific layers of the data, ensuring data integrity and security while preserving transparency in the grain quality certification process.</li>
                </ul>  


                <br/>
                <TitleContainer>
                    <Title>{"4.	Grain Quality Certification"}</Title>
                </TitleContainer>
                <br/>
                <p>
                The GrainSupp 2.0 blockchain system implements a 5-step smart contract process to ensure an automated, transparent, and secure certification of grain quality. This process is critical for verifying the quality of grain at various points in the supply chain, ensuring compliance with both local and international standards.
                </p>
                <br/>
                <h3>S1. In-chain Data for Certification</h3>
                <p>
                Grain quality certification begins with the submission of in-chain data. This includes real-time data captured through IoT devices deployed at farms, storage facilities, and during transportation. This data could include all relevant quality metrics. Once the data is recorded, it is securely uploaded to the blockchain for further verification.  
                </p>
                <br/>
                <h3>S2. Machine Verification Against Standards</h3>
                <p>
                Once the in-chain data is collected, it undergoes machine verification. Advanced AI algorithms compare the submitted grain quality data against pre-set industry standards for barley and wheat. 
                </p>
                <br/>
                <h3>S3. Stakeholder Endorsement</h3>
                <p>
                After machine verification, stakeholder endorsement is required to validate the grain quality data. This step involves key stakeholders such as farmers, certifiers, and regulators who review the results of the machine verification process. A minimum of three stakeholders must be identified and participate in the endorsement process before a grain quality certificate can be issued. 
                </p>
                <br/>
                <h3>S4. Issue Certificate</h3>
                <p>
                Once the stakeholders have endorsed the data, GrainSupp issues a certificate confirming that the grain has met all necessary quality standards. This certificate acts as a verifiable proof of quality and is used by downstream stakeholders, such as exporters or regulators, to confirm that the grain is fit for market or export.
                </p>
                <br/>
                <h3>S5. Certificate On-chain Deployment and Execution</h3>
                <p>
                The final step is the on-chain deployment and execution of the grain quality certificate. The issued certificate is stored immutably on the blockchain, ensuring it cannot be tampered with or altered post-issuance. This allows for real-time access by any authorized stakeholder in the supply chain, offering a transparent and secure method of verifying grain quality from farm to market or export. Furthermore, the certificate can trigger automated actions or smart contracts, such as final approval for sale or release for export.
                </p>
                <br/>
                <p>
                In GrainSupp 2.0, all stakeholders involved, including farmers, transporters, storage managers, certifiers, and regulators, agree to the five-step smart contract process for grain quality certification. This agreement ensures that once a grain quality certificate is issued by GrainSupp, it cannot be rejected or disputed by any party. The use of blockchain technology guarantees the immutability and transparency of the certification process, meaning that all data, endorsements, and verification steps leading to the certificate are permanently recorded on-chain.
                </p>

                <br/>
                <TitleContainer>
                    <Title>{"5. Liability and Indemnification"}</Title>
                </TitleContainer>
                <br/>
                <h3>Liability Clauses:</h3>
                <p>
                Data Accuracy: Stakeholders are responsible for ensuring that any data they submit to the GrainSupp 2.0 system is accurate, complete, and up to date. In cases where inaccurate or false data is provided, stakeholders may be held liable for any consequences that arise, including any damages to the supply chain or regulatory non-compliance issues.
                </p>
                <br/>
                <p>
                System Malfunctions: GrainSupp 2.0 operates on blockchain and IoT infrastructure, and while it aims to provide reliable service, it cannot be held liable for system malfunctions, technical failures, or data loss that may occur due to external factors (e.g., network issues, IoT device failure, or hardware malfunctions). Stakeholders agree to use the system with the understanding that technology has inherent risks.
                </p>
                <br/>
                <p>
                Non-Compliance: In the event of a stakeholder failing to comply with GrainSupp 2.0's policies, procedures, or certification guidelines, they may be liable for any losses, damages, or regulatory breaches resulting from such non-compliance.
                </p>
                <br/>
                <h3>Indemnification Clauses:</h3>
                <p>
                Stakeholder Actions or Omissions: Stakeholders agree to indemnify and hold harmless GrainSupp, its developers, Federation University Australia, and any affiliated entities from any claims, losses, damages, or legal actions that arise due to the actions, omissions, or data inaccuracies provided by the stakeholders.
                </p>
                <br/>
                <p>
                Third-Party Claims: If a third party brings a claim or legal action against GrainSupp or any associated entities due to the actions of a stakeholder, the stakeholder will be responsible for covering any associated legal costs, damages, or settlements.
                </p>
                <br/>


                <br/>
                <TitleContainer>
                    <Title>{"6.	Termination and Suspension"}</Title>
                </TitleContainer>
                <br/>
                <p>
                Stakeholder participation in GrainSupp 2.0 can be suspended or terminated under the following conditions:
                </p>
                <br/>
                <p>
                Non-Compliance: If a stakeholder is found to be in breach of any terms of the agreement, including failing to submit accurate data, manipulating system processes, or violating Australian Grain Supply Chain policies.
                </p>
                <br/>
                <p>
                Breach of Contract: Repeated or severe breaches of contractual obligations, including violations of data integrity or certification processes.
                </p>
                <br/>
                <p>
                Unauthorized Access or Misuse: Engaging in unauthorized activities or attempts to bypass system security could result in immediate suspension.
                </p>
                <br/>
                <p>
                Regulatory Violations: If a stakeholder's actions cause violations of local, national, or international regulations (e.g., food safety, export compliance), GrainSupp reserves the right to terminate their participation.
                </p>
                <br/>
                <p>
                Fraud or Misconduct: Any instance of fraudulent behavior or misconduct, such as falsifying grain quality data, will result in immediate termination.
                </p>
                <br/>
                <p>
                Suspension or termination will follow a formal review process and may include temporary suspension during the investigation of any breaches.
                </p>
                <br/>
                <TitleContainer>
                    <Title>{"7.	Amendments"}</Title>
                </TitleContainer>
                <br/>
                <p>
                Procedures for Amendments:
                </p>
                <br/>
                <p>
                Notification: Any amendments to these terms and conditions will be communicated to all stakeholders via email or the internal GrainSupp notification system. The notification will provide a clear outline of the proposed changes.
                </p>
                <br/>
                <p>
                Consent Process: Stakeholders will be given a specified period (e.g., 30 days) to review and accept or reject the proposed amendments. Failure to provide a response within the given period will be treated as acceptance of the new terms.
                </p>
                <br/>
                <p>
                Feedback and Negotiation: In the event that stakeholders disagree with proposed changes, they may submit feedback or negotiate amendments. GrainSupp reserves the right to review and consider stakeholder feedback, though it is not obliged to incorporate all suggested changes.
                </p>
                <br/>
                <TitleContainer>
                    <Title>{"8.	Dispute Resolution"}</Title>
                </TitleContainer>
                <br/>
                <p>
                In the event of disputes between stakeholders and the GrainSupp organization, the following dispute resolution mechanisms will be employed:
                </p>
                <br/>
                <p>
                Mediation: Initial disputes will be addressed through mediation, where both parties attempt to reach a mutually agreeable solution with the help of a neutral third-party mediator.
                </p>
                <br/>
                <p>
                Arbitration: If mediation fails, disputes will proceed to binding arbitration. An independent arbitrator will review the case and make a final decision, which all parties must adhere to.
                </p>
                <br/>
                <p>
                Legal Recourse: If arbitration does not resolve the dispute, or in cases where legal action is necessary, the parties may pursue litigation under the laws specified in the "Governing Law" section. Litigation should be considered a last resort, following the exhaustion of mediation and arbitration.
                </p>
                <br/>
                <TitleContainer>
                    <Title>{"9.	Governing Law"}</Title>
                </TitleContainer>
                <br/>
                <p>
                This agreement, along with all terms, conditions, and any related disputes, will be governed by and construed in accordance with the laws of Australia. Specifically, it will adhere to Australian commercial, privacy, and agricultural regulations, ensuring compliance with national standards and practices, including those concerning food safety, data protection (such as the Australian Privacy Act), and blockchain usage. Any legal action arising from this agreement must be brought to the relevant court in Victoria, Australia.
                </p>
                <br/>



            </Modal.Body>
            <Modal.Footer>
                <CancelButton onClick={() => this.props.hideTermsAndConditionsModal()}>Agree to process registration</CancelButton>
            </Modal.Footer>
        </Modal>
        );
    }
}
