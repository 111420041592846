import { 
    // FETCH_USER,
    ON_CLICK_CREATE_USER,


    CREATE_USER_SUCCESS,
    CREATE_USER_FAILED,
    FETCH_USERS_FAILED,
    FETCH_USERS_SUCCESS,
    VIEW_USER_DETAIL,

    FETCH_USER_SUCCESS,
    FETCH_USER_FAILED,
    GET_CREATE_USER_SUCCESS,
    GET_CREATE_USER_FAILED,
    GET_UPDATE_USER_FAILED,
    GET_UPDATE_USER_SUCCESS,
    UPDATE_PASSWORD_POST_FAILED,
    UPDATE_PASSWORD_POST_SUCCESS,
    RESET_PASSWORD_POST_FAILED,
    RESET_PASSWORD_POST_SUCCESS,
    UPDATE_USER_POST_SUCCESS,
    UPDATE_USER_POST_FAILED,
    UPDATE_PROFILE_POST_SUCCESS,
    UPDATE_PROFILE_POST_FAILED,
    GET_UPDATE_PROFILE_FAILED,
    GET_UPDATE_PROFILE_SUCCESS,
    NEW_PASSWORD_POST_SUCCESS,
    NEW_PASSWORD_POST_FAILED,
} from '../actions/actionTypes';

const INIT_STATE = {
    invalidMsg: null,
    user: null,
    users: [],
    title: null,
    resetPassword: null,
    groups: [],
    loginNotification: null
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case ON_CLICK_CREATE_USER:
            return {
                ...state,
                user: null,
            }
        case NEW_PASSWORD_POST_SUCCESS:
            return {
                ...state,
                invalidMsg: null,
                user: null,
            }
        case NEW_PASSWORD_POST_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
            }
        case VIEW_USER_DETAIL:
            return {
                ...state,
                user: action.payload.user,
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            }
        case FETCH_USER_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
                user: action.payload.user,
            }
        case UPDATE_USER_POST_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
            }
        case UPDATE_USER_POST_FAILED:
            return {
                ...state,
                user: action.payload.user,
                invalidMsg: action.payload.invalidMsg,
            }
        case UPDATE_PROFILE_POST_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
            }
        case UPDATE_PROFILE_POST_FAILED:
            return {
                ...state,
                user: action.payload.user,
                invalidMsg: action.payload.invalidMsg,
            }
        case RESET_PASSWORD_POST_SUCCESS:
            return {
                ...state,
                resetPassword: true,
                loginNotification: "Please check you email for new password"
            }
        case RESET_PASSWORD_POST_FAILED:
            return {
                ...state,
                resetPassword: false,
                invalidMsg: action.payload.invalidMsg,
            }
        case UPDATE_PASSWORD_POST_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            }
        case UPDATE_PASSWORD_POST_FAILED:
            return {
                ...state,
                user: action.payload.user,
                invalidMsg: action.payload.invalidMsg,
            }
        case GET_UPDATE_USER_FAILED:
            return {
                ...state,
                user: action.payload.user,
                invalidMsg: action.payload.invalidMsg,
            }
        case GET_UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            }
        case GET_CREATE_USER_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
                user: null,
            }
        case GET_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            }
        case GET_UPDATE_PROFILE_FAILED:
            return {
                ...state,
                user: action.payload.user,
                invalidMsg: action.payload.invalidMsg,
            }
        case GET_CREATE_USER_SUCCESS:
            return {
                ...state,
                title: action.payload.title,
                user: null,
                groups: action.payload.groups,
                company: action.payload.company,
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users || [],
            }
        case FETCH_USERS_FAILED:
            return {
                ...state,
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                users: action.payload,
            }
        case CREATE_USER_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
                user: action.payload.user,
            }
        default:
            return state;
    }
}