import React from 'react'
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components'

import SystemBaseLayout from '../layout/SystemLayout';
import searchIcon from '../../assets/images/icons/search22x22.png'

import {
  fetchGroups, emailLogoutAction, onClickCreateGroup, updateGroupGet, onViewGroupDetail,
} from '../../store/actions';

import {
  groupManagement, selectLanguage, userFormControl, formControl, userRole, userGroupStatus,
} from '../../langueges';
import util from 'util';

class GroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Header: 60px, Footer: 52px, border included
      height: window.innerHeight - 112,
      groups: [],
      redirect: false,
      editPermission: false,
    };
  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };
  componentDidMount() {
    
    window.addEventListener('resize', this.updateDimensions);
    let currentUser = this.props.currentUser;
    if (!currentUser) {
      return emailLogoutAction();
    }
    // fetch groups
    this.props.onFetchGroups();
    this.checkEditPermission();
    let groups = this.props.groups;
    if (groups) {
      if (groups.length > 0) {
        this.setState({groups});
      }
    } 
    
  }

  onViewDetail = (group) => {
    this.props.onViewGroupDetail(group);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderTableData() {
    let groups = this.props.groups ? this.props.groups : this.state.groups;
    if ((groups) && (groups.length > 0)) {
      return groups.map((group, index) => {
        const { _id, groupName, status } = group //destructuring
        let groupNameLabel = userRole[groupName] ? userRole[groupName] : groupName;

        return (
            <TR key={_id}
            onClick={() => this.onViewDetail(group)}
            >
              <TD>{(index + 1).toString()}</TD>
              <TD>{selectLanguage(this.props.language, groupNameLabel)}</TD>
              <TD>{selectLanguage(this.props.language, userGroupStatus[status])}</TD>
              <TD align="right">
                <YellowButton onClick={() => this.onUpdateGroup(group)} data-id={_id}>
                  {selectLanguage(this.props.language, formControl.update)}
                </YellowButton>
              </TD>
              
            </TR>
        )
      })
    }
  }

  onUpdateGroup(_group) {
    this.props.onUpdateGroup(_group);
  }

  onClickCreateGroup = () => {
    this.props.onClickCreateGroup();
  }

  checkEditPermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let editPermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('editSystem')) {
        editPermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin' || currentUser.role == 'admin') {
        editPermission = true;
      }
      this.setState({editPermission: editPermission})
    }
  }

  checkDeletePermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let deletePermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('deleteSystem')) {
        deletePermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        deletePermission = true;
      }
      this.setState({deletePermission: deletePermission})
    }
  }

  renderCreateUpdateButton() {
    const editPermission = true;
    // const editPermission = this.state.editPermission;
    if (editPermission == true) {
      return (
        <GreenButton onClick={() => this.onClickCreateGroup()}><PlusIcon> + </PlusIcon>
          {selectLanguage(this.props.language, groupManagement.addGroup)}
        </GreenButton>
      )
    } else {
      return null;
    }
  }

  body() {
    return (
      <RightContainer>
        <HeaderContainer>
          <Title>{selectLanguage(this.props.language, userFormControl.userManagement)}</Title>
          <HeaderRight>
            {this.renderCreateUpdateButton()}
          </HeaderRight>
        </HeaderContainer>
        <TableContainer>
          <Table>
            <THEAD>
              <TR>
                <TH width="5%">{selectLanguage(this.props.language, groupManagement.item)}</TH>
                <TH width="15%">{selectLanguage(this.props.language, groupManagement.groupName)}</TH>
                <TH width="25%">{selectLanguage(this.props.language, groupManagement.status)}</TH>
                <TH width="10%"></TH>
              </TR>
            </THEAD>
            
            <TBODY>
              {this.renderTableData()}
            </TBODY>
          </Table>

        </TableContainer>
      </RightContainer>
    )
  }


  render() {
    return (
      <SystemBaseLayout 
        leftMenuItem={"2"}
        mainComponent={this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log(`current list of group are `);
  console.log(util.inspect(state.group.groups));
  return {
      groups: state.group.groups,
      currentUser: state.authState.currentUser,
      language: state.topMenu.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      onFetchGroups: () => dispatch(fetchGroups()),
      onClickCreateGroup: () => dispatch(onClickCreateGroup()),
      onUpdateGroup: (_group) => dispatch(updateGroupGet(_group)),
      onViewGroupDetail: (group) => dispatch(onViewGroupDetail(group)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);

/*********************Start Stylesheet**********************/
const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 10px 15px 0px;
  font-size: 14px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

const HeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const GreenButton = styled.button`
  border: solid 1px #707070;
  background-color: #17C177;
  color: #ffffff;
  height: 40px;
  padding: 0px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchContainer = styled.div`
  border: solid 1px #B7B7B7;
  background-color: #ffffff;
  height: 40px;
  width: 529px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const SearchInput = styled.input`
  width: 490px;
  height: 38px;
  border-width: 0px;
  padding-left: 10px;
  font-size: 14px;

  :focus {
    outline: none;
  }
`;

const SearchIcon = styled.button`
  border-width: 0px;
  height: 22px;
  width: 22px;
  background-color: #FFFFFF;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  cursor: pointer;
`;

const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: solid 1px #A1A1A1;
`;

const THEAD = styled.thead`
  
`;

const TBODY = styled.tbody`
  
`;

const TR = styled.tr`
  border: solid 1px #A1A1A1;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:hover {
    background-color:#FFF1C4;
  }
`;

const TH = styled.th.attrs(props => ({
  width: props.width
  }))`
  padding: 10px;
  font-weight: bold;
  background-color: #E1E1E1;
  text-align: left;
  border-collapse: collapse;
  width: ${props => props.width};
}
`;

const TD = styled.td.attrs(props => ({
  paddingLeft: props.paddingLeft,
  align: props.align
  }))`
  text-align: ${props => props.align || "left"};
  padding-top: 6px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: ${props => props.paddingLeft || "10px"};
`;

const YellowButton = styled.button`
  border: solid 1px #707070;
  background-color: #FFD86D;
  color: #272727;
  height: 30px;
  width: 90px;
  padding: 0px 10px;
  font-size: 14px;
  cursor: pointer;
`;

const PagingContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const RecordInfo = styled.div`
  align-items: center;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const PagingButtons = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const PButton = styled.button`
  border: solid 1px #707070;
  background-color: #FFFFFF;
  color: #272727;
  width: 33px;
  height: 25px;
  cursor: pointer;
  margin-left: 6px;
`;

const PageNumber = styled.div`
  border: solid 1px #707070;
  background-color: #484848;
  color: #FFFFFF;
  font-weight: bold;
  width: 33px;
  height: 23px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/*********************End Stylesheet**********************/