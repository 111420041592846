import Container from 'react-bootstrap/Container'
import styled from "styled-components";
import ReactSearchBox from 'react-search-box';
import {Link} from 'react-router-dom';
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import printIcon from '../../assets/images/icons/printer_24x24.png'
import exportIcon from '../../assets/images/icons/export18x18.png'
import checkIcon from '../../assets/images/icons/check24x24.png'
import rightArrowIcon from '../../assets/images/icons/arrow17x15.png'
import {ArrowRightSquare, Intersect, SlashSquare, X} from 'react-bootstrap-icons';
import {Filter,InfoCircle, People, PersonCircle, PeopleFill, Square, CheckSquare, Plus, XCircleFill, Save } from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const responsiveSize = "768px";

export const ReSearchBox = styled(ReactSearchBox)`
  flex: 1 !important;
  border: solid 1px blue !important;
`;

export const StandardButton = styled(Link)`
  width: 80px;
  height: 40px;
  line-height: 36px;
  background-color: #FFFFFF;
  border: solid 1px #A2A2A2;
  color: #707070;
  border-radius: 3px;
  text-align: center;
  margin-top: 5px;
`;

export const ControlerRow = styled(Row)`
  
`;

export const ControlerCol = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 15px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const BackButton = styled(StandardButton)`
  
`;

export const EditButton = styled(StandardButton)`
  margin-right: 8px;
`;


export const BeContainer = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 15px 0px;
  background-color: #ffffff;
  box-shadow: 3px 4px 5px #C9C9C9;
`;

export const TemplateSection = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${responsiveSize}) {
    flex-direction: column;
  }
`;

export const TemplateName = styled.div`
  margin-right: 20px;
`;

export const BoldLabel = styled.span`
  font-size: 12px;
  font-weight: bold
`;

export const FormTitle = styled.div`
  font-size: 24px;
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
  color: "#505050";
  margin-right: 20px;

  &:visited, &:active {
    color: "#505050";
  }
`;
  
export const InfoContainer = styled.div.attrs(props => ({
    padding: props.padding,
  }))`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${props => props.padding || "0px"}
`;

export const ComponentContainer = styled.div`
  margin: 0px 15px;
`;

export const ApproveLevelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 15px 0px;
  background-color: #F9F9F9;
`;

export const ApprovalContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 15px;
  border: 1px solid #E0E0E0;
  border-top-width: 0px;
  border-radius: 0px 0px 3px 3px;
`;


export const FormHeader = styled.div`
  border-left: solid 5px #0059C2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #373C44;
  color: #FFFFFF;
  padding: 10px 15px;
  border: 1px solid #373C44;
  border-bottom-width: 0px;
  border-radius: 3px 3px 0px 0px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
`;

export const NormalButton = styled.button`
  border: solid 1px #A1A1A1;
  color: #303030;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
  margin-right: 10px;
`;


export const PrintIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${printIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const ExportIcon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${exportIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const DefaultButton = styled.button`
  padding: 8px 10px;
  color: #FFFFFF;
  border-width: 0px;
  border-radius: 3px;
  min-width: 80px;
  min-height: 40px;
  @media (max-width: 768px) {
    min-width: 60px;
  }
`;

export const GreenButton = styled(DefaultButton)`
  background-color: #17C177;
  margin-left: 8px;

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

export const GreenBorderButton = styled(DefaultButton)`
  border: solid 1px #17C177;
  color: #1A1D21;
  background-color: #FFFFFF;
  margin-left: 8px;

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

export const RedBorderButton = styled(DefaultButton)`
  border: solid 1px #D84B65;
  color: #1A1D21;
  background-color: #FFFFFF;
  margin-left: 8px;

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

export const GrayBorderButton = styled(DefaultButton)`
  border: solid 1px #E1E1E1;
  color: #1A1D21;
  background-color: #F2F2F2;
  margin-left: 8px;

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

export const RedButton = styled(DefaultButton)`
  background-color: #D84B65;
  margin-left: 8px;
  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

export const BlueButton = styled(DefaultButton)`
  background-color: #0059C2;
`;

export const BlueBorderButton = styled(DefaultButton)`
  border: solid 1px #0059C2;
  background-color: #FFFFFF;
  color: #0059C2;
`;

export const CancelButton = styled(DefaultButton)`
  background-color: #A2A2A2;
`;

export const IssueIcon = styled.div`
  width: 17px;
  height: 15px;
  background-image: url(${rightArrowIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;
export const YellowButton = styled.button`
  border: solid 1px #A1A1A1;
  color: #303030;
  background-color: #FFD86D;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
  margin-right: 10px;
`;


export const LeftArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const TemplateContainer = styled.div`
  padding: 20px 40px;
`;

export const ApprovalInfoContainer = styled.div`
  border: dashed 1px #B7B7B7;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px;
  margin-bottom: 15px;
  font-size: 14px;
`;

export const YellowStatus = styled.div`
  color: #FFAC06;
  font-weight: bold;
  margin: 0px 15px;
`;

export const SearchingCode = styled.div`
  margin-left: auto;
`;

export const SearchboxContainer = styled.div.attrs(props => ({
  flexGrow: props.flexGrow,
  }))`
  display: flex;
  flex-direction: column;
  flex-grow: ${props => props.flexGrow || 1}
`;

export const GreenText = styled.span`
  color: #17C177;
`;

export const IssueTime = styled.span`
  margin-left: 15px;
`;


export const Select = styled.select.attrs(props => ({
  width: props.width,
  flexGrow: props.flexGrow,
  }))`
  display: flex;
  flex-grow: ${props => props.flexGrow || 4}
  border: solid 1px #E0E0E0;
  height: 40px;
  padding: 0px 10px;
  font-size: 14px;
  border-radius: 3px;
`;

export const Option = styled.option`
  
`;

export const Table = styled.table`
  margin: 10px 0px;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px #E0E0E0;
`;

export const THead = styled.thead`
`;

export const TBody = styled.tbody`
`;

export const Tr = styled.tr`
  border: solid 1px #E0E0E0;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:nth-child(even) {
    background-color: #F9F9F9;
  }
  &:hover {
    background-color: #FFFAD9;
  }
`;

export const Th = styled.th`
  padding: 10px;
  font-weight: bold;
  background-color: #F2F2F2;
  text-align: left;
  border-collapse: collapse;
}
`;

export const Td = styled.td`
  padding: 10px;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const RemoveButton = styled.button`
  height: 28px;
  padding: 5px 15px;
  border: dotted 1px #A2A2A2;
  color: #D84B65;
  background-color: #F2F2F2;
  border-radius: 3px;
  text-align: center;
`;

export const RadioButton = styled.input`
  
`;

// export const ViewButton = styled.button`
//   min-height: 28px;
//   padding: 5px 15px;
//   border: dotted 1px #A2A2A2;
//   color: #0059C2;
//   background-color: #F2F2F2;
//   border-radius: 3px;
//   text-align: center;
// `;

export const ViewButton = styled(Link)`
  min-height: 28px;
  padding: 5px 15px;
  border: dotted 1px #A2A2A2;
  color: #0059C2;
  background-color: #F2F2F2;
  border-radius: 3px;
  text-align: center;
`;


export const ApprovalDate = styled.div`
  margin-top: 10px;
  margin-left: 250px;
`;

export const Input = styled.input.attrs(props => ({
  width: props.width,
  marginRight: props.marginRight,
  flexGrow: props.flexGrow,
  bgColor: props.bgColor,
  }))`
  display: flex;
  border: solid 1px #E0E0E0;
  height: 40px;
  flex-grow: ${props => props.flexGrow || 4}
  margin-right: ${props => props.marginRight || "0px"};
  background-color: ${props => props.bgColor || "#FFFFFF"};
  font-size: 14px;
  padding-left: 10px;
  border-radius: 3px;
  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

export const Label = styled.div.attrs(props => ({
  width: props.width,
  flexGrow: props.flexGrow,
  }))`
  display: flex
  margin-right: 10px;
  flex-grow: ${props => props.flexGrow || 1}
  height: 38px;
  font-weight: bold;
  line-height: 38px;
  font-size: 12px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`;

export const ApproveListContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  border: none;
  padding: 10px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
  border: solid 1px #A1A1A1;
  margin: 5px 0px 5px 0px
  padding: 10px;
`;

export const CardRow = styled.p`
  display: flex;
  flex-direction: row;
`;

export const SigingButtonBox = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

export const CardCell = styled.span.attrs(props => ({
  flexGrow: props.flexGrow,
  }))`
  display: flex;
  text-align: left;
  flex-grow: ${props => props.flexGrow || 1}
`;

export const ApprovalIcon = styled(Intersect)`
  width: 24px;
  height: 24px;
  color: #0059C2;
`;

export const RemoveApprovalIcon = styled(X)`
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  cursor: pointer;
`;

export const SearchBox = styled(Input)`
  min-height: 40px;
  flex: 1;
`;

export const AddButton = styled.button`
  color: #FFFFFF;
  background-color: #0059C2;
  border-width: 0px;
  border-radius: 3px;
  min-height: 38px;
  margin-left: 8px;
  margin-top: 1px;
`;

export const PlusIcon = styled(Filter)`
  width: 28px;
  height: 28px;
`;

export const CheckSquareIcon = styled(CheckSquare)`
  width: 28px;
  height: 28px;
  color: #1A1D21;
`;

export const XIcon = styled(XCircleFill)`
  width: 28px;
  height: 28px;
  color: #D84B65;
  margin-right: 10px;
`;

export const SearchBoxContainer = styled.div`
  flex: 1;
`;

export const LoadTemplateContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubmitButton = styled(DefaultButton)`
  background-color: #0059C2;
`;

