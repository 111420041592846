export const userRole = {
    admin: {
        en: "Admin",
        vn: "Quản trị Hệ thống"
    },
    saleStaff: {
        en: "Sale Staff",
        vn: "N.Viên Bán hàng"
    },
    saleManager: {
        en: 'Sale Manager',
        vn: "Q.Lý Bán hàng"
    },
    accountant: {
        en: "Accountant",
        vn: "Kế toán"
    },
    accountChief: {
        en: "Account Chief",
        vn: "Kế toán trưởng",
    },
    financeStaff: {
        en: "Fianace Staff",
        vn: "N.Viên Tài chính"
    },
    financeChief: {
        en: "Finance Chief",
        vn: "Giám đốc Tài chính",
    },
    director: {
        en: "Director",
        vn: "Giám đốc",
    },
    chairman: {
        en: "Chairman",
        vn: "Chủ tịch",
    },
    representative: {
        en: "Representative",
        vn: "Đại diện pháp luật",
    },
    docEditor: {
        en: "Document Editor",
        vn: "N.Viên Soạn thảo V.bản",
    },
    approvalEditor: {
        en: "Approval Editor",
        vn: "N.Viên Soạn thảo P.duyệt",
    },
    staff: {
        en: "Staff",
        vn: "Nhân viên"
    }
}

export const userRoleArray = {
    'admin': {
        groupName: 'admin',
        status: 'active'
    }
    // 'saleStaff',
    // 'saleManager',
    // 'accountant',
    // 'accountChief',
    // 'financeStaff',
    // 'financeChief',
    // 'director',
    // 'chairman',
    // 'representative',
    // 'docEditor',
    // 'approvalEditor',
    // 'staff'
}

export const accessRoleText = {
    editSystem: {
        en: "Edit System",
        vn: "Thay đổi Hệ thống"
    },
    deleteSystem: {
        en: "Delete System",
        vn: "Xoá Hệ thống"
    },
    viewSystem: {
        en: "View System",
        vn: "Xem Hệ thống"
    },
    editApproval: {
        en: "Edit Approval",
        vn: "Thay đổi Phê duyệt"
    },
    deleteApproval: {
        en: "Delete Approval",
        vn: "Xoá Phê duyệt"
    },
    viewApproval: {
        en: "View Approval",
        vn: "Xem Phê duyệt"
    },
    editDocument: {
        en: "Edit Document",
        vn: "Thay đổi Tài liệu"
    },
    deleteDocument: {
        en: "Delete Document",
        vn: "Xoá Tài liệu"
    },
    viewDocument: {
        en: "View Document",
        vn: "Xem Tài liệu"
    },
    editInfo: {
        en: "Edit Info",
        vn: "Thay đổi Thông tin"
    },
    deleteInfo: {
        en: "Delete Info",
        vn: "Xoá Thông tin"
    },
    viewInfo: {
        en: "View Info",
        vn: "Xem Thông tin"
    },
    signApproval: {
        en: "Sign Approval",
        vn: "Ký Phê duyệt"
    },
    signDocument: {
        en: "Sign Document",
        vn: "Ký Văn bản"
    }
}

export const userFormControl = {
    userManagement: {
        en: "User Management",
        vn: "Quản lý người dùng"
    },
    addUser: {
        en: "Add user",
        vn: "Thêm người dùng"
    },
    searchBoxPlaceHolder: {
        en: "Search by name, company, email, phone",
        vn: "Tìm kiếm theo họ tên, công ty, số điện thoại"
    },
    groupManagement: {
        en: "Group Management",
        vn: "Quản lý nhóm người dùng"
    },
    createGroup: {
        en: "Create New Group",
        vn: "Tạo Nhóm người dùng",
    },
    groupName: {
        en: "Group Name",
        vn: "Tên nhóm người dùng"
    },
    roles: {
        en: 'Access Roles',
        vn: 'Quyền truy cập'
    },
    status: {
        en: 'Status',
        vn: 'Trạng thái'
    },
}

export const groupRoleText = {
    editRole: {
        en: "Edit Role",
        vn: "Thay đổi"
    },
    deleteRole: {
        en: "Delete Role",
        vn: "Xoá"
    },
    viewRole: {
        en: "View Role",
        vn: "Xem"
    }
}

export const groupManagement = {
    groupName: {
        en: "Group Name", 
        vn: "Nhóm"
    },
    status: {
        en: "Status",
        vn: "Trạng thái"
    },
    item: {
        en: "Item",
        vn: "STT"
    },
    addGroup: {
        en: "New Group",
        vn: "Thêm nhóm",
    }
}

export const userGroupStatus = {
    active: {
        en: "Active",
        vn: "Hoạt động"
    },
    inactive: {
        en: "Inactive",
        vn: "Dừng hoạt động"
    },
    locked: {
        en: "Locked",
        vn: "Bị khoá"
    },
    suspended: {
        en: "Suspend",
        vn: "Tạm dừng"
    },
    canceled: {
        en: "Canceled",
        vn: "Huỷ bỏ"
    }
}

export const userProfile = {
    item: {
        en: "Item",
        vn: "STT"
    },
    fullname: {
        en: "Fullname",
        vn: "Họ tên"
    },
    lastname: {
        en: "lastname",
        vn: "Họ",
    },
    firstname: {
        en: "firstname",
        vn: "Tên"
    },
    company: {
        en: "company name",
        vn: "Công ty"
    },
    email: {
        en: 'Email',
        vn: 'Email'
    },
    password: {
        en: "Password",
        vn: "Mật khẩu"
    },
    confirmPassword: {
        en: "Confirm Password",
        vn: "Xác nhận Mật khẩu"
    },
    phone: {
        en: 'phone',
        vn: 'Điện thoại'
    },
    role: {
        en: 'Role',
        vn: 'Vai trò'
    },
    status: {
        en: 'Status',
        vn: 'Trạng thái'
    },
    avatar: {
        en: 'Avatar',
        vn: 'Hình đại diện'
    }
}

export const userManagementText = {
    userManagement: {
        en: "User Management",
        vn: "Quản lý Người dùng"
    },
    searchBoxText: {
        en: "Search users by name, email ..",
        vn: "Tìm kiếm người dùng theo tên, email .."
    },
    userCreateTitle: {
        en: "Create User",
        vn: "Tạo mới Người dùng"
    },
    userUpdateTitle: {
        en: "Update User",
        vn: "Cập nhật Người dùng"
    }
}

export const userPermission = {
    accessDeny: {
        en: "Access Deny",
        vn: "Không có quyền truy cập"
    }
}