import React, {Component} from 'react';
// new import for using redux
import { connect } from 'react-redux';
import {Switch, Route} from 'react-router-dom'
import { Router } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import createBrowserHistory from 'history/createBrowserHistory';
import './App.css';
import Login from './views/auth/Login';
import ResetPassword from './views/auth/ResetPassword';
import NotFound from './components/NotFound';
//import Signup from './views/auth/Signup'
import AdminSignup from './views/auth/adminSignup';
// import ForgotPassword from './views/auth/ForgotPassword'
// import ChangePassword from './views/auth/ChangePassword'

import CompanyCreate from './views/company/CompanyCreate';
import CompanyUpdate from './views/company/CompanyUpdate';
import CompanyDetail from './views/company/CompanyDetail';

import AddressDetail from './views/address/AddressDetail';
import AddressForm from './views/address/AddressCreate';

import UserManagement from './views/user/UserManagement';
import UserCreate from './views/user/UserCreate';
import UserDetail from './views/user/UserDetail';

import GroupForm from './views/group/GroupForm';
import GroupList from './views/group/GroupList';
import GroupDetail from './views/group/GroupDetail';

import ApprovalDetail from './views/approval/ApprovalDetail/index'
import ApprovalForm from './views/approval/ApprovalForm';
//import ApprovalList from './views/approval/ApprovalList';

import ApprovalList from './views/approval/ApprovalList';

import ApprovalSign from './views/approval/ApprovalSign';
import ApprovalReject from './views/approval/ApprovalReject';
import ApprovalDelegate from './views/approval/ApprovalDelegate';

// import for Document routes
import DocumentList from './views/document/DocumentList';
import DocumentForm from './views/document/DocumentForm';
import DocumentDetail from './views/document/DocumentDetail';

import TemplateList from './views/template/TemplateList';

import SignatureForm from './views/signature/SignatureForm';
import SignatureDetail from './views/signature/SignatureDetail';

import Blockchain from './views/blockchain/blockchain';
// import actions
import { fetchUserAction } from './store/actions';
import ApprovalUpdate from './views/approval/ApprovalUpdate';

import FarmDataList from './views/farmData/FarmDataList';
import FarmDataForm from './views/farmData/FarmDataForm';
import FarmDataUpload from './views/farmData/FarmDataUpload';
import FarmDataStreaming from './views/farmData/FarmDataStreaming';


// export history to use for redirect route on other pages
export const history = createBrowserHistory();

class App extends Component {
  // for checking login user
  componentDidMount() {
    // anything need to preload need to be placed here
    // this.props.onFetchUser(); 
  }
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/views/login' component={Login} />
          <Route exact path='/views/resetpassword' component={ResetPassword} />
          {/* <Route exact path='/views/ForgotPassword' component={ForgotPassword} /> */}
          {/* <Route exact path='/views/ChangePassword' component={ChangePassword} /> */}
          
          {/* <Route exact path='/views/adminSignup' component={AdminSignup} /> */}    
          <Route exact path='/views/home' component={ApprovalList} />
          {/* <Route exact path='/views/adminsignup' component={Signup} /> */}
          <Route exact path='/views/adminSignup' component={AdminSignup} />
          {/* <Route exact path='/views/ForgotPassword' component={ForgotPassword} /> */}
          {/* <Route exact path='/views/ChangePassword' component={ChangePassword} /> */}
          
          <Route exact path='/views/user/detail' component={UserCreate} />
          <Route exact path='/views/user/create' component={UserCreate} />
          <Route exact path='/views/user/list' component={UserManagement} />

          <Route exact path='/views/group/create' component={GroupForm} />
          <Route exact path='/views/group/update' component={GroupForm} />
          <Route exact path='/views/group/list' component={GroupList} />
          <Route exact path='/views/group/detail' component={GroupDetail} />

          <Route exact path='/views/company/create' component={CompanyCreate} />
          <Route exact path='/views/company/update' component={CompanyUpdate} />
          <Route exact path='/views/company/detail' component={CompanyDetail} />

          <Route exact path='/views/address/detail' component={AddressDetail} />
          <Route exact path='/views/address/create' component={AddressForm} />
          <Route exact path='/views/address/update' component={AddressForm} />
          
          {/* <Route exact path='/views/adminSignup' component={AdminSignup} /> */}
          {/* dynamic routes for user detail */}
          {/* <Route exact path='/views/user_detail' component={user_detail} />
          <Route exact path='/views/user_form' component={UserForm} /> */}
          {/* APPROVAL ROUTE GO HERE */}
          <Route exact path='/views/approval/detail' component={ApprovalDetail} />
          <Route exact path='/views/approval/create' component={ApprovalForm} />
          <Route exact path='/views/approval/update' component={ApprovalForm} />
          <Route exact path='/views/approval/list' component={ApprovalList} />
          <Route exact path='/views/approval/sign' component={ApprovalSign} />
          <Route exact path='/views/approval/reject' component={ApprovalReject} />
          <Route exact path='/views/approval/delegate' component={ApprovalDelegate} />

          {/* DOCUMENT ROUTES */}
          <Route exact path='/views/document/list' component={DocumentList} />
          <Route exact path='/views/document/detail' component={DocumentDetail} />
          <Route exact path='/views/document/create' component={DocumentForm} />
          <Route exact path='/views/document/update' component={DocumentForm} />

          {/* FARM DATA ROUTES */}
          <Route exact path='/views/farmdata/harvesting/list' component={FarmDataList} />
          <Route exact path='/views/farmdata/harvesting/create' component={FarmDataForm} />
          <Route exact path='/views/farmdata/harvesting/upload' component={FarmDataUpload} />
          <Route exact path='/views/farmdata/harvesting/streaming' component={FarmDataStreaming} />

          {/* TEMPLATE ROUTES */}
          <Route exact path='/views/template/list' component={TemplateList} />

          {/* signature */}
          <Route exact path='/views/signature/create' component={SignatureForm} />
          <Route exact path='/views/signature/update' component={SignatureForm} />
          <Route exact path='/views/signature/detail' component={SignatureDetail} />

          {/* Routes for blockchain */}
          <Route exact path='/views/blockchain' component={Blockchain} />

          {/* Image Validation external url */}
          <Route path='/imagevalidation' component={() => {
              window.location.href = 'https://nice-coast-0b79d381e.4.azurestaticapps.net/';
              return null;
          }}/>
          <Route path = '*' >
            <NotFound />
          </Route>
        </Switch>
        
      </Router>
    );
  }
}

// create mapToProps function

// create DispatchToProps function
const mapDispatchToProps = (dispatch) => {
  console.log('fetching current user..');
  return {
    // onFetchUser: () => dispatch(fetchUserAction()),
  }
}

export default connect(null, mapDispatchToProps)(App);
