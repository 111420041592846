import { 
    // FETCH_USER,
    FETCH_COMPANY_FAILED,
    FETCH_COMPANY_SUCCESS,
    CREATE_ADDRESS_FAILED,
    CREATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAILED,
    UPDATE_ADDRESS_SUCCESS,
    CREATE_COMPANY_FAILED,
    CREATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILED,
    UPDATE_COMPANY_SUCCESS,
} from '../actions/actionTypes';

const INIT_STATE = {
    company: null,
    address: null,
    invalidMsg: null,
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload.company,
            }
        case CREATE_COMPANY_FAILED: 
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
                company: action.payload.company,
            }
        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload.company,
                invalidMsg: null,
            }
        case UPDATE_COMPANY_FAILED: 
            return {
                ...state,
                company: action.payload.company,
                invalidMsg: action.payload.invalidMsg,
            }
        case CREATE_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload,
                invalidMsg: null,
            }
        case CREATE_ADDRESS_FAILED:
            return {
                ...state,
            }
        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload,
                invalidMsg: null,
            }
        case UPDATE_ADDRESS_FAILED:
            return {
                ...state,
            }
        case FETCH_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload.company,
                address: action.payload.address,
            };
        case FETCH_COMPANY_FAILED:
            return state;
        default:
            return state;
    }
}
