import { 
    SELECTED_APPROVAL_VIEW_DETAIL_SUCCESS,
    SELECTED_APPROVAL_VIEW_DETAIL_FAILED,
    UPDATE_APPROVAL_ACTION,
    CREATE_DOCUMENT_ACTION_SUCCESS,
    CREATE_DOCUMENT_ACTION_FAILED,
    GET_CREATE_APPROVAL,
    SELECT_CREATE_APPROVAL_ACTION,
    CREATE_APPROVAL_ACTION_SUCCESS,
    FETCH_APPROVALS_FAILED,
    FETCH_APPROVALS_SUCCESS,
    FETCH_APPROVAL_FAILED,
    FETCH_APPROVAL_SUCCESS,
    ON_CLICK_SIGN_APPROVAL_ACTION,
    ON_CLICK_REJECT_APPROVAL_ACTION,
    ON_CLICK_DELEGATE_APPROVAL_ACTION,
    ON_CLICK_UPDATE_APPROVAL_ACTION,
    UPDATE_APPROVAL_LEVEL_ACTION_SUCCESS,
    UPDATE_APPROVAL_ACTION_SUCCESS,
    CREATE_DOCUMENT_MODAL_ACTION_SUCCESS,
    CREATE_DOCUMENT_MODAL_ACTION_FAILED,
    ONCLICK_CREATE_DOCUMENT_MODAL_ACTION,
    SIGN_APPROVAL_LEVEL_ACTION_FAILED,
    SIGN_APPROVAL_LEVEL_ACTION_SUCCESS,
    GET_GRAIN_IMAGE_VALIDATION_SUCCESS,
    GET_GRAIN_IMAGE_VALIDATION_FAILED,
    ON_SAVE_DRAFT_APPROVAL_FAILED,
    ON_SAVE_DRAFT_APPROVAL_SUCCESS,
    GENERATE_APPROVAL_CERTFIFICATE_SUCCESS,
    GENERATE_APPROVAL_CERTFIFICATE_FAILED,
} from '../actions/actionTypes'; 
import { stat } from 'fs';

 // initicate email and pass to prevent from returning undefined object
const INIT_STATE = { 
    selectedApproval: null,  //using on approval_detail, user selected an approval
    approval: null,
    approvalNumber: null,
    customers: [],
    company: null,
    approvalTemplates: [],
    approvals: [],
    approvalLevel: null,
    popupModal: true,
    approvalDoc: null,
    supportDoc: null,
    hideDocumentModal: false,
    invalidMsg: null,
    grainImageValidation: true,
    grainImageValidationError: "Get Validation",
    draftApproval: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_SAVE_DRAFT_APPROVAL_SUCCESS:
            return {
                ...state,
                draftApproval: action.payload.draftApproval,
            }
        case GET_GRAIN_IMAGE_VALIDATION_SUCCESS:
            return {
                ...state,
                grainImageValidation: action.payload.grainImageValidation,
            }
        case SIGN_APPROVAL_LEVEL_ACTION_SUCCESS:
            return {
                ...state,
                popupModal: action.payload.popupModal,
                approvals: action.payload.approvals,     
                approvalNames: action.payload.approvalNames,
            }
        case SIGN_APPROVAL_LEVEL_ACTION_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
            }
        case ONCLICK_CREATE_DOCUMENT_MODAL_ACTION:
            return {
                ...state,
                createDocType: action.payload.createDocType,
                hideDocumentModal: false,
            }
        case CREATE_DOCUMENT_MODAL_ACTION_SUCCESS:
            return {
                ...state,
                document: action.payload.documents,
                documentNames: action.payload.documentNames,
                hideDocumentModal: true,
                approvalDoc: action.payload.approvalDoc ? action.payload.approvalDoc : null,
                supportDoc: action.payload.supportDoc ? action.payload.supportDoc : null,
            }
        case UPDATE_APPROVAL_ACTION_SUCCESS:
            return {
                ...state,
                // popupModal: action.payload.popupModal,
                approvals: action.payload.approvals,     
                approvalNames: action.payload.approvalNames,
            }
        case UPDATE_APPROVAL_LEVEL_ACTION_SUCCESS:
            return {
                ...state,
                popupModal: action.payload.popupModal,
                approvals: action.payload.approvals,     
                approvalNames: action.payload.approvalNames,
            }
        case ON_CLICK_UPDATE_APPROVAL_ACTION:
            return {
                ... state,
                approval: action.payload.approval,
            }
        case ON_CLICK_SIGN_APPROVAL_ACTION:
            return {
                ...state,
                approval: action.payload.approval,
                approvalLevel: action.payload.approvalLevel,
                popupModal: action.payload.popupModal,
                invalidMsg: null,
            }
        case ON_CLICK_DELEGATE_APPROVAL_ACTION:
            return {
                ...state,
                approval: action.payload.approval,
                approvalLevel: action.payload.approvalLevel,
            }
        case ON_CLICK_REJECT_APPROVAL_ACTION:
            return {
                ...state,
                approval: action.payload.approval,
                approvalLevel: action.payload.approvalLevel,
            }
        case FETCH_APPROVAL_SUCCESS:
            return {
                ...state,
                approval: action.payload,
            };
        case FETCH_APPROVAL_FAILED:
            return {
                ...state
            };
        case FETCH_APPROVALS_SUCCESS:
            return {
                ...state,
                approvals: action.payload.approvals,
                approvalNames: action.payload.approvalNames,
            };
        case FETCH_APPROVALS_FAILED:
            return {
                ...state,
            };
        case SELECT_CREATE_APPROVAL_ACTION:
            return {
                ...state,
                approval: null,
                approvalNumber: action.payload.approvalNumber,
            }
        case SELECTED_APPROVAL_VIEW_DETAIL_SUCCESS:
            return {
                ...state,
                approval: action.payload.approval,
            }
        case UPDATE_APPROVAL_ACTION:
            return {
                ...state,
                approval: action.payload.approval,
            }
        case CREATE_APPROVAL_ACTION_SUCCESS:
            return {
                ...state,
                approval: action.payload.approval,
                selectedApproval: action.payload.approval,
            }
        case CREATE_APPROVAL_ACTION_SUCCESS:
            return {
                ...state,
                approval: action.payload.approval,
                selectedApproval: null,
            }
        case GET_CREATE_APPROVAL:
            return {
                ...state,
                approvalNumber: action.payload.approvalNumber,
                signers: action.payload.signers,
                // approvalTemplates: action.payload.approvalTemplates
            }
        default:
            return state;
    }
};