import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
// import {Link} from 'react-router-dom';
// import Dropzone from 'react-dropzone';
// import ApprovalBase from './ApprovalBase';
// import styles

import {
  CancelButton, SubmitButton, Input, LoadTemplateContentContainer, InfoContainer, RadioButton, Tr, Td, Th, TBody, THead, Title, Label, RemoveButton, InputContainer,
  Table, SearchboxContainer,
} from './style';

import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../store/actions';

const util = require('util');
class SaveTemplateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            approvalLevel: null,
            comment: '',
        };
    }

    componentDidMount() {
        // fetch all Documents
        if (this.props.approvalLevel) {
            let {approvalLevel} = this.state;
            approvalLevel = this.props.approvalLevel;
            this.setState({approvalLevel});
        } 
    }
    
    onSaveTemplate = () => {
        console.log('On Save Template');
    }

    body = () => {
        return (
          <LoadTemplateContentContainer>
            <InputContainer>
              <Input placeholder="Enter template name"/>
            </InputContainer>
          </LoadTemplateContentContainer>
        )
    }
    
    render() {
      return (
        <Modal
          show={this.props.showSaveTemplateModal}
          onHide={() => this.props.hideTemplateModal()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Save as a template
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.body()}
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton onClick={() => this.onSaveTemplate()}>Save</SubmitButton>
            <CancelButton onClick={() => this.props.hideTemplateModal()}>Cancel</CancelButton>
          </Modal.Footer>
        </Modal>
      );
    }
}

const mapStateToProps = state => {

    return {
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signers: state.fetchData.signers,
        signersInfo: state.fetchData.signersInfo,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
        // onUpdateApproval: (approval, approvalLevels) => dispatch(updateApprovalAction(approval, approvalLevels)),
        onUpdateApprovalLevel: (approvalLevel, approval) => dispatch(updateApprovalLevelAction(approvalLevel, approval)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveTemplateModal);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

