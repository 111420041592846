import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import searchIcon from '../../assets/images/icons/search22x22.png'
import ApprovalBase from './ApprovalBase';
import util from 'util';
import TableRow from '../../commons/tableInput';
import {
  Table, THead, Tr, Th, Td, TBody, RemoveButton, FormContainer, Title, ApprovalDate, InfoContainer,
  Label, Select, Option, ApproveLevelContainer, InputContainer, Input, SearchboxContainer,
} from './style';
import {
  SpanLabel, Button,
} from '../../commons';
import async from 'async';
// import fetchData actions
import {
    fetchSigner,
    fetchSigners,
    fetchCompany,
    fetchGetApprovalCreate,
    fetchApprovalTemplates,
    getCreateApproval,
    fetchDocuments,
    createApprovalAction,
  } from '../../store/actions';
// import {utilities} from '../commons';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const _today = formatDate(new Date());

class ApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalNumber: '',
      approvalName: '',
      priority: 'Medium',
      company: {},
      existingCheck: false,
      signers: [],
      supportDocs: [],
      approvalDoc: '',
      approvalLevels: [
        {level: 1, group: "Phê duyệt cấp 1", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
        {level: 2, group: "Phê duyệt cấp 2", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
      ],  //default = 2 leve
      errMsg: '',
    };
    // this.props.onGetCreateApproval();
  }

//   level: {
//     type: Number,   //#[\d]{8}    //auto increasement by date
//     default: 1,
// },
// group: {
//     type: String,   //describing the name of department need to approve for this approval
//     default: 'approval group',
// },
// signers: [{
//     type: Schema.Types.ObjectId,
//     ref: 'User',         //CK][\d]{2}[TDLM]{1}[A-Z]{2}
// }],
// signed: {
//     type: Number,
//     default: 0
// },
// signatures: {   
//     type: Number,      //how many signature required to pass to next level
//     default: 1,
// },
// deadline: {
//     type: Date,                     //format: YYYY-MM-DD, https://mongoosejs.com/docs/tutorials/dates.html
//     default: _today,         //CK][\d]{2}[TDLM]{1}[A-Z]{2}
// },
// status: {
//     type: String,
//     default: 'created'      //created, waiting, done
// }

  componentDidMount() {
      // fetch documents, documentNames
    this.props.onFetchDocuments();
    // fetch signers/users
    this.props.onFetchSigners();
    if (this.props.approval) {
      // this is an update approval, load this value to the form
      let approval = this.props.approval
      let {
        approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, company, stage,
      } = this.state;
      approvalName = approval.approvalName ? approval.approvalName : '';
      approvalNumber = approval.approvalNumber ? approval.approvalNumber : '';
      approvalLevels = approval.approvalLevels ? approval.approvalLevels : [];
      priority = approval.priority ? approval.priority : '';
      supportDocs = approval.supportDocs ? approval.supportDocs : [];
      approvalDoc = approval.approvalDoc ? approval.approvalDoc : '';
      company = approval.company ? approval.company._id : '';
      stage = approval.stage ? approval.stage : '';
      this.setState({
        approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, company, stage,
      })
    }
   
  }


  addRequiredDocIdHandler = (e) => {
    
  }

  preSetRequiredDocs = (numberOfRows) => {
    
  }

  setApprovalTemplate = (templateId) => {
    let approvalTemplates = this.state.approvalTemplates;
    console.log('inspect approvalTemplates: ');
    console.log(util.inspect(approvalTemplates));
    for (let i = 0; i < approvalTemplates.length; i++) {
      if (approvalTemplates[i]._id == templateId) {
        console.log('update approvalTemplate state');
        this.setState(prevState => ({
          ... prevState,
          approvalTemplate: approvalTemplates[i],
        }));
        // return;
      }
    }
  }

  submitCreateApproval = () => {
    let approval = {};
    let {approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc} = this.state;
    // preset the first level as signing level
    approvalLevels[0].status = 'signing';
    approval.approvalNumber = approvalNumber;
    approval.priority = priority;
    approval.approvalName = approvalName;
    approval.company = this.props.company._id;
    approval.supportDocs = supportDocs;
    approval.approvalDoc = approvalDoc;
    approval.approvalLevels = approvalLevels;
    console.log('approval from state: ', util.inspect(approval));
    // send to action
    this.props.onSubmitCreateApprovalAction(approval);
  }

  submitIssueApproval = () => {
    console.log('observe approval before issue ..');
    console.log(util.inspect(this.state.approval));
    let approvalId = this.state.approval._id;
    if ((approvalId !== undefined) && approvalId !== '') {
      console.log('issuing this approval ..');
      axios.post(`${url}company/approval/${approvalId}/issue`, {approvalId: approvalId}).then(res =>{
        console.log('res data: ');
        console.log(util.inspect(res.data));
      })
    } else {
      console.log('approval id not valid', approvalId);
    }
  }


  formatDate = () => {
    const _today = new Date();
    let formatDate = _today.getFullYear() + '-'
    + (_today.getMonth() + 1).toString() + '-'
    + _today.getDate();

    if (this.state.approval) {
      if (this.state.approval.issueDate) {
        const issueDate = new Date(this.state.approval.issueDate.toString());
        formatDate = issueDate.getFullYear() + '-'
                    + (issueDate.getMonth() + 1).toString() + '-'
                    + issueDate.getDate();
      }
    }
    return formatDate;
  }

  onViewDocumentDetail = (documentId) => {
    // implemented later
  }

  onRemoveMainDocument = (document) => {
    let documents = this.state.approvalDoc;
    let _newDocument = []
    documents.forEach((_row, _index) => {
      if (_row.docId.toString() != document.toString()) {
        _newDocument.push(_row);
      }
    })
    this.setState(prevState => ({
      ... prevState,
      approvalDoc: _newDocument,
    }))
  }

  onRemoveRelevantDocument = (document) => {
    let documents = this.state.supportDocs;
    let _newDocument = []
    documents.forEach((_row, _index) => {
      if (_row.docId.toString() != document.toString()) {
        _newDocument.push(_row);
      }
    })
    this.setState(prevState => ({
      ... prevState,
      supportDocs: _newDocument,
    }))
  }

  handleAddingMainDocument = (documentName) => {
    console.log('documentName selected is: ', documentName);
    let documents = this.props.documents ? this.props.documents : [];
    let approvalDoc = this.state.approvalDoc;
    documents.forEach((_row, _index) => {
      if (_row.docName.toString() == documentName.value.toString()) {
        approvalDoc =_row;
        this.setState(prevState => ({
          ... prevState,
          approvalDoc: approvalDoc,
        }))
      }
    })
    
  }

  handleAddingRelevantDocument = (documentName) => {
    console.log('documentName selected is: ', documentName);
    let documents = this.props.documents ? this.props.documents : [];
    let _documents = this.state.supportDocs;
    documents.forEach((_row, _index) => {
      if (_row.docName.toString() == documentName.value.toString()) {
        _documents.push(_row);
      }
    })
    this.setState(prevState => ({
      ... prevState,
      supportDocs: _documents,
    }))
  }

  handleAddingSigner = (_invidualSignerInfo, level) => {
    {
      console.log('Signer selected is: ', _invidualSignerInfo);
      let signers = this.props.signers ? this.props.signers : [];
      let approvalLvels = this.state.approvalLevels;
      let _levelSigners;
      approvalLvels.forEach((_row, _index) => {
        if (_row.level.toString() == level.toString()) {
          _levelSigners = _row.signers;
        }
      })
      signers.forEach((_row, _index) => {
        if (_row._id.toString() == _invidualSignerInfo.key.toString()) {
          _levelSigners.push(_row);
        }
      })
      approvalLvels.forEach((_row, _index) => {
        if (_row.level.toString() == level.toString()) {
          approvalLvels[_index].signers = _levelSigners;
        }
      })
      this.setState(prevState => ({
        ... prevState,
        approvalLvels: approvalLvels,
      }))
    }
  }

  onAddApprovalLevel = (e) => {
    let approvalLevels = this.state.approvalLevels;
    const _levels = approvalLevels.length;
    let newApprovalLevel =  {
      level: parseInt(_levels) + 1, 
      group: "Phê duyệt cấp " + (parseInt(_levels) + 1).toString(), 
      signers: [],
      signatures: 1,
      deadline: '', 
      status: 'created', 
      signed: []
    };
    // {level: 2, group: "Phê duyệt cấp 2", signers: [], signatures: 1, deadline: '', status: 'created', signed: []},
    approvalLevels.push(newApprovalLevel);
    this.setState(prevState => ({
      ...prevState,
      approvalLevels: approvalLevels,
    }))

  }

  renderMainDocumentTableData() {
    console.log('list of documents:');
    console.log(util.inspect(this.props.documents));
    let documents = [];
    documents.push(this.state.approvalDoc);
    if (!documents) {
      return (
        <div>Loading ..</div>
      )
    }
    if (documents.length > 0) {
      return documents.map((document, index) => {
        const { _id, description, docName, uploadUser, uploadDate } = document //destructuring
        let uploadUserName = uploadUser;
        if( uploadUser) {
          if (uploadUser._id) {
            uploadUserName = uploadUser.firstname + ' ' + uploadUser.lastname;
          }
        }
        let item = parseInt(index + 1)
        return (
            <Tr key={item}>
              <Td>{item ? item.toString() : ''}</Td>
              <Td>{docName ? docName : ''}</Td>
              <Td>{description ? description : ''}</Td>
              <Td>{uploadUserName ? uploadUserName : ''}</Td>
              <Td>{uploadDate ? uploadDate : ''}</Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                  onClick = {() => {
                    this.onViewDocumentDetail(_id)
                  }}
              >Xem</RemoveButton></Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                onClick = {() => {
                  this.onRemoveMainDocument(_id)
                }}
              >X</RemoveButton></Td>
            </Tr>
        )
      })
    } else {
      return (
        <div>Loading ..</div>
      )
    }
    
  }

  renderRelevantDocumentTableData() {
    console.log('list of documents:');
    console.log(util.inspect(this.props.documents));
    let documents = this.state.supportDocs;
    if (!documents) {
      return (
        <div>Loading ..</div>
      )
    }
    if (documents.length > 0) {
      return documents.map((document, index) => {
        const { _id, description, docName, uploadUser, uploadDate } = document //destructuring
        let uploadUserName = uploadUser;
        if( uploadUser) {
          if (uploadUser._id) {
            uploadUserName = uploadUser.firstname + ' ' + uploadUser.lastname;
          }
        }
        let item = parseInt(index + 1)
        return (
            <Tr key={item}>
              <Td>{item ? item.toString() : ''}</Td>
              <Td>{docName ? docName : ''}</Td>
              <Td>{description ? description : ''}</Td>
              <Td>{uploadUserName ? uploadUserName : ''}</Td>
              <Td>{uploadDate ? uploadDate : ''}</Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                  onClick = {() => {
                    this.onViewDocumentDetail(_id)
                  }}
              >Xem</RemoveButton></Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                onClick = {() => {
                  this.onRemoveRelevantDocument(_id)
                }}
              >X</RemoveButton></Td>
            </Tr>
        )
      })
    } else {
      return (
        <div>Loading ..</div>
      )
    }
  }

  renderSignerInfoTable(level) {
    console.log('list of documents:');
    console.log(util.inspect(this.props.signers));
    let approvalLevels = this.state.approvalLevels;
    let _levelSigners = [];
    approvalLevels.forEach((_row, _index) => {
      if (_row.level.toString() == level.toString()) {
        _levelSigners = _row.signers;
      }
    })
    if (!_levelSigners) {
      return (
        <div>Loading ..</div>
      )
    }
    if (_levelSigners.length > 0) {
      return _levelSigners.map((_signerInfo, _index) => {
        let { _id, fullName, role, firstname, lastname} = _signerInfo //destructuring
        let __index = parseInt(_index) + 1;
        if (firstname) {
          fullName = firstname + ' ' + lastname
        }
        return (
            <Tr key={_id}>
              <Td>{__index ? __index.toString() : ''}</Td>
              <Td>{fullName ? fullName : ''}</Td>
              <Td>{role ? role : ''}</Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                  onClick = {() => {
                    this.onViewDocumentDetail(_id)
                  }}
              >Xem</RemoveButton></Td>
              <Td paddingLeft="0px" align="right">
                <RemoveButton
                onClick = {() => {
                  this.onRemoveRelevantDocument(_id)
                }}
              >X</RemoveButton></Td>
            </Tr>
        )
      })
    } else {
      return (
        <div>Loading ..</div>
      )
    }
  }

  renderApprovalLevelTable() {
  
    let approvalLevels = this.state.approvalLevels;
    
    return approvalLevels.map((_approvalLevel, _index) => {
      const { level, group, signatures, deadline, status } = _approvalLevel //destructuring

      return (
        <ApproveLevelContainer key={level}>
          <Title>{'PHÊ DUYỆT CẤP ' + (_index + 1).toString()}</Title>
          <InputContainer>
            <Label flexGrow={1}>{'Tên nhóm phê duyệt'}</Label>
            <Input inputType={'text'}
              value={group} 
              placeholder = {'nhập tên nhóm phê duyệt'}
              onChange = {(e) => {
                let group = e.target.value;
                approvalLevels[_index].group = group
                this.setState(prevState => ({
                  ... prevState,
                  approvalLevels: approvalLevels,
                }));
              }} 
            />
          </InputContainer>
          <InputContainer>
            <Label>{'Thời hạn'}</Label>
            <Input inputType={'text'}
                value={deadline} 
                placeholder = {'yyyy-mm-dd'}
                onChange = {(e) => {
                  approvalLevels[_index].deadline = e.target.value;
                  this.setState(prevState => ({
                    ... prevState,
                    approvalLevels: approvalLevels,
                  }));
                }}
                // style={inputStyle}                   
            /> 
          </InputContainer>
          <InputContainer>
            <Label>{'Số lượng chữ ký cần'}</Label>
            <Select 
            value={this.state.approvalLevels.signatures} 
            onChange={(e) => {
              approvalLevels[_index].signatures = e.target.value;
              this.setState(prevState => ({
                ... prevState,
                approvalLevels: approvalLevels,
              }));
            }}
            width="150px"
          >
            {[1,2,3,4,5,6,7,8,9,10].map(_number => {
            return (
              <Option
                selected={parseInt(_number) === parseInt(signatures) ? true : false}
                value={_number}>
                  {_number + ' chữ ký'}
              </Option>
            );
          })}
          </Select>
          </InputContainer>
          <InputContainer>
            <Label>{'Thêm người ký'}</Label>
            <SearchboxContainer flexGrow={4}>
              <ReactSearchBox
                placeholder="Tìm kiếm người ký"
                value=""
                data={this.props.signersInfo}
                callback={(record )=> {
                  console.log(record);
                }}
                // onChange={(e) => {
                //   let {searchValue} = this.state;
                //   searchValue = e.target.value;
                //   this.setState(searchValue);
                // }}
                onSelect={(_invidualSignerInfo) => {
                  this.handleAddingSigner(_invidualSignerInfo, level)
                }}
                onFocus={() => {
                  console.log('This function is called when is focussed')
                }}
                onChange={value => console.log(value)}
                fuseConfigs={{
                  threshold: 0.05,
                }}
              />
            </SearchboxContainer>
            
          </InputContainer>
          
          <Table>
            <THead>
              <Tr>
                <Th>TT</Th>
                <Th>Tên người ký</Th>
                <Th>Chức vụ</Th>
                <Th>Xem</Th>
                <Th>Loại bỏ</Th>
              </Tr>
            </THead>
              
            <TBody>
              {this.renderSignerInfoTable(level)}
            </TBody>
          </Table>
        </ApproveLevelContainer>
      )
    })

    
  }

  body = () => {
    return (
      <FormContainer>
        <Title>TẠO PHÊ DUYỆT MỚI</Title>
        <ApprovalDate>
          Ngày: {this.state.approval ?
            new Date(this.state.approval.issueDate).getDate() : '' 
          }
          &emsp;Tháng: {this.state.approval ?
            new Date(this.state.approval.issueDate).getMonth() + 1 : '' }
          &emsp;Năm: {this.state.approval ?
            new Date(this.state.approval.issueDate).getFullYear() : '' }
        </ApprovalDate>
        <SpanLabel text={this.state.errMsg} name={'invalidForm'} id={"invalideFrom"} />
        <InfoContainer>

        </InfoContainer>
        <InfoContainer>
          <Label>Số phê duyệt</Label>
          <Input inputType={'text'}
              value={this.state.approvalNumber} 
              placeholder = {'Nhập Số Phê duyệt'}
              onChange = {(e) => {
                let approvalNumber = e.target.value;
                this.setState(prevState => ({
                  ... prevState,
                  approvalNumber: approvalNumber,
                }));
              }}
              // style={inputStyle}                   
              /> 
        </InfoContainer>
        <InfoContainer>
        <Label>Tên phê duyệt</Label>
          <Input inputType={'text'}
              value={this.state.approvalName} 
              placeholder = {'Nhập tên Phê duyệt'}
              onChange = {(e) => {
                let approvalName = e.target.value;
                this.setState(prevState => ({
                  ... prevState,
                  approvalName: approvalName,
                }));
              }}
              // style={inputStyle}                   
              /> 
        </InfoContainer>
        <InputContainer>
            <Label>{'Mức độ ưu tiên'}</Label>
            <Select 
            value={this.state.priority} 
            onChange={(e) => {
              let {priority} = this.state;
              priority = e.target.value;
              this.setState(prevState => ({
                ... prevState,
                priority: priority,
              }));
            }}
            width="150px"
          >
            {['High', 'Medium', 'Low'].map(_priority => {
            return (
              <Option
                selected={_priority === this.state.priority ? true : false}
                value={_priority}>
                  {'Mức độ ưu tiên: ' + _priority}
              </Option>
            );
          })}
          </Select>
          </InputContainer>
        <InfoContainer>
          <Label width="135px">Văn bản chính</Label>
          <ReactSearchBox
            placeholder="Tìm kiếm văn bản chính"
            value=""
            data={this.props.documentNames}
            callback={(record )=> {
              console.log(record);
            }}
            // onChange={(e) => {
            //   let {searchValue} = this.state;
            //   searchValue = e.target.value;
            //   this.setState(searchValue);
            // }}
            onSelect={(documentName) => {
              this.handleAddingMainDocument(documentName)
            }}
            onFocus={() => {
              console.log('This function is called when is focussed')
            }}
            onChange={value => console.log(value)}
            fuseConfigs={{
              threshold: 0.05,
            }}
          />
          <Table>
            <THead>
              <Tr>
                <Th>Item</Th>
                <Th>Mã</Th>
                <Th>Tên Văn Bản</Th>
                <Th>Người upload</Th>
                <Th>Ngày upload</Th>
                <Th>Xem</Th>
                <Th>Huỷ</Th>
              </Tr>
            </THead>
              
            <TBody>
              {this.renderMainDocumentTableData()}
            </TBody>

          </Table>
        </InfoContainer>

        <InfoContainer>
          <Label width="135px">Văn bản Tham khảo</Label>
          <ReactSearchBox
            placeholder="Tìm kiếm văn bản liên quan"
            value=""
            data={this.props.documentNames}
            callback={(record )=> {
              console.log(record);
            }}
            // onChange={(e) => {
            //   let {searchValue} = this.state;
            //   searchValue = e.target.value;
            //   this.setState(searchValue);
            // }}
            onSelect={(documentName) => {
              this.handleAddingRelevantDocument(documentName)
            }}
            onFocus={() => {
              console.log('This function is called when is focussed')
            }}
            onChange={value => console.log(value)}
            fuseConfigs={{
              threshold: 0.05,
            }}
          />
          <Table>
            <THead>
              <Tr>
                <Th>Item</Th>
                <Th>Mã</Th>
                <Th>Tên Văn Bản</Th>
                <Th>Người upload</Th>
                <Th>Ngày upload</Th>
                <Th>Xem</Th>
                <Th>Huỷ</Th>
              </Tr>
            </THead>
              
            <TBody>
              {this.renderRelevantDocumentTableData()}
            </TBody>

          </Table>
        </InfoContainer>
      
        {this.renderApprovalLevelTable()}
        <InfoContainer>
        <Button
          title={"Thêm cấp phê duyệt"}
          className={"btn btn-outline-secondary"}
          onClick = {(e) => {
            this.onAddApprovalLevel(e)
          }}
        />
        </InfoContainer>

      <InfoContainer>
          <Label width="135px">Ngày khởi tạo</Label>
          {/* <Input width="400px" 
            value={this.state.approval.issueDate} 
            onChange={(e) => {
              let {approval} = this.state;
              approval.issueDate = e.target.value;
              this.setState({approval});
            }}
          /> */}
        </InfoContainer>
      
      </FormContainer>
    );
  }
  render() {
    return (
      <ApprovalBase
        contentContainer={this.body()}
        onCreateOrUpdateApproval={() => this.submitCreateApproval()}
        onIssueApproval={this.submitIssueApproval}
        // passing approvalTemplates to ApprovalBase
        approvalTemplates={this.props.approvalTemplates ? this.props.approvalTemplates : []}
      />
    )
  }
}

const mapStateToProps = state => {
  // console.log('documents received from reducer:');
  // console.log(util.inspect(state.document.documents));
  console.log('signerInfo received from reducer:');
  console.log(util.inspect(state.fetchData.signersInfo));
  return {
      company: state.company.company,
      signers: state.fetchData.signers,
      signersInfo: state.fetchData.signersInfo,
      approvalNumber: state.approvalState.approvalNumber,
      currentUser: state.authState.currentUser,
      approval: state.approvalState.approval,
      signer: state.fetchData.signer,
      // approvalTemplate: state.fetchData.approvalTemplate,
      // approvalTemplates: state.approvalState.approvalTemplates,
      documentNames: state.document.documentNames,
      documents: state.document.documents,
  }
}
  
  const mapDispatchToProps = dispatch => {
    return {
        // onNewEvent: () => dispatch(lastEventAction()),
        onFetchCompany: (companyId) => dispatch(fetchCompany(companyId)),
        onFetchSigners: () => dispatch(fetchSigners()),
        onFetchSigner: (signerId) => dispatch(fetchSigner(signerId)),
        // onFetchApprovalNumber: () => dispatch(fetchGetApprovalCreate()),
        // onFetchApprovalTemplates: () => dispatch(fetchApprovalTemplates()),
        onGetCreateApproval: () => dispatch(getCreateApproval()),
        onFetchDocuments: () => dispatch(fetchDocuments()),
        onSubmitCreateApprovalAction: (approval) => dispatch(createApprovalAction(approval)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
  

/*********************Start Stylesheet**********************/
function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

/*********************Start Stylesheet**********************/
// const Container = styled.section`
//   color: #4B4B4B;
//   //display: flex;
//   //flex-direction: column;
//   //align-items: center;
//   //justify-content: flex-start;
//   padding: 10px 0px;
//   font-size: 14px;
// `;

// const Title = styled.div`
//   font-size: 24px;
//   margin-left: 200px;
// `;

// const ApprovalDate = styled.div`
//   margin-top: 10px;
//   margin-left: 250px;
// `;

// const InfoContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 20px;
//   width: 830px;
// `;

// const InfoContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-top: 12px;
// `;

// const Label = styled.div.attrs(props => ({
//   width: props.width,
//   }))`
//   margin-right: 10px;
//   width: ${props => props.width};
//   height: 38px;
//   line-height: 38px;
// `;

// const Input = styled.input.attrs(props => ({
//   width: props.width,
//   marginRight: props.marginRight,
//   }))`
//   border: solid 1px #B7B7B7;
//   height: 36px;
//   width: ${props => props.width || "100px"};
//   margin-right: ${props => props.marginRight || "0px"};
//   font-size: 14px;
//   padding-left: 10px;
  
//   :focus {
//     background-color: #FFF6DB;
//     box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
//   }
// `;



// const RemoveButton = styled.button`
//   border: solid 1px #A1A1A1;
//   color: #303030;
//   height: 25px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 0px 15px;
//   cursor: pointer;
//   margin-right: 15px;
// `;

// const AddButton = styled.button.attrs(props => ({
//   marginLeft: props.marginLeft
//   }))`
//   border: solid 1px #17C177;
//   background-color: #FFFFFF;
//   color: #303030;
//   height: 36px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 0px 15px;
//   cursor: pointer;
//   margin-left: ${props => props.marginLeft || "auto"};
// `;

// const PlusIcon = styled.span`
//   font-size: 24px;
//   margin-right: 5px;
// `;

// const Select = styled.select`
//   border: solid 1px #B7B7B7;
//   height: 36px;
//   padding: 0px 10px;
//   font-size: 14px;
//   color: #4B4B4B;
//   width: 400px;
// `;

// const Option = styled.option`
// `;
// /*********************End Stylesheet**********************/