import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import ApprovalBase from './ApprovalBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, Title, Label,
  YellowButton, ContentContainer, RightContainer, HeaderContainer, BodyHeader,
  GreenButton, Table, PagingContainer, RecordInfo, PButton, PageNumber, SearchBoxContainer,
  InfoContainer, FormContainer, DropzoneContainer,
} from './style';

import {
  Input
} from '../../commons';

import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class ApprovalSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            nextApprovalLevel: null,
            comment: '',
        };
    }

    updateDimensions = () => {
        this.setState({ 
        height: window.innerHeight - 112
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        // fetch all Documents
        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onSignApproval = () => {
        // add signature to approvalLevel accordingly
        let comment = this.state.comment;
        let approvalLevel = this.props.approvalLevel;
        let fullname = this.props.currentUser.firstname + ' ' + this.props.currentUser.lastname;
        if (!approvalLevel.comments) {
            approvalLevel.comments = [];
            approvalLevel.comments.push(fullname + ': comment');
        } else {
            approvalLevel.comments.push(fullname + ': comment');
        }
        let approvalLevels = this.props.approval.approvalLevels;
        let updateApprovalLevels = approvalLevels;
        let approval = this.props.approval;
        approvalLevel.signed.push(this.props.currentUser._id);
        // update status of this approvalLevel if require signatures meet
        if (approvalLevel.signed.length >= parseInt(approvalLevel.signatures)) {
            console.log('signatures required for this approval level is done')
            approvalLevel.status = "done";
            // upate this approvalLevel for approval
            approvalLevels.forEach((__approvalLevel, _index) => {
                if (__approvalLevel._id.toString() == approvalLevel._id.toString()) {
                    updateApprovalLevels[_index] = approvalLevel;
                    approval.approvalLevels = updateApprovalLevels;
                }
            })

            // move stage of approval to next approvalLevel
            const _level = approvalLevel.level;
            // if this is the last stage, so the stage of the approval need to set to done
            if (parseInt(_level) == approvalLevels.length) {
                approval.status = 'done';
            } else {
                let _nextLevel = parseInt(_level) + 1;
                approvalLevels.forEach((__approvalLevel, _index) => {
                    if (parseInt(__approvalLevel.level) == _nextLevel) {
                        // set stage of approval
                        approval.stage = __approvalLevel._id;
                    }
                })
            }
            // then update stage of approval
            this.props.onUpdateApproval(approval, [approvalLevel]);

        } else {
            // update approvalLevel accordingly
            console.log('still requiring more signatures..')
            this.props.onUpdateApprovalLevel(approvalLevel);
        }
    }

    body = () => {
        return (
            <ContentContainer height={this.state.height}>
                
                <FormContainer>
                    <Title>{"Sign and Endorse"}</Title>
                    
                    <InfoContainer>
                        <Input 
                        title={'Note'}
                        className={'form-control'}
                        placeholder={'Comment if any'}
                        value={this.state.comment} 
                        onChange={(e) => {
                            let {comment} = this.state;
                            comment = e.target.value;
                            this.setState({comment});
                        }}
                        />
                    </InfoContainer>
                    <InfoContainer>
                        <Input 
                        title={'Authentication Code'}
                        className={'form-control'}
                        placeholder={'Enter otp received to sign'}
                        value={this.state.comment} 
                        onChange={(e) => {
                            let {comment} = this.state;
                            comment = e.target.value;
                            this.setState({comment});
                        }}
                        />
                        <Label>
                            {"check your email to get OTP"}
                        </Label>
                    </InfoContainer>
                        
                    <InfoContainer>
                        <GreenButton
                            onClick={() => this.onSignApproval()}
                        >{'Sign Now'}
                        </GreenButton>
                    </InfoContainer>
                </FormContainer>
            </ContentContainer>
        )
    }
    
    render() {
        return (
        <ApprovalBase 
            contentContainer = {this.body()}
            onSignApproval={() => this.onSignApproval()}
            onRejectApproval={() => this.onRejectApproval()}
        />
        );
    }
}

const mapStateToProps = state => {

    return {
        approvalLevels: state.approvalState.approvalLevels,
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signer: state.fetchData.signer,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
        onUpdateApproval: (approval, approvalLevels) => dispatch(updateApprovalAction(approval, approvalLevels)),
        onUpdateApprovalLevel: (approvalLevel) => dispatch(updateApprovalLevelAction(approvalLevel)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalSign);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

