import styled from "styled-components";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import PDFViewer from 'pdf-viewer-reactjs'
import {AppIndicator, LayersHalf} from 'react-bootstrap-icons'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import leftArrow from '../../assets/images/icons/leftarrow16x16.png'
import printIcon from '../../assets/images/icons/printer_24x24.png'
import exportIcon from '../../assets/images/icons/export18x18.png'
import checkIcon from '../../assets/images/icons/check24x24.png'
import rightArrowIcon from '../../assets/images/icons/arrow17x15.png'
import ApprovalNavigation from '../../components/ApprovalNavigation'

export const PDFViewerContainer = styled(PDFViewer)`
  width: 100%;
`;

export const DocumentIcon = styled(LayersHalf)`
  width: 20px;
  height: 20px;
  color: #2680EB;
  margin-right: 8px;
`;

export const BeContainer = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
  border: solid 1px red;
`;

export const ControlerRow = styled(Row)`
  
`;

export const DefaultButton = styled.button`
  padding: 8px 10px;
  color: #FFFFFF;
  border-width: 0px;
  border-radius: 3px;
  min-width: 80px;
  min-height: 40px;
`;

export const CancelButton = styled(DefaultButton)`
  background-color: #A2A2A2;
`;

export const SubmitButton = styled(DefaultButton)`
  background-color: #2680EB;
`;

export const ControlerCol = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

export const DocumentFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow:1;
  font-size: 24px;
  margin-right: 50px;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow:1;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const NormalButton = styled.button`
  border: solid 1px #A1A1A1;
  color: #303030;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
  margin-right: 10px;
`;

export const PrintIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${printIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const ExportIcon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${exportIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const DownloadButton = styled.button`
  border: solid 1px #A1A1A1;
  color: #FFFFFF;
  background-color: #87bdd8;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
  margin-right: 10px;
`;

export const IssueIcon = styled.div`
  width: 17px;
  height: 15px;
  background-image: url(${rightArrowIcon});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const BackButton = styled(Link)`
  width: 80px;
  height: 40px;
  line-height: 36px;
  border: solid 1px #F2F2F2;
  color: #F2F2F2;
  border-radius: 3px;
  text-align: center;
`;

export const LeftArrow = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${leftArrow});
  background-repeat: no-repeat;
  margin-right: 10px;
`;

export const TemplateContainer = styled.div`
  padding: 20px 40px;
`;

export const ApprovalInfoContainer = styled.div`
  border: dashed 1px #B7B7B7;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px;
  margin-bottom: 15px;
  font-size: 14px;
`;

export const YellowStatus = styled.div`
  color: #FFAC06;
  font-weight: bold;
  margin: 0px 15px;
`;

export const SearchingCode = styled.div`
  margin-left: auto;
`;

export const GreenText = styled.span`
  color: #17C177;
`;

export const IssueTime = styled.span`
  margin-left: 15px;
`;


export const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 38px;
  padding: 0px 10px;
  margin-right: 10px;
  font-size: 14px;
  color: #4B4B4B;
`;

export const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

export const Option = styled.option`
  
`;

export const Table = styled.table`
  width: 100%;
  margin: 10px 0px;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px #B8B8B8;
`;

export const THead = styled.thead`
`;

export const TBody = styled.tbody`
`;

export const Tr = styled.tr`
  border: solid 1px #B8B8B8;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:nth-child(even) {
    background-color: #F9F9F9;
  }
  &:hover {
    background-color: #FFFAD9;
  }
`;

export const Th = styled.th`
  padding: 10px;
  font-weight: bold;
  background-color: #EEEEEE;
  text-align: left;
  border-collapse: collapse;
}
`;

export const Td = styled.td`
  padding: 10px;
`;

export const BaseContentContainer = styled.section`
  width: 100%;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const DocumentContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 3px 4px 5px #C9C9C9;
`;

export const DocumentHeadRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

export const RemoveButton = styled.button`
  border: solid 1px #A1A1A1;
  color: #303030;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
  margin-right: 15px;
`;

export const ApprovalDate = styled.div`
  margin-top: 10px;
  margin-left: 250px;
`;

export const Input = styled.input.attrs(props => ({
  width: props.width,
  marginRight: props.marginRight,
  }))`
  border: solid 1px #B7B7B7;
  height: 36px;
  width: ${props => props.width || "100px"};
  margin-right: ${props => props.marginRight || "0px"};
  font-size: 14px;
  padding-left: 10px;
  
  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

export const Label = styled.div.attrs(props => ({
  width: props.width,
  }))`
  margin-right: 10px;
  width: ${props => props.width};
  height: 38px;
  line-height: 38px;
`;

export const DocumentHeader = styled.div.attrs(props => ({
    marginBottom: props.marginBottom,
    padding: props.padding,
    bgColor: props.bgColor,
  }))`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color:  ${props => props.bgColor};
  padding:  ${props => props.padding || "0px"};
  margin-bottom: ${props => props.marginBottom || "0px"};
`;

export const DocumentHeadTitle = styled.div.attrs(props => ({
  color: props.color,
}))`
  display: flex;
  flex-direction: row;
  color: ${props => props.color};
  margin-top: 8px;
  font-size: 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BeButton = styled.button.attrs(props => ({
  marginRight: props.marginRight,
  marginLeft: props.marginLeft,
}))`
  flex: 1
  padding: 8px 10px;
  color: #FFFFFF;
  border-width: 0px;
  border-radius: 3px;
  margin-right: ${props => props.marginRight || "0px"};
  margin-left: ${props => props.marginLeft || "0px"};
  min-height: 40px;
`;

export const RedButton = styled(BeButton)`
  background-color: #D84B65;
`;

export const YellowButton = styled(BeButton)`
  background-color: #F3A83C;
  min-width: 80px;
`;

export const GreenButton = styled(BeButton)`
  background-color: #17C177;
`;

export const GrayBorderButton = styled(BeButton)`
  border: solid 1px #A2A2A2;
  color: #707070;
  padding: 8px 5px;
  min-width: 80px;
`;

export const StandardButton = styled.button.attrs(props => ({
  width: props.width
}))`
  height: 30px;
  width: ${props => props.width || "auto"};
  padding: 5px 10px;
  background-color: #FFFFFF;
  border: solid 1px #A2A2A2;
  color: #2680EB;
  border-radius: 3px;
  text-align: center;
`;

export const PagingContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const RecordInfo = styled.div`
  align-items: center;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const PagingButtons = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

export const PButton = styled.button`
  border: solid 1px #707070;
  background-color: #FFFFFF;
  color: #272727;
  width: 33px;
  height: 25px;
  cursor: pointer;
  margin-left: 6px;
`;

export const PageNumber = styled.div`
  border: solid 1px #707070;
  background-color: #484848;
  color: #FFFFFF;
  font-weight: bold;
  width: 33px;
  height: 23px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  flex-grow: 1;

`;

export const DropzoneContainer = styled.div`
  display: flex;
  height: 200px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px #707070;
  background-color: #d5e1df;
`;