

import { 
    SHOW_PROGRESS_SPINNER,
    HIDE_PROGRESS_SPINNER,
} from './actionTypes';


export const showProgress = () => {
    return dispatch => {
        dispatch({
            type: SHOW_PROGRESS_SPINNER,
            payload: {
                progress: true,
            }
        })
    }
}

export const hideProgress = () => {
    return dispatch => {
        dispatch({
            type: HIDE_PROGRESS_SPINNER,
            payload: {
                progress: false,
            }
        })
    }
}