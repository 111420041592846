export const displayVietnameseAccountNumber = (dicimalNumber) => {
    let finalText = "";
    if (parseFloat(dicimalNumber) > 0.0) {
        dicimalNumber = dicimalNumber.toString();
        let firstPart = (dicimalNumber.split('.')[0]).toString();
        const secondPart = dicimalNumber.split('.')[1];

        const _length = firstPart.length;
        if (_length <= 3) {
            return dicimalNumber.toString();
        }
        const _round = Math.floor(_length / 3);
        const _remainder = _length % 3;
        finalText = finalText.slice(0, _remainder);
       
        if (_round >= 1) {
            for (var i = 0; i < _round; i++) {
                finalText = finalText + "," + firstPart.slice(_remainder + i * 3, _remainder + (i + 1) * 3);
            }
        }
        
        if (finalText.charAt(0) == ',') {
            finalText = finalText.slice(1,);
        }
        if (secondPart) {
            finalText = finalText + '.' + secondPart;
        }
        
    } else {
        return dicimalNumber;
    }
    return finalText;
}

export const formatDateYYYMMDD = (_date) => {
    let __date = new Date(_date);
    let _month = __date.getMonth() + 1;
    if (_month <= 9) {
        _month = "0" + _month.toString();
    }
    let _day = __date.getDate();
    if (_day <= 9) {
        _day = "0" + _day.toString();
    }
    let formatDate = __date.getFullYear() + '-' + _month + "-" + _day;
    return formatDate;
}

export const compareTwoDate = (_date1, _date2) => {
    const __date1 = new Date(_date1);
    const __date2 = new Date(_date2);
    let result = false;
    console.log('compared dates are: ', __date1, ', ', __date2);
    if (__date1 < __date2) {
        result = true;
    }
    console.log('compare result are: ', result);
    return result;
}

export const displayVietnameseInvoiceNumber = (invoiceNumber) => {
    let result = "";
    invoiceNumber = invoiceNumber.toString();
    if (!invoiceNumber || invoiceNumber == "") {
        return result;
    }
    const _length = invoiceNumber.length;
    let prefixZoro = "";
    for (var i = 0; i < (8 - _length); i++) {
        prefixZoro = prefixZoro + "0";
    }
    result = prefixZoro + invoiceNumber;
    return result;
}