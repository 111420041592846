import React from 'react'
import {Link, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dropdown, DropdownMenu, DropdownToggle} from 'react-bootstrap';

import { authenticationService } from '../../services';
import { fetchCompany} from '../../store/actions';
import util from 'util';

import {
  Container, BootContainer, Logo, MenuItemContainer, HomeIcon, UserProfileContainer, CustomLink, 
  ListIcon, UserIcon, Dropdown1, Dropdown2, CustomToggle, UserMenu, DefaultToggle
} from './style';

import {
  selectCreateApprovalAction,
  onViewUserDetail,
  fetchUser,
  fetchSigners,
} from '../../store/actions';

class Header extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      user: authenticationService.currentUser.source._value,
      redirect: false,
      menuItem: props.menuItem,
      border0: '#FFFFFF',
      border1: '#FFFFFF',
      border2: '#FFFFFF',
      border3: '#FFFFFF',
      textColor0: '#505050',
      textColor1: '#505050',
      textColor2: '#505050',
      textColor3: '#505050',
      loginRedirect: false,
      companyDirect: false,
    }
    
  }

  componentDidMount() {
    if(this.state.menuItem === '0') {
      this.setState({
        border0: '#2680EB',
        textColor0: '#2680EB',
      });
    }

    else if(this.state.menuItem === '1') {
      this.setState({ 
        border1: '#2680EB',
        textColor1: '#2680EB',
      });
    }
    else if(this.state.menuItem === '2') {
      this.setState({ 
        border2: '#2680EB',
        textColor2: '#2680EB',
      });
    }
    else if(this.state.menuItem === '3') {
      this.setState({ 
        border3: '#2680EB',
        textColor3: '#2680EB',
      });
    }
    
    let _user = this.props.currentUser;
    if (!_user) {
      console.log('must login to manage user');
      this.props.history.push('/views/login');
    } else {
      console.log('implemented later...')
      // if (_user.company) {
      //   if (_user.company._id) {
      //     let _companyId = _user.company;
      //     this.props.onFetchCompany(_companyId);
      //   }
      // }

    }
  }

  logoutHandler = () => {
    authenticationService.logout().then(() => {
      this.props.history.push('/views/login');
      // this.setState(prevState => ({
      //   loginRedirect: true,
      // }))
    })
  }

  manageUsers = () => {
    this.props.history.push('/views/user/list');
  }

  viewTemplates = () => {
    this.props.history.push('/views/template/list');
  }

  viewProfile = () => {
    let currentUser = this.props.currentUser;
    this.props.onViewUserDetail(currentUser);
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/views/login' />
    }
  }

  renderCompanyRedirect = () => {
    if (this.state.companyDirect) {
      return <Redirect to='/views/company_detail' />
    }
  }

  renderUsername() {
    if(this.props.currentUser){
      return this.props.currentUser.firstname + " " + this.props.currentUser.lastname;
    }
  }

  onClickCreateNewApproval = () => {
    // return;
    // this.props.onFetchSigners();
    this.props.onClickCreateNewApproval();
  }
  
  render() {
    return (
      <Container>
        <BootContainer>
          <CustomLink to='/views/blockchain'>
            <Logo />
          </CustomLink>
          <MenuItemContainer borderColor={this.state.border0}>
            <CustomLink textColor={this.state.textColor0} to='/views/approval/list'>{"Grain Certificates"}</CustomLink>
          </MenuItemContainer>

          

          <MenuItemContainer borderColor={this.state.border1}>
            <CustomLink textColor={this.state.textColor1} to='/views/farmdata/harvesting/list'>{"Farm Data"}</CustomLink>
          </MenuItemContainer>

          <MenuItemContainer borderColor={this.state.border2}>
            <CustomLink textColor={this.state.textColor2} to='/views/document/list'>{"Support Documents"}</CustomLink>
          </MenuItemContainer>

          <MenuItemContainer borderColor={this.state.border3}>
            <CustomLink textColor={this.state.textColor3} onClick={() => this.onClickCreateNewApproval()}>{"Create New Certificate"}</CustomLink>
          </MenuItemContainer>
          {
            /**
             *  <MenuItemContainer borderColor={this.state.border3}>
                <CustomLink textColor={this.state.textColor3} to='/views/user/list'>Users</CustomLink>
                </MenuItemContainer>
             */
          }
          
          <UserProfileContainer>
            <Dropdown1>
              <DefaultToggle variant="light" id="dropdown-basic" style={UserMenu}>
                <UserIcon /> {this.renderUsername()}
              </DefaultToggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item style={UserMenu} onClick={() => this.viewProfile()}>My Profile</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick={this.manageUsers}>User Management</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick={this.viewTemplates}>Certfificate Templates</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick="#/action-3">Settings</Dropdown.Item> */}
                <Dropdown.Item style={UserMenu} onClick={this.manageUsers}>User Management</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick={this.logoutHandler}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown1>

            <Dropdown2>
              <CustomToggle variant="Primary" id="dropdown-basic" style={UserMenu}>
                <ListIcon />
              </CustomToggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item style={UserMenu} onClick="#/action-2">Create New...</Dropdown.Item> */}
                <Dropdown.Divider />
                {/* <Dropdown.Item style={UserMenu} onClick="#/action-2">Certificates</Dropdown.Item> */}
                <Dropdown.Divider />
                {/* <Dropdown.Item style={UserMenu} onClick="#/action-2">Documents</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick="#/action-2">Templates</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick="#/action-2">Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick="#/action-2">My Profile</Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item style={UserMenu} onClick={this.logoutHandler}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown2>
          </UserProfileContainer>
        </BootContainer>

        
      </Container>
    );
  }
}

const mapStateToProps = state => {
  console.log('check if addresses receive from reducer:');
  console.log(util.inspect(state.fetchData.addresses));
  return {
      currentUser: state.authState.currentUser,
      //company: state.company.company,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchCompany: (companyId) => dispatch(fetchCompany(companyId)),
    //onLogout: () => dispatch(emailLogoutAction()),
    onClickCreateNewApproval: () => dispatch(selectCreateApprovalAction()),
    onViewUserDetail: (user) => dispatch(fetchUser(user)),
    onFetchSigners: () => dispatch(fetchSigners()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
