import React, { Component } from 'react'
// import for layout design
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'



class BaseLayout extends Component {
  render() {
    return (
      <Container style={layoutStyle}>
        
        <Row>
          <Col xs={12} md={12} lg={12}>
            {this.props.header}
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={3} lg={2}>
                {this.props.leftComponent}
          </Col>
          <Col xs={8} md={9} lg={10}>  
                {this.props.mainComponent}   
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            {this.props.footer}
          </Col>
        </Row>
        
      </Container>
    )
  }
}

export {BaseLayout}
/**
 * <div className="row" style={{ marginTop: 0, marginBottom: 0, }}>
          <LeftMenu />
          <div className="col s8" style={{ backgroundColor: '#333333' }}>  
            {this.props.mainComponent}   
          </div>
          <Aside />     
        </div>
 */

const layoutStyle = {
  maxWidth: '100%'
}