import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
const prodKeys = require('../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const currentCompanySubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentCompany')));


export const authenticationService = {
    login,
    logout,
    isLogined,
    currentUser: currentUserSubject.asObservable(),
    currentCompany: currentCompanySubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password) {
    return axios.post(`${url}auth/login`, {email, password})
    .then((response) => {
      
        if(response.data.user) {
            let user = response.data.user;
            // using local storate temporarily for Demo
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            // populate company if needed
            if (user.company != undefined && user.company !='') {
                populateCompany(user.company).then();
            }
            return user;
        }
    })
    .catch((_err) => {
        console.log('error: ', util.inspect(_err));
    })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    return axios.get(`${url}auth/logout`).then(res =>{
        console.log('logout sucess from suer: ', res.data.title);
        return;
    })
}

function isLogined() {
    // let requestedId = this.state.userId;
    return axios.get(`${url}auth/current_user`)
    .then((response) => {
        console.log('respond data: ');
        console.log(util.inspect(response.data));
        let _user = response.data;
        console.log('user from authentication: ', _user)
        if (_user) {
            return _user;
        } else {
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);
            
        }
        
    })
    .catch((_err) => {
        console.log('error: ', util.inspect(_err));
    })
}

function populateCompany(companyId) {
    return axios.get(`${url}company/profile/${companyId}`)
    .then((response) => {
      
        if(response.data.company) {
            let company = response.data.company;
            // using local storate temporarily for Demo
            localStorage.setItem('currentCompany', JSON.stringify(company));
            currentCompanySubject.next(company);
            return company;
        }
    })
    .catch((_err) => {
        console.log('error: ', util.inspect(_err));
    })
}