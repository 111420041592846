import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import ApprovalBase from './ApprovalBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, Title, Label,
  YellowButton, ContentContainer, RightContainer, HeaderContainer, BodyHeader,
  GreenButton, Table, PagingContainer, RecordInfo, PButton, PageNumber, SearchBoxContainer,
  InfoContainer, FormContainer, DropzoneContainer, Select, Option,
} from './style';

import {
  Input,
} from '../../commons';

import {
    updateApprovalAction,
    updateApprovalLevelAction,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class ApprovalReject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            nextApprovalLevel: null,
            comment: '',
            invalidMsg: '',
        };
    }

    updateDimensions = () => {
        this.setState({ 
        height: window.innerHeight - 112
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        // set default return to first approval level
        let approval = this.props.approval;
        if (approval) {
            let {nextApprovalLevel} = this.state;
            nextApprovalLevel = approval.approvalLevels[0]._id;
            this.setState({nextApprovalLevel});
        }
        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onRejectApproval = () => {
        let approval = this.props.approval;
        // if this is alreay updated approval, then cannot reject
        if (approval.status == 'done') {
            console.log('this is alreay approved, cannot reverse or reject');
            return this.props.history.push('/views/approval/list');
        }
        // add signature to approvalLevel accordingly
        let comment = this.state.comment;
        let nextApprovalLevel = this.state.nextApprovalLevel;
        
        let approvalLevel = this.props.approvalLevel;
        let fullname = this.props.currentUser.firstname + ' ' + this.props.currentUser.lastname;
        if (!approvalLevel.comments) {
            approvalLevel.comments = [];
            approvalLevel.comments.push(fullname + ': ' + comment);
        } else {
            approvalLevel.comments.push(fullname + ': ' + comment);
        }
        let approvalLevels = this.props.approval.approvalLevels;
        let updateApprovalLevels = [];
        let backLevel = 1;
        let currentLevel = approvalLevel.level;
        for (var i = 0; i < approvalLevels.length; i++) {
            if (approvalLevels[i]._id.toString() == nextApprovalLevel) {
                backLevel = parseInt(approvalLevels[i].level);
                
            }
            if (backLevel > currentLevel) {
                console.log('cannot move forward on the signing chain');
                this.setState({invalidMsg: 'cannot move forward on the signing chain'});
                return;
            } else if (backLevel == currentLevel) {
                // then just update this current approvalLevel only, no need to touch on approval stage
                approvalLevel.signed = [];
                this.props.onUpdateApprovalLevel(approvalLevel);
            } else if (backLevel < currentLevel) {
                if (parseInt(approvalLevels[i].level) >= backLevel) {
                    // remove the signatuer, start signging again
                    approvalLevels[i].signed = [];
                    updateApprovalLevels.push(approvalLevels[i]);
                }
                // move stage of approval backward
                approval.stage = nextApprovalLevel;
                // then, update approval
                this.props.onUpdateApproval(approval, approvalLevels)
            }
        }
        // update approval
    }

    body = () => {
        return (
            <ContentContainer height={this.state.height}>
        
                <FormContainer>
                    <Title>{"Reject Form"}</Title>
                    
                    <InfoContainer>
                        <Input 
                        title={'Rejected Reason'}
                        className={'form-control'}
                        placeholder={'Reason for rejecting'}
                        value={this.state.comment} 
                        onChange={(e) => {
                            let {comment} = this.state;
                            comment = e.target.value;
                            this.setState({comment});
                        }}
                        />
                    </InfoContainer>
                        
                    <InfoContainer>
                    <Label>{'Reject to'}</Label>
                    <Select 
                    value={this.state.nextApprovalLevel} 
                    onChange={(e) => {
                        let {nextApprovalLevel} = this.state;
                        nextApprovalLevel = e.target.value;
                        this.setState(prevState => ({
                            ... prevState,
                            nextApprovalLevel,
                        }));
                    }}
                    width="150px"
                >
                    {(this.props.approval? this.props.approval.approvalLevels : []).map(_approvalLevel => {
                    return (
                    <Option
                        selected={this.state.nextApprovalLevel ? (_approvalLevel._id.toString() === this.state.nextApprovalLevel.toString()) ? true : false : false}
                        value={_approvalLevel._id}>
                        {_approvalLevel.group}
                    </Option>
                    );
                })}
                </Select>
                    </InfoContainer>
                </FormContainer>
                <InfoContainer>
                        <YellowButton
                            onClick={() => this.onRejectApproval()}
                        >{'Sign'}
                        </YellowButton>
                    </InfoContainer>
            </ContentContainer>
        )
    }
    
    render() {
        return (
        <ApprovalBase 
            contentContainer = {this.body()}
            onRejectApproval={() => this.onRejectApproval()}
        />
        );
    }
}

const mapStateToProps = state => {

    return {
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signer: state.fetchData.signer,
        currentUser: state.authState.currentUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateApproval: (approval, approvalLevel) => dispatch(updateApprovalAction(approval, approvalLevel)),
        onUpdateApprovalLevel: (approvalLevel) => dispatch(updateApprovalLevelAction(approvalLevel)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalReject);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

