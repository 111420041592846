import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import base layout
import {BaseLayout} from '../../layout/BaseLayout';
import {
  ApprovalBaseContainer, TopButtonContainer,SelectTemplateBox,
} from './style';
import {
  EditButton, BackButton, Select, Option, Title, LeftArrow
} from '../style';

import {
  fetchApprovalTemplate,
  fetchApprovalTemplates,
} from '../../../store/actions';
import util from 'util';
const prodKeys = require('../../../config/prodKeys');
const url = prodKeys.apiUrl;
const _today = new Date();

class ApprovalBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // approvalTemplate: '',
      approvalTemplate: null,
      approvalTemplates: [],
    };
    // this._fetchApprovalTemplates();
  }

  renderCreateUpdateButton = () => {
    return (
      <EditButton onClick={() => this.props.onCreateOrUpdateApproval()}>
        {
          this.props.view_detail ? "Thay Đổi" : "Lưu"
        }
      </EditButton>
    )
  }

  renderApprovalTemplate = () => {
    let approvalTemplates = this.props.approvalTemplates ? this.props.approvalTemplates : []
    if (approvalTemplates) {
      return (
        <Select 
          value={
            this.state.approvalTemplate ?
            this.state.approvalTemplate._id : ''} 
          onChange={(e) => {
            console.log('selected approvalTemplate id: ', e.target.value);
            
            console.log('inspect approvalTemplates: ');
            console.log(util.inspect(approvalTemplates));
            this.setApprovalTemplate(e.target.value)
            
            console.log('selected approvalTemplate now:', util.inspect(this.state.approvalTemplate));
          }}>
            {approvalTemplates.map(approvalTemplate => {
            return (
              <Option
                value={approvalTemplate._id}>
                  {approvalTemplate.approvalName}
              </Option>
            );
          })}
        </Select>
      )
    } else {
      return null;
    }
  }

  renderEditTopMenu = () => {
    const editeApprovalRoles = ['admin', 'chief', 'ceo', 'director', 'accountChief', 'approving', 'representative', 'chaiman'];
    if (editeApprovalRoles.includes(this.props.currentUser.role)) {
      return (
        <TopButtonContainer>
          <SelectTemplateBox>
            <Title>Approval Template: </Title>
            {this.renderApprovalTemplate()}
            {this.renderCreateUpdateButton()}
          </SelectTemplateBox>
          <BackButton to='/views/approval/list'><LeftArrow />Back</BackButton>
        </TopButtonContainer>
      )
    } else {
      return null;
    }
  }

  body = () => {
    return (
      <ApprovalBaseContainer>
        {this.props.contentContainer}
      </ApprovalBaseContainer>
    )
  }

  render() {
      return (
      <BaseLayout 
          mainComponent={this.body()}
          selectMenuItem={this.props.selectMenuItem}
      />
      );
  }
  
}

const mapStateToProps = state => {
  console.log('list of approvalTemplates after dispatch is');
  // console.log(util.inspect(state.fetchData.approvalTemplates));
  return {
      approvalTemplates: state.fetchData.approvalTemplates,
      approvalTemplate: state.fetchData.approvalTemplate,
      currentUser: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onFetchApprovalTemplates: () => dispatch(fetchApprovalTemplates()),
      onSelectApprovalTemplate: (templateId) => dispatch(fetchApprovalTemplate(templateId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalBase);
