import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import CSVReader from 'react-csv-reader';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import FarmDataBase from './FarmDataBase';
// import { authenticationService, fetchData } from '../../services';
import {
  emailLogoutAction,
  createFarmDataAction,
} from '../../store/actions';

import {
  SpanLabel,
} from '../../commons';
import {
  InfoContainer, FormHeader, Title, BackButton, LeftArrow, InnerContainer, Tr, Td, TableContainer, Table, THead, Th, TBody, Button,
  FormContainer, FormBox, Label, TextInfo, GreenButton
} from './style';
import {
  formControl
} from '../../langueges';

import { history } from '../../App';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
const {
  nameValidate,
  farmDataCodeValidate,
  farmDataNameValidate,
  farmDataUnitValidate,
  farmDataQuantityValidate,
  farmDataPriceValidate,
  floatValidate,
} = require('../../services/validate');

class FarmDataUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        height: window.innerHeight - 112,
        user: this.props.currentUser,
        farmDatas: null,
        editPermission: true,
        validation: false,
        grainTypes: ['Categorical', 'Wheat', 'Barley', 'Corn'],
        harvestGrades: ['APH', 'AH', 'APW', 'ASW', 'ADR', 'AMW', 'ASFT', 'APWN', 'FEED']
    };


  }

  updateDimensions = () => {
    this.setState({ 
      height: window.innerHeight - 112
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    if (this.props.currentUser) {
      this.setState( prevState => ({ 
          ...prevState,
          user: this.props.currentUser,
        }))
      if (this.props.farmData) {
        console.log('this is an existing farmData for update');
        let {farmData} = this.state;
        farmData = this.props.farmData;
        this.setState({existingCheck: true, farmData});
      }
    } else {
        return emailLogoutAction();
    }
    
  }

  onUploadedFileHandler = (data, fileInfo) => {
    this.setState({invalidMsg: ""});
    console.log('data part: ', util.inspect(data));
    console.log('file info: ', util.inspect(fileInfo));
    let newData = data;
    data.forEach((_farmData, _index) => {
      newData[_index].status = "Success";
    })
    this.setState({farmDatas: data});
  }

  handleDarkSideForce = () => {
      console.log('not implemented DarkSideForce yet');
  }

  uploadFarmDatasHandler = () => {
    if (this.state.invalidMsg != '') {
      // this.setState({invalidMsg: selectLanguage(this.props.language, farmDataLanguage.uploadFileErrorMsg)});
      return
    }
    let farmDatas = this.state.farmDatas;
    if (!farmDatas) {
      this.setState({invalidMsg: "Upload farm csv data file error"});
      return
    }
        // grainType: 'Wheat',
    // harvestGrade: 'APH',
    // storageLocation: '',
    // quantity: '',
    // protein: '',
    // moisture: '',
    // uploadUser: '',
    // farmDataionDate: new Date(),
    // modifiedDate: new Date(),
    let checkFarmDataFormat = true;
    farmDatas.forEach((_farmData, _index) => {
      if (!_farmData.item) {
        checkFarmDataFormat = false;
      }
      if (!_farmData.grainType) {
        checkFarmDataFormat = false;
      } else {
        if (!this.state.grainTypes.includes(_farmData.grainType)) {
          checkFarmDataFormat = false;
        }
      }
      if (!_farmData.harvestGrade) {
        checkFarmDataFormat = false;
      } else {
        if (!this.state.harvestGrades.includes(_farmData.harvestGrade)) {
          checkFarmDataFormat = false;
        }
      }
      if (!_farmData.storageLocation) {
        checkFarmDataFormat = false;
      } 
      if (!_farmData.protein) {
        checkFarmDataFormat = false;
      }
      if (!_farmData.moisture) {
        checkFarmDataFormat = false;
      }
      if (!_farmData.productionDate) {
        checkFarmDataFormat = false;
      }
    })
    if (!checkFarmDataFormat) {
      this.setState({invalidMsg: "CSV format data error"})
      return
    }

    if (farmDatas) {
      if (farmDatas.length > 0) {
        console.log('validate farmData before upload ..');
        let validateResult = true;
        for (var i = 0; i < farmDatas.length; i++) {
          if (this.onSubmitValidation(farmDatas[i]) == false) {
            validateResult = false;
          }
        }
        if (validateResult === false) {
          console.log('validation farmDatas failed');
          return;
        }
        console.log('farmDatas before sedning updating: ', farmDatas);
        // return;
        let user = this.props.currentUser;

        farmDatas.forEach((_farmData, _index) => {
          
          let _uploadFarmData = _farmData;
          _uploadFarmData.stage = "Harvest";
          _uploadFarmData.modifiedDate = new Date();
          _uploadFarmData.productionDate = new Date(_uploadFarmData.productionDate);
          _uploadFarmData.uploadUser = user._id;
          console.log('submiting farm data for: ', _uploadFarmData);
          this.props.onCreateFarmData(_uploadFarmData)
        })
        // this.props.onUploadFarmDatas(farmDatas);
      }
    }
  }

  onSubmitValidation = (farmData) => {
    let result = true;

    if (farmDataQuantityValidate(this.props.language, farmData.quantity) != "") {
      this.setState({invalidMsg: "Quantity must be an integer"});
      result = false;
    }
    if (floatValidate(this.props.language, farmData.moisture) != "") {
      this.setState({invalidMsg: "Moistrue must be a float"});
      result = false;
    }
    if (farmDataUnitValidate(this.props.language, farmData.protein) != "") {
      this.setState({invalidMsg: "Protein must be a float"});
      result = false;
    }
    return result;
  }

  uploadCsvFileError = () => {
    this.setState({invalidMsg: "Upload csv file process error"})
  }


  renderCreateUpdateButton() {
    const editPermission = this.state.editPermission;
    if (editPermission == true) {
      return (
        <GreenButton onClick={() => this.uploadFarmDatasHandler()}>
            {"Upload Farm Data"}
        </GreenButton>
      )
    } else {
      return null;
    }
  }


  // grainType: 'Wheat',
  //       harvestGrade: 'APH',
  //       storageLocation: '',
  //       quantity: '',
  //       protein: '',
  //       moisture: '',
  //       uploadUser: '',
  //       productionDate: new Date(),
  //       modifiedDate: new Date(),
  renderTableData() {
    return (this.state.farmDatas ? this.state.farmDatas : []).map((farmData, index) => {
      const { item, grainType, harvestGrade, storageLocation,quantity, protein, moisture, productionDate } = farmData //destructuring
      return (
        <Tr 
          key={item}
          // onClick={() => this.props.onViewFarmDataDetail(farmData)}
        >
            <Td>{item}</Td>
            <Td>{grainType}</Td>
            <Td>{harvestGrade}</Td>
            <Td>{storageLocation}</Td>
            <Td>{quantity}</Td>
            <Td>{protein}</Td>
            <Td>{moisture}</Td>
            <Td>{productionDate ? formatDate(productionDate)  : ''}</Td>
            {/* <Td paddingLeft="0px" align="right">
                <YellowButton onClick={() => this.updateFarmData(farmData)} data-id={_id}>
                    {selectLanguage(this.props.language, farmDataLanguage.updateFarmData)}
                </YellowButton>
            </Td> */}
        </Tr>
      )
    })
  }

  body() {
    var papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header =>
        header
          // .toLowerCase()
          .replace(/\W/g, '_')
    }
    return (
        <InfoContainer>
          <FormHeader>
              <Title>{"Upload File"}</Title>
              <BackButton to='/views/farmdata/harvesting/list'><LeftArrow />{"Back"}</BackButton>
          </FormHeader>
          <InnerContainer>
            <FormContainer>
              {
                (this.state.invalidMsg != '') ? 
                <SpanLabel 
                  text={this.state.invalidMsg}
                  style={{color: 'red'}}
                /> : null
              }
              <FormBox>
                <Label>{"Upload CSV Format File"}</Label>
                <CSVReader
                    cssClass="csv-reader-input"
                    label={"uploading file"}
                    onFileLoaded={(data, fileInfo) => this.onUploadedFileHandler(data, fileInfo)}
                    onError={() => this.uploadCsvFileError()}
                    parserOptions={papaparseOptions}
                    inputId="ObiWan"
                    inputStyle={{color: 'red'}}
                />
              </FormBox>
              <FormBox>
                <Label>{"CSV column name format as below:"}</Label>
                <TextInfo>{'item, grainType, harvestGrade, storageLocation, quantity, protein, moisture, productionDate'}</TextInfo>
              </FormBox>
              {this.renderCreateUpdateButton()}
            </FormContainer>
          </InnerContainer>
          <TableContainer>
              <Table>
                <THead>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Grain Type</Th>
                    <Th>Havest Grade</Th>
                    <Th>Storage Location</Th>
                    <Th>Quantity</Th>
                    <Th>Protein</Th>
                    <Th>Moisture</Th>
                    <Th>FarmDataion Date</Th>
                  </Tr>
                </THead>
                
                <TBody>
                  {this.renderTableData()}
                </TBody>
              </Table>
            </TableContainer>
        </InfoContainer>
    );
  }

  render() {
    return (
      <FarmDataBase 
        contentContainer = {this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('check if farmDataes receive from reducer:');

  return {
    currentUser: state.authState.currentUser,
    language: state.topMenu.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // onUploadFarmDatas: (farmDatas) => dispatch(uploadFarmDatas(farmDatas)),
    onCreateFarmData: (farmData) => dispatch(createFarmDataAction(farmData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmDataUpload);


/*********************End Stylesheet**********************/


function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}