import React from 'react'
import styled from 'styled-components';
import axios from 'axios';
import { connect } from 'react-redux';
// import ReactSearchBox from 'react-search-box';
// import searchIcon from '../../assets/images/icons/search22x22.png'
import ApprovalBase from '../ApprovalBase';
import DelegateModal from './DelegateModal';
import RejectModal from './RejectModal';
import SignModel from './SignModal';
import util from 'util';
import {compareTwoDate} from '../../../services/utilities';
import BeLogo from '../../../assets/images/Be_Sign_90x43.png';
import DemoCertificatePdf from '../../../files/DemoCertificate.pdf';

import {
  Table, THead, Tr, Th, Td, TBody, ViewButton, ContentContainer, DocumentInfoContainer, DocumentList, TableContainer,
  Title,TitleContainer, TextContainer, DocumentTitle, DocumentDoneTitle, DocumentInfo, ItemContainer, Text,
  Label, SigingButtonBox, RowContainer, ColContainer, TextArea, DownArrow, RightArrow,
  BodyContainer, HeadContainer, RejectButton, ApproveButton, DelegateButton, BackButton, 
  ActiveContainer, ActiveContentContainer, HeadTitle, ActivityStatus, WorkflowArrowDown,
  InactiveContainer, BackIcon, TopButtonContainer, EditButton, EditIcon, FlexiText, SummaryContainer, ApprovalContainer,
  ApprovalIconBlue, ApprovalIconGray, DownloadPdfButton, DownloadButton
} from './style';

import {
  StandardButton,
} from '../style';

import async from 'async';
// import fetchData actions
import {
    fetchSigner,
    fetchSigners,
    fetchCompany,
    fetchGetApprovalCreate,
    // fetchApprovalTemplates,
    getCreateApproval,
    fetchDocuments,
    fetchApproval,
    onClickSignApproval,
    onClickRejectApproval,
    updateApprovalAction,
    onClickUpdateApproval,
    deleteUpprovalAction,
    onClickDelegateApproval,
    selectDocumentAction,
    onViewUserDetail,
    downloadApproval,
    generateApprovalCertificate,
  } from '../../../store/actions';
import { sign } from 'crypto';
import { parse } from 'querystring';
// import {utilities} from '../commons';
const prodKeys = require('../../../config/prodKeys');
const url = prodKeys.apiUrl;
const _today = new Date();

class ApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalLevel: this.props.approval ? this.props.approval.stage : null,
      signingPermission: false,
      updatePermission: false,
      delegatePermission: false,
      comments: [],
      approvalLevelsOpen: [],
      showDelegateModal: false,
      showRejectModal: false,
      showSignModal: false,
    };
    // this.props.onGetCreateApproval();
  }

  componentDidMount() {
    // get currentUser
    let currentUser = this.props.currentUser;
    if (!currentUser) {
      return this.props.history.push('/views/login');
    }
    // fetch signers/users
    this.props.onFetchSigners();
    // fetch approval
    let approval = this.props.approval;
    if (approval) {
      // this.props.onFetchApprovalDetail(_approval._id);
      let {comments} = this.state;
      let approvalLevel = approval.stage;
      let approvalLevels = approval.approvalLevels;
      if (Array.isArray(approvalLevels)) {
        let approvalSingers = [];
        let {approvalLevelsOpen} = this.state;
        approvalLevels.forEach((_approvalLevel, _index) => {
          // adding approvalLevelsOpen state to control opeing state of approvalLevel container
          let _levelOpen = {level: _approvalLevel.level, open: false};
          
          // aggregate comments
          if (_approvalLevel.comments) {
            _approvalLevel.comments.forEach((_comment, _index) => {
              comments.push(_comment);
            })
          }
          // aggregate signers if signer not signed
          let _signers = _approvalLevel.signers;
          let _alreadySigners = _approvalLevel.signed;
          _signers.forEach((_signer, __index) => {
            if (_signer._id) {
              // if this signer did not signed yet, then add to signing list
              if (!_alreadySigners.includes(_signer._id)) {
                approvalSingers.push(_signer._id);
              }
              
            } else {
              // if this signer did not signed yet, then add to signing list
              if (!_alreadySigners.includes(_signer)) {
                approvalSingers.push(_signer._id);
              }
            }
          })
          
          if (_approvalLevel._id.toString() == approvalLevel) {
            _levelOpen.level = true;
            if (this.onCheckSigningPermission(_approvalLevel, approval.status)) {
              let {signingPermission} = this.state;
              signingPermission = true;
              this.setState({signingPermission});
            }
          }
          // set permission for delegate, 
          if (approvalSingers.includes(this.props.currentUser._id)) {
            let {delegatePermission} = this.state;
            if (_approvalLevel.status == 'done') {
              delegatePermission = false
            } else {
              delegatePermission = true;
            }
            this.setState({delegatePermission});
          }
          // set approvallevel open
          approvalLevelsOpen.push(_levelOpen);
        })
        
        
        // update state of approvalLevel Open container
        this.setState({approvalLevelsOpen});
      }
      if (comments.length > 0) {
        // update comment 
        console.log('total comment aggregate at component did mount: ', util.inspect(comments));
        this.setState({comments});
      }

      
  
      // set permission for update/delete approval
      const userRole = currentUser.role;
      const submitter = approval.submitter;
      const approvalStatus = approval.status;
      const editeApprovalRoles = ['admin'];
      // if current user is submitter of this approval, or this user is an admin
      if (editeApprovalRoles.includes(userRole) || submitter._id.toString() == currentUser._id.toString) {
        if (approvalStatus == 'created' || approvalStatus == 'rejected') {
          let {updatePermission} = this.state;
          updatePermission = true;
          this.setState({updatePermission});
        }
      }
      // let signers;
      // let approvalLevels = this.
      this.setState({approvalLevel});
      // set state of popup modal
      this.setShowDelegate(this.state.showDelegateModal);
      this.setShowReject(this.state.showRejectModal);
    } else {
      this.props.history.push('/views/approval/list');
    }
   
  }

  showArrow(level) {
    var show = true;
    if(show) {
      return(
        <DownArrow />
      );
    } else {
      return(
        <RightArrow />
      );
    }
  }

  addRequiredDocIdHandler = (e) => {
    
  }

  preSetRequiredDocs = (numberOfRows) => {
    
  }

  setShowDelegate(show) {
    if (this.props.popupModal) {
      this.setState({showDelegateModal: show});
    } else {
      this.setState({showDelegateModal: false});
    }
  }

  setShowReject(show) {
    if (this.props.popupModal) {
      this.setState({showRejectModal: show});
    } else {
      this.setState({showRejectModal: false});
    }
  }

  setShowSign(show) {
    if (this.props.popupModal) {
      this.setState({showSignModal: show});
    } else {
      this.setState({showSignModal: false});
    }
  }

  onClickUpdateApproval = () => {
    // get approval info, pass to the form
    let approval = this.props.approval;
    if (approval) {
      this.props.onUpdateApproval(approval);
    }
  }

  submitIssueApproval = () => {
    console.log('observe approval before issue ..');
    console.log(util.inspect(this.state.approval));
    let approvalId = this.state.approval._id;
    if ((approvalId !== undefined) && approvalId !== '') {
      console.log('issuing this approval ..');
      axios.post(`${url}company/approval/${approvalId}/issue`, {approvalId: approvalId}).then(res =>{
        console.log('res data: ');
        console.log(util.inspect(res.data));
      })
    } else {
      console.log('approval id not valid', approvalId);
    }
  }


  formatDate = (_date) => {
    var d = new Date(_date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
    // const _today = new Date(_date);
    // let formatDate = _today.getFullYear() + '-'
    // + (_today.getMonth() + 1).toString() + '-'
    // + _today.getDate();

    // // if (this.state.approval) {
    // //   if (this.state.approval.issueDate) {
    // //     const issueDate = new Date(this.state.approval.issueDate.toString());
    // //     formatDate = issueDate.getFullYear() + '-'
    // //                 + (issueDate.getMonth() + 1).toString() + '-'
    // //                 + issueDate.getDate();
    // //   }
    // // }
    // return formatDate;
  }

  onSignApproval = () => {
    let approval = this.props.approval;
    let approvalLevel;
    if (approval) {
      // check if this user is the one need to sign document on this approvalLevel
      const currentApprovalLevel = approval.stage;
      const approvalLevels = approval.approvalLevels;
      approvalLevels.forEach((_approvalLevel, _index) => {
        if (_approvalLevel._id.toString() == currentApprovalLevel.toString()) {

          approvalLevel = _approvalLevel;
          let signers = _approvalLevel.signers;
          let signinPermission = this.onCheckSigningPermission(_approvalLevel, approval.status);
          if (signinPermission) {
            console.log('this user have permission to sign at this stage');
            // show SignModal
            this.setState({showSignModal: true});
            // if this signer is in 
            this.props.onSignApproval(approval, approvalLevel);
          } else {
            console.log('this user does not have permission to sign the doc in this stage');
          }
        }
      })
    }
  }

  onRejectApproval = () => {
    let approval = this.props.approval;
    let approvalLevel;
    if (approval) {
      // check if this user is the one need to sign document on this approvalLevel
      const currentApprovalLevel = approval.stage;
      const approvalLevels = approval.approvalLevels;
      approvalLevels.forEach((_approvalLevel, _index) => {
        if (_approvalLevel._id.toString() == currentApprovalLevel.toString()) {
          approvalLevel = _approvalLevel;
          let signinPermission = this.onCheckSigningPermission(_approvalLevel, approval.status)
          if (signinPermission) {
            console.log('this user have permission to sign at this stage');
            // set state of showDelegate box
            this.setState({showRejectModal: true});
            // if this signer is in 
            this.props.onRejectApproval(approval, approvalLevel);
          } else {
            console.log('this user does not have permission to sign the doc in this stage');
          }
          
        }
      })
    }
  }

  onDelegateApproval = () => {
    console.log('user delegate this signing to someone else');
    // need to open the approval form
    // let him add more signature for delegator
    // update approvalLevel of his
    let approval = this.props.approval;
    let approvalLevel;
    if (approval) {
      // check if this user is the one need to sign document on this approvalLevel
      const currentApprovalLevel = approval.stage;
      const approvalLevels = approval.approvalLevels;
      approvalLevels.forEach((_approvalLevel, _index) => {
        if (_approvalLevel._id.toString() == currentApprovalLevel.toString()) {
          approvalLevel = _approvalLevel;
          let signinPermission = this.onCheckSigningPermission(_approvalLevel, approval.status)
          if (signinPermission) {
            console.log('this user have permission to sign or delegate at this stage');
            // set state of showDelegate box
            this.setState({showDelegateModal: true});
            // if adding signer into the approval
            this.props.onDelegateApproval(approval, approvalLevel);
          } else {
            console.log('this user does not have permission to sign the doc in this stage');
          }
          
        }
      })
    }
  }

  onDeleteApproval = () => {
    let approval = this.props.approval;
    if (approval) {
      if (approval._id) {
        this.props.onDeleteApprovalAction(approval._id);
      }
    }
  }

  onCheckSigningPermission = (_approvalLevel, approvalStatus) => {
    let signinPermission = false;
    if (approvalStatus == 'done') {
      return signinPermission;
    }
    if (this.props.currentUser) {
      let signers = _approvalLevel.signers;
      
      let signed = _approvalLevel.signed;
      signers.forEach((_signer, _index) => {
        if (_signer._id) {
          if (_signer._id.toString() == this.props.currentUser._id.toString()) {
            console.log('this user have permission to sign at this stage');
            // need to check whether or not he/shre already sign
              if (signed.includes(this.props.currentUser._id)) {
                console.log('this user alreay signed the approval');
              } else {
                signinPermission = true;
              }
          } else {
            console.log('this user does not have permission to sign the doc in this stage');
          }
        } else if (_signer.toString() == this.props.currentUser._id.toString()) {
          console.log('this user have permission to sign at this stage');
          // need to check whether or not he/shre already sign
            if (signed.includes(this.props.currentUser._id)) {
              console.log('this user alreay signed the approval');
            } else {
              signinPermission = true;
            }
        } else {
          console.log('this user does not have permission to sign the doc in this stage');
        }
        
      })
    }
    
    return signinPermission;
  }

  setApprovalLevelOpen = (level) => {
    let { approvalLevelsOpen } = this.state;
    approvalLevelsOpen.forEach((_row, _index) => {
      if (_row.level == level) {
        approvalLevelsOpen[_index].open = !(approvalLevelsOpen[_index].open);
        this.setState({approvalLevelsOpen});
      }
    })
  }

  renderMainDocumentTableData() {
    let approval = this.props.approval;
    if (approval) {
      let documents = []
      documents.push(approval.approvalDoc);
      if (!documents) {
        return (
          <div>Loading ..</div>
        )
      }
      if (documents.length > 0) {
        return documents.map((document, index) => {
          const { _id, docName, uploadUser, uploadDate, description } = document //destructuring
          let item = parseInt(index) + 1;
          return (
              <Tr key={item}>
                <Td>{item ? item.toString() : ''}</Td>
                <Td>{docName ? docName : ''}</Td>
                <Td>{description ? description : 'Default description of this document'}</Td>
                <Td mobileDisplay="none">{uploadUser ? (uploadUser.firstname + ' ' + uploadUser.lastname) : ''}</Td>
                <Td mobileDisplay="none">{uploadDate ? formatDate(uploadDate) : ''}</Td>
                <Td paddingLeft="0px" align="right">
                  <ViewButton
                    onClick = {() => {
                      this.onViewDocumentDetail(document);
                    }}
                >Xem</ViewButton></Td>
              
              </Tr>
          )
        })
      } else {
        return (
          <div>Loading ..</div>
        )
      }
    }
  }

  renderRelevantDocumentTableData() {
    let approval = this.props.approval;
    if (approval) {
      let documents = approval.supportDocs;
      if (!documents) {
        return (
          <div>Loading ..</div>
        )
      }
      if (documents.length > 0) {
        return documents.map((document, index) => {
          const { _id, docName, uploadUser, uploadDate, description } = document //destructuring
          let item = parseInt(index) + 1;
          return (
              <Tr key={item}>
                <Td>{item ? item.toString() : ''}</Td>
                <Td>{docName ? docName : ''}</Td>
                <Td>{description ? description : 'Default desciption of this support document'}</Td>
                <Td mobileDisplay="none">{uploadUser ? (uploadUser.firstname + ' ' + uploadUser.lastname) : ''}</Td>
                <Td mobileDisplay="none">{uploadDate ? formatDate(uploadDate) : ''}</Td>
                <Td paddingLeft="0px" align="right">
                  <ViewButton
                    onClick = {() => {
                      this.onViewDocumentDetail(document)
                    }}
                >View</ViewButton></Td>
              </Tr>
          )
        })
      } else {
        return (
          <div>Loading ..</div>
        )
      }
    }
    
  }

  onViewDocumentDetail = (_document) => {
    this.props.onViewDocumentAction(_document);
  }

  renderSigningButtonBox = (approvalLevel, currentStage) => {
    // check if his sinature required in this level
    return (
      <SigingButtonBox>
          {this.renderSigningButton(approvalLevel, currentStage)}
          {this.renderRejectButton(approvalLevel, currentStage)}
          {this.renderDelegateButton(approvalLevel, currentStage)}
      </SigingButtonBox>
    )
  }

  renderRejectButton = (approvalLevel, currentStage) => {
    if (this.state.signingPermission) {
      // check if this is the current approvlLevel
      if (approvalLevel._id.toString() == currentStage.toString()) {
        // check if his sinature required in this level
        return (
              <RejectButton
                onClick={() => this.onRejectApproval()}
              >Reject</RejectButton>
        )
      }
    }
  }

  renderSigningButton = (approvalLevel, currentStage) => {
    if (this.state.signingPermission) {
      // check if this is the current approvlLevel
      if (approvalLevel._id.toString() == currentStage.toString()) {
        // check if his sinature required in this level
        return (
              <ApproveButton
                onClick={() => this.onSignApproval()}
              >Approve</ApproveButton>
        )
      }
    }
  }

  renderDelegateButton = (approvalLevel, currentStage) => {
    if (this.state.delegatePermission) {
      // check if this is the current approvlLevel have signes include this user
      let levelSigners = approvalLevel.signers;
      let levelSignerIds = [];
      levelSigners.forEach((_signer, _index) => {
        if (_signer._id) {
          levelSignerIds.push(_signer._id);
        } else {
          levelSignerIds.push(_signer);
        }
      })

      if (levelSignerIds.includes(this.props.currentUser._id)) {
        // check if his sinature required in this level
        return (
              <DelegateButton
                onClick={() => this.onDelegateApproval()}
              >Delegate</DelegateButton>
        )
      }
    }
  }

  renderSignerInfoTableRow = (level, _signers, _signed, deadline) => {
    // get list of all signers
    console.log('list of signer of this level ', level.toString(), ' are: ', _signers);

    if (_signers.length > 0) {
      return _signers.map((_signerInfo, index) => {
        const { _id, firstname, lastname, role} = _signerInfo //destructuring
        let fullName = firstname + ' ' + lastname;
        let status = "Pending";
        _signed.forEach((_signerId, _index) => {
          if (_signerId.toString() == _signerInfo._id.toString()) {
            status = "Signed";
          }
        });
        let item = index ? parseInt(index) + 1 : 1;
        return (
            <Tr key={index}>
              <Td>{item ? item.toString() : ''}</Td>
              <Td>{fullName ? fullName : ''}</Td>
              <Td>{role ? role : ''}</Td>
              <Td mobileDisplay="none">{deadline ? this.formatDate(deadline) : ''}</Td>
              <Td>{status ? status : ''}</Td>
              <Td mobileDisplay="none" paddingLeft="0px" align="right">
                <ViewButton
                  onClick = {() => {
                    this.onViewSignerDetail(_signerInfo)
                  }}
              >Profile</ViewButton></Td>
            </Tr>
        )
      })
    }  
  }

  onViewSignerDetail = (_signer) => {
    this.props.onViewSignerDetail(_signer);
  }

  renderSignerInfoTable = (level, signers, signed, deadline) => {
    return (
      <TableContainer>
        <Table>
          <THead>
            <Tr>
              <Th>Item</Th>
              <Th>Approver</Th>
              <Th>Position</Th>
              <Th mobileDisplay="none">Due Date</Th>
              <Th>Status</Th>
              <Th mobileDisplay="none">Profile</Th>
            </Tr>
          </THead>
            
          <TBody>
            {level ? this.renderSignerInfoTableRow(level, signers, signed, deadline) : ''}
          </TBody>

        </Table>
      </TableContainer>
    )
  }

  renderWorkflowArrowDwon = (level) => {
    return (
      <WorkflowArrowDown />
    )
  }
  renderActiveApprovalLevel = (_approvalLevel, approvalStage) => {
    let {level, signers, signed, deadline, status} = _approvalLevel;
    let _approvalLevelStatus = status;
    if (deadline) {
      if (compareTwoDate(deadline, _today)) {
        _approvalLevelStatus = 'overdue';
      }
    }
    
    return (
      <ApprovalContainer>
        {/* <WorkflowArrowDown /> */}
        {this.renderWorkflowArrowDwon(_approvalLevel.level)}
        <ActiveContainer>
          <ActiveContentContainer>
            <HeadContainer 
                onClick={() => this.setApprovalLevelOpen(level)}
                aria-controls="collapse-text"
                aria-expanded={this.state.open2}
              >
              <ApprovalIconBlue />
              <HeadTitle>{_approvalLevel.group}</HeadTitle>
              <ActivityStatus backgroundColor="#F3A83C">{_approvalLevel.status}</ActivityStatus>

              
            </HeadContainer>
            <BodyContainer in={true}>
              <div id="collapse-text">
                <RowContainer>
                
                  <ColContainer>
                    <ItemContainer>
                      <Label>Approval Status:</Label><Text>
                        {_approvalLevelStatus}</Text>
                    </ItemContainer>
                    <ItemContainer>
                      <Label>Required Signatures:</Label><Text>
                        {_approvalLevel.signatures}</Text>
                    </ItemContainer>
                    <ItemContainer>
                      <Label>Signed Signatures:</Label><Text>
                        {_approvalLevel.signed.length}</Text>
                    </ItemContainer>
                  </ColContainer>
                </RowContainer>
                <RowContainer>
                  <ColContainer>
                    <Label>Approver(s)</Label>
                  </ColContainer>
                </RowContainer>
                {this.renderSignerInfoTable(level, signers, signed, deadline)}
                <RowContainer>
                  <ColContainer>
                    <Label>Comment: * </Label>
                    <TextArea 
                      value={_approvalLevel.comments ? _approvalLevel.comments : ''} 
                    />
                  </ColContainer>
                </RowContainer>
              </div>
            </BodyContainer>
            <RowContainer>
              <ColContainer>
                <Text mobileDisplay="none">Fields with (*) are required.</Text>
              </ColContainer>
              <ColContainer>
                {this.renderSigningButtonBox(_approvalLevel, approvalStage)}
              </ColContainer>
            </RowContainer>
          </ActiveContentContainer>
          
        </ActiveContainer>   
      </ApprovalContainer>
    )
  }

  renderInactiveApprovalLevel = (_approvalLevel) => {
    let {level, signers, signed, deadline, status} = _approvalLevel;
    let { approvalLevelsOpen } = this.state;
    let _openStatus = false;
    approvalLevelsOpen.forEach((_row, _index) => {
      if (_row.level == _approvalLevel.level) {
        _openStatus = _row.open;
      }
    });
    let _approvalLevelStatus = status;
    if (deadline) {
      if (compareTwoDate(deadline, _today)) {
        _approvalLevelStatus = 'overdue';
      }
    }
    return (
      <ApprovalContainer>
        {this.renderWorkflowArrowDwon(_approvalLevel.level)}
        <InactiveContainer>
          <HeadContainer 
              onClick={() => this.setApprovalLevelOpen(_approvalLevel.level)}
              aria-controls="collapse-text"
              aria-expanded={this.state.open0}
            >
            <ApprovalIconGray />
            <HeadTitle>{_approvalLevel.group}{this.showArrow(0)}</HeadTitle>
            <ActivityStatus backgroundColor="#818181">{_approvalLevel.status}</ActivityStatus>
          </HeadContainer>
          <BodyContainer in={_openStatus}>
            <div id="collapse-text">
              <RowContainer>
                <ColContainer>
                  <ItemContainer>
                    <Label>Approval Status:</Label><Text>
                      {_approvalLevelStatus}</Text>
                  </ItemContainer>
                  <ItemContainer>
                    <Label>Required Signatures:</Label><Text>
                      {_approvalLevel.signatures}</Text>
                  </ItemContainer>
                  <ItemContainer>
                    <Label>Signed Signatures:</Label><Text>
                      {_approvalLevel.signed.length}</Text>
                  </ItemContainer>
                </ColContainer>
              </RowContainer>
              {this.renderSignerInfoTable(level, signers, signed, deadline)}  
              <RowContainer>
                <ColContainer>
                  <Label>Comment: </Label>
                  <TextArea 
                    disabled={true}
                    value={_approvalLevel.comments ? _approvalLevel.comments : ''}
                  />
                </ColContainer>
              </RowContainer>
            </div>
          </BodyContainer>
        </InactiveContainer>
      </ApprovalContainer>
    )
  }

  renderApprovalLevelTable() {
    let approval = this.props.approval;
    if (approval) {
      console.log('approvalLevels at rendering Table: ');
      console.log(util.inspect(approval));
      console.log(util.inspect(approval.approvalLevels));
      let approvalLevels = approval.approvalLevels ? approval.approvalLevels : [];
      let approvalStage = approval.stage ? approval.stage : (approvalLevels[0]._id ? approvalLevels[0]._id : approvalLevels[0]);
      return approvalLevels.map((_approvalLevel, index) => {
        let { level, group, signed, signatures, signers, deadline, status } = _approvalLevel //destructuring
        console.log('check signers are _id or not: ', util.inspect(signers));
        if (approvalStage.toString() == _approvalLevel._id.toString()) {
          return this.renderActiveApprovalLevel(_approvalLevel, approvalStage);
        } else {
          return this.renderInactiveApprovalLevel(_approvalLevel);
        }
      })
    }
  }

  onDownloadApproval = (document) => {
    this.props.onDownloadApproval(document);
  }

  renderDownloadApprovaButton = (approval) => {
    return (
      <StandardButton width="100px"
        onClick = {() => {
          this.onDownloadApproval(approval)
        }}
      >{"Download"}</StandardButton>
    )
        

  }

  renderDownloadApprovaButtonNew = () => {
    return (

      

      <a href={DemoCertificatePdf} 
      >
        {"Download New"}
      </a>
    )
  }

  renderGetGenerateCert = (_approval) => {
    if (_approval.status == "done") {
      return (
        <DownloadButton onClick = {() => {
          this.geenerateCertificate(_approval);
        }}>{"Get Cert"}</DownloadButton>
      )
    }
  }

  geenerateCertificate = (_approval) => {
    console.log('user create on dynamic generte certificate ..');
    this.props.onGenerateCertificate(_approval);
  }

  renderApprovalStatus = () => {
    let approval = this.props.approval;
    if (approval) {
      let approvalLevels = approval.approvalLevels;
      let stage = approval.stage ? approval.stage : (approvalLevels[0]._id ? approvalLevels[0]._id : approvalLevels[0]);
      console.log('current stage of approval: ', stage);
      let approvalState;
      approvalLevels.forEach((_approvalLevel, _index) => {
        console.log('approvalLevel in this loop ', parseInt(_index).toString(), ' is: ', _approvalLevel);
        if (_approvalLevel._id) {
          if (_approvalLevel._id.toString() == stage.toString()) {
            approvalState = _approvalLevel;
          }
        } else if (_approvalLevel.toString() == stage.toString()) {
          approvalState = _approvalLevel;
        }
      })
      // get summiter
      let _summiter = 'default submitter';
      if (approval.submitter) {
        if (approval.submitter._id) {
          _summiter = approval.submitter.firstname + ' ' + approval.submitter.lastname;
        }
      }
      // status of approval
      let _approvalStatus = approval.status;
      if (_approvalStatus != 'done') {
        if (compareTwoDate(approval.deadline, _today)) {
          _approvalStatus = 'overdue';
        }
      }
      
      return (
        
        <TitleContainer>
          <TextContainer>
            {(_approvalStatus == 'done') ? (<DocumentDoneTitle>[{approval.approvalNumber}] {approval.approvalName}</DocumentDoneTitle>) : (<DocumentTitle>[{approval.approvalNumber}] {approval.approvalName}</DocumentTitle>)}
            {/* <DocumentTitle>[{approval.approvalNumber}] {approval.approvalName}</DocumentTitle> */}
            <DocumentInfo>
              <ItemContainer>
                <Label>Submitter:</Label><Text>
                  {_summiter}</Text>
              </ItemContainer>
              <ItemContainer>
                <Label>Submitted Date:</Label><Text>{formatDate(approval.startDate)}</Text>
              </ItemContainer>
              <ItemContainer>
                <Label>Priority:</Label><Text>{approval.priority}</Text>  
              </ItemContainer>
              <ItemContainer>
                <Label>Status:</Label><Text>{_approvalStatus}</Text>  
              </ItemContainer>
            </DocumentInfo>
          </TextContainer>
          <TopButtonContainer>
            {/* {this.renderEditApprovalButton()} */}
            {/* {this.renderDownloadApprovaButton(approval)} */}
            {/* {this.renderDownloadApprovaButtonNew()} */}
            {/* <DownloadButton href={DemoCertificatePdf} >{"View"}</DownloadButton> */}
            {this.renderGetGenerateCert(approval)}
            <BackButton to='/views/approval/list'>
              <BackIcon /><FlexiText>Back</FlexiText>
            </BackButton>
          </TopButtonContainer>
        </TitleContainer>
         
        )
    }
  }

  renderEditApprovalButton = () => {
    if (this.state.updatePermission == true) {
      let approval = this.props.approval;
      return (
        <EditButton onClick={() => this.onClickUpdateApproval(approval)}>
          <EditIcon /><FlexiText>Edit</FlexiText>
        </EditButton>
      )
    }
  }

  renderSummary = () => {
    let approval = this.props.approval;
    if (approval) {
      let _summary = approval.summary ? approval.summary : 'default notes from submitter';
      return (
        <SummaryContainer>
          <Label>Summary</Label>
          <TextArea value={_summary} />
        </SummaryContainer>
      )
    }
  }

  renderSignModel = () => {
    if (this.state.showSignModal) {
      return (
        <SignModel 
            showRejectModal={this.state.showSignModal} 
            hideSignModal={() => this.setShowSign(false)} 
        />
      )
    }
  }

  body = () => {
    return (
      
      <div>
        <ContentContainer>
          <DocumentInfoContainer>
            {this.renderApprovalStatus()}
            <DocumentList>
              <Label width="135px">Primary Document</Label>
              <Table>
                <THead>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th mobileDisplay="none">Uploaded By</Th>
                    <Th mobileDisplay="none">Uploaded Date</Th>
                    <Th>View</Th>
                  </Tr>
                </THead>
                  
                <TBody>
                  {this.renderMainDocumentTableData()}
                </TBody>

              </Table>
            </DocumentList>
            
            <DocumentList>
              <Label width="135px">Related Document</Label>
              <Table>
                <THead>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th mobileDisplay="none">Uploaded By</Th>
                    <Th mobileDisplay="none">Uploaded Date</Th>
                    <Th>View</Th>
                  </Tr>
                </THead>
                <TBody>
                  {this.renderRelevantDocumentTableData()}
                </TBody>
              </Table>
            </DocumentList>
            {this.renderSummary()}
          </DocumentInfoContainer>
          
          {this.renderApprovalLevelTable()}
          {/* {this.renderSignerInfoTable()} */}
          
        </ContentContainer>
        <div>
         <SignModel 
            showRejectModal={this.state.showSignModal} 
            hideSignModal={() => this.setShowSign(false)} 
          />
          <RejectModal 
            showRejectModal={this.state.showRejectModal} 
            hideRejectModal={() => this.setShowReject(false)} 
          />

          <DelegateModal 
            showDelegateModal={this.state.showDelegateModal} 
            hideDelegateModal={() => this.setShowDelegate(false)} 
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <ApprovalBase
        contentContainer={this.body()}
        onSubmitApproval={() => this.onClickUpdateApproval()}
        // passing approvalTemplates to ApprovalBase
        approvalTemplates={this.props.approvalTemplates ? this.props.approvalTemplates : []}
        onSignApproval={() => this.onSignApproval()}
        onRejectApproval={() => this.onRejectApproval()}
        onDeleteApproval={() => this.onDeleteApproval()}
        updateButtonLabel={'Edit'}
        signingPermission={this.state.signingPermission}
        updatePermission={this.state.updatePermission}
        selectMenuItem="0"
      />
    )
  }
}

const mapStateToProps = state => {
  // console.log('documents received from reducer:');
  // console.log(util.inspect(state.document.documents));
  console.log('approval received from reducer:');
  console.log(util.inspect(state.approvalState.approval));
  return {
      approval: state.approvalState.approval,
      signers: state.fetchData.signers,
      currentUser: state.authState.currentUser,
      approvalLevel: state.approvalState.popupModal,
  }
}
  
  const mapDispatchToProps = dispatch => {
    return {
        // onNewEvent: () => dispatch(lastEventAction()),
        onFetchCompany: (companyId) => dispatch(fetchCompany(companyId)),
        onFetchSigners: () => dispatch(fetchSigners()),
        onFetchSigner: (signerId) => dispatch(fetchSigner(signerId)),
        // onFetchApprovalNumber: () => dispatch(fetchGetApprovalCreate()),
        // onFetchApprovalTemplates: () => dispatch(fetchApprovalTemplates()),
        onGetCreateApproval: () => dispatch(getCreateApproval()),
        onFetchDocuments: () => dispatch(fetchDocuments()),
        onFetchApprovalDetail: (approvalId) => dispatch(fetchApproval(approvalId)),
        onSignApproval: (approval, approvalLevel) => dispatch(onClickSignApproval(approval, approvalLevel)),
        onRejectApproval: (approval, approvalLevel) => dispatch(onClickRejectApproval(approval, approvalLevel)),
        onDelegateApproval: (approval, approvalLevel) => dispatch(onClickDelegateApproval(approval, approvalLevel)),
        onUpdateApproval: (approval) => dispatch(onClickUpdateApproval(approval)),
        onDeleteApprovalAction: (approvalId) => dispatch(deleteUpprovalAction(approvalId)),
        onViewDocumentAction: (_document) => dispatch(selectDocumentAction(_document)),
        onViewSignerDetail: (_signer) => dispatch(onViewUserDetail(_signer)),
        onDownloadApproval: (_approval) => dispatch(downloadApproval(_approval)),
        onGenerateCertificate: (_approval) => dispatch(generateApprovalCertificate(_approval))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
  

/*********************Start Stylesheet**********************/
function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
