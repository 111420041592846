// import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import 'materialize-css/dist/css/materialize.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
// testing server site using axios
import axios from 'axios';
// end of testing
import reducers from './store/reducers';

import {loadState, saveState} from './services/localStorage';

window.axios = axios;
// const survey = { title: 'my title', body: 'my body', subject: 'my subject', recipients: 'cuong.buivan79@gmail.com' };
const persistedState = loadState();
const store = createStore(
  reducers, 
  persistedState,
  applyMiddleware(reduxThunk)
)
// anytime store changes, 
store.subscribe(() => {
  saveState(store.getState());
})

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

ReactDOM.render(
  <Provider
      store={store}
      className="container"
  >
      <App />
  </Provider>,
  document.getElementById('root')
);
// ReactDOM.render((
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// ), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
