import { 
    EVENT_LAST_BLOCK_SUCCESS,
    EVENT_LAST_BLOCK_FAILED,
} from '../actions/actionTypes'; 
import { stat } from 'fs';

 // initicate email and pass to prevent from returning undefined object
const INIT_STATE = { 
    lastBlock: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case EVENT_LAST_BLOCK_SUCCESS:
            return {
                ...state,
                lastBlock: action.payload,
            }
        case EVENT_LAST_BLOCK_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};