import axios from 'axios';
import { push } from 'react-router-redux';
import { history } from '../../App';

import {
    SELECTED_APPROVAL_VIEW_DETAIL_SUCCESS,
    SELECTED_APPROVAL_VIEW_DETAIL_FAILED,
    UPDATE_APPROVAL_ACTION,
    CREATE_APPROVAL_ACTION_SUCCESS,
    CREATE_APPROVAL_ACTION_FAILED,
    GET_CREATE_APPROVAL,
    SELECT_CREATE_APPROVAL_ACTION,
    FETCH_APPROVALS_FAILED,
    FETCH_APPROVALS_SUCCESS,
    FETCH_APPROVAL_SUCCESS,
    FETCH_APPROVAL_FAILED,
    UPDATE_APPROVAL_ACTION_SUCCESS,
    UPDATE_APPROVAL_ACTION_FAILED,
    UPDATE_APPROVAL_LEVEL_ACTION_SUCCESS,
    UPDATE_APPROVAL_LEVEL_ACTION_FAILED,
    ON_CLICK_REJECT_APPROVAL_ACTION,
    ON_CLICK_SIGN_APPROVAL_ACTION,
    ON_CLICK_UPDATE_APPROVAL_ACTION,
    ON_CLICK_DELEGATE_APPROVAL_ACTION,
    DELETE_APPROVAL_SUCCESS,
    DELETE_APPROVAL_FAILED,
    CREATE_DOCUMENT_MODAL_ACTION_SUCCESS,
    CREATE_DOCUMENT_MODAL_ACTION_FAILED,
    ONCLICK_CREATE_DOCUMENT_MODAL_ACTION,
    SIGN_APPROVAL_LEVEL_ACTION_FAILED,
    SIGN_APPROVAL_LEVEL_ACTION_SUCCESS,
    FETCH_SIGNERS_SUCCESS,
    GET_GRAIN_IMAGE_VALIDATION_SUCCESS,
    GET_GRAIN_IMAGE_VALIDATION_FAILED,
    ON_SAVE_DRAFT_APPROVAL_FAILED,
    ON_SAVE_DRAFT_APPROVAL_SUCCESS,
    GENERATE_APPROVAL_CERTFIFICATE_SUCCESS,
    GENERATE_APPROVAL_CERTFIFICATE_FAILED,
} from './actionTypes';
import { dispatch } from 'rxjs/internal/observable/pairs';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

const IMAGE_UPLOAD_BASE_URL = "https://grainapi.victoriousstone-f80887c9.australiaeast.azurecontainerapps.io/"

// const url = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:8080/api/";

export const getGrainImageValidationAction = (farmData) => {
    return async (dispatch) => {
        console.log('selected inoice received on actions:');
        const farm_data_id = farmData.number.toString();
        console.log(farm_data_id);
        let config = {
            headers: {
                // 'content-type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*'
            }
        }
        // axios.interceptors.request.use(x => {
        //     console.log(x);
        //     return x;
        // })
        // axios.interceptors.response.use(x => {
        //     console.log(x);
        //     return x;
        // })
        const res = await axios.post(`${IMAGE_UPLOAD_BASE_URL}get_verification_result`, {farm_data_id: farm_data_id}, config);
        console.log(res.status);
        console.log(res.data);
        if (res.data.error) {
            dispatch({
                type: GET_GRAIN_IMAGE_VALIDATION_FAILED,
                payload: {
                    grainImageValidation: null,
                }
            });
            
        } else {
            let verifycationResult = res.data['verification result'][0];
            console.log('verifiction result: ', verifycationResult);
            dispatch({
                
                type: GET_GRAIN_IMAGE_VALIDATION_SUCCESS,
                payload: {
                    grainImageValidation: verifycationResult,
                }
            });
        }
    };
};

export const onClickUploadDocumentByModal = (createDocType) => {
    return dispatch => {
        dispatch({
            type: ONCLICK_CREATE_DOCUMENT_MODAL_ACTION,
            payload: {
                createDocType: createDocType,
            }
        });
    }
}

export const onSaveDraftApproval = (_draftApproval) => {
    return dispatch => {
        dispatch({
            type: ON_SAVE_DRAFT_APPROVAL_SUCCESS,
            payload: {
                draftApproval: _draftApproval,
            }
        });
    }
}

export const createModalDocumentAction = (document, createDocType) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('create document ..');
        const {file, docName, docType, uploadUser, uploadUrl, description, company} = document;
        console.log('file before sending to server: ', file);
        const data = new FormData();
        data.append('file', file);
        data.append('docName', docName);
        data.append('docType', docType);
        data.append('uploadUser', uploadUser);
        data.append('uploadUrl', uploadUrl);
        data.append('description', description);
        data.append('company', company);
        // console.log('data of file before sending to server: ');
        // console.log(util.inspect(data));
        // console.log(util.inspect(data.file));
        // console.log(util.inspect(data.docName));

        // return;
        const res = await axios.post(`${url}company/document/create`, data, {'Content-Type': 'multipart/form-data',});
        
        if (res.data.documents) {
            var _docNames = [];
            let _document;
            const _documents = res.data.documents;
            _documents.forEach((_row, _index) => {

                let keyValue = {
                    key: _row.docId,
                    value: _row.docName,
                }
                _docNames.push(keyValue);
                if (_row.docName.toString() == docName.toString()) {
                    _document = _row;
                }
            
            })
            // supportDocs, approvalDoc
            let supportDoc;
            let approvalDoc;
            if (createDocType == "approvalDoc") {
                approvalDoc = _document;
            } else {
                supportDoc = _document;
            }
            console.log('type of document created here: ', createDocType);
            console.log('main document created', util.inspect(approvalDoc));
            console.log('support document created', util.inspect(supportDoc));
            dispatch({
                type: CREATE_DOCUMENT_MODAL_ACTION_SUCCESS,
                payload: {
                    documents: res.data.documents,
                    documentNames: _docNames,
                    hideDocumentModal: false,
                    approvalDoc: approvalDoc ? approvalDoc : null,
                    supportDoc: supportDoc ? supportDoc : null,
                }
            });
            
        } else {
            dispatch({
                type: CREATE_DOCUMENT_MODAL_ACTION_FAILED,
                payload: {
                    document: document,      //data is thr user's data
                    page: 'create',
                }
            });
            history.push('/views/approval/create');
        }
        
    };
}

export const fetchApprovals = () => {
    const {approvals} = require('../../data/approvals')
    console.log('approval constant imported: ');
    console.log(util.inspect(approvals));
    let approvalNames = [];
    approvals.forEach((_row, index) => {
        let keyValue = {
            key: _row._id,
            value: _row.approvalName,
        }
        approvalNames.push(keyValue);
    })
    return async (dispatch) => {
        console.log('fetching approvals ..')
        const res = await axios.get(`${url}company/approvals`);
        console.log('response from fetch approvals');
        console.log(util.inspect(res.data));
        if (res.data.approval_list) {
            console.log('approvals received: ', util.inspect(res.data.approval_list))
            // update state of addresses
            // return res.data.approval_list;
            let approvalNames = [];
            res.data.approval_list.forEach((_row, index) => {
                let keyValue = {
                    key: _row._id,
                    value: _row.approvalNumber + ' ' + _row.approvalName,
                }
                approvalNames.push(keyValue);
            })
            dispatch({
                type: FETCH_APPROVALS_SUCCESS,
                //data is thr user's data
                payload: {
                    approvals: res.data.approval_list,
                    approvalNames: approvalNames
                },
            });
        } else {
            dispatch({
                type: FETCH_APPROVALS_FAILED,
                payload: {
                    approvals: approvals,
                    approvalNames: approvalNames
                },
            });
        }
        
    }
}

export const fetchApproval = (approvalId) => {
    return async (dispatch) => {
        console.log('fetching approvals ..')
        const res = await axios.get(`${url}company/approval/${approvalId}`);
        console.log('response from fetch approval detail');
        console.log(util.inspect(res.data));
        if (res.data.approval) {
            console.log('approval received from server: ', util.inspect(res.data.approval))
            // update state of addresses
            // return res.data.approval_list;
            dispatch({
                type: FETCH_APPROVAL_SUCCESS,
                payload: res.data.approval,      //data is thr user's data
            });
        } else {
            dispatch({
                type: FETCH_APPROVAL_FAILED,
            });
        }
        
    }
}

export const selectViewApprovalDetailAction = (selectedApproval) => {
    
    return async (dispatch) => {
        console.log('selected approval received on actions:');
        console.log('fetching approvals ..')
        const res = await axios.get(`${url}company/approval/${selectedApproval._id}`);
        console.log('response from fetch approval detail');
        console.log(util.inspect(res.data));
        if (res.data.approval) {
            dispatch({
                type: SELECTED_APPROVAL_VIEW_DETAIL_SUCCESS,
                payload: {
                    approval: res.data.approval,
                }
            });
            history.push(`/views/approval/detail`);
        } else {
            console.log('fetch approval detail failed from server');
            dispatch({
                type: SELECTED_APPROVAL_VIEW_DETAIL_FAILED,
            });
            history.push(`/views/approval/list`);
        }
        
    };
};

export const createApprovalAction = (approval) => {
    return (dispatch) => {
        let {approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, company, deadline, summary, status} = approval;
        axios.post(`${url}company/approval/create`, {
            approvalLevels, approvalNumber, priority, approvalName, supportDocs, approvalDoc, company, deadline, summary, status
        }).then(res => {
            if (res.data.approval_list) {
                let approvalNames = [];
                res.data.approval_list.forEach((_row, index) => {
                    let keyValue = {
                        key: _row._id,
                        value: _row.approvalName,
                    }
                    approvalNames.push(keyValue);
                })
                dispatch({
                    type: CREATE_APPROVAL_ACTION_SUCCESS,
                    payload: {
                        approvalNames: approvalNames,
                        approvals: res.data.approval_list,
                    }
                });
                history.push(`/views/approval/list`);
            }
            else if (res.data.invalidMsg) {
                dispatch({
                    type: CREATE_APPROVAL_ACTION_FAILED,
                    payload: {
                        selectedApproval: res.data.approval,      //data is thr user's data
                        view_detail: true,
                        approval: approval,
                    }
                });
                history.push(`/views/approval/detail`);
            } else {
                dispatch({
                    type: CREATE_APPROVAL_ACTION_FAILED,
                    payload: {
                        selectedApproval: null,      //data is thr user's data
                        view_detail: true,
                        approval: approval,
                    }
                });
                history.push(`/views/approval/detail`);
            }
        })
        
    };
}

export const updateApprovalAction = (approval, approvalLevels) => {
    return async (dispatch) => {
        const {
            approvalNumber,
            approvalName,
            priority,
            stage,
            approvalDoc,
            supportDocs,
            deadline,
        } = approval;
        const res = await axios.post(`${url}company/approval/${approval._id}/update`, {
            approvalNumber,
            approvalName,
            priority,
            stage,
            approvalDoc,
            supportDocs,
            deadline,
            approvalLevels,
        });
        if (res.data.approval_list) {
            let approvalNames = [];
            res.data.approval_list.forEach((_row, index) => {
                let keyValue = {
                    key: _row._id,
                    value: _row.approvalName,
                }
                approvalNames.push(keyValue);
            })
            dispatch({
                type: UPDATE_APPROVAL_ACTION_SUCCESS,
                payload: {
                    approvalNames: approvalNames,
                    approvals: res.data.approval_list,
                }
            });
            history.push(`/views/approval/list`);
        } else {
            dispatch({
                type: UPDATE_APPROVAL_ACTION_FAILED,
                payload: {
                    approval: approval,      //data is thr user's data
                }
            });
            history.push(`/views/approval/detail`);
        }
        
    };
}


export const signApprovalAction = (approval, approvalLevels, otp) => {
    return async (dispatch) => {
        const {
            approvalNumber,
            approvalName,
            priority,
            stage,
            approvalDoc,
            supportDocs,
            deadline,
        } = approval;
        const res = await axios.post(`${url}company/approval/${approval._id}/sign`, {
            approvalNumber,
            approvalName,
            priority,
            stage,
            approvalDoc,
            supportDocs,
            deadline,
            approvalLevels,
            otp,
        });
        if (res.data.approval_list) {
            let approvalNames = [];
            res.data.approval_list.forEach((_row, index) => {
                let keyValue = {
                    key: _row._id,
                    value: _row.approvalName,
                }
                approvalNames.push(keyValue);
            })
            dispatch({
                type: UPDATE_APPROVAL_ACTION_SUCCESS,
                payload: {
                    approvalNames: approvalNames,
                    approvals: res.data.approval_list,
                }
            });
            history.push(`/views/approval/list`);
        } else {
            dispatch({
                type: UPDATE_APPROVAL_ACTION_FAILED,
                payload: {
                    approval: approval,      //data is thr user's data
                }
            });
            history.push(`/views/approval/detail`);
        }
        
    };
}

// group: req.body.group,
//             signers: req.body.signers,
//             signed: req.body.signed,
//             requireSigns: requireSigns,
//             deadline: req.body.deadline,
//             status: req.body.status,
//             comments: req.body.comments ? req.body.comments : '',
export const updateApprovalLevelAction = (approvalLevel, approval) => {
    return async (dispatch) => {
        const {
            group, signers, signed, signatures, dealine, status, comments,
        } = approvalLevel;
        const {approvalNumber, deadline} = approval;
        const res = await axios.post(`${url}company/approvallevel/${approvalLevel._id}/update`, {
            group, signers, signed, signatures, dealine, status, comments, approvalNumber, deadline
        });
        if (res.data.approval_list) {
            let approvalNames = [];
                res.data.approval_list.forEach((_row, index) => {
                    let keyValue = {
                        key: _row._id,
                        value: _row.approvalName,
                    }
                    approvalNames.push(keyValue);
                })
            dispatch({
                type: UPDATE_APPROVAL_LEVEL_ACTION_SUCCESS,
                payload: {
                    approvals: res.data.approval_list,      //data is thr user's data
                    approvalNames: approvalNames,
                    popupModal: false,
                }
            });
            history.push(`/views/approval/list`);
        } else {
            dispatch({
                type: UPDATE_APPROVAL_LEVEL_ACTION_FAILED,
                payload: {
                    approvalLevel: approvalLevel,      //data is thr user's data
                }
            });
            history.push(`/views/approval/detail`);
        }
    };
}

export const signApprovalLevelAction = (approvalLevel, approval, otp) => {
    return async (dispatch) => {
        const {
            group, signers, signed, signatures, dealine, status, comments,
        } = approvalLevel;
        const {approvalNumber, deadline} = approval;
        const res = await axios.post(`${url}company/approvallevel/${approvalLevel._id}/sign`, {
            group, signers, signed, signatures, dealine, status, comments, approvalNumber, deadline, otp, approval, approvalLevel
        });
        if (res.data.approval_list) {
            let approvalNames = [];
                res.data.approval_list.forEach((_row, index) => {
                    let keyValue = {
                        key: _row._id,
                        value: _row.approvalName,
                    }
                    approvalNames.push(keyValue);
                })
            dispatch({
                type: SIGN_APPROVAL_LEVEL_ACTION_SUCCESS,
                payload: {
                    approvals: res.data.approval_list,      //data is thr user's data
                    approvalNames: approvalNames,
                    popupModal: false,
                }
            });
            history.push(`/views/approval/list`);
        } else {
            dispatch({
                type: SIGN_APPROVAL_LEVEL_ACTION_FAILED,
                payload: {
                    invalidMsg: res.data.invalidMsg,
                }
            });
        }
    };
}

export const onClickUpdateApproval = (approval) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('use create approval ..');
        dispatch({
            type: ON_CLICK_UPDATE_APPROVAL_ACTION,
            payload: {
                approval: approval,      //data is thr user's data
            }
        });
        history.push('/views/approval/update');
    };
}

export const onClickSignApproval = (approval, approvalLevel) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        const res = await axios.get(`${url}company/approvalLevel/${approvalLevel._id}/sign`);
        console.log('use sign approval ..');
        if (!res.data.invalidMsg || res.data.invalidMsg == ""){
            dispatch({
                type: ON_CLICK_SIGN_APPROVAL_ACTION,
                payload: {
                    approval: approval,      //data is thr user's data
                    approvalLevel: approvalLevel,
                }
            });
        } else {
            console.log('sending otp backend error: ', res.data.invalidMsg);
            dispatch({
                type: ON_CLICK_SIGN_APPROVAL_ACTION,
                payload: {
                    approval: approval,      //data is thr user's data
                    approvalLevel: approvalLevel,
                }
            });
        }
        
        // history.push('/views/approval/sign');
    };
}

export const onClickRejectApproval = (approval, approvalLevel) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('use create approval ..');
        dispatch({
            type: ON_CLICK_REJECT_APPROVAL_ACTION,
            payload: {
                approval: approval,      //data is thr user's data
                approvalLevel: approvalLevel,
            }
        });
        // history.push('/views/approval/reject');
    };
}

export const onClickDelegateApproval = (approval, approvalLevel) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('use choose to delegate approval ..');
        dispatch({
            type: ON_CLICK_DELEGATE_APPROVAL_ACTION,
            payload: {
                approval: approval,      //data is thr user's data
                approvalLevel: approvalLevel,
            }
        });
        // history.push('/views/approval/delegate');
    };
}

export const selectCreateApprovalAction = () => {
    return (dispatch) => {
        axios.get(`${url}company/approval/create`)
        .then(res =>{
            console.log('res data received through service fetchData:');
            console.log(util.inspect(res));
            let _signers = res.data.signers;
            var _signersInfo = [];
            _signers.forEach((_row, _index) => {

                let keyValue = {
                    key: _row._id,
                    value: _row.firstname + ' ' + _row.lastname,
                }
                _signersInfo.push(keyValue);
            
            })
            dispatch({
                type: SELECT_CREATE_APPROVAL_ACTION,
                payload: {
                    approval: null,
                    approvalNumber: res.data.approvalNumber,
                    signers: res.data.signers,
                    // approvalLevels: res.data.approvalLevels,
                }
            });
            dispatch({
                type: FETCH_SIGNERS_SUCCESS,
                // payload: res.data.user_list,      //data is thr user's data
                payload: {
                    signers: _signers,
                    signersInfo: _signersInfo,
                }
            });
            history.push('/views/approval/create');
        })
    }
    // return async (dispatch) => {
    //     // make sure proxy for '/api/current_user' was configued on json file
    //     console.log('use create approval ..');
    //     dispatch({
    //         type: SELECT_CREATE_APPROVAL_ACTION,
    //         payload: {
    //             approval: null,      //data is thr user's data
    //             view_detail: false
    //         }
    //     });
    //     history.push('/views/approval/create');
    // };
}

export const getCreateApproval = () => {
    return (dispatch) => {
        axios.get(`${url}company/approval/create`)
        .then(res =>{
            console.log('res data received through service fetchData:');
            console.log(util.inspect(res));
            dispatch({
                type: GET_CREATE_APPROVAL,
                payload: {
                    approvalNumber: res.data.approvalNumber,
                    signers: res.data.signers,
                    // approvalLevels: res.data.approvalLevels,
                }
            })
        })
    }
}

export const deleteUpprovalAction = (approvalId) => {
    return async (dispatch) => {
        const res = axios.post(`${url}company/approval/${approvalId}/delete`, {approvalId})
        if (res.status == 200) {
            dispatch({
                type: DELETE_APPROVAL_SUCCESS,
            })
            history.push('/views/approval/list');
        } else {
            dispatch({
                type: DELETE_APPROVAL_FAILED,
            })
            history.push('/views/approval/list');
        }
    }
}

export const fetchApprovalTemplates = () => {
    console.log('not implemented yer')
}


export const downloadApproval = (_approval) => {
    const testDownloadUrl = "../../DemoCertificate.pdf";
    return (dispatch) => {
        const method = 'GET';
        axios
            .request({
                url: `${url}company/approval/${_approval._id}`,
                method,
                responseType: 'blob', //importan
            })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;

                link.setAttribute('download', `${testDownloadUrl}`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
  
    };
}


export const generateApprovalCertificate = (_approval) => {
    const testDownloadUrl = "../../DemoCertificate.pdf";
    // let remotHost = 'https://127.0.0.1:5000/';
    let remotHost = 'https://grainsupp.net:5000/';
    let _requestData = JSON.stringify({
        "certification_number": "1NO:C034859-XXX-000",
        "location": "2TTTT Grains, Ballarat, \n Lake Gardens VIC 3350",
        "grade": "3WWWW",
        "membership_number": "Membership Number:\n51299488",
        "date_of_certification": "Date of Certification Decision:\n40-OctoXXX-2022",
        "chain_id": " Chain ID:\n 7bbaab6eaf640a34f784593f2074416accd3b8e62c620175fca0997b1ba234733943539453458see84998 9aa0d79e754c308209ea36811dfa40c1 9aa0d79e754c308209ea36811dfa40c1",
        "valid_to": "Certificate Valid To:\n 81-OOOber-2025",
        "valid_from": "Certificate Valid FromT:\n 60-TTTober-2024",
        "creation_date": "90-10-5555 21:27:29"
        })
    const submitter = _approval.submitter;
    const farmData = _approval.approvalDoc;
    const createdDate = _approval.startDate;
    const today = new Date()
    const expiredDate = new Date(today.setFullYear(today.getFullYear() + 3));
    let certification_number = "CERT No: " + _approval.approvalNumber.toString();
    let location = farmData.storageLocation;
    let grade = farmData.harvestGrade;
    let membership_number = "Member ID: " + submitter._id.toString();
    const certifiedDate = _approval.completedDate ? _approval.completedDate : _approval.deadline;
    let date_of_certification = "Date of Certification Decision:\n" + certifiedDate.toString();
    let chain_id = " Chain ID:\n 7bbaab6eaf640a34f784593f2074416accd3b8e62c620175fca0997b1ba234733943539453458see84998 9aa0d79e754c308209ea36811dfa40c1 9aa0d79e754c308209ea36811dfa40c1";
    // Sat Nov 06 2027 11:41:35 GMT+1100
    let valid_to = "Valid to: " + expiredDate.toString().slice(0,33);
    let valid_from = "Valid from: " + createdDate.toString().slice(0,33);
    let creation_date = today.toString().slice(0,33);

    let config = {
        headers: {
            'Content-Type': 'application/pdf',
            'Access-Control-Allow-Origin': '*',
            // 'responseType': 'blob'
        }
    }
    const _dynamicCertUrl = `${remotHost}generate_certification`;
    console.log('dynamically generate cert url: ', _dynamicCertUrl);



    return async (dispatch) => {
        // const res = await axios.post(_dynamicCertUrl, {
        //     certification_number, location, grade, membership_number, date_of_certification, chain_id, valid_to, valid_from, creation_date
        // }, config)

        // axios.post(_dynamicCertUrl, _dynamicCertUrl, config, {responseType: 'blob'})

        axios.post(_dynamicCertUrl, 
            {certification_number, location, grade, membership_number, date_of_certification, chain_id, valid_to, valid_from, creation_date},
            {
                method: 'POST',
                responseType: 'blob'
            })
        .then(response => {
        //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'});
        //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
            window.open(fileURL);
            dispatch({
                type: GENERATE_APPROVAL_CERTFIFICATE_SUCCESS,
            })
        })
        .catch(error => {
            console.log(error);
            dispatch({
                type: GENERATE_APPROVAL_CERTFIFICATE_FAILED,
            })
        });
        

    }
}


