import { combineReducers } from 'redux';
import authReducer from './authReducer';
import LastEventReducer from './lastBlockReducer';
import fetchDataReducer from './fetchDataReducer';
import approvalReducer from './approvalReducer';
import documentReducer from './documentReducer';
import farmDataReducer from './farmDataReducer';
import company from './company';
import user from './user';
import group from './group';
import topMenu from './topMenu';
import signature from './signature';
import feedback from './feedback';

export default combineReducers({
    authState: authReducer,
    lastBlock: LastEventReducer,
    fetchData: fetchDataReducer,
    approvalState: approvalReducer,
    document: documentReducer,
    farmData: farmDataReducer,
    company: company,
    user: user,
    group: group,
    topMenu: topMenu,
    signatureState: signature,
    feedbackState: feedback,
});