import React from 'react'
import {connect} from 'react-redux';
import ApprovalBase from './../ApprovalBase';
import {compareTwoDate} from '../../../services/utilities';

import {
  selectViewApprovalDetailAction,
  fetchApprovals,
  fetchSigner,
  fetchSigners,
  selectCreateApprovalAction,
} from '../../../store/actions';

// import { authenticationService, fetchData } from '../services';

import {
  ContentContainer, ItemTitle, CustomLink, RowContainer, ColContainer, Status, InfoContainer, 
  BoldText, InactiveBar, ActiveBar, ControlerContainer, ControlerRow, ControlerCol, RedButton, 
  YellowButton, GreenButton, GreyButton, EmptySquare, CheckedSquare, AddButton, PlusIcon, ReSearchBox, SearchBoxContainer,
  PurpleButton
} from './style';
import {
  StandardButton,
} from '../style';

const _today = new Date();
const util = require('util');

class ApprovalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.currentUser,
      //Header: 60px, Footer: 52px, border included
      approvals: this.props.approvals,
      customers: [],
      overdueCheck: false,
      pendingCheck: true,
      approvedCheck: false,
      createdCheck: false,
      signingCheck: true,
      searchFocus: false,
    };

    this.onToggle = this.onToggle.bind(this);
    // this.defaultLoadApprovals = this.defaultLoadApprovals.bind(this);
  }

  onToggle() {
    this.setState({ toggleActive: !this.state.toggleActive });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // fetch all Approvals
    this.props.onFetchApprovals();
    this.props.onFetchSigners();
    let approvals = this.props.approvals ? this.props.approvals : null;
    if (this.props.approvals) {
      let _approvals = this.filterApprovalsByState(
        approvals,
        this.state.approvedCheck,
        this.state.overdueCheck,
        this.state.pendingCheck,
        this.state.createdCheck,
        this.state.signingCheck,
      );
      if (_approvals.length > 0) {
        this.setState(prevState => ({
          ...prevState,
          approvals: _approvals,
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          approvals: null,
        }))
      }
    }
    
  }

  // defaultLoadApprovals = () => {
  //   let approvals = this.filterApprovalsByState(true, true, true);
  //   this.setState({approvals});
  // }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getSignerById = (customerId) => {
    let selectedSigner = null;
    let customers = this.props.customers;
    for (let i = 0; i < customers.length; i++) {
      if (customers[i]._id.toString() == customerId.toString()) {
        selectedSigner = customers[i]
      }
    }
    return selectedSigner;
  }

  selectedApprovalHandler = (approval) => {
    console.log('user selected this Approval: ');
    console.log(util.inspect(approval));
    this.props.onSelectedApproval(approval);
  }

  createApprovalHandler = () => {
    console.log('start action of creating new Approval..');
    this.props.onSelectCreateApprovalClick();
  }

  handleSearchApproval = (approvalInfo) => {
    console.log('check name or id ', approvalInfo);
    let approvals = this.props.approvals ? this.props.approvals : [];
    if (approvals.length > 0) {
      approvals.forEach((_approval, _index) => {
        if (_approval._id.toString() == approvalInfo.key.toString()) {
          let {approvals,searchFocus} = this.state;
          if (this.state.searchFocus == false) {
            approvals = [_approval];
            searchFocus = true;
          } else {
            approvals.push(_approval);
          }
          
          this.setState({approvals, searchFocus});
        }
      })
    }
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

  renderStatusBar = (_approvalLevels, _done, _numberOfLevels) => {
    
    console.log('approvalLevels at status bar: ', _approvalLevels);
    return _approvalLevels.map((_approvalLevel, _index) => {
      const approvalLevleStatus = _approvalLevel.status;
      if (approvalLevleStatus == "done") {
        return (
          <ActiveBar />
        )
      } else {
        return (
          <InactiveBar />
        )
      }
      // if (parseInt(_index) <= parseInt(_done - 1)) {
      //   return (
      //     <ActiveBar />
      //   )
      // } else {
      //   return (
      //     <InactiveBar />
      //   )
      // }
      
    })
  }

  setApprovalsState = (approvals) => {
    this.setState(prevState => ({
      ...prevState,
      approvals: approvals,
    }))
  }

  onDownloadDocument = (approval) => {
    this.props.onDownloadApproval(approval);
  }

  renderItemStatus = (status, approval) => {
    var bgColor = "";
    switch(status) {
      case "created":
        bgColor = "#B4B4B4";
        break;
      case "overdue":
        bgColor = "#D84B65";
        break;
      case "pending":
        bgColor = "#F3A83C";
        break;
      case "done":
        bgColor = "#17C177";
        break;
      default:
        //bgColor = this.state.bgColor0;
    }

    return(
      <Status bgcolor={bgColor}>
        {status ? status : ''}
      </Status>
    );
    
  }

  renderCheckIcon = (isChecked) => {
    if(isChecked) {
      return(
        <CheckedSquare />
      );
    } else {
      return(
        <EmptySquare />
      );
    }
  }

  renderViewCertificate = (_approvalStatus, approval) => {
    if (_approvalStatus != "done") {
      return;
    } else {
      return (
        <a>View Certificate</a>
      )
    }

  }



  filterApprovalsByState = (approvals, approvedCheck, overdueCheck, pendingCheck, createdCheck, signingCheck) => {
    // let approvals = this.props.approvals;
    let doneApprovals = [];
    let overdueApprovals = [];
    let signingApprovals = [];
    let pendingApprovals = [];
    let createdApprovals = [];
    approvals.forEach((_approval, _index) => {
      if (_approval.status == 'done') {
        doneApprovals.push(_approval);
      } else if (_approval.status == 'pending') {
        pendingApprovals.push(_approval);
      } else {
        // this deadline is alreay in date format, can be use to compare
        let _deadline = _approval.deadline;
        console.log('deadline of this approvel: ', _deadline);
        
        if (compareTwoDate(_deadline, _today)) {
          overdueApprovals.push(_approval);
        } else if (_approval.status == 'created') {
          createdApprovals.push(_approval);
        } else {
          signingApprovals.push(_approval);
        }
      }
    })
    let _approvals = [];
    if (approvedCheck) {
      doneApprovals.forEach((_approval, _index) => {
        _approvals.push(_approval)
      })
    }
    if (overdueCheck) {
      overdueApprovals.forEach((_approval, _index) => {
        _approvals.push(_approval)
      })
    }
    if (pendingCheck) {
      pendingApprovals.forEach((_approval, _index) => {
        _approvals.push(_approval)
      })
    }
    if (createdCheck) {
      createdApprovals.forEach((_approval, _index) => {
        _approvals.push(_approval)
      })
    }
    if (signingCheck) {
      signingApprovals.forEach((_approval, _index) => {
        _approvals.push(_approval)
      })
    }
    return _approvals;
    
  }

  overDueButtonClick = (isChecked) => {
    let overdueCheck = this.state.overdueCheck;
    if(overdueCheck) {
      this.setState({ overdueCheck: false });
      overdueCheck = false
    } else {
      this.setState({ 
        overdueCheck: true ,
      });
      overdueCheck = true
    }
    console.log('status of the overdue check now: ', this.state.overdueCheck);

    let approvals = this.filterApprovalsByState(
      this.props.approvals, 
      this.state.approvedCheck, 
      overdueCheck, 
      this.state.pendingCheck, 
      this.state.createdCheck,
      this.state.signingCheck,
    )
    this.setState((prevState) => ({
      prevState,
      searchFocus: false,
      approvals: approvals,
    }))
  }

  pendingButtonClick = () => {
    let pendingCheck = this.state.pendingCheck;
    if(pendingCheck) {
      this.setState({ pendingCheck: false });
      pendingCheck = false
    } else {
      this.setState({ 
        pendingCheck: true ,
      });
      pendingCheck = true
    }
    console.log('status of the pending check now: ', this.state.pendingCheck);
    let approvals = this.filterApprovalsByState(
      this.props.approvals, 
      this.state.approvedCheck, 
      this.state.overdueCheck, 
      pendingCheck, 
      this.state.createdCheck,
      this.state.signingCheck
    )
    this.setState((prevState) => ({
      prevState,
      searchFocus: false,
      approvals: approvals,
    }))
  }

  approvedButtonClick = () => {
    let approvedCheck = this.state.approvedCheck;
    if(approvedCheck) {
      this.setState({ approvedCheck: false });
      approvedCheck = false;
    } else {
      this.setState({ 
        approvedCheck: true,
      });
      approvedCheck = true
    }
    console.log('status of the done check now: ', this.state.approvedCheck);
    let approvals = this.filterApprovalsByState(
      this.props.approvals, 
      approvedCheck, 
      this.state.overdueCheck, 
      this.state.pendingCheck, 
      this.state.createdCheck,
      this.state.signingCheck
    )
    this.setState((prevState) => ({
      prevState,
      searchFocus: false,
      approvals: approvals,
    }))
  }

  createdButtonClick = () => {
    let createdCheck = this.state.createdCheck;
    if(createdCheck) {
      this.setState({ createdCheck: false });
      createdCheck = false;
    } else {
      this.setState({ 
        createdCheck: true,
      });
      createdCheck = true
    }
    console.log('status of the done check now: ', this.state.createdCheck);
    let approvals = this.filterApprovalsByState(
      this.props.approvals, 
      this.state.approvedCheck, 
      this.state.overdueCheck, 
      this.state.pendingCheck, 
      createdCheck,
      this.state.signingCheck
    )
    this.setState((prevState) => ({
      prevState,
      searchFocus: false,
      approvals: approvals,
    }))
  }

  signingButtonClick = () => {
    let signingCheck = this.state.signingCheck;
    if(signingCheck) {
      this.setState({ signingCheck: false });
      signingCheck = false;
    } else {
      this.setState({ 
        signingCheck: true,
      });
      signingCheck = true
    }
    console.log('status of the done check now: ', this.state.signingCheck);
    let approvals = this.filterApprovalsByState(
      this.props.approvals, 
      this.state.approvedCheck, 
      this.state.overdueCheck, 
      this.state.pendingCheck, 
      this.state.createdCheck,
      signingCheck
    )
    this.setState((prevState) => ({
      prevState,
      searchFocus: false,
      approvals: approvals,
    }))
  }

  renderCardList = (approvals) => {
    // let approvals = this.props.approvals ? this.props.approvals : [];
    let signers = this.props.signers ? this.props.signers : [];
    if (approvals.length > 0) {
      return approvals.map((approval, index) => {
          const { _id, approvalName, approvalNumber, priority, approvalDoc, deadline, status, stage } = approval //destructuring
          let approvalLevel;
          let levelSigners = '';
          let doneApprovalLevels = -1;
          let _numberOfLevels = 0;
          if (stage) {
            approval.approvalLevels.forEach((_approvalLevel, _index) => {
              // not populate approvalLevels yet, so only _id
              _numberOfLevels = _numberOfLevels + 1;
              if (_approvalLevel._id) {
                if (_approvalLevel._id.toString() == stage.toString()) {
                  approvalLevel = _approvalLevel;
                  doneApprovalLevels = parseInt(_index);
                  let signerIds = _approvalLevel.signers;
                  console.log('examine signerIds of this level: ', util.inspect(signerIds));
                  for (var i = 0; i < signerIds.length; i++) {
                    levelSigners = levelSigners + ' ' + signerIds[i].firstname + ' ' + signerIds[i].lastname;
                  }
                  
                }
              }
            })
          }
          // status of approval
          let _approvalStatus = status;
          if (_approvalStatus != 'done') {
            if (compareTwoDate(deadline, _today)) {
              _approvalStatus = 'overdue';
            } else {
              _approvalStatus = "signing";
            }
          }
          
          if (status == 'done' && this.state.approvedCheck == false) {
            return;
          } else {
            doneApprovalLevels = doneApprovalLevels + 1;
          }

          // var pendingList = ["pending", "created"];
          // if ((pendingList.indexOf(status) > 0) && this.state.pendingCheck == false) {
          //   return;
          // }

          // get status of approval
          

          // if (_approvalStatus == 'overdue' && this.state.overdueCheck == false) {
          //   return;
          // }
          const addingLevel0 = {level: 0, group: "Created", signers: [], signatures: 1, deadline: '', status: 'created', signed: []};
          // if (__approvalLevels.length <= 3)
          
          let insertIndex = 0;
          let _barStatusApprovalLevels = approval.approvalLevels;

          return (
            <CustomLink onClick = {() => {
              this.selectedApprovalHandler(approval)
            }}>
              <RowContainer>
                <ColContainer xs={8}>
                  <ItemTitle>
                    {`[${approvalNumber ? approvalNumber : ''}]`}: 
                    {approvalName ? approvalName : ''}
                  </ItemTitle>
                </ColContainer>
                {/* <ColContainer>
                  {this.renderViewCertificate(_approvalStatus, approval)}
                </ColContainer> */}
                <ColContainer>
                  {this.renderItemStatus(_approvalStatus, approval)}
                </ColContainer>
              </RowContainer>
              <RowContainer paddingTop="0px">
                <ColContainer sm>
                  <InfoContainer>
                    <BoldText>Current Stage: </BoldText> {approvalLevel ? approvalLevel.group : ''}
                  </InfoContainer>
                  <InfoContainer>
                    <BoldText>Assignee: </BoldText> {levelSigners ? levelSigners : ''}
                  </InfoContainer>
                </ColContainer>
                <ColContainer sm>
                  <InfoContainer>
                    <BoldText>Document Type: </BoldText> {approvalDoc.docType}
                  </InfoContainer>
                  <InfoContainer>
                    <BoldText>Priority: </BoldText> {priority ? priority : ''}
                  </InfoContainer>
                </ColContainer>
                <ColContainer sm>
                  <InfoContainer>
                    <BoldText>Due Date: </BoldText> {deadline ? this.formatDate(deadline) : ''}
                  </InfoContainer>
                  <InfoContainer>
                    <ActiveBar />
                    {this.renderStatusBar(_barStatusApprovalLevels, doneApprovalLevels, _numberOfLevels)}
                  </InfoContainer>
                </ColContainer>
              </RowContainer>
          </CustomLink>
          )
      })
    }
  }

  body = () => {
    let approvals = this.state.approvals ? this.state.approvals : this.props.approvals;
    // let approvals = this.filterApprovalsByState(this.state.);
    let doneApprovals = [];
    let overdueApprovals = [];
    let signingApprovals = [];
    let createdApprovals = [];
    let pendingApprovals = [];
    approvals.forEach((_approval, _index) => {
      if (_approval.status == 'done') {
        doneApprovals.push(_approval);
      } else if (_approval.status == 'pending') {
        pendingApprovals.push(_approval);
      } else {
        // this deadline is alreay in date format, can be use to compare
        let _deadline = _approval.deadline;
        console.log('deadline of this approvel: ', _deadline);
        
        if (compareTwoDate(_deadline, _today)) {
          overdueApprovals.push(_approval);
        } else if (_approval.status == 'created') {
          createdApprovals.push(_approval);
        } else {
          signingApprovals.push(_approval);
        }
      }
    })
    // let _approvals = [];
    // if (this.state.approvedCheck) {
    //   doneApprovals.forEach((_approval, _index) => {
    //     _approvals.push(_approval)
    //   })
    // }
    // if (this.state.overdueCheck) {
    //   overdueApprovals.forEach((_approval, _index) => {
    //     _approvals.push(_approval)
    //   })
    // }
    // if (this.state.pendingCheck) {
    //   signingApprovals.forEach((_approval, _index) => {
    //     _approvals.push(_approval)
    //   })
    // }
    return (
      <ContentContainer>

        <ControlerContainer>
          <ControlerRow>
            <ControlerCol lg>
              <RedButton
                onClick={() => this.overDueButtonClick()}
              >{this.renderCheckIcon(this.state.overdueCheck)}Overdue 
                ({overdueApprovals.length.toString()})
              </RedButton>
              <YellowButton
                onClick={() => this.pendingButtonClick()}
              >{this.renderCheckIcon(this.state.pendingCheck)}Pending 
                ({pendingApprovals.length.toString()})
              </YellowButton>
              <GreenButton 
                // marginRight="0px"
                onClick={() => this.approvedButtonClick()}
              >{this.renderCheckIcon(this.state.approvedCheck)}Approved 
                ({doneApprovals.length.toString()})
              </GreenButton>
              <PurpleButton 
                // marginRight="0px"
                onClick={() => this.signingButtonClick()}
              >{this.renderCheckIcon(this.state.signingCheck)}Signing 
                ({signingApprovals.length.toString()})
              </PurpleButton>
              <GreyButton 
                marginRight="0px"
                onClick={() => this.createdButtonClick()}
              >{this.renderCheckIcon(this.state.createdCheck)}Created
                ({createdApprovals.length.toString()})
              </GreyButton>
            </ControlerCol>
          </ControlerRow>
          <ControlerRow>
            <ControlerCol lg>
              <SearchBoxContainer>
                <ReSearchBox
                  placeholder="Search for approvals by Name and Number"
                  value=""
                  data={this.props.approvalNames}
                  callback={(record )=> {
                    console.log(record);
                  }}
                  // onChange={(e) => {
                  //   let {searchValue} = this.state;
                  //   searchValue = e.target.value;
                  //   this.setState(searchValue);
                  // }}
                  onSelect={(approvalInfo) => {
                    this.handleSearchApproval(approvalInfo);
                  }}
                  onFocus={() => {
                    console.log('This function is called when is focussed')
                  }}
                  onChange={value => console.log(value)}
                  fuseConfigs={{
                    threshold: 0.05,
                  }}
                />
              </SearchBoxContainer>
              
              <AddButton><PlusIcon /></AddButton>
            </ControlerCol>
            
          </ControlerRow>
        </ControlerContainer>
        {this.renderCardList(this.state.approvals ? this.state.approvals : this.props.approvals)}
      </ContentContainer>
    );
  }

  render() {
    return (
      <ApprovalBase
        contentContainer={this.body()}
        selectMenuItem="0"
      />
    );
  }
}

const mapStateToProps = state => {
  console.log(`current list of Approvals are `);
  console.log(util.inspect(state.approvalState.approvals));
  return {
      approvals: state.approvalState.approvals,
      signers: state.fetchData.signers,
      approvalNames: state.approvalState.approvalNames,
      currentUser: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      onFetchApprovals: () => dispatch(fetchApprovals()),
      onSelectedApproval: (approval) => dispatch(selectViewApprovalDetailAction(approval)),
      onFetchSigner: (customerId) => dispatch(fetchSigner(customerId)),
      onFetchSigners: () => dispatch(fetchSigners()),
      onSelectCreateApprovalClick: () => dispatch(selectCreateApprovalAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalList);

