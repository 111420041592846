import React from 'react'
import { connect } from 'react-redux';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
// import styles
import {
    Title, ContentContainer, GreenButton, InfoContainer, FormContainer
} from '../style';

import {
    CancelButton, SubmitButton, Input, ModalContainer, Notification
  } from './style';

import {
    signApprovalAction,
    signApprovalLevelAction,
} from '../../../store/actions';
import {
    approvalResErrors, selectLanguage
} from '../../../langueges';

const util = require('util');
class SignModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.currentUser ? this.props.currentUser : null,
            nextApprovalLevel: null,
            comment: '',
            otp: "",
        };
    }

    updateDimensions = () => {
        this.setState({ 
        height: window.innerHeight - 112
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        // fetch all Documents
        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onSignApproval = () => {
        // add signature to approvalLevel accordingly
        let comment = this.state.comment;
        let approvalLevel = this.props.approvalLevel;
        let fullname = this.props.currentUser.firstname + ' ' + this.props.currentUser.lastname;
        if (!approvalLevel.comments) {
            approvalLevel.comments = [];
            approvalLevel.comments.push(fullname + ': comment');
        } else {
            approvalLevel.comments.push(fullname + ': comment');
        }
        let approvalLevels = this.props.approval.approvalLevels;
        let updateApprovalLevels = approvalLevels;
        let approval = this.props.approval;
        approvalLevel.signed.push(this.props.currentUser._id);
        let {otp} = this.state;
        // update status of this approvalLevel if require signatures meet
        if (approvalLevel.signed.length >= parseInt(approvalLevel.signatures)) {
            approvalLevel.status = "done";
            // upate this approvalLevel for approval
            approvalLevels.forEach((__approvalLevel, _index) => {
                if (__approvalLevel._id.toString() == approvalLevel._id.toString()) {
                    updateApprovalLevels[_index] = approvalLevel;
                    approval.approvalLevels = updateApprovalLevels;
                }
            })

            // move stage of approval to next approvalLevel
            const _level = approvalLevel.level;
            // if this is the last stage, so the stage of the approval need to set to done
            if (parseInt(_level) == approvalLevels.length) {
                approval.status = 'done';
            } else {
                let _nextLevel = parseInt(_level) + 1;
                approvalLevels.forEach((__approvalLevel, _index) => {
                    if (parseInt(__approvalLevel.level) == _nextLevel) {
                        // set stage of approval
                        approval.stage = __approvalLevel._id;
                    }
                })
            }
            
            // then update stage of approval
            // this.props.onSignApproval(approval, [approvalLevel], otp);
            this.props.onSignApprovalLevel(approvalLevel, approval, otp);

        } else {
            // update approvalLevel accordingly
            this.props.onSignApprovalLevel(approvalLevel, approval, otp);
        }
    }

    body = () => {
        return (
            <ContentContainer height={this.state.height}>
                
                <FormContainer>
                    <Title>{"Sing and pass to next process"}</Title>
                    
                    <InfoContainer>
                        <Input 
                        title={'Note'}
                        className={'form-control'}
                        placeholder={'Comment if needed'}
                        value={this.state.comment} 
                        onChange={(e) => {
                            let {comment} = this.state;
                            comment = e.target.value;
                            this.setState({comment});
                        }}
                        />
                    </InfoContainer>
                        
                    <InfoContainer>
                        <GreenButton
                            onClick={() => this.onSignApproval()}
                        >{'Sign here to approve'}
                        </GreenButton>
                    </InfoContainer>
                </FormContainer>
            </ContentContainer>
        )
    }
    
    render() {
        return (
            <ModalContainer>
                <Modal
                    show={this.props.showRejectModal}
                    onHide={() => this.props.hideSignModal()}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {"Sign Form"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Notification>
                            {this.props.invalidMsg ? selectLanguage(this.props.language, this.props.invalidMsg) : ""}
                        </Notification>
                        <InfoContainer>
                            <Input 
                            title={'Note'}
                            className={'form-control'}
                            placeholder={'Comment if needed'}
                            value={this.state.comment} 
                            onChange={(e) => {
                                let {comment} = this.state;
                                comment = e.target.value;
                                this.setState({comment});
                            }}
                            />
                        </InfoContainer>
                        <InfoContainer>
                            <Input 
                            title={'Authentication Code'}
                            className={'form-control'}
                            placeholder={'Please input OTP provided through your emal'}
                            value={this.state.otp} 
                            onChange={(e) => {
                                let {otp} = this.state;
                                otp = e.target.value;
                                this.setState({otp});
                            }}
                            />
                            <Notification>
                                {"please check your email to get the OTP code"}
                            </Notification>
                        </InfoContainer>
                                
                    </Modal.Body>
                    <Modal.Footer>
                        <SubmitButton onClick={() => this.onSignApproval()}>{"Sign Here"}</SubmitButton>
                        <CancelButton onClick={() => this.props.hideSignModal()}>{"Cancel"}</CancelButton>
                    </Modal.Footer>
                </Modal>
            </ModalContainer>
            
        );
    }
}

const mapStateToProps = state => {

    return {
        language: state.topMenu.language ? state.topMenu.language : "en",
        approvalLevels: state.approvalState.approvalLevels,
        approvalLevel: state.approvalState.approvalLevel,
        approval: state.approvalState.approval,
        signer: state.fetchData.signer,
        currentUser: state.authState.currentUser,
        invalidMsg: state.approvalState.invalidMsg,
    }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
        onSignApproval: (approval, approvalLevels, otp) => dispatch(signApprovalAction(approval, approvalLevels, otp)),
        onSignApprovalLevel: (approvalLevel, approval, otp) => dispatch(signApprovalLevelAction(approvalLevel, approval, otp)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignModal);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

