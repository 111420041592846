import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import base layout
import {BaseLayout} from '../layout/BaseLayout';

import {
  BaseContentContainer,
} from './style';

import {

} from '../../store/actions';
import util from 'util';
const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const _today = new Date();

class FarmDataBase extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
 
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateDimensions);
  }

  
  
  body = () => {
    return (
      <BaseContentContainer>
        {this.props.contentContainer}
      </BaseContentContainer>
    )
  }

  render() {
      return (
      <BaseLayout 
          // header={this.header()}
          selectMenuItem="1"
          mainComponent={this.body()}
          // footer={this.footer()}
      />
      );
  }
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmDataBase);