// import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import {Filter,InfoCircle, People, PersonCircle, PeopleFill, Square, CheckSquare, Plus } from 'react-bootstrap-icons'
// import { Input } from 'semantic-ui-react'
import ReactSearchBox from 'react-search-box'
import styled from 'styled-components';
import leftArrow from '../../assets/images/icons/leftarrow16x16.png';
import userAvatar from '../../assets/images/avatar186x148.png';
import userGroupIcon from '../../assets/images/icons/usergroup22x14.png';

export const Container = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;

export const ContentContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 12px 20px 15px 0px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 10px 15px 0px;
  border: solid 1px #C4C4C4;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
`;

export const FormHeader = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  border-bottom: solid 1px #C4C4C4;
  background-color: #F2F2F2;
  padding: 10px 15px;
  font-size: 24px;
`;

export const InnerContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const Input = styled.input`
  border: solid 1px #B7B7B7;
  height: 40px;
  width: 390px;
  font-size: 14px;
  padding-left: 10px;

  :focus {
    background-color: #FFF6DB;
    box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
  }
`;

export const Label = styled.div.attrs(props => ({
    marginRight: props.marginRight
  }))`
  height: 42px;
  line-height: 42px;
  width: 180px;
  margin-right: ${props => props.marginRight || "10px"};
`;

export const LogoContainer = styled.div`
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoBox = styled.div`
  width: 200px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border: dotted 1px #B7B7B7;
`;

export const Logo = styled.div`
  width: 186px;
  height: 148px;
  background-image: url(${userAvatar});
  background-repeat: no-repeat;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
`;

export const Button = styled.button.attrs(props => ({
  color: props.color,
  backgroundColor: props.backgroundColor,
  marginRight: props.marginRight
  }))`
  border: solid 1px #707070;
  background-color: ${props => props.backgroundColor || "#2680EB"};
  color: ${props => props.color || "#ffffff"};
  margin-right: ${props => props.marginRight || "0px"};
  height: 40px;
  width: 190px;
  font-size: 16px;
  cursor: pointer;
`;

export const CustomLink = styled(Link)`
  margin-top: 15px;
  text-decoration: none;
  color: #0021FB;
`;

export const Select = styled.select`
  border: solid 1px #B7B7B7;
  height: 40px;
  padding: 0px 10px;
  font-size: 14px;
  color: #4B4B4B;
  width: 390px;
`;

export const Option = styled.option`
`;

export const ConfirmBox = styled.div`
  width: 410px;
  height: 130px;
  border: solid 1px #B7B7B7;
  background-color: #FFFFFF;
  padding: 0px 20px 20px 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

export const ButtonGroup = styled.div`
  margin-top: 20px;
  text-align: right;
`;

export const ConfirmationText = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

export const LinkContainer = styled.div`
  font-size: 14px;
  width: 400px;
  text-align: center;
`;

export const FormTitle = styled.div`
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
`;


export const UserGroupIcon = styled.div`
  margin-right: 5px;
  width: 22px;
  height: 14px;
  background-image: url(${userGroupIcon});
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  margin-top: 20px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: solid 1px #A1A1A1;
`;

export const THEAD = styled.thead`
  
`;

export const TBODY = styled.tbody`
  
`;

export const TR = styled.tr`
  border: solid 1px #A1A1A1;
  &:nth-child(odd) {
    background-color: #FFFFFF;
  }
  &:hover {
    background-color:#FFF1C4;
  }
`;

export const TH = styled.th.attrs(props => ({
  width: props.width,
  align: props.align,
  }))`
  padding: 10px;
  font-weight: bold;
  background-color: #E1E1E1;
  text-align: left;
  border-collapse: collapse;
  width: ${props => props.width};
  border-left: solid 1px #A1A1A1;
  text-align: ${props => props.align || "left"};
}
`;

export const TD = styled.td.attrs(props => ({
  paddingLeft: props.paddingLeft,
  align: props.align
  }))`
  text-align: ${props => props.align || "left"};
  padding-top: 6px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: ${props => props.paddingLeft || "10px"};
  border-left: solid 1px #A1A1A1;
`;

export const Checkbox = styled.input.attrs({ 
  type: 'checkbox',
  },)`
  width: 18px;
  height: 18px;
  background-color: #FFFFFF;
`;