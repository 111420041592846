import styled from "styled-components";
import {Link} from "react-router-dom";
import px2vw from "../../utils/px2vw";

export const BeContainer = styled.section`
  background-color: #F2F2F2;
  color: #4B4B4B;
`;


export const ContentContainer = styled.div.attrs(props => ({
  height: props.height
  }))`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  background-color: #F2F2F2;
  color: #4B4B4B;

  min-height: ${props => props.height}px;
`;

export const BodyContainer = styled.section.attrs(props => ({
  height: props.height
  }))`
  min-height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px;
  font-size: 14px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 0px 15px 50px;
`;