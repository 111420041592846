import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

export default class ApprovalNavigation extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      username: 'Ha Pham',
      redirect: false,
      menuItem: props.menuItem,
      border0: '#F2F2F2',
      border1: '#F2F2F2',
      border2: '#F2F2F2',
      border3: '#F2F2F2',
    };
  }

  componentDidMount() {
    if(this.state.menuItem === '0') {
      this.setState({
        border0: '#AF2433'
      });
    }
    if(this.state.menuItem === '1') {
      this.setState({
        border1: '#AF2433'
      });
    }
    if(this.state.menuItem === '2') {
      this.setState({
        border2: '#AF2433'
      });
    }
    if(this.state.menuItem === '3') {
      this.setState({
        border3: '#AF2433'
      });
    }
  }
  
  render() {
    return (
      <Container>
        <Title>Quản lý hóa đơn</Title>
        <MenuItemContainer borderColor={this.state.border0}>
          <CustomLink to='/views/ApprovalManagement'>Tất cả hóa đơn</CustomLink>
        </MenuItemContainer>
        <MenuItemContainer borderColor={this.state.border1}>
          <CustomLink to='/views/ApprovalManagement'>Hóa đơn chưa phát hành</CustomLink>
        </MenuItemContainer>
        <MenuItemContainer borderColor={this.state.border2}>
          <CustomLink to='/views/ApprovalManagement'>Hóa đơn đã phát hành</CustomLink>
        </MenuItemContainer>
        <MenuItemContainer borderColor={this.state.border3}>
          <CustomLink to='/views/ApprovalManagement'>Hóa đơn phát hành lỗi</CustomLink>
        </MenuItemContainer>
      </Container>
    );
  }
}

/*********************Start Stylesheet**********************/
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #303030;
  font-size: 14px;
`;

const MenuItemContainer = styled.div.attrs(props => ({
  borderColor: props.borderColor,
  backgroundColor: props.backgroundColor,
  }))` 
  height: 34px;
  margin: 3px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left-style: solid;
  border-left-color: ${props => props.borderColor};
  border-left-width: 5px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
`;


const CustomLink = styled(Link)`
  text-decoration: none;
  color: #303030;
  margin-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
`;

/*********************End Stylesheet**********************/