export const FETCH_USER = 'fetching_user';
export const VIEW_USER_DETAIL = 'viewUserDetail';
export const ON_CLICK_CREATE_USER = "onClickCreateNewUser";
export const EMAIL_LOGOUT_ACTION_SUCCESS = 'LogoutActionSuccess';
export const FETCH_STRIPE_CARD = 'fetchingTokenForPayment';
export const EMAIL_REGISTER_USER_SUCCESS = "UserUsingEmailPassToRegisterNewUserSuccess";
export const EMAIL_REGISTER_USER_FAIL = "UserUsingEmailPassToRegisterNewUserFail";
export const EMAIL_LOGIN_SUCCESS = "UserLoginByEmailSuccess";
export const EMAIL_LOGIN_FAILED = "UserLoginByEmailFailed";
export const EVENT_LAST_BLOCK_SUCCESS = "fetLastBlockSuccess";
export const EVENT_LAST_BLOCK_FAILED = "fetLastBlockFail";
export const FETCH_COMPANY_SUCCESS = 'fetchCompanySucessAction';
export const FETCH_COMPANY_FAILED = 'fetchCompanyFailedAction';
export const FETCH_ADDRESSES_SUCCESS = 'fetchAddressesSucessAction';
export const FETCH_ADDRESSES_FAILED = 'fetchAddressesFailedAction';
export const FETCH_BANKACCOUNTS_SUCCESS = 'fetchBankAccountsSucessAction';
export const FETCH_BANKACCOUNTS_FAILED = 'fetchBankAccountsFailedAction';
export const FETCH_INVOICETEMPLATES_SUCCESS = 'fetchInvoiceTemplatesSucessAction';
export const FETCH_INVOICETEMPLATES_FAILED = 'fetchInvoiceTemplatesFailedAction';
export const FETCH_INVOICETEMPLATE_SUCCESS = 'fetchInvoiceTemplateSucessAction';
export const FETCH_INVOICETEMPLATE_FAILED = 'fetchInvoiceTemplateFailedAction';
export const FETCH_INVOICE_SUCCESS = 'fetchInvoiceSucessAction';
export const FETCH_INVOICE_FAILED = 'fetchInvoiceFailedAction';
export const FETCH_INVOICES_SUCCESS = 'fetchInvoicesSucessAction';
export const FETCH_INVOICES_FAILED = 'fetchInvoicesFailedAction';
export const FETCH_INVOICE_NUMBER_SUCCESS = 'fetchCurrentInvoiceNumberSuccess';
export const FETCH_INVOICE_NUMBER_FAILED = 'fetchCurrentInvoiceNumberFailed';
export const SELECTED_INVOICE = "userSelectAnInvoiceToView";
export const UPDATE_INVOICE_ACTION = "updatingExistingInvoice";
export const CREATE_INVOICE_ACTION = "createNewInvoice";
export const GET_CREATE_INVOICE = "getAllInfoNeededForCreatingInvoie";
export const COMPANY_ADMIN_REGISTER_FAILED = "companyAdminRegisterFistUserFailed";
export const COMPANY_ADMIN_REGISTER_SUCCESS = "companyAdminRegisterFistUserSuccess";
export const CREATE_BANKACCOUNT_SUCCESS = "createBankAccountSuccess";
export const CREATE_BANKACCOUNT_FAILED = "createBankAccountFailed";
export const UPDATE_BANKACCOUNT_SUCCESS = "updateBankAccountSuccess";
export const UPDATE_BANKACCOUNT_FAILED = "updateBankAccountFailed";
export const CREATE_ADDRESS_SUCCESS = "createAddressSuccess";
export const CREATE_ADDRESS_FAILED = "createAddressFailed";
export const UPDATE_ADDRESS_SUCCESS = "updateAddressSuccess";
export const UPDATE_ADDRESS_FAILED = "updateAddressFailed";
export const CREATE_COMPANY_SUCCESS = "createCompanySuccess";
export const CREATE_COMPANY_FAILED = "createCompanyFailed";
export const UPDATE_COMPANY_SUCCESS = "updateCompaySuccess";
export const UPDATE_COMPANY_FAILED = "updateCompanyFailed";

// user
export const CREATE_USER_SUCCESS = "createUserSuccess";
export const CREATE_USER_FAILED = "createUserFailed";
export const GET_CREATE_USER_SUCCESS = "getCreateNewUserSuccess";
export const GET_CREATE_USER_FAILED = "getCreateNewUserFailed";
export const FETCH_USERS_SUCCESS = "fetchUsersSuccess";
export const FETCH_USERS_FAILED = "fetchUsersFailed";
export const FETCH_USER_SUCCESS = "fetchUserSuccess";
export const FETCH_USER_FAILED = "fetchUserFailed";
export const GET_UPDATE_USER_FAILED = "getUpdateUserGetFaile";
export const GET_UPDATE_USER_SUCCESS = "getUpdateUserGetSuccess";
export const UPDATE_USER_POST_FAILED = "adminUpdateUserSuccess";
export const UPDATE_USER_POST_SUCCESS = "adminUpdateUserFail";
export const UPDATE_PASSWORD_POST_FAILED = "updatePasswordPostFailed";
export const UPDATE_PASSWORD_POST_SUCCESS = "updatePasswordPostSuccess";
export const RESET_PASSWORD_POST_FAILED = "resetPasswordPostFailed";
export const RESET_PASSWORD_POST_SUCCESS = "resetPasswordPostSuccess";
export const NEW_PASSWORD_POST_SUCCESS = "userResetAdnCreateNewPasswordSuccess";
export const NEW_PASSWORD_POST_FAILED = "userResetAdnCreateNewPasswordFailed";
export const UPDATE_PROFILE_POST_SUCCESS = "userSelectToUpdateItsProfilePostSuccess";
export const UPDATE_PROFILE_POST_FAILED = "userSelectToUpdateItsProfilePostFailed";
export const GET_UPDATE_PROFILE_FAILED = "userSelectToUpdateItsProfileGetSuccess";
export const GET_UPDATE_PROFILE_SUCCESS = "userSelectToUpdateItsProfileGetFailed";

// group
export const CREATE_GROUP_SUCCESS = "adminCreateUserGroupForCampnySuccess";
export const CREATE_GROUP_FAILED = "adminCreateUserGroupForCampnyFailed";
export const GET_CREATE_GROUP = "userClickOnCreqteNewGroup";
export const FETCH_GROUPS_SUCCESS = "fetchingAllUserGroupsSuccess";
export const FETCH_GROUPS_FAILED = "fetchingAllUserGroupsFailed";
export const GET_UPDATE_GROUP = "getUpdateGroupForAGivenGroup";
export const UPDATE_GROUP_SUCCESS = "updateGroupSucessfully";
export const UPDATE_GROUP_FAILED = "updateGroupSucessFailed";
export const GET_GROUP_DETAIL_SUCCESS = "getGroupDetailSuccess";
export const GET_GROUP_DETAIL_FAILED = "getGroupDetailFailed";


// export const CREATE_CUSTOMER_FAILED = "createCustomerFailed";
// export const CREATE_CUSTOMER_SUCCESS = "createCustomerSuccess";
export const ADD_NEW_ADDRESS_ACTION = "addNewAddressToReducerAfterCreatingSuccess";
export const CREATE_DOCUMENT_MODAL_ACTION_SUCCESS = "userCreateDocumentUsingModalOnApprovalFormSuccess";
export const CREATE_DOCUMENT_MODAL_ACTION_FAILED = "userCreateDocumentUsingModalOnApprovalFormFailed";
export const ONCLICK_CREATE_DOCUMENT_MODAL_ACTION = "userClickOnAttachDocuemntFromApprovalForm";


export const FETCH_SIGNER_SUCCESS = 'fetchSignerSucessAction';
export const FETCH_SIGNER_FAILED = 'fetchSignerailedAction';
export const FETCH_SIGNERS_SUCCESS = 'fetchSignersSucessAction';
export const FETCH_SIGNERS_FAILED = 'fetchSignersFailedAction';
// Approval Constants
export const FETCH_APPROVALTEMPLATES_SUCCESS = 'fetchApprovalTemplatesSucessAction';
export const FETCH_APPROVALTEMPLATES_FAILED = 'fetchApprovalTemplatesFailedAction';
export const FETCH_APPROVALTEMPLATE_SUCCESS = 'fetchApprovalTemplateSucessAction';
export const FETCH_APPROVALTEMPLATE_FAILED = 'fetchApprovalTemplateFailedAction';
export const FETCH_APPROVAL_SUCCESS = 'fetchApprovalSucessAction';
export const FETCH_APPROVAL_FAILED = 'fetchApprovalFailedAction';
export const FETCH_APPROVALS_SUCCESS = 'fetchApprovalsSucessAction';
export const FETCH_APPROVALS_FAILED = 'fetchApprovalsFailedAction'; 
export const FETCH_APPROVAL_NUMBER_SUCCESS = 'fetchCurrentApprovalNumberSuccess';
export const FETCH_APPROVAL_NUMBER_FAILED = 'fetchCurrentApprovalNumberFailed';
export const SELECTED_APPROVAL_VIEW_DETAIL_SUCCESS = "userSelectAnApprovalToViewSuccess";
export const SELECTED_APPROVAL_VIEW_DETAIL_FAILED = "userSelectAnApprovalToViewFailed";
export const UPDATE_APPROVAL_ACTION = "updatingExistingApproval";
export const CREATE_APPROVAL_ACTION_SUCCESS = "createNewApprovalSuccess";
export const CREATE_APPROVAL_ACTION_FAILED = "createNewApprovalFailed";
export const GET_CREATE_APPROVAL = "getAllInfoNeededForCreatingApproval";
export const SELECT_CREATE_APPROVAL_ACTION = "userClickOnCreateApprovalAction";

export const FETCH_DOCUMENT_SUCCESS = 'fetchDocumentSucessAction';
export const FETCH_DOCUMENT_FAILED = 'fetchDocumentFailedAction';
export const FETCH_DOCUMENTS_SUCCESS = 'fetchDocumentsSucessAction';
export const FETCH_DOCUMENTS_FAILED = 'fetchDocumentsFailedAction';
export const FETCH_DOCUMENT_NUMBER_SUCCESS = 'fetchCurrentDocumentNumberSuccess';
export const FETCH_DOCUMENT_NUMBER_FAILED = 'fetchCurrentDocumentNumberFailed';
export const SELECTED_DOCUMENT = "userSelectAnDocumentToView";
export const ON_CLICK_UPDATE_DOCUMENT = "userClickOnUpdateDocument";
export const UPDATE_DOCUMENT_ACTION = "updatingExistingDocument";
export const CREATE_DOCUMENT_ACTION_SUCCESS = "updateDocumentSuccess";
export const CREATE_DOCUMENT_ACTION_FAILED = "updateDocumentFailed";
export const UPDATE_DOCUMENT_ACTION_SUCCESS = "createNewDocumentSuccess";
export const UPDATE_DOCUMENT_ACTION_FAILED = "createNewDocumentFailed";
export const GET_CREATE_DOCUMENT_SECCUSS = "getAllInfoNeededForCreatingDocumentSuccess";
export const GET_CREATE_DOCUMENT_FAILED = "getAllInfoNeededForCreatingDocumentFailed";
export const SELECT_CREATE_DOCUMENT_ACTION = "userSelectCreateOrUploadDocument";
export const ON_CLICK_UPDATE_APPROVAL_ACTION = "userClickOnUpdateApprovalAction";
export const UPDATE_APPROVAL_ACTION_SUCCESS = "updateApprovalActionSuccess";
export const UPDATE_APPROVAL_ACTION_FAILED = "updateApprovalActionFailed";
export const UPDATE_APPROVAL_LEVEL_ACTION_SUCCESS = "updateApprovalLevelActionSuccess";
export const UPDATE_APPROVAL_LEVEL_ACTION_FAILED = "SignApprovalLevelActionFailed";
export const SIGN_APPROVAL_LEVEL_ACTION_SUCCESS = "SignApprovalLevelActionSuccess";
export const SIGN_APPROVAL_LEVEL_ACTION_FAILED = "updateApprovalLevelActionFailed";
export const ON_CLICK_SIGN_APPROVAL_ACTION = "userClickOnSignApprovalAction";
export const ON_CLICK_REJECT_APPROVAL_ACTION = "userClickOnRejectApprovalAction";
export const ON_CLICK_DELEGATE_APPROVAL_ACTION = "userClickOnDelegateApprovalAction";
export const DELETE_APPROVAL_SUCCESS = "deleteApprovalSuccess";
export const DELETE_APPROVAL_FAILED = "deleteApprovalFailed";
export const ON_SAVE_DRAFT_APPROVAL_SUCCESS = "onSaveDraftCreatingApprovalSuccess";
export const ON_SAVE_DRAFT_APPROVAL_FAILED = "onSaveDraftCreatingApprovalFailed";
export const GENERATE_APPROVAL_CERTFIFICATE_SUCCESS = "onGenerateDynamicCertificateSuccess";
export const GENERATE_APPROVAL_CERTFIFICATE_FAILED = "onGenerateDynamicCertificateFailed";

// language
export const CHANGE_LANGUAGE_ACTION = "userChangeLanguageForTheApp";

// SIGNATURE
export const CREATE_SIGNATURE_SUCCESS = "userCreateSignatureSuccess";
export const CREATE_SIGNATURE_FAILED = "userCreateSignatureFailed";
export const UPDATE_SIGNATURE_SUCCESS = "userUpdateSignatureSuccess";
export const UPDATE_SIGNATURE_FAILED = "userUpdateSignatureFailed";
export const CLICK_CREATE_SIGNATURE = "userClickOnCreateSignature";
export const CLICK_UPDATE_SIGNATURE = "userClickOnUpdateSignature";
export const FETCH_SIGNATURE_SUCCESS = "fetchCompanySignatureSuccess";
export const FETCH_SIGNATURE_FAILED = "fetchCompanySignatureFailed";

// FEEDBACK, PROGRESS, DIAGLO
export const SHOW_PROGRESS_SPINNER = "showProgressSpinner";
export const HIDE_PROGRESS_SPINNER = "hideProgressSpinner";

// farm data
export const FETCH_FARMDATA_SUCCESS = 'fetchFarmDataSucessAction';
export const FETCH_FARMDATA_FAILED = 'fetchFarmDataFailedAction';
export const FETCH_FARMDATAS_SUCCESS = 'fetchFarmDatasSucessAction';
export const FETCH_FARMDATAS_FAILED = 'fetchFarmDatasFailedAction';
export const FETCH_FARMDATA_NUMBER_SUCCESS = 'fetchCurrentFarmDataNumberSuccess';
export const FETCH_FARMDATA_NUMBER_FAILED = 'fetchCurrentFarmDataNumberFailed';
export const SELECTED_FARMDATA = "userSelectAnFarmDataToView";
export const ON_CLICK_UPDATE_FARMDATA = "userClickOnUpdateFarmData";
export const UPDATE_FARMDATA_ACTION = "updatingExistingFarmData";
export const CREATE_FARMDATA_ACTION_SUCCESS = "updateFarmDataSuccess";
export const CREATE_FARMDATA_ACTION_FAILED = "updateFarmDataFailed";
export const UPDATE_FARMDATA_ACTION_SUCCESS = "createNewFarmDataSuccess";
export const UPDATE_FARMDATA_ACTION_FAILED = "createNewFarmDataFailed";
export const GET_CREATE_FARMDATA_SECCUSS = "getAllInfoNeededForCreatingFarmDataSuccess";
export const GET_CREATE_FARMDATA_FAILED = "getAllInfoNeededForCreatingFarmDataFailed";
export const SELECT_CREATE_FARMDATA_ACTION = "userSelectCreateOrUploadFarmData";
export const FETCH_AUTO_GENERATED_FARMDATA_SUCCESS = "userSelectToGenerateFarmDataAutomaticallySucess";
export const FETCH_AUTO_GENERATED_FARMDATA_FAILED = "userSelectToGenerateFarmDataAutomaticallyFailed";
export const FARMDATA_IMAGE_UPLOAD_GENERATE_URL_SUCCESS = "farmerGenerateImageURLSuccess";
export const FARMDATA_IMAGE_UPLOAD_GENERATE_URL_FAIL = "farmerGenerateImageURLFail";
export const GET_GRAIN_IMAGE_VALIDATION_SUCCESS = "fetchGrainImageValidationSuccess";
export const GET_GRAIN_IMAGE_VALIDATION_FAILED = 'fetchGrainImageValidationFailed';
