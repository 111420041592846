import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import {Filter,InfoCircle, People, PersonCircle, PeopleFill, Square, Check2Square, Plus } from 'react-bootstrap-icons'
import styled from 'styled-components'


export const ApprovalBaseContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-right: 15px;
`;

export const SelectTemplateBox = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
`;