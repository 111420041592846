import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { formatDateYYYMMDD } from '../../services/utilities';
import SystemBaseLayout from '../layout/SystemLayout';

import {
  Button, InfoContainer, HeaderContainer, Title, HeaderRight, GreenButton, PlusIcon, InnerContainer, FormContainer, FormBox, Label, 
  TextInfo, LargeTextInfo, ContentContainer
} from './styles'

import {
  selectLanguage, signatureLanguage, companyCreate, formControl,
} from '../../langueges';
import util from 'util';
import {
  createSignature,
  updateSignature,
} from '../../store/actions';

class SignatureDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        height: window.innerHeight - 112,
        user: this.props.currentUser,
        editPermission: false,
        deletePermission: false,
    };
  }

  // updateDimensions = () => {
  //   this.setState({ 
  //     height: window.innerHeight - 112
  //   });
  // };

  componentDidMount() {
    // window.addEventListener('resize', this.updateDimensions);
    // let requestedId = this.state.userId;
    if (!this.props.currentUser) {
      this.props.history.push('/views/login');
    }
    if (!this.props.signature) {
      if (!this.props.currentUser.signature) {
        this.props.history.push('/views/signature/create');
      }
    }
    this.checkEditPermission();
    this.checkDeletePermission();
  }

  updateSignatureHandler = (e) => {
    this.props.history.push('/views/signature/update');
  };



  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  createNotification = (title,message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  addNewSignatureHandler = (e) => {
    this.props.history.push('/views/signature/create');
  }

  checkEditPermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let editPermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('editInfo')) {
        editPermission = true;
      }
      // make sure admin user have the right to access
      if (currentUser.role == 'admin') {
        editPermission = true;
      }
      this.setState({editPermission: editPermission})
    }
  }

  checkDeletePermission = () => {
    let currentUser = this.props.currentUser;
    let _groups = this.props.groups ? this.props.groups : [];
    let deletePermission = false;
    if (currentUser) {
      let _userGroup = currentUser.role;
      let _userRoles = [];
      _groups.forEach((_group, _index) => {
        if (_group.groupName.toString() == _userGroup.toString()) {
          _userRoles = _group.roles;
        }
      })
      console.log('this user roles are: ', _userRoles);
      if (_userRoles.includes('deleteInfo')) {
        deletePermission = true;
      }
      this.setState({deletePermission: deletePermission})
    }
  }

  renderCreateUpdateButton() {
    const editPermission = this.state.editPermission;
    if (editPermission == true) {
      return (
        <Button onClick={(e) => this.updateSignatureHandler(e)}>
          {selectLanguage(this.props.language, signatureLanguage.updateSignature)}
        </Button>
      )
    } else {
      return null;
    }
  }

  // renderDeleteButton() {
  //   const deletePermission = this.state.deletePermission;
  //   if (deletePermission == true) {
  //     return (
  //       <Button onClick={() => this.onDeleteHandler(this.props.customer)} backgroundColor="#F2F2F2" color="#303030" marginRight="10px">
  //         {selectLanguage(this.props.language, customerCreate.delete)}
  //       </Button>
  //     )
  //   } else {
  //     return null;
  //   }
  // }


  body() {
    let signature = this.props.signature;
    if (!signature) {
      if (this.props.currentUser.signature) {
        signature = this.props.currentUser.signature;
      }
    }
    return (
      <InfoContainer>
                    
        <HeaderContainer>
          <Title>{selectLanguage(this.props.language, signatureLanguage.signatureInfo)}</Title>
          <HeaderRight>
            <GreenButton onClick={() => {this.updateSignatureHandler()}}><EditIcon />
            {selectLanguage(this.props.language, signatureLanguage.updateSignature)}</GreenButton>
          </HeaderRight>
        </HeaderContainer>
          
        <InnerContainer>
          <FormContainer>
            <FormBox>
              <Label>{"GUID"}</Label>
              <TextInfo>{signature ? signature._id : ''} </TextInfo>
            </FormBox>
            <FormBox>
              <Label>{selectLanguage(this.props.language, signatureLanguage.lastModified)}</Label>
              <LargeTextInfo>{signature ? formatDateYYYMMDD(signature.lastModified) : ''} </LargeTextInfo>
            </FormBox>
            <FormBox>
              <Label>{selectLanguage(this.props.language, signatureLanguage.effectiveDate)}</Label>
              <LargeTextInfo>{signature ? formatDateYYYMMDD(signature.effectiveDate) : ''} </LargeTextInfo>
            </FormBox>
            {/* <FormBox>
              <Label>{selectLanguage(this.props.language, signatureLanguage.creator)}</Label>
              <LargeTextInfo>{signature ? signature.creator : ''} </LargeTextInfo>
            </FormBox> */}
            <FormBox>
              <Label>{selectLanguage(this.props.language, signatureLanguage.certificate)}</Label>
            </FormBox>

            <LargeTextInfo>{signature ? signature.certificate : ''} </LargeTextInfo>
          </FormContainer>
          
        </InnerContainer>
      </InfoContainer>
    );
  }

  render() {
    return (
      <SystemBaseLayout 
        leftMenuItem={"5"}
        mainComponent={this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('check if signaturees receive from reducer ..:');
  console.log(util.inspect(state.signatureState.signature));
  return {
      currentUser: state.authState.currentUser,
      groups: state.group.groups,
      language: state.topMenu.language,
      signature: state.signatureState.signature,
      company: state.company.company,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateSignature: (_signature, _prevPath) => dispatch(createSignature(_signature, _prevPath)),
    onUpdateSignature: (_signature) => dispatch(updateSignature(_signature)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignatureDetail);

