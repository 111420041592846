import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import FarmDataBase from './FarmDataBase';
// import styles
import {
  Tr, Td, Th, TBody, THead, 
  YellowButton, ContentContainer, RightContainer, HeaderContainer, FarmDataHeader, PlusIcon,
  GreenButton, Table, FarmDataHeadTitle, FarmDataHeadRight, StandardButton
} from './style';

import {
  fetchFarmDatas,
  selectCreateFarmDataAction,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class FarmDataList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.currentUser ? this.props.currentUser : null,
      farmDatas: [],
    };
  }

 
  componentDidMount() {
    // fetch all FarmDatas
    this.props.onFetchFarmDatas();
    // set state for farmDatas
    let farmDatas = this.props.farmDatas ? this.props.farmDatas : [];
    this.setState(prevState => ({
      ...prevState,
      farmDatas: farmDatas,
    }))
  }
  componentWillUnmount() {
    
  }

  // selectedFarmDataHandler = (farmData) => {
  //   console.log('user selected this FarmData: ');
  //   console.log(util.inspect(farmData));
  //   this.props.onSelectedFarmData(farmData);
  // }

  createFarmDataHandler = () => {
    console.log('start action of creating new FarmData..');
    this.props.onSelectCreateFarmData();
  }

  // handleSearchFarmDataFarmData = (farmDataName) => {
  //   console.log('farmDataName selected is: ', farmDataName);
  //   let farmDatas = this.props.farmDatas ? this.props.farmDatas : [];
  //   let _farmDatas = [];
  //   farmDatas.forEach((_row, _index) => {
  //     if (_row.docName.toString() == farmDataName.value.toString()) {
  //       _farmDatas.push(_row);
  //     }
  //   })
  //   this.setState(prevState => ({
  //     ... prevState,
  //     farmDatas: _farmDatas,
  //   }))
  // }

  uploadCsvFileHandler = () => {
    this.props.history.push('/views/farmdata/harvesting/upload');
  }

  streamingFarmDataHandler = () => {
    this.props.history.push('/views/farmdata/harvesting/streaming');
  }

  renderTableData() {

    // issueDate: '2022-03-03',
    // issuer: '5ecfa2ee02ca33324141568c',
    // key: '"5ecfa2ee02ca33324141568c":"1646348967319"',
    // metaData:
    // '{"DateTime":"2022-03-01 15:00:00","TotalArea":20030,"TotalWataer":2000,"GroudSpeed":6,"Nozzle":"Type2","Temp":29.3,"WindSpeed":23,"DewPoit":"Dew Point 1"}',
    // paperNumber: '1646348967319' }


    console.log('list of FarmDatas:');
    console.log(util.inspect(this.props.farmDatas));
    let farmDatas = this.props.farmDatas;

    if (farmDatas.length > 0) {
      return farmDatas.map((farmData, index) => {
          const { number, grainType, harvestGrade, storageLocation, quantity, protein, moisture, productionDate, dataType } = farmData //destructuring
          // let _metaData = JSON.parse(metaData);
          let _item = parseInt(index) + 1;
          if (dataType.toString() == "Manual") {
            return (
              <Tr key={_item}>
                <Td>{number ? number.toString() : ''}</Td>
                <Td>{grainType ? grainType : ''}</Td>
                <Td>{harvestGrade ? harvestGrade : ''}</Td>
                <Td>{storageLocation ? storageLocation : ''}</Td>
                <Td>{quantity ? quantity.toFixed(0) : ''}</Td>
                <Td>{protein ? protein.toFixed(2) : ''}</Td>
                <Td>{moisture ? moisture.toFixed(2) : ''}</Td>
                <Td>{productionDate ? formatDate(productionDate) : ''}</Td>
                
              </Tr>
          )
          }
      })
    } else {
      return (
        <div>loading ..</div>
      )
    }
 }

        // number
        // stage: 'Harvest',
        // grainType: 'Wheat',
        // harvestGrade: 'APH',
        // storageLocation: '',
        // quantity: '',
        // protein: '',
        // moisture: '',
        // uploadUser: '',
        // productionDate: new Date(),
        // modifiedDate: new Date(),

 body = () => {
   return (
    <ContentContainer>
      <FarmDataHeader>
        <FarmDataHeadTitle>Farm Data for Havesting Activities</FarmDataHeadTitle>
        <FarmDataHeadRight>
          <GreenButton marginLeft="5px"
            onClick={() => this.createFarmDataHandler()}>
            {"Upload FarmData"}
          </GreenButton>
          <GreenButton marginLeft="5px"
            onClick={() => this.streamingFarmDataHandler()}>
            {"Streaming FarmData"}
          </GreenButton>
          <GreenButton marginLeft="5px"
            onClick={() => {this.uploadCsvFileHandler()}} 
          >
            <PlusIcon marginTop="8px"> * </PlusIcon>
              {"Upload CSV File"}
          </GreenButton>
        </FarmDataHeadRight>
        
      </FarmDataHeader>
    
      <Table>
        <THead>
          <Tr>
            <Th>Number</Th>
            <Th>Grain Type</Th>
            <Th>Havest Grade</Th>
            <Th>Storage Location</Th>
            <Th>Quantity</Th>
            <Th>Protein</Th>
            <Th>Moisture</Th>
            <Th>Production Date</Th>
          </Tr>
        </THead>
        
        <TBody>
          {this.renderTableData()}
        </TBody>
      </Table>
      <p><i>{"Note: for the Demo, only harvesting data is used as farm data"}</i></p>
  </ContentContainer>
   )
 }
  
  render() {
    return (
      <FarmDataBase 
        contentContainer = {this.body()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log(`current list of FarmDatas are `);
  // console.log(util.inspect(state.fetchData.farmDataNames));
  console.log(`current list of FarmDatas are `);
  console.log(util.inspect(state.farmData.farmDatas));
  return {
      farmDatas: state.farmData.farmDatas,
      // farmDataNames: state.farmData.farmDataNames,
      currentUser: state.authState.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      // onNewEvent: () => dispatch(lastEventAction()),
      onFetchFarmDatas: () => dispatch(fetchFarmDatas()),
      // onSelectedFarmData: (farmData) => dispatch(selectFarmDataAction(farmData)),
      onSelectCreateFarmData: () => dispatch(selectCreateFarmDataAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmDataList);

/*********************Start Stylesheet**********************/
const seachBoxStyle = {
  display: 'flex',
  flexGrow: 1,
}

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}