import React from 'react'
import { connect } from 'react-redux';
import ReactSearchBox from 'react-search-box';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import FarmDataBase from './FarmDataBase';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
// import styles
import {
  Tr, Td, Th, TBody, THead, Title, Label,
  YellowButton, ContentContainer, RightContainer, HeaderContainer, FarmDataHeader,
  GreenButton, Table, PagingContainer, RecordInfo, PButton, PageNumber, SearchBoxContainer,
  InfoContainer, FarmDataFormContainer, DropzoneContainer, BackButton, FarmDataHeadRight, FarmDataHeadTitle,
  FarmDataIcon, FarmDataContainer, GrayBorderButton, Select, Option, DatePickerCustom, CheckSquareIcon
} from './style';

import {
  Input,
} from '../../commons';

import {
  createFarmDataAction,
  getImageUploadUrl,
} from '../../store/actions';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');
class FarmDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.currentUser ? this.props.currentUser : null,
      farmData: {
        _id: '',
        number: (new Date()).getTime().toString(),
        stage: 'Harvest',
        grainType: 'Wheat',
        harvestGrade: 'APH',
        storageLocation: '',
        quantity: '',
        protein: '',
        moisture: '',
        imageValidation: 'NA',
        imageUrl: "localhost",
        uploadUser: '',
        productionDate: new Date(),
        modifiedDate: new Date(),
      },
      grainTypes: ['Categorical', 'Wheat', 'Barley'],
      harvestGrades: ['APH', 'AH', 'APW', 'ASW', 'ADR', 'AMW', 'ASFT', 'APWN', 'FEED'],
      submitingNotification: ""
    };
  }

  componentDidMount() {
  
    // this.props.onGetCreateFarmData();
    // if this is update farmData
    if (this.props.farmData) {
      this.setState(prevState => ({
        ...prevState,
        farmData: this.props.farmData,
      }))
    }
  }
  componentWillUnmount() {
    
  }


  onSubmitCreateFarmData = () => {
    // handle external farmData
    console.log('submit creating farmData ..');
    let {farmData} = this.state;
    farmData.file = this.state.attachFile;
    let user = this.props.currentUser;
    farmData.company = user.company ? user.company._id? user.company._id : user.company : '';
    farmData.uploadUser = user._id;
    farmData.imageUrl = this.props.imageUrl;
    console.log('farmData before sending to action');
    console.log(farmData);
    // return;
    console.log(' show notification ..');
    this.setState(prevState => ({
      ...prevState,
      submitingNotification: "Please wait from 3 to 10 seconds for the blockchain to record this data ..",
    }))
    this.props.onCreateFarmData(farmData);
  }
  
  createFarmDataHandler = (e) => {
    // handle external farmData
    const _data = new FormData();
    _data.append('file', this.state.attachFile);
    console.log('file data: ', util.inspect(_data));
    return;
  }

  onUploadAndValidateImage = () => {
    console.log('generate iamge url..');
    const farmDataNumber = this.state.farmData.number;
    // temporarily disable
    this.props.onGenerateImageUrlLink(farmDataNumber);
  }

  createNotification = (title,message) => {
    toast.success(message, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  renderCheckSign = (_isImageValidate) => {
    if (_isImageValidate) {
      return (
        <Label>{"NA"}</Label>
      )
    } else {
      return (<Label>{"NA"}</Label>)
    }
  }

  renderNotification = () => {
    if (this.state.submitingNotification != "") {
      return (
        <Label>{this.state.submitingNotification}</Label>
      )
    } else {
      return;
    }
  }

  body = () => {
    return (
      <ContentContainer>
        <FarmDataContainer>
          <FarmDataHeader bgColor="#373C44" padding="10px 15px" marginBottom="10px">
            <FarmDataHeadTitle color="#FFFFFF"><FarmDataIcon />{"Upload Farm Data"}</FarmDataHeadTitle>
            <FarmDataHeadRight>
              <GrayBorderButton marginRight="8px" onClick={() => this.onSubmitCreateFarmData()}>Submit</GrayBorderButton>
              <BackButton to='/views/farmData/list'>Back</BackButton>
            </FarmDataHeadRight>
          </FarmDataHeader>

          <FarmDataFormContainer>
            <InfoContainer>
              {this.renderNotification()}
              <Label>{"Type of grain"}</Label>
              <Select value={this.state.farmData.grainType} onChange={(e) => {
                  let {farmData} = this.state;
                  farmData.grainType = e.target.value;
                  this.setState({farmData});
                  
                }}>
                  {this.state.grainTypes.map(grainType => {
                  return (
                    <Option
                      selected={(grainType == this.state.farmData.grainType) 
                      ? true : false
                      }
                      value={grainType}>
                        {grainType}
                    </Option>
                  );
                })}
                </Select>
            </InfoContainer>

            {/* <InfoContainer>
              <Label>{"Havest grade"}</Label>
              <Select value={this.state.farmData.harvestGrade} onChange={(e) => {
                  let {farmData} = this.state;
                  farmData.harvestGrade = e.target.value;
                  this.setState({farmData});
                  
                }}>
                  {this.state.harvestGrades.map(harvestGrade => {
                  return (
                    <Option
                      selected={(harvestGrade == this.state.farmData.harvestGrade) 
                      ? true : false
                      }
                      value={harvestGrade}>
                        {harvestGrade}
                    </Option>
                  );
                })}
                </Select>
            </InfoContainer> */}

            <InfoContainer>
              <Input 
                title={'Storage Location'}
                className={'form-control'}
                placeholder={'Please enter Storage location'}
                value={this.state.farmData.storageLocation} 
                onChange={(e) => {
                  let {farmData} = this.state;
                  farmData.storageLocation = e.target.value;
                  this.setState({farmData});
                }}
              />
            </InfoContainer>

            <InfoContainer>
              <Input 
                title={'Quantity'}
                className={'form-control'}
                placeholder={'Please enter quantity'}
                value={this.state.farmData.quantity } 
                onChange={(e) => {
                  let {farmData} = this.state;
                  farmData.quantity = e.target.value;
                  this.setState({farmData});
                }}
              />
            </InfoContainer>

            <InfoContainer>
              <Input 
                title={'Protein'}
                className={'form-control'}
                placeholder={'Please enter protein'}
                value={this.state.farmData.protein } 
                onChange={(e) => {
                  let {farmData} = this.state;
                  farmData.protein = e.target.value;
                  this.setState({farmData});
                }}
              />
            </InfoContainer>

            <InfoContainer>
              <Input 
                title={'Moisture'}
                className={'form-control'}
                placeholder={'Please enter moisture'}
                value={this.state.farmData.moisture } 
                onChange={(e) => {
                  let {farmData} = this.state;
                  farmData.moisture = e.target.value;
                  this.setState({farmData});
                }}
              />
            </InfoContainer>

            {/* <InfoContainer>
              
              <GrayBorderButton marginRight="8px" onClick={() => this.onUploadAndValidateImage()}>{"Upload Grain image and validate via email"}</GrayBorderButton>
              <Label>{"Image Url: "}{this.props.imageUrl.toString().slice(0, 20)}</Label>
            </InfoContainer> */}

            {/* <InfoContainer>
              <Label>Upload file: {this.state.farmData.uploadUrl}</Label>
              <input type="file" name="file" onChange={this.onChangeUploadFileHandler}/>
            </InfoContainer> */}
            <InfoContainer>
              <Label>{"Production Date"}</Label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.farmData.productionDate}
                onSelect={(_value) => {
                  console.log('selected date: ', _value)
                  let {farmData} = this.state;
                  farmData.productionDate = _value;
                  this.setState({farmData});
                }} //when day is clicked
                onChange={(value) => {
                  console.log('date selected changed: ', value);
                }} //only when value has changed
                customInput={<DatePickerCustom />}
              />

            </InfoContainer>
        </FarmDataFormContainer>

        </FarmDataContainer>
    </ContentContainer>
    )
  }
  
  render() {
    return (
      <FarmDataBase 
        contentContainer = {this.body()}
        onSubmitFarmData={() => this.onSubmitCreateFarmData()}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log(`current list of FarmDataTypes are `);
  console.log(util.inspect(state.farmData.farmDataTypes));
  return {
      farmDatas: state.farmData.farmDatas,
      currentUser: state.authState.currentUser,
      farmData: state.farmData.farmData,
      imageUrl: state.farmData.imageUrl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onCreateFarmData: (farmData) => dispatch(createFarmDataAction(farmData)),
      onGenerateImageUrlLink: (farmDataNumber) => dispatch(getImageUploadUrl(farmDataNumber)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmDataForm);

/*********************Start Stylesheet**********************/


const PlusIcon = styled.span`
  font-size: 24px;
  margin-right: 5px;
`;

