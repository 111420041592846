import { 
    // FETCH_GROUP,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILED,
    FETCH_GROUPS_FAILED,
    FETCH_GROUPS_SUCCESS,
    GET_UPDATE_GROUP,
    GET_CREATE_GROUP,
    UPDATE_GROUP_FAILED,
    UPDATE_GROUP_SUCCESS,
    GET_GROUP_DETAIL_SUCCESS,
    GET_GROUP_DETAIL_FAILED,
} from '../actions/actionTypes';

const INIT_STATE = {
    invalidMsg: null,
    group: null,
    groups: [],
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case GET_GROUP_DETAIL_SUCCESS:
            return {
                ...state,
                group: action.payload.group,
            }
        case GET_GROUP_DETAIL_FAILED:
            return {
                ...state,
            }
        case GET_CREATE_GROUP: 
            return {
                ...state,
                group: null,
            }
        case GET_UPDATE_GROUP:
            return {
                ...state,
                group: action.payload.group,
            }
        case UPDATE_GROUP_SUCCESS: 
            return {
                ...state,
                groups: action.payload.groups,
            }
        case UPDATE_GROUP_FAILED:
            return {
                ...state,
            }
        case FETCH_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload.groups,
            }
        case FETCH_GROUPS_FAILED:
            return {
                ...state,
            }
        case CREATE_GROUP_SUCCESS:
            return {
                ...state,
                groups: action.payload.groups,
            }
        case CREATE_GROUP_FAILED:
            return {
                ...state,
                invalidMsg: action.payload.invalidMsg,
                group: action.payload.group,
            }
        default:
            return state;
    }
}