import {Link} from 'react-router-dom';
import styled from 'styled-components'

import BContainer from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {List, PersonCircle, House, Person, PersonFill} from 'react-bootstrap-icons';
import {Dropdown, DropdownMenu, DropdownToggle} from 'react-bootstrap';

import BeLogo from '../../assets/images/Be_Sign_90x43.png';

const responsiveSize = "768px";

export const Container = styled.section`
  background-color: #FFFFFF;
  color: #0059C2;
  font-size: 14px;
`;

export const BootContainer = styled(BContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Logo = styled.div`
  width: 90px;
  height: 43px;
  margin-right: 80px;
  background-image: url(${BeLogo});
  background-repeat: no-repeat;

  @media (max-width: ${responsiveSize}) {
    margin-right: 0px;
  }
`;

export const MenuItemContainer = styled.div.attrs(props => ({
  borderColor: props.borderColor,
  }))` 
  height: 50px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.borderColor};
  border-bottom-width: 4px;

  @media (max-width: ${responsiveSize}) {
    display: none;
  }
`;

export const HomeIcon = styled(House)` 
  width: 20px;
  height: 20px;
`;

export const UserProfileContainer = styled.div` 
  margin-left: auto;
  @media (max-width: ${responsiveSize}) {
    
  }
`;

export const CustomLink = styled(Link).attrs(props => ({
  textColor: props.textColor,
  }))`
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.textColor || "#505050"};

  @media (max-width: ${responsiveSize}) {
    height: 60px;
  }
`;

export const ListIcon = styled(List)`
  width: 36px;
  height: 36px;
  color: #0059C2;
`;

export const UserIcon = styled(PersonCircle)`
  width: 24px;
  height: 24px;
  color: #6B6B6B;
`;

export const Dropdown1 = styled(Dropdown)`
  @media (max-width: ${responsiveSize}) {
    display: none;  
  }
`;

export const Dropdown2 = styled(Dropdown)`
  display: none;
  @media (max-width: ${responsiveSize}) {
    display: block;
  }
`;

export const DefaultToggle = styled(Dropdown.Toggle)`
  background-color: #FFFFFF;
  border-color: #D8D8D8;
  height: 38px;
`;

export const CustomToggle = styled(Dropdown.Toggle)`
  @media (max-width: ${responsiveSize}) {
    border-radius: 0px;
    padding: 0px;
    border-width: 0px;
    :after {
      display: none;
    }
  }
`;

export const UserMenu = {
  fontSize: '14px'

}