import styled from 'styled-components';
import { Link } from 'react-router-dom';
import leftArrow from '../../assets/images/icons/leftarrow16x16.png';
import searchIcon from '../../assets/images/icons/search22x22.png';
import ReactSearchBox from 'react-search-box';


export const Container = styled.section`
background-color: #F2F2F2;
color: #4B4B4B;
`;

export const ContentContainer = styled.section.attrs(props => ({
height: props.height
}))`
min-height: ${props => props.height}px;
display: flex;
flex-direction: row;
justify-content: flex-start;
padding: 0px 20px;
font-size: 14px;
`;

export const TextInfo = styled.div`
height: 42px;
line-height: 42px;
width: 400px;
`;

export const LargeTextInfo = styled.div`
line-height: 42px;
width: 400px;
`;

export const NavigationContainer = styled.div`
display: flex;
flex-direction: column;
width: 200px;
margin: 12px 20px 15px 0px;
`;

export const InfoContainer = styled.div`
display: flex;
flex: 1;
flex-direction: column;
margin: 12px 0px 15px 0px;
border: solid 1px #C4C4C4;
background-color: #ffffff;
box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
`;

export const FormHeader = styled.div`
border-bottom: solid 1px #C4C4C4;
background-color: #F2F2F2;
padding: 5px 15px;
display: flex;
flex-direction: row;
align-items: center;
`;

export const Title = styled.div`
font-size: 24px;
`;

export const BackButton = styled(Link)`
text-decoration: none;
border: solid 1px #707070;
color: #303030;
height: 36px;
margin-left: auto;
display: flex;
flex-direction: row;
align-items: center;
padding: 0px 15px;
`;

export const LeftArrow = styled.div`
width: 16px;
height: 16px;
background-image: url(${leftArrow});
background-repeat: no-repeat;
margin-right: 10px;
`;

export const InnerContainer = styled.div`
padding: 20px 60px;
display: flex;
flex-direction: row;
`;

export const FormContainer = styled.div`
display: flex;
flex-direction: column;
`;

export const FormBox = styled.div`
display: flex;
flex-direction: row;
margin-top: 10px;
`;

export const Input = styled.input`
border: solid 1px #B7B7B7;
height: 40px;
width: 390px;
font-size: 14px;
padding-left: 10px;

:focus {
  background-color: #FFF6DB;
  box-shadow:0 3px 8px 0 rgba(123, 193, 255, 0.7);
}
`;

export const Label = styled.div`
height: 42px;
line-height: 42px;
width: 160px;
`;


export const ButtonContainer = styled.div`
margin: 20px 0px 20px 160px;
display: flex;
flex-direction: row;
`;

export const Button = styled.button`
border: solid 1px #707070;
background-color: #2680EB;
color: #ffffff;
height: 40px;
width: 390px;
font-size: 16px;
margin: 20px 0px 20px 160px;
cursor: pointer;
`;

export const CustomLink = styled(Link)`
margin-top: 15px;
text-decoration: none;
color: #0021FB;
`;

export const Select = styled.select`
border: solid 1px #B7B7B7;
height: 44px;
padding: 0px 10px;
font-size: 14px;
color: #4B4B4B;
width: 410px;
`;

export const Option = styled.option`
`;

export const ConfirmBox = styled.div`
width: 410px;
height: 130px;
border: solid 1px #B7B7B7;
background-color: #FFFFFF;
padding: 0px 20px 20px 20px;
box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

`;

export const HeaderContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
border-bottom: solid 1px #C4C4C4;
background-color: #F2F2F2;
padding: 4px 15px;
`;

export const HeaderRight = styled.div`
margin-left: auto;
display: flex;
flex-direction: row;
`;

export const GreenButton = styled.button`
border: solid 1px #707070;
background-color: #17C177;
color: #ffffff;
height: 38px;
padding: 0px 10px;
font-size: 14px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
`;

export const SearchContainer = styled.div`
border: solid 1px #B7B7B7;
background-color: #ffffff;
height: 40px;
width: 529px;
display: flex;
flex-direction: row;
align-items: center;
margin-right: 10px;
`;

export const SearchInput = styled.input`
width: 490px;
height: 38px;
border-width: 0px;
padding-left: 10px;
font-size: 14px;

:focus {
  outline: none;
}
`;

export const SearchIcon = styled.button`
border-width: 0px;
height: 22px;
width: 22px;
background-color: #FFFFFF;
background-image: url(${searchIcon});
background-repeat: no-repeat;
cursor: pointer;
`;

export const PlusIcon = styled.span`
font-size: 24px;
margin-right: 5px;
`;
