import axios from 'axios';
import { push } from 'react-router-redux';
import { history } from '../../App';

import {
    SELECTED_DOCUMENT,
    UPDATE_DOCUMENT_ACTION_SUCCESS,
    UPDATE_DOCUMENT_ACTION_FAILED,
    CREATE_DOCUMENT_ACTION_SUCCESS,
    CREATE_DOCUMENT_ACTION_FAILED,
    CREATE_DOCUMENT_ACTION,
    GET_CREATE_DOCUMENT_SECCUSS,
    GET_CREATE_DOCUMENT_FAILED,
    SELECT_CREATE_DOCUMENT_ACTION,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_FAILED,
    ON_CLICK_UPDATE_DOCUMENT,
} from './actionTypes';

const prodKeys = require('../../config/prodKeys');
const url = prodKeys.apiUrl;
const util = require('util');

// const url = process.env.NODE_ENV === 'production' ? "/api/" : "http://localhost:8080/api/";

export const selectDocumentAction = (selectedDocument) => {
    console.log('selected inoice received on actions:');
    console.log(util.inspect(selectedDocument));
    // temporarily use crafted data
    // const {documentDetail} = require('../../data/documents');
    // console.log('document detail imported: ');
    // console.log(util.inspect(documentDetail));
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: SELECTED_DOCUMENT,
            payload: {
                selectedDocument,      //data is thr user's data
                page: 'detail',
                // documentDetail: documentDetail
            }
        });
        history.push(`/views/document/detail`);
    };
};

export const selectViewDocumentAction = (selectedDocument) => {
    console.log('selected inoice received on actions:');
    console.log(util.inspect(selectedDocument));
    // temporarily use crafted data
    // const {documentDetail} = require('../../data/documents');
    // console.log('document detail imported: ');
    // console.log(util.inspect(documentDetail));
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: SELECTED_DOCUMENT,
            payload: {
                selectedDocument,      //data is thr user's data
                page: 'detail',
                // documentDetail: documentDetail
            }
        });
        // history.push(`/views/document/detail`);
    };
}

export const onClickUpdateDocument = (_document) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: ON_CLICK_UPDATE_DOCUMENT,
            payload: {
                selectedDocument: _document,      //data is thr user's data
                page: 'update',
            }
        });
        history.push(`/views/document/update`);
    };
}
// action hanling for downloading a file
export const downloadDocument = (_document) => {

    return (dispatch) => {
        const method = 'GET';
        axios
            .request({
                url: `${url}company/document/${_document._id}`,
                method,
                responseType: 'blob', //importan
            })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;

                link.setAttribute('download', `${_document.uploadUrl}`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
  
    };
}

export const updateDocumentAction = (updatingDocument) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        dispatch({
            type: UPDATE_DOCUMENT_ACTION_SUCCESS,
            payload: {
                document: updatingDocument,      //data is thr user's data
                page: 'update'
            }
        });
        history.push(`/views/document/update`);
    };
}

export const SelectCreateDocumentAction = () => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('use select create document ..');
        dispatch({
            type: SELECT_CREATE_DOCUMENT_ACTION,
            payload: {
                document: null,      //data is thr user's data
                page: 'create',
            }
        });
        history.push('/views/document/create');
    };
}

export const createDocumentAction = (document, isModal) => {
    return async (dispatch) => {
        // make sure proxy for '/api/current_user' was configued on json file
        console.log('create document ..');
        const {file, docName, docType, uploadUser, uploadUrl, description, company} = document;
        console.log('file before sending to server: ', file);
        const data = new FormData();
        data.append('file', file);
        data.append('docName', docName);
        data.append('docType', docType);
        data.append('uploadUser', uploadUser);
        data.append('uploadUrl', uploadUrl);
        data.append('description', description);
        data.append('company', company);
        // console.log('data of file before sending to server: ');
        // console.log(util.inspect(data));
        // console.log(util.inspect(data.file));
        // console.log(util.inspect(data.docName));

        // return;
        const res = await axios.post(`${url}company/document/create`, data, {'Content-Type': 'multipart/form-data',});
        
        if (res.data.documents) {
            var _docNames = [];
            const _documents = res.data.documents;
            _documents.forEach((_row, _index) => {

                let keyValue = {
                    key: _row.docId,
                    value: _row.docName,
                }
                _docNames.push(keyValue);
            
            })
            dispatch({
                type: CREATE_DOCUMENT_ACTION_SUCCESS,
                payload: {
                    documents: res.data.documents,
                    documentNames: _docNames,
                    hideDocumentModal: true,
                }
            });
            if (!isModal) {
                history.push('/views/document/list');
            }
            
        } else {
            dispatch({
                type: CREATE_DOCUMENT_ACTION_FAILED,
                payload: {
                    document: document,      //data is thr user's data
                    page: 'create',
                }
            });
            history.push('/views/document/create');
        }
        
    };
}

export const getCreateDocument = () => {
    return async (dispatch) => {
        const res = await axios.get(`${url}company/document/create`);
        if (res.data.documentTypes) {
            console.log('res data received through service fetchData:');
            console.log(util.inspect(res));
            dispatch({
                type: GET_CREATE_DOCUMENT_SECCUSS,
                payload: {
                    documentTypes: res.data.documentTypes
                }
            })
        }
        else {
            // use crafted data
            const {documentTypes} = require('../../data/documents');
            console.log('imported document types: ');
            console.log(util.inspect(documentTypes));
            dispatch({
                type: GET_CREATE_DOCUMENT_FAILED,
                payload: {
                    documentTypes: documentTypes,
                }
            })
        }
    }
}


export const fetchDocuments = () => {
    const {sampleDocs} = require('../../data/documents');
    var docNames = [];
    sampleDocs.forEach((_row, _index) => {

        let keyValue = {
            key: _row.docId,
            value: _row.docName,
        }
        docNames.push(keyValue);
    
    })
    return async (dispatch) => {
        const res = await axios.get(`${url}company/documentes`)
        console.log('res data received through service fetchData:');
        console.log(util.inspect(res.data));
        if (res.data.documents) {
            var _docNames = [];
            const _documents = res.data.documents;
            _documents.forEach((_row, _index) => {

                let keyValue = {
                    key: _row.docId,
                    value: _row.docName,
                }
                _docNames.push(keyValue);
            
            })
            dispatch({
                type: FETCH_DOCUMENTS_SUCCESS,
                payload: {
                    documents: res.data.documents,
                    documentNames: _docNames,
                },
            });
        } else {
            dispatch({
                type: FETCH_DOCUMENTS_FAILED,
                payload: {
                    documents: sampleDocs,
                    documentNames: docNames
                },
            });
        }
    }
}