import { 
    FETCH_USER,
    EMAIL_REGISTER_USER_SUCCESS,
    EMAIL_REGISTER_USER_FAIL,
    EMAIL_LOGIN_FAILED,
    EMAIL_LOGIN_SUCCESS,
    EMAIL_LOGOUT_ACTION_SUCCESS,
    COMPANY_ADMIN_REGISTER_FAILED,
    COMPANY_ADMIN_REGISTER_SUCCESS,
} from '../actions/actionTypes';

const INIT_STATE = {
    currentUser: null,
};

export default (state=INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case COMPANY_ADMIN_REGISTER_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
            };
        case COMPANY_ADMIN_REGISTER_FAILED:
            return {
                currentUser: null,
            }
        case FETCH_USER:
            return {
                ...state,
                currentUser: action.payload || false,
            };
        case EMAIL_REGISTER_USER_SUCCESS:
            return {
                ...state,
                currentUser: action.currentUser,
            }
        case EMAIL_REGISTER_USER_FAIL:
            return state;
        case EMAIL_LOGIN_FAILED:
            return state;
        case EMAIL_LOGIN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload.currentUser,
            }
        case EMAIL_LOGOUT_ACTION_SUCCESS:
            return {
                currentUser: null,
            }
        default:
            return state;
    }
}
