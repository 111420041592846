import React from 'react'

import {
  BeContainer, ContentContainer, FooterComponent, FacebookIcon, GoogleIcon, TwitterIcon, RowContainer, BeLogo, FooterText, FooterTitle
} from './style';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirect: false
    };
  }
  
  render() {
    return (
      <BeContainer>
        <ContentContainer>
          <RowContainer>
            <FooterComponent sm>
              <BeLogo />
              <FooterText>Email: contact@grainchain.com</FooterText>
              <FooterText>Phone: (61) xxxx xxxx</FooterText>
              <FooterText>Address: Federation University</FooterText>
            </FooterComponent>

            <FooterComponent sm>
              <FooterTitle>Support</FooterTitle>
              <FooterText>Tutorials</FooterText>
              <FooterText>Request a Demo</FooterText>
              <FooterText>User Manual</FooterText>
            </FooterComponent>

            <FooterComponent sm>
              <FooterTitle>Follow Us</FooterTitle>
              <FooterText>Facebook</FooterText>
              <FooterText>Twitter</FooterText>
              <FooterText>Grainchain Blogs</FooterText>
            </FooterComponent>
          </RowContainer>
        </ContentContainer>
      </BeContainer>
    );
  }
}